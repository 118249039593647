import { MandatorViewModel } from '../../shared/apis/advis';
import { MandatorOverviewVsModel } from '../../shared/models/view-setting/user-overview-vs.model';

export interface IMandatorState {
  mandators: MandatorViewModel[];
  mandatorsLoading: boolean;
  mandatorsLoaded: boolean;
  mandatorOverviewViewSettings: MandatorOverviewVsModel;
  mandatorOVerviewViewSettingsLoaded: boolean;
}

export function initMandatorState(): IMandatorState {
  return {
    mandators: [],
    mandatorsLoading: false,
    mandatorsLoaded: false,
    mandatorOverviewViewSettings: new MandatorOverviewVsModel(),
    mandatorOVerviewViewSettingsLoaded: false,
  };
}
