import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeactivationGuard } from '../shared/services/deactivation.guard';
import { MandatorMainComponent } from './components/mandator-main/mandator-main.component';
import { MandatorOverviewComponent } from './components/mandator-main/mandator-overview/mandator-overview.component';
import { MandatorFormComponent } from './components/mandator-main/mandator-edit/mandator-form.component';
import { OrganisationUnitOverviewComponent } from './components/organisation-unit/organisation-unit-overview/organisation-unit-overview.component';
import { OrganisationUnitFormComponent } from './components/organisation-unit/organisation-unit-form/organisation-unit-form.component';
import { RoleGuard } from '../shared/guards/role.guard';

const routes: Routes = [
  {
    path: '',
    component: MandatorMainComponent,
    children: [
      {
        path: '',
        redirectTo: 'mandator/overview',
        pathMatch: 'full',
      },
      {
        path: 'mandator/overview',
        component: MandatorOverviewComponent,
        canActivate: [RoleGuard],
        data: ['CanManageMandators'],
      },
      {
        path: 'mandator/add',
        component: MandatorFormComponent,
        canDeactivate: [DeactivationGuard],
        canActivate: [RoleGuard],
        data: ['CanManageMandators'],
      },
      {
        path: 'mandator/edit/:id',
        component: MandatorFormComponent,
        canDeactivate: [DeactivationGuard],
        canActivate: [RoleGuard],
        data: ['CanManageMandators'],
      },
      {
        path: 'organisation-unit/overview',
        component: OrganisationUnitOverviewComponent,
        canActivate: [RoleGuard],
        data: ['CanManageOrgUnits'],
      },
      {
        path: 'organisation-unit/add',
        component: OrganisationUnitFormComponent,
        canDeactivate: [DeactivationGuard],
        canActivate: [RoleGuard],
        data: ['CanManageOrgUnits'],
      },
      {
        path: 'organisation-unit/edit/:mandatorId/:id',
        component: OrganisationUnitFormComponent,
        canDeactivate: [DeactivationGuard],
        canActivate: [RoleGuard],
        data: ['CanManageOrgUnits'],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MandatorRoutingModule {}
