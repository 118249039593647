import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const keepAliveEndpointsPatterns: RegExp[] = [
  /.+\/api\/admin\/mandators\/[0-9]+\/trade-types\/[a-zA-Z]+\/data/,
];

@Injectable()
export class KeepAliveInterceptor implements HttpInterceptor {
  constructor() {
    // empty
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (keepAliveEndpointsPatterns.some(pattern => pattern.test(req.url))) {
      const modifiedReq = req.clone({
        headers: req.headers.append('Connection', 'keep-alive'),
      });
      return next.handle(modifiedReq);
    }
    return next.handle(req);
  }
}
