/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ValidationRuleListViewModel { 
    /**
     * Identification of the rule
     */
    Id?: number;
    /**
     * Mandator ids of the rule
     */
    MandatorIds?: Array<number>;
    RuleLevel?: ValidationRuleListViewModel.RuleLevelEnum;
    /**
     * Description of the rule
     */
    Description?: string;
}
export namespace ValidationRuleListViewModel {
    export type RuleLevelEnum = 'Information' | 'Warning';
    export const RuleLevelEnum = {
        Information: 'Information' as RuleLevelEnum,
        Warning: 'Warning' as RuleLevelEnum
    };
}
