/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PostPutProjectChecklistTemplateRequest } from '../model/postPutProjectChecklistTemplateRequest';
import { ProjectChecklistTemplateListViewModelPageResultViewModel } from '../model/projectChecklistTemplateListViewModelPageResultViewModel';
import { ProjectChecklistTemplateViewModel } from '../model/projectChecklistTemplateViewModel';
import { ProjectChecklistTemplatesRequest } from '../model/projectChecklistTemplatesRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProjectChecklistTemplateService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Copies a project checklist template
     * 
     * @param templateId the id of the project checklist template to copy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistTemplateCopyTemplate(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<ProjectChecklistTemplateViewModel>;
    public projectChecklistTemplateCopyTemplate(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectChecklistTemplateViewModel>>;
    public projectChecklistTemplateCopyTemplate(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectChecklistTemplateViewModel>>;
    public projectChecklistTemplateCopyTemplate(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling projectChecklistTemplateCopyTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<ProjectChecklistTemplateViewModel>(`${this.basePath}/api/admin/projectchecklisttemplates/${encodeURIComponent(String(templateId))}/copy`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new template from an excel
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistTemplateCreateTemplateFromExcel(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public projectChecklistTemplateCreateTemplateFromExcel(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public projectChecklistTemplateCreateTemplateFromExcel(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public projectChecklistTemplateCreateTemplateFromExcel(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling projectChecklistTemplateCreateTemplateFromExcel.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/projectchecklisttemplates/excel-import`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a project checklist template
     * 
     * @param templateId the id of the project checklist template to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistTemplateDeleteTemplate(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public projectChecklistTemplateDeleteTemplate(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public projectChecklistTemplateDeleteTemplate(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public projectChecklistTemplateDeleteTemplate(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling projectChecklistTemplateDeleteTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/projectchecklisttemplates/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Exports a project checklist template in xlsx format to download
     * 
     * @param templateId the id of the template to export
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistTemplateExportTemplateAsExcel(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public projectChecklistTemplateExportTemplateAsExcel(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public projectChecklistTemplateExportTemplateAsExcel(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public projectChecklistTemplateExportTemplateAsExcel(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling projectChecklistTemplateExportTemplateAsExcel.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/admin/projectchecklisttemplates/${encodeURIComponent(String(templateId))}/excel-export`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific template by id
     * 
     * @param templateId the id of the project checklist template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistTemplateGetTemplate(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<ProjectChecklistTemplateViewModel>;
    public projectChecklistTemplateGetTemplate(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectChecklistTemplateViewModel>>;
    public projectChecklistTemplateGetTemplate(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectChecklistTemplateViewModel>>;
    public projectChecklistTemplateGetTemplate(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling projectChecklistTemplateGetTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProjectChecklistTemplateViewModel>(`${this.basePath}/api/admin/projectchecklisttemplates/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets filtered project checklist templates
     * 
     * @param Title 
     * @param MandatorIds &gt;only retrieve entries with a matching mandator id (you can provide multiple mandators by separating them with a &#39;,&#39;
     * @param TradeTypeCodes only retrieve entries with a matching trade type code (you can provide multiple codes by separating them with a &#39;,&#39;)
     * @param PageIndex the index of the current page(starts at 1)
     * @param PageSize the amount of entries per page (15 per default)
     * @param SortField the field used for sorting (Title per default)
     * @param SortOrder the sorting order (desc per default)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistTemplateGetTemplates(Title?: string, MandatorIds?: string, TradeTypeCodes?: string, PageIndex?: number, PageSize?: number, SortField?: 'None' | 'Id' | 'Title' | 'TradeTypeCode', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'body', reportProgress?: boolean): Observable<ProjectChecklistTemplateListViewModelPageResultViewModel>;
    public projectChecklistTemplateGetTemplates(Title?: string, MandatorIds?: string, TradeTypeCodes?: string, PageIndex?: number, PageSize?: number, SortField?: 'None' | 'Id' | 'Title' | 'TradeTypeCode', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectChecklistTemplateListViewModelPageResultViewModel>>;
    public projectChecklistTemplateGetTemplates(Title?: string, MandatorIds?: string, TradeTypeCodes?: string, PageIndex?: number, PageSize?: number, SortField?: 'None' | 'Id' | 'Title' | 'TradeTypeCode', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectChecklistTemplateListViewModelPageResultViewModel>>;
    public projectChecklistTemplateGetTemplates(Title?: string, MandatorIds?: string, TradeTypeCodes?: string, PageIndex?: number, PageSize?: number, SortField?: 'None' | 'Id' | 'Title' | 'TradeTypeCode', SortOrder?: 'None' | 'Asc' | 'Desc', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Title !== undefined && Title !== null) {
            queryParameters = queryParameters.set('Title', <any>Title);
        }
        if (MandatorIds !== undefined && MandatorIds !== null) {
            queryParameters = queryParameters.set('MandatorIds', <any>MandatorIds);
        }
        if (TradeTypeCodes !== undefined && TradeTypeCodes !== null) {
            queryParameters = queryParameters.set('TradeTypeCodes', <any>TradeTypeCodes);
        }
        if (PageIndex !== undefined && PageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>PageIndex);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (SortField !== undefined && SortField !== null) {
            queryParameters = queryParameters.set('SortField', <any>SortField);
        }
        if (SortOrder !== undefined && SortOrder !== null) {
            queryParameters = queryParameters.set('SortOrder', <any>SortOrder);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProjectChecklistTemplateListViewModelPageResultViewModel>(`${this.basePath}/api/admin/projectchecklisttemplates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Imports an excel file to an existing template
     * 
     * @param templateId the id of the template to import the checklist to
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistTemplateImportTemplateAsExcel(templateId: number, file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public projectChecklistTemplateImportTemplateAsExcel(templateId: number, file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public projectChecklistTemplateImportTemplateAsExcel(templateId: number, file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public projectChecklistTemplateImportTemplateAsExcel(templateId: number, file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling projectChecklistTemplateImportTemplateAsExcel.');
        }

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling projectChecklistTemplateImportTemplateAsExcel.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/projectchecklisttemplates/${encodeURIComponent(String(templateId))}/excel-import`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistTemplatePostProjectChecklistTemplatesRequestMock(body?: ProjectChecklistTemplatesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public projectChecklistTemplatePostProjectChecklistTemplatesRequestMock(body?: ProjectChecklistTemplatesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public projectChecklistTemplatePostProjectChecklistTemplatesRequestMock(body?: ProjectChecklistTemplatesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public projectChecklistTemplatePostProjectChecklistTemplatesRequestMock(body?: ProjectChecklistTemplatesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/projectchecklisttemplates/projectChecklistTemplatesRequestmock`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a project checklist template
     * 
     * @param body the new project checklist template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistTemplatePostTemplate(body?: PostPutProjectChecklistTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<ProjectChecklistTemplateViewModel>;
    public projectChecklistTemplatePostTemplate(body?: PostPutProjectChecklistTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectChecklistTemplateViewModel>>;
    public projectChecklistTemplatePostTemplate(body?: PostPutProjectChecklistTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectChecklistTemplateViewModel>>;
    public projectChecklistTemplatePostTemplate(body?: PostPutProjectChecklistTemplateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProjectChecklistTemplateViewModel>(`${this.basePath}/api/admin/projectchecklisttemplates`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a project checklist template
     * 
     * @param templateId the id of the project checklist template to update
     * @param body the project checklist template data to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistTemplatePutTemplate(templateId: number, body?: PostPutProjectChecklistTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<ProjectChecklistTemplateViewModel>;
    public projectChecklistTemplatePutTemplate(templateId: number, body?: PostPutProjectChecklistTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectChecklistTemplateViewModel>>;
    public projectChecklistTemplatePutTemplate(templateId: number, body?: PostPutProjectChecklistTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectChecklistTemplateViewModel>>;
    public projectChecklistTemplatePutTemplate(templateId: number, body?: PostPutProjectChecklistTemplateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling projectChecklistTemplatePutTemplate.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ProjectChecklistTemplateViewModel>(`${this.basePath}/api/admin/projectchecklisttemplates/${encodeURIComponent(String(templateId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
