/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PageResultViewModelPositionDependencyRuleListViewModel } from '../model/pageResultViewModelPositionDependencyRuleListViewModel';
import { PositionDependencyRuleRequest } from '../model/positionDependencyRuleRequest';
import { PositionDependencyRuleViewModel } from '../model/positionDependencyRuleViewModel';
import { PostPutPositionDependencyRuleRequest } from '../model/postPutPositionDependencyRuleRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PositionDependencyRuleService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletes a position dependency rule
     * 
     * @param tradeTypeCode trade type code of the dependency rule
     * @param dependencyRuleId id of the dependency rule to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionDependencyRuleDeletePositionDependencyRule(tradeTypeCode: string, dependencyRuleId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public positionDependencyRuleDeletePositionDependencyRule(tradeTypeCode: string, dependencyRuleId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public positionDependencyRuleDeletePositionDependencyRule(tradeTypeCode: string, dependencyRuleId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public positionDependencyRuleDeletePositionDependencyRule(tradeTypeCode: string, dependencyRuleId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionDependencyRuleDeletePositionDependencyRule.');
        }

        if (dependencyRuleId === null || dependencyRuleId === undefined) {
            throw new Error('Required parameter dependencyRuleId was null or undefined when calling positionDependencyRuleDeletePositionDependencyRule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/dependencyrules/${encodeURIComponent(String(dependencyRuleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of paged and sorted and filtered position dependency rules  the mandators are restricted to mandators the logged in user is responsible for.  The dependency rules are limited to the specific trade type
     * 
     * @param tradeTypeCode trade type code of the dependency rules
     * @param SourceArticleNumber source article number of this dependency rule, substring search works
     * @param TargetArticleNumber target article number of this dependency rule, substring search works
     * @param Note the note of this dependency rule, substring search works
     * @param MandatorIds only retrieve entries with a matching mandator id (you can provide multiple mandators by separating them with a &#39;,&#39; or &#39;;&#39;)
     * @param ProductlineIds only retrieve entries with a matching productline ids (you can provide multiple productline ids by separating them with a &#39;,&#39; or &#39;;&#39;)
     * @param PageIndex the index of the current page(starts at 1)
     * @param PageSize the amount of entries per page
     * @param SortField the field used to sort
     * @param SortOrder the direction of sorting
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionDependencyRuleGetAllPositionDependencyRules(tradeTypeCode: string, SourceArticleNumber?: string, TargetArticleNumber?: string, Note?: string, MandatorIds?: string, ProductlineIds?: string, PageIndex?: number, PageSize?: number, SortField?: 'ProductlineId' | 'SourceArticleNumber' | 'TargetArticleNumber' | 'Note', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'body', reportProgress?: boolean): Observable<PageResultViewModelPositionDependencyRuleListViewModel>;
    public positionDependencyRuleGetAllPositionDependencyRules(tradeTypeCode: string, SourceArticleNumber?: string, TargetArticleNumber?: string, Note?: string, MandatorIds?: string, ProductlineIds?: string, PageIndex?: number, PageSize?: number, SortField?: 'ProductlineId' | 'SourceArticleNumber' | 'TargetArticleNumber' | 'Note', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageResultViewModelPositionDependencyRuleListViewModel>>;
    public positionDependencyRuleGetAllPositionDependencyRules(tradeTypeCode: string, SourceArticleNumber?: string, TargetArticleNumber?: string, Note?: string, MandatorIds?: string, ProductlineIds?: string, PageIndex?: number, PageSize?: number, SortField?: 'ProductlineId' | 'SourceArticleNumber' | 'TargetArticleNumber' | 'Note', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageResultViewModelPositionDependencyRuleListViewModel>>;
    public positionDependencyRuleGetAllPositionDependencyRules(tradeTypeCode: string, SourceArticleNumber?: string, TargetArticleNumber?: string, Note?: string, MandatorIds?: string, ProductlineIds?: string, PageIndex?: number, PageSize?: number, SortField?: 'ProductlineId' | 'SourceArticleNumber' | 'TargetArticleNumber' | 'Note', SortOrder?: 'None' | 'Asc' | 'Desc', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionDependencyRuleGetAllPositionDependencyRules.');
        }










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (SourceArticleNumber !== undefined && SourceArticleNumber !== null) {
            queryParameters = queryParameters.set('SourceArticleNumber', <any>SourceArticleNumber);
        }
        if (TargetArticleNumber !== undefined && TargetArticleNumber !== null) {
            queryParameters = queryParameters.set('TargetArticleNumber', <any>TargetArticleNumber);
        }
        if (Note !== undefined && Note !== null) {
            queryParameters = queryParameters.set('Note', <any>Note);
        }
        if (MandatorIds !== undefined && MandatorIds !== null) {
            queryParameters = queryParameters.set('MandatorIds', <any>MandatorIds);
        }
        if (ProductlineIds !== undefined && ProductlineIds !== null) {
            queryParameters = queryParameters.set('ProductlineIds', <any>ProductlineIds);
        }
        if (PageIndex !== undefined && PageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>PageIndex);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (SortField !== undefined && SortField !== null) {
            queryParameters = queryParameters.set('SortField', <any>SortField);
        }
        if (SortOrder !== undefined && SortOrder !== null) {
            queryParameters = queryParameters.set('SortOrder', <any>SortOrder);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageResultViewModelPositionDependencyRuleListViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/dependencyrules`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific dependency rule by id
     * 
     * @param tradeTypeCode trade type code of the dependency rule
     * @param dependencyRuleId the id of the dependency rule
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionDependencyRuleGetPositionDependencyRule(tradeTypeCode: string, dependencyRuleId: number, observe?: 'body', reportProgress?: boolean): Observable<PositionDependencyRuleViewModel>;
    public positionDependencyRuleGetPositionDependencyRule(tradeTypeCode: string, dependencyRuleId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PositionDependencyRuleViewModel>>;
    public positionDependencyRuleGetPositionDependencyRule(tradeTypeCode: string, dependencyRuleId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PositionDependencyRuleViewModel>>;
    public positionDependencyRuleGetPositionDependencyRule(tradeTypeCode: string, dependencyRuleId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionDependencyRuleGetPositionDependencyRule.');
        }

        if (dependencyRuleId === null || dependencyRuleId === undefined) {
            throw new Error('Required parameter dependencyRuleId was null or undefined when calling positionDependencyRuleGetPositionDependencyRule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<PositionDependencyRuleViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/dependencyrules/${encodeURIComponent(String(dependencyRuleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates position dependency rule
     * 
     * @param tradeTypeCode trade type code of the dependency rule
     * @param productlineId id of the productline where the dependency rule is attached to
     * @param body the new position dependency rule
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionDependencyRulePostPositionDependencyRule(tradeTypeCode: string, productlineId?: number, body?: PostPutPositionDependencyRuleRequest, observe?: 'body', reportProgress?: boolean): Observable<PositionDependencyRuleViewModel>;
    public positionDependencyRulePostPositionDependencyRule(tradeTypeCode: string, productlineId?: number, body?: PostPutPositionDependencyRuleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PositionDependencyRuleViewModel>>;
    public positionDependencyRulePostPositionDependencyRule(tradeTypeCode: string, productlineId?: number, body?: PostPutPositionDependencyRuleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PositionDependencyRuleViewModel>>;
    public positionDependencyRulePostPositionDependencyRule(tradeTypeCode: string, productlineId?: number, body?: PostPutPositionDependencyRuleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionDependencyRulePostPositionDependencyRule.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productlineId !== undefined && productlineId !== null) {
            queryParameters = queryParameters.set('productlineId', <any>productlineId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PositionDependencyRuleViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/dependencyrules`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionDependencyRulePostPositionDependencyRuleRequestMock(body?: PositionDependencyRuleRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public positionDependencyRulePostPositionDependencyRuleRequestMock(body?: PositionDependencyRuleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public positionDependencyRulePostPositionDependencyRuleRequestMock(body?: PositionDependencyRuleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public positionDependencyRulePostPositionDependencyRuleRequestMock(body?: PositionDependencyRuleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/positionDependencyRuleRequestmock`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a position dependency rule
     * 
     * @param tradeTypeCode trade type code of the dependency rule
     * @param dependencyRuleId id of the dependency rule to update
     * @param body the new position dependency rule data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionDependencyRulePutPositionDependencyRule(tradeTypeCode: string, dependencyRuleId: number, body?: PostPutPositionDependencyRuleRequest, observe?: 'body', reportProgress?: boolean): Observable<PositionDependencyRuleViewModel>;
    public positionDependencyRulePutPositionDependencyRule(tradeTypeCode: string, dependencyRuleId: number, body?: PostPutPositionDependencyRuleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PositionDependencyRuleViewModel>>;
    public positionDependencyRulePutPositionDependencyRule(tradeTypeCode: string, dependencyRuleId: number, body?: PostPutPositionDependencyRuleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PositionDependencyRuleViewModel>>;
    public positionDependencyRulePutPositionDependencyRule(tradeTypeCode: string, dependencyRuleId: number, body?: PostPutPositionDependencyRuleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionDependencyRulePutPositionDependencyRule.');
        }

        if (dependencyRuleId === null || dependencyRuleId === undefined) {
            throw new Error('Required parameter dependencyRuleId was null or undefined when calling positionDependencyRulePutPositionDependencyRule.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PositionDependencyRuleViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/dependencyrules/${encodeURIComponent(String(dependencyRuleId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
