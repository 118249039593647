import { IPageSettings } from '../../interfaces/Settings';
import { Observable } from 'rxjs';

export interface IOverviewFilterDefine {
  key: string;
  translateKey: string;
  control: OverviewControl;
  isServerSide: boolean;
  options?: IOverviewFilterOptionDefine[];
  multiple?: boolean;
}

export interface IOverviewFilterOptionDefine {
  id: string | number;
  key: string;
}

export class OverviewFilterViewModel implements IOverviewFilterDefine {
  key: string;
  translateKey: string;
  control: OverviewControl;
  isServerSide: boolean;
  options?: IOverviewFilterOptionDefine[];
  value?: any;
  multiple?: boolean;
  changeFilter?: (filter: OverviewFilterViewModel[]) => OverviewFilterViewModel[];
}

export interface IFilter {
  column: string;
  value: any;
  isServerSide: boolean;
}

export interface IFilterOverviewComponent {
  pageSettings: IPageSettings;
  pageSettings$: Observable<IPageSettings>;
  displayedFilters: IOverviewFilterDefine[];
  isFilterIconTurned: boolean;

  isFilterActive(): boolean;

  toggleFilter(): void;

  filtering(filterValue: IFilter[]): void;

  filterTable(event: KeyboardEvent): void;
}

export enum OverviewControl {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  SELECT = 'select',
}
