/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GenericPositionBuilderViewModel } from '../model/genericPositionBuilderViewModel';
import { PageResultViewModelGenericPositionBuilderListViewModel } from '../model/pageResultViewModelGenericPositionBuilderListViewModel';
import { PositionBuilderRequest } from '../model/positionBuilderRequest';
import { PostPutPositionBuilderRequest } from '../model/postPutPositionBuilderRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PositionBuilderService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletes a position builder
     * 
     * @param tradeTypeCode trade type code of the builder
     * @param builderId id of the builder to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionBuilderDeletePositionBuilder(tradeTypeCode: string, builderId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public positionBuilderDeletePositionBuilder(tradeTypeCode: string, builderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public positionBuilderDeletePositionBuilder(tradeTypeCode: string, builderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public positionBuilderDeletePositionBuilder(tradeTypeCode: string, builderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionBuilderDeletePositionBuilder.');
        }

        if (builderId === null || builderId === undefined) {
            throw new Error('Required parameter builderId was null or undefined when calling positionBuilderDeletePositionBuilder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/positionbuilders/${encodeURIComponent(String(builderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of paged and sorted and filtered position builders (rules)  the mandators are restricted to mandators the logged in user is responsible for.  The builders are limited to the specific trade type
     * 
     * @param tradeTypeCode trade type code of the builders
     * @param ArticleNumber article number of this builder, substring search works
     * @param IsOption whether this builder should be added as an option
     * @param IsSelected whether this builder should be added as selected
     * @param Note the note of this builder, substring search works
     * @param TopRulePropertyName the property name of the top rule (ignoring sub rules). substring search works
     * @param MandatorIds only retrieve entries with a matching mandator id (you can provide multiple mandators by separating them with a &#39;,&#39; or &#39;;&#39;)
     * @param ProductlineIds only retrieve entries with a matching productline ids (you can provide multiple productline ids by separating them with a &#39;,&#39; or &#39;;&#39;)
     * @param PageIndex the index of the current page(starts at 1)
     * @param PageSize the amount of entries per page
     * @param SortField the field used to sort
     * @param SortOrder the direction of sorting
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionBuilderGetAllPositionBuilders(tradeTypeCode: string, ArticleNumber?: string, IsOption?: boolean, IsSelected?: boolean, Note?: string, TopRulePropertyName?: string, MandatorIds?: string, ProductlineIds?: string, PageIndex?: number, PageSize?: number, SortField?: 'ProductlineId' | 'ArticleNumber' | 'Order' | 'Amount' | 'IsOption' | 'IsSelected' | 'Note' | 'PositionGroupTemplateId', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'body', reportProgress?: boolean): Observable<PageResultViewModelGenericPositionBuilderListViewModel>;
    public positionBuilderGetAllPositionBuilders(tradeTypeCode: string, ArticleNumber?: string, IsOption?: boolean, IsSelected?: boolean, Note?: string, TopRulePropertyName?: string, MandatorIds?: string, ProductlineIds?: string, PageIndex?: number, PageSize?: number, SortField?: 'ProductlineId' | 'ArticleNumber' | 'Order' | 'Amount' | 'IsOption' | 'IsSelected' | 'Note' | 'PositionGroupTemplateId', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageResultViewModelGenericPositionBuilderListViewModel>>;
    public positionBuilderGetAllPositionBuilders(tradeTypeCode: string, ArticleNumber?: string, IsOption?: boolean, IsSelected?: boolean, Note?: string, TopRulePropertyName?: string, MandatorIds?: string, ProductlineIds?: string, PageIndex?: number, PageSize?: number, SortField?: 'ProductlineId' | 'ArticleNumber' | 'Order' | 'Amount' | 'IsOption' | 'IsSelected' | 'Note' | 'PositionGroupTemplateId', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageResultViewModelGenericPositionBuilderListViewModel>>;
    public positionBuilderGetAllPositionBuilders(tradeTypeCode: string, ArticleNumber?: string, IsOption?: boolean, IsSelected?: boolean, Note?: string, TopRulePropertyName?: string, MandatorIds?: string, ProductlineIds?: string, PageIndex?: number, PageSize?: number, SortField?: 'ProductlineId' | 'ArticleNumber' | 'Order' | 'Amount' | 'IsOption' | 'IsSelected' | 'Note' | 'PositionGroupTemplateId', SortOrder?: 'None' | 'Asc' | 'Desc', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionBuilderGetAllPositionBuilders.');
        }












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ArticleNumber !== undefined && ArticleNumber !== null) {
            queryParameters = queryParameters.set('ArticleNumber', <any>ArticleNumber);
        }
        if (IsOption !== undefined && IsOption !== null) {
            queryParameters = queryParameters.set('IsOption', <any>IsOption);
        }
        if (IsSelected !== undefined && IsSelected !== null) {
            queryParameters = queryParameters.set('IsSelected', <any>IsSelected);
        }
        if (Note !== undefined && Note !== null) {
            queryParameters = queryParameters.set('Note', <any>Note);
        }
        if (TopRulePropertyName !== undefined && TopRulePropertyName !== null) {
            queryParameters = queryParameters.set('TopRulePropertyName', <any>TopRulePropertyName);
        }
        if (MandatorIds !== undefined && MandatorIds !== null) {
            queryParameters = queryParameters.set('MandatorIds', <any>MandatorIds);
        }
        if (ProductlineIds !== undefined && ProductlineIds !== null) {
            queryParameters = queryParameters.set('ProductlineIds', <any>ProductlineIds);
        }
        if (PageIndex !== undefined && PageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>PageIndex);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (SortField !== undefined && SortField !== null) {
            queryParameters = queryParameters.set('SortField', <any>SortField);
        }
        if (SortOrder !== undefined && SortOrder !== null) {
            queryParameters = queryParameters.set('SortOrder', <any>SortOrder);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageResultViewModelGenericPositionBuilderListViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/positionbuilders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific builder by id, including generic values and rules
     * 
     * @param tradeTypeCode trade type code of the builder
     * @param builderId the id of the builder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionBuilderGetPositionBuilder(tradeTypeCode: string, builderId: number, observe?: 'body', reportProgress?: boolean): Observable<GenericPositionBuilderViewModel>;
    public positionBuilderGetPositionBuilder(tradeTypeCode: string, builderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericPositionBuilderViewModel>>;
    public positionBuilderGetPositionBuilder(tradeTypeCode: string, builderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericPositionBuilderViewModel>>;
    public positionBuilderGetPositionBuilder(tradeTypeCode: string, builderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionBuilderGetPositionBuilder.');
        }

        if (builderId === null || builderId === undefined) {
            throw new Error('Required parameter builderId was null or undefined when calling positionBuilderGetPositionBuilder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<GenericPositionBuilderViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/positionbuilders/${encodeURIComponent(String(builderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates position builder
     * 
     * @param tradeTypeCode trade type code of the builder
     * @param productlineId id of the productline where the builder is attached to
     * @param body the new position builder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionBuilderPostPositionBuilder(tradeTypeCode: string, productlineId?: number, body?: PostPutPositionBuilderRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericPositionBuilderViewModel>;
    public positionBuilderPostPositionBuilder(tradeTypeCode: string, productlineId?: number, body?: PostPutPositionBuilderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericPositionBuilderViewModel>>;
    public positionBuilderPostPositionBuilder(tradeTypeCode: string, productlineId?: number, body?: PostPutPositionBuilderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericPositionBuilderViewModel>>;
    public positionBuilderPostPositionBuilder(tradeTypeCode: string, productlineId?: number, body?: PostPutPositionBuilderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionBuilderPostPositionBuilder.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productlineId !== undefined && productlineId !== null) {
            queryParameters = queryParameters.set('productlineId', <any>productlineId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericPositionBuilderViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/positionbuilders`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionBuilderPostPositionBuilderRequestMock(body?: PositionBuilderRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public positionBuilderPostPositionBuilderRequestMock(body?: PositionBuilderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public positionBuilderPostPositionBuilderRequestMock(body?: PositionBuilderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public positionBuilderPostPositionBuilderRequestMock(body?: PositionBuilderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/positionBuilderRequestmock`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a position builder
     * 
     * @param tradeTypeCode trade type code of the builder
     * @param builderId id of the builder to update
     * @param body the new position builder data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionBuilderPutPositionBuilder(tradeTypeCode: string, builderId: number, body?: PostPutPositionBuilderRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericPositionBuilderViewModel>;
    public positionBuilderPutPositionBuilder(tradeTypeCode: string, builderId: number, body?: PostPutPositionBuilderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericPositionBuilderViewModel>>;
    public positionBuilderPutPositionBuilder(tradeTypeCode: string, builderId: number, body?: PostPutPositionBuilderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericPositionBuilderViewModel>>;
    public positionBuilderPutPositionBuilder(tradeTypeCode: string, builderId: number, body?: PostPutPositionBuilderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionBuilderPutPositionBuilder.');
        }

        if (builderId === null || builderId === undefined) {
            throw new Error('Required parameter builderId was null or undefined when calling positionBuilderPutPositionBuilder.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<GenericPositionBuilderViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/positionbuilders/${encodeURIComponent(String(builderId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
