import { TradeTypeViewModel } from '../apis/advis';
import { IMandatorSelectItem, ITradeTypeSelectItem } from '../store';

export class GlobalFilterUtil {
  public static mapMandatorSelectItemToIds(mandatorsSelectItems: IMandatorSelectItem[]): string[] {
    return mandatorsSelectItems.map((item: IMandatorSelectItem) => item.id);
  }

  public static mapTradeTypeSelectItemToIds(
    tradeTypeSelectItems: ITradeTypeSelectItem[]
  ): string[] {
    return tradeTypeSelectItems.map((item: ITradeTypeSelectItem) => item.id);
  }

  public static filterSelectedTradeTypesAccordingMandators(
    tradeTypeItems: ITradeTypeSelectItem[],
    mandatorItems: IMandatorSelectItem[]
  ): ITradeTypeSelectItem[] {
    const selectedMandatorItems: IMandatorSelectItem[] = mandatorItems.filter(
      (mandatorItem: IMandatorSelectItem) => mandatorItem.selected
    );

    return tradeTypeItems.filter((tradeTypeItem: ITradeTypeSelectItem) => {
      for (let mandatorItem of selectedMandatorItems) {
        if (
          !mandatorItem.mandatorVm.TradeTypes.find(
            (tradeTypeVm: TradeTypeViewModel) =>
              tradeTypeVm.Code.localeCompare(tradeTypeItem.tradeTypeVm.Code) === 0
          )
        ) {
          return false;
        }
      }
      return true;
    });
  }

  public static filterSelectedMandatorsAccordingTradeTypes(
    mandatorItems: IMandatorSelectItem[],
    tradeTypeItems: ITradeTypeSelectItem[]
  ): IMandatorSelectItem[] {
    const selectedTradeTypeItems: ITradeTypeSelectItem[] = tradeTypeItems.filter(
      (tradeTypeItem: ITradeTypeSelectItem) => tradeTypeItem.selected
    );
    const selectedTradeTypeCodes: string[] = selectedTradeTypeItems.map(
      (tradeTypeItem: ITradeTypeSelectItem) => tradeTypeItem.tradeTypeVm.Code
    );

    return mandatorItems.filter((mandatorItem: IMandatorSelectItem) => {
      const mandatorTradeTypeCodes: string[] = mandatorItem.mandatorVm.TradeTypes.map(
        (tradeTypeVm: TradeTypeViewModel) => tradeTypeVm.Code
      );
      return selectedTradeTypeCodes.every((code: string) => {
        return mandatorTradeTypeCodes.includes(code);
      });
    });
  }
}
