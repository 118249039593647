import { IPrincipal } from '../../shared/interfaces/principal.interface';

export interface IAuthState {
  loggedIn: boolean;
  principal: IPrincipal | null;
  error: any;
  pending: boolean;
}

export function initAuthState(): IAuthState {
  return {
    loggedIn: false,
    principal: undefined,
    error: undefined,
    pending: false,
  };
}
