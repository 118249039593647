import { Action } from '@ngrx/store';
import { OrganisationUnitViewModel } from '../../shared/apis/advis';

const prefix: string = '[OrganisationUnit]';

export const GET_ORGANISATION_UNITS: string = `${prefix} Get all organisation units`;
export const GET_ORGANISATION_UNITS_SUCCESS: string = `${prefix} Get all organisation units success`;
export const GET_ORGANISATION_UNITS_FAILED: string = `${prefix} Get all organisation units failed`;

export const GET_ORGANISATION_UNIT: string = `${prefix} Get organisation unit`;
export const GET_ORGANISATION_UNIT_SUCCESS: string = `${prefix} Get organisation unit success`;
export const GET_ORGANISATION_UNIT_FAILED: string = `${prefix} Get organisation unit failed`;

export const ADD_ORGANISATION_UNIT: string = `${prefix} Add organisation unit`;
export const ADD_ORGANISATION_UNIT_SUCCESS: string = `${prefix} Add organisation unit success`;
export const ADD_ORGANISATION_UNIT_FAILED: string = `${prefix} Add organisation unit failed`;

export const EDIT_ORGANISATION_UNIT: string = `${prefix} Edit organisation unit`;
export const EDIT_ORGANISATION_UNIT_SUCCESS: string = `${prefix} Edit organisation unit success`;
export const EDIT_ORGANISATION_UNIT_FAILED: string = `${prefix} Edit organisation unit failed`;

export const DEACTIVATE_ORGANISATION_UNITS: string = `${prefix} Deactivate specific organisation unit`;
export const DEACTIVATE_ORGANISATION_UNITS_SUCCESS: string = `${prefix} Deactivate specific organisation unit success`;
export const DEACTIVATE_ORGANISATION_UNITS_FAILED: string = `${prefix} Deactivate specific organisation unit failed`;

export const ACTIVATE_ORGANISATION_UNITS: string = `${prefix} Activate specific organisation unit`;
export const ACTIVATE_ORGANISATION_UNITS_SUCCESS: string = `${prefix} Activate specific organisation unit success`;
export const ACTIVATE_ORGANISATION_UNITS_FAILED: string = `${prefix} Activate specific organisation unit failed`;

export class GetOrganisationUnitsAction implements Action {
  readonly type: string = GET_ORGANISATION_UNITS;

  constructor(public payload: string) {}
}

export class GetOrganisationUnitsSuccessAction implements Action {
  readonly type: string = GET_ORGANISATION_UNITS_SUCCESS;

  constructor(public payload: OrganisationUnitViewModel[]) {}
}

export class GetOrganisationUnitsFailedAction implements Action {
  readonly type: string = GET_ORGANISATION_UNITS_FAILED;

  constructor(public payload: any = undefined) {}
}

export interface IGetOrganisationUnitRequest {
  organisationUnitId: number;
  mandatorId: number;
}

export class GetOrganisationUnitAction implements Action {
  readonly type: string = GET_ORGANISATION_UNIT;

  constructor(public payload: IGetOrganisationUnitRequest) {}
}

export class GetOrganisationUnitSuccessAction implements Action {
  readonly type: string = GET_ORGANISATION_UNIT_SUCCESS;

  constructor(public payload: OrganisationUnitViewModel) {}
}

export class GetOrganisationUnitFailedAction implements Action {
  readonly type: string = GET_ORGANISATION_UNIT_FAILED;

  constructor(public payload: any = undefined) {}
}

export interface IAddOrganisationUnitRequest {
  patchOrganisationUnitRequest: OrganisationUnitViewModel;
  mandatorId: number;
}

export class AddOrganisationUnitAction implements Action {
  readonly type: string = ADD_ORGANISATION_UNIT;

  constructor(public payload: IAddOrganisationUnitRequest) {}
}

export class AddOrganisationUnitSuccessAction implements Action {
  readonly type: string = ADD_ORGANISATION_UNIT_SUCCESS;

  constructor(public payload: OrganisationUnitViewModel) {}
}

export class AddOrganisationUnitFailedAction implements Action {
  readonly type: string = ADD_ORGANISATION_UNIT_FAILED;

  constructor(public payload: any = undefined) {}
}

export interface IEditOrganisationUnitRequest {
  patchOrganisationUnitRequest: OrganisationUnitViewModel;
  organisationUnitId: number;
  mandatorId: number;
}

export class EditOrganisationUnitAction implements Action {
  readonly type: string = EDIT_ORGANISATION_UNIT;

  constructor(public payload: IEditOrganisationUnitRequest) {}
}

export class EditOrganisationUnitSuccessAction implements Action {
  readonly type: string = EDIT_ORGANISATION_UNIT_SUCCESS;

  constructor(public payload: OrganisationUnitViewModel) {}
}

export class EditOrganisationUnitFailedAction implements Action {
  readonly type: string = EDIT_ORGANISATION_UNIT_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeactivateOrganisationUnitAction implements Action {
  readonly type: string = DEACTIVATE_ORGANISATION_UNITS;

  constructor(public payload: number) {}
}

export class DeactivateOrganisationUnitSuccessAction implements Action {
  readonly type: string = DEACTIVATE_ORGANISATION_UNITS_SUCCESS;

  constructor(public payload: OrganisationUnitViewModel) {}
}

export class DeactivateOrganisationUnitFailedAction implements Action {
  readonly type: string = DEACTIVATE_ORGANISATION_UNITS_FAILED;

  constructor(public payload: any = undefined) {}
}

export class ActivateOrganisationUnitAction implements Action {
  readonly type: string = ACTIVATE_ORGANISATION_UNITS;

  constructor(public payload: number) {}
}

export class ActivateOrganisationUnitSuccessAction implements Action {
  readonly type: string = ACTIVATE_ORGANISATION_UNITS_SUCCESS;

  constructor(public payload: OrganisationUnitViewModel) {}
}

export class ActivateOrganisationUnitFailedAction implements Action {
  readonly type: string = ACTIVATE_ORGANISATION_UNITS_FAILED;

  constructor(public payload: any = undefined) {}
}

export type Actions =
  | GetOrganisationUnitsAction
  | GetOrganisationUnitsSuccessAction
  | GetOrganisationUnitsFailedAction
  | GetOrganisationUnitAction
  | GetOrganisationUnitSuccessAction
  | GetOrganisationUnitFailedAction
  | EditOrganisationUnitAction
  | EditOrganisationUnitSuccessAction
  | EditOrganisationUnitFailedAction
  | AddOrganisationUnitAction
  | AddOrganisationUnitSuccessAction
  | AddOrganisationUnitFailedAction
  | DeactivateOrganisationUnitAction
  | DeactivateOrganisationUnitSuccessAction
  | DeactivateOrganisationUnitFailedAction
  | ActivateOrganisationUnitAction
  | ActivateOrganisationUnitSuccessAction
  | ActivateOrganisationUnitFailedAction;
