import { Component, OnDestroy, OnInit } from '@angular/core';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { IRootState } from '../../../root.state';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { IIghTheiaConverter, UploadAction } from '../../state/ighTheiaConverter.action';
import {
  getIghTheiaConverterFilesUploading,
  getIghTheiaConverterResultFile,
} from '../../state/ighTheiaConverter.selectors';

export enum FileLanguageEnum {
  DE = <any>'DE',
  FR = <any>'FR',
  IT = <any>'IT',
}

@Component({
  selector: 'ac-igh-theia-converter',
  templateUrl: './igh-theia-converter.component.html',
  styleUrls: ['./igh-theia-converter.component.sass'],
})
export class IghTheiaConverterComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  readonly fileanguage: typeof FileLanguageEnum = FileLanguageEnum;
  ikkFile: Blob;
  ighFileDE: Blob;
  ighFileFR: Blob;
  ighFileIT: Blob;
  ighFiles: Blob[] = [];

  constructor(private spinner: SpinnerService, private store: Store<IRootState>) {}

  ngOnInit(): void {
    const fileUploading$: Observable<boolean> = this.store.select(
      getIghTheiaConverterFilesUploading
    );
    const convertedFile$: Observable<Blob> = this.store.select(getIghTheiaConverterResultFile);

    this.subscriptions.add(
      fileUploading$.subscribe((loading: boolean) => {
        loading ? this.spinner.EnableSpinner() : this.spinner.DisableSpinner();
      })
    );

    this.subscriptions.add(
      convertedFile$.subscribe((file: Blob) => {
        if (file) {
          let a: HTMLAnchorElement = document.createElement('a');
          a.href = URL.createObjectURL(file);
          a.download = 'result';
          a.click();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  isFormValid(): boolean {
    return (
      isNotNullOrUndefined(this.ighFileDE) ||
      isNotNullOrUndefined(this.ighFileFR) ||
      isNotNullOrUndefined(this.ighFileIT)
    );
  }

  sendFilesToConvert(): void {
    const payload: IIghTheiaConverter = {
      ighFileDE: this.ighFileDE,
      ighFileFR: this.ighFileFR,
      ighFileIT: this.ighFileIT,
      ikkFile: this.ikkFile,
    };

    this.store.dispatch(new UploadAction(payload));
  }

  onFileSelect(files: FileList | File, fileLanguage?: FileLanguageEnum): void {
    let file: File;
    if (files instanceof FileList) {
      file = files[0];
    } else {
      file = files;
    }
    switch (fileLanguage) {
      case this.fileanguage.DE:
        this.ighFileDE = file;
        this.ighFiles.push(this.ighFileDE);
        break;
      case this.fileanguage.FR:
        this.ighFileFR = file;
        this.ighFiles.push(this.ighFileFR);
        break;
      case this.fileanguage.IT:
        this.ighFileIT = file;
        this.ighFiles.push(this.ighFileIT);
        break;
      default:
        this.ikkFile = file;
        break;
    }
  }
}
