import { IFieldConfig } from './../../models/sharedInterfaces';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl } from '@angular/forms';

@Component({
  selector: 'ac-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit {
  // Input parameter - fields: Form Fields
  @Input() fields: IFieldConfig[] = [];
  // Output event - Submission of the form
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  // Output event - Cancellation of the from
  @Output() cancellation: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;

  get value(): any {
    return this.form.value;
  }
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.createControl();
  }

  createControl(): any {
    const group: UntypedFormGroup = this.fb.group({});
    this.fields.forEach((field: IFieldConfig) => {
      // Control creation - value / validators
      const control: any = this.fb.control(field.Value, []);
      group.addControl(field.Name, control);
    });
    return group;
  }

  validateAllFormFields(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((field: string) => {
      const control: AbstractControl = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
