import { createSelector, MemoizedSelector } from '@ngrx/store';
import { IRootState } from '../../../root.state';
import { RoleViewModel } from '../../../shared/apis/advis';
import { IRoleState } from './role.state';

export const getRoleState: (state: IRootState) => IRoleState = (state: IRootState) => state.role;

export const getRolesFiltered: MemoizedSelector<IRootState, RoleViewModel[]> = createSelector(
  getRoleState,
  (state: IRoleState) => state.roles
);

export const getRolesLoading: MemoizedSelector<IRootState, boolean> = createSelector(
  getRoleState,
  (state: IRoleState) => state.rolesLoading
);

export const getRoleLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getRoleState,
  (state: IRoleState) => state.rolesLoaded
);

export const getAllPermissions: MemoizedSelector<IRootState, string[]> = createSelector(
  getRoleState,
  (state: IRoleState) => state.permissions
);

export const getAllPermissionsLoading: MemoizedSelector<IRootState, boolean> = createSelector(
  getRoleState,
  (state: IRoleState) => state.permissionsLoading
);

export const getAllPermissionsLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getRoleState,
  (state: IRoleState) => state.permissionsLoaded
);
