import { Component } from '@angular/core';

@Component({
  selector: 'ac-project-reporting-main',
  templateUrl: './project-reporting-main.component.html',
  styleUrls: ['./project-reporting-main.component.sass'],
})
export class ProjectReportingMainComponent {
  constructor() {}
}
