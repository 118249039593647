import { Injectable } from '@angular/core';
import { CommonUtil } from '../utils/common.util';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _apiDebounceTimeMs: number = 400;
  private _apiMaxPageSize: number = CommonUtil.getInt32Max();
  private _apiPollOwnOpenTasksIntervalMs: number = 60 * 1000; // Every minute
  private _onlinepoolIndicators: string[] = [' OW', ' CC']; // Space by intention

  constructor() {
    // empty
  }

  get apiDebounceTimeMs(): number {
    return this._apiDebounceTimeMs;
  }

  get apiMaxPageSize(): number {
    return this._apiMaxPageSize;
  }

  get apiPollOwnOpenTasksIntervalMs(): number {
    return this._apiPollOwnOpenTasksIntervalMs;
  }

  get onlinepoolIndicators(): string[] {
    return this._onlinepoolIndicators;
  }
}
