/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PageResultViewModelValidationRuleListViewModel } from '../model/pageResultViewModelValidationRuleListViewModel';
import { PostPutValidationRuleRequest } from '../model/postPutValidationRuleRequest';
import { ValidationRuleRequest } from '../model/validationRuleRequest';
import { ValidationRuleViewModel } from '../model/validationRuleViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ValidationRuleService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletes a validation rule
     * 
     * @param tradeTypeCode trade type code of the rule
     * @param ruleId id of the rule to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validationRuleDeleteValidationRule(tradeTypeCode: string, ruleId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public validationRuleDeleteValidationRule(tradeTypeCode: string, ruleId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public validationRuleDeleteValidationRule(tradeTypeCode: string, ruleId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public validationRuleDeleteValidationRule(tradeTypeCode: string, ruleId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling validationRuleDeleteValidationRule.');
        }

        if (ruleId === null || ruleId === undefined) {
            throw new Error('Required parameter ruleId was null or undefined when calling validationRuleDeleteValidationRule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/validation-rules/${encodeURIComponent(String(ruleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of paged and sorted and filtered validation rules (rules)  the mandators are restricted to mandators the logged in user is responsible for.  The rules are limited to the specific trade type
     * 
     * @param tradeTypeCode trade type code of the rules
     * @param Description description of the rule, substring search works
     * @param RuleLevel Rule level of the rule
     * @param MandatorIds only retrieve entries with a matching mandator id (you can provide multiple mandators by separating them with a &#39;,&#39; or &#39;;&#39;)
     * @param PageIndex the index of the current page(starts at 1)
     * @param PageSize the amount of entries per page
     * @param SortField the field used to sort
     * @param SortOrder the direction of sorting
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validationRuleGetAllValidationRules(tradeTypeCode: string, Description?: string, RuleLevel?: 'Information' | 'Warning', MandatorIds?: string, PageIndex?: number, PageSize?: number, SortField?: 'Description' | 'RuleLevel', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'body', reportProgress?: boolean): Observable<PageResultViewModelValidationRuleListViewModel>;
    public validationRuleGetAllValidationRules(tradeTypeCode: string, Description?: string, RuleLevel?: 'Information' | 'Warning', MandatorIds?: string, PageIndex?: number, PageSize?: number, SortField?: 'Description' | 'RuleLevel', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageResultViewModelValidationRuleListViewModel>>;
    public validationRuleGetAllValidationRules(tradeTypeCode: string, Description?: string, RuleLevel?: 'Information' | 'Warning', MandatorIds?: string, PageIndex?: number, PageSize?: number, SortField?: 'Description' | 'RuleLevel', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageResultViewModelValidationRuleListViewModel>>;
    public validationRuleGetAllValidationRules(tradeTypeCode: string, Description?: string, RuleLevel?: 'Information' | 'Warning', MandatorIds?: string, PageIndex?: number, PageSize?: number, SortField?: 'Description' | 'RuleLevel', SortOrder?: 'None' | 'Asc' | 'Desc', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling validationRuleGetAllValidationRules.');
        }








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Description !== undefined && Description !== null) {
            queryParameters = queryParameters.set('Description', <any>Description);
        }
        if (RuleLevel !== undefined && RuleLevel !== null) {
            queryParameters = queryParameters.set('RuleLevel', <any>RuleLevel);
        }
        if (MandatorIds !== undefined && MandatorIds !== null) {
            queryParameters = queryParameters.set('MandatorIds', <any>MandatorIds);
        }
        if (PageIndex !== undefined && PageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>PageIndex);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (SortField !== undefined && SortField !== null) {
            queryParameters = queryParameters.set('SortField', <any>SortField);
        }
        if (SortOrder !== undefined && SortOrder !== null) {
            queryParameters = queryParameters.set('SortOrder', <any>SortOrder);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageResultViewModelValidationRuleListViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/validation-rules`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific validation rule by id, including the actual rule
     * 
     * @param tradeTypeCode trade type code of the rule
     * @param ruleId the id of the rule
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validationRuleGetValidationRule(tradeTypeCode: string, ruleId: number, observe?: 'body', reportProgress?: boolean): Observable<ValidationRuleViewModel>;
    public validationRuleGetValidationRule(tradeTypeCode: string, ruleId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValidationRuleViewModel>>;
    public validationRuleGetValidationRule(tradeTypeCode: string, ruleId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValidationRuleViewModel>>;
    public validationRuleGetValidationRule(tradeTypeCode: string, ruleId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling validationRuleGetValidationRule.');
        }

        if (ruleId === null || ruleId === undefined) {
            throw new Error('Required parameter ruleId was null or undefined when calling validationRuleGetValidationRule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<ValidationRuleViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/validation-rules/${encodeURIComponent(String(ruleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates validation rule
     * 
     * @param tradeTypeCode trade type code of the rule
     * @param body the new validation rule
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validationRulePostValidationRule(tradeTypeCode: string, body?: PostPutValidationRuleRequest, observe?: 'body', reportProgress?: boolean): Observable<ValidationRuleViewModel>;
    public validationRulePostValidationRule(tradeTypeCode: string, body?: PostPutValidationRuleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValidationRuleViewModel>>;
    public validationRulePostValidationRule(tradeTypeCode: string, body?: PostPutValidationRuleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValidationRuleViewModel>>;
    public validationRulePostValidationRule(tradeTypeCode: string, body?: PostPutValidationRuleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling validationRulePostValidationRule.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ValidationRuleViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/validation-rules`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validationRulePostValidationRuleRequestMock(body?: ValidationRuleRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public validationRulePostValidationRuleRequestMock(body?: ValidationRuleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public validationRulePostValidationRuleRequestMock(body?: ValidationRuleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public validationRulePostValidationRuleRequestMock(body?: ValidationRuleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/ValidationRuleRequestmock`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a validation rule
     * 
     * @param tradeTypeCode trade type code of the rule
     * @param ruleId id of the rule to update
     * @param body the new validation rule data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validationRulePutValidationRule(tradeTypeCode: string, ruleId: number, body?: PostPutValidationRuleRequest, observe?: 'body', reportProgress?: boolean): Observable<ValidationRuleViewModel>;
    public validationRulePutValidationRule(tradeTypeCode: string, ruleId: number, body?: PostPutValidationRuleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValidationRuleViewModel>>;
    public validationRulePutValidationRule(tradeTypeCode: string, ruleId: number, body?: PostPutValidationRuleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValidationRuleViewModel>>;
    public validationRulePutValidationRule(tradeTypeCode: string, ruleId: number, body?: PostPutValidationRuleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling validationRulePutValidationRule.');
        }

        if (ruleId === null || ruleId === undefined) {
            throw new Error('Required parameter ruleId was null or undefined when calling validationRulePutValidationRule.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ValidationRuleViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/validation-rules/${encodeURIComponent(String(ruleId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
