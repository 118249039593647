import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep, isEqual } from 'lodash';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  AddOrganisationUnitAction,
  ADD_ORGANISATION_UNIT_SUCCESS,
  EditOrganisationUnitAction,
  EDIT_ORGANISATION_UNIT_SUCCESS,
  GetOrganisationUnitAction,
  GetOrganisationUnitSuccessAction,
  GET_ORGANISATION_UNIT_SUCCESS,
} from '../../../state/organisation-unit.action';
import { IRootState } from '../../../../root.state';
import { MandatorViewModel, OrganisationUnitViewModel } from '../../../../shared/apis/advis';
import { IGuardDeactivation } from '../../../../shared/interfaces/guard-deactivation';
import { getMandators } from '../../../../shared/store';

@Component({
  selector: 'ac-organisation-unit-edit',
  templateUrl: './organisation-unit-form.component.html',
  styleUrls: ['./organisation-unit-form.component.sass'],
})
export class OrganisationUnitFormComponent implements OnInit, OnDestroy, IGuardDeactivation {
  private subscription: Subscription = new Subscription();

  organisationUnit: OrganisationUnitViewModel = {
    Address: {},
  } as OrganisationUnitViewModel;
  initialOrganisationUnit: OrganisationUnitViewModel = {
    Address: {},
  } as OrganisationUnitViewModel;

  isNew: boolean = true;

  organisationUnitFormTitle: string = '';

  @ViewChild('organisationUnitForm')
  organisationUnitForm: UntypedFormGroup;

  mandators: MandatorViewModel[] = [];
  storageTypes: string[];
  storageChemistries: string[];

  constructor(
    private route: ActivatedRoute,
    private store: Store<IRootState>,
    private router: Router,
    private action$: Actions
  ) {
    // empty
  }

  ngOnInit(): void {
    let url$: Observable<UrlSegment[]> = this.route.url;
    let params$: Observable<Params> = this.route.params;

    this.subscription.add(
      combineLatest([url$, params$]).subscribe(([url, params]: any) => {
        let urlPath: string = url[1].path.toString();

        if (urlPath === 'edit') {
          if (params && params.id && params.mandatorId) {
            this.store.dispatch(
              new GetOrganisationUnitAction({
                mandatorId: params.mandatorId,
                organisationUnitId: params.id,
              })
            );
            this.loadOrganisationUnit();
            this.organisationUnitFormTitle = 'Edit organisation unit';
            this.isNew = false;
          }
        } else {
          this.organisationUnitFormTitle = 'Add organisation unit';
        }
      })
    );

    this.subscription.add(
      this.store.select(getMandators).subscribe((mandators: MandatorViewModel[]) => {
        this.mandators = mandators;
      })
    );

    this.subscription.add(
      this.action$
        .pipe(ofType(ADD_ORGANISATION_UNIT_SUCCESS, EDIT_ORGANISATION_UNIT_SUCCESS), take(1))
        .subscribe(() => this.onBack())
    );
  }

  loadOrganisationUnit(): void {
    this.subscription.add(
      this.action$
        .pipe(ofType(GET_ORGANISATION_UNIT_SUCCESS), take(1))
        .subscribe((action: GetOrganisationUnitSuccessAction) => {
          this.organisationUnit = cloneDeep(action.payload as OrganisationUnitViewModel);
          this.initialOrganisationUnit = cloneDeep(this.organisationUnit);
        })
    );
  }

  isFormValid(): boolean {
    return (
      this.organisationUnitForm &&
      this.organisationUnitForm.valid &&
      this.organisationUnit !== undefined
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onBack(): void {
    this.router.navigate(['/mandator/organisation-unit/overview']);
  }

  onSave(): void {
    this.initialOrganisationUnit = cloneDeep(this.organisationUnit);
    if (this.isNew) {
      this.store.dispatch(
        new AddOrganisationUnitAction({
          mandatorId: this.organisationUnit.MandatorId,
          patchOrganisationUnitRequest: this.organisationUnit,
        })
      );
    } else {
      this.store.dispatch(
        new EditOrganisationUnitAction({
          organisationUnitId: this.organisationUnit.Id,
          mandatorId: this.organisationUnit.MandatorId,
          patchOrganisationUnitRequest: this.organisationUnit,
        })
      );
    }
  }

  get canDeactivateSafely(): boolean {
    return isEqual(this.organisationUnit, this.initialOrganisationUnit);
  }
}
