import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';

import * as AuthAction from '../auth/state/auth.action';
import { IRootState } from '../root.state';
import { Authenticate } from '../shared/models/principal.model';
import { EnvironmentService } from '../shared/services/environment.service';
import { InitStateAction, SetNavigationProcessAction } from '../shared/store';
import { InitAction } from './state/auth.action';
import { getAuthState } from './state/auth.selectors';
import { IAuthState } from './state/auth.state';
import { isNullOrUndefined } from '../shared/utils/isNullOrUndefined';

@Component({
  selector: 'ac-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private auth$: Observable<IAuthState> = this.store.select(getAuthState);

  public errorMessage: string;
  public sessionTimeout: boolean;

  username: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  password: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  constructor(
    private store: Store<IRootState>,
    private translate: TranslateService,
    public environment: EnvironmentService,
    private titleService: Title,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.translate.instant('TITLE.LOGIN'));

    this.subscriptions.add(
      this.route.queryParams.subscribe((params: Params) => {
        if (!isNullOrUndefined(params.timeout)) {
          this.sessionTimeout = true;
        } else {
          this.sessionTimeout = false;
        }
      })
    );

    this.subscriptions.add(
      this.auth$.subscribe((auth: IAuthState) => {
        if (auth.error) {
          this.spinner.hide();

          this.sessionTimeout = false;

          if (auth.error.status && auth.error.status === 400) {
            this.errorMessage = this.translate.instant('AUTH.INVALID_CREDENTIALS');
          } else if ((auth.error as any).message) {
            this.errorMessage =
              this.translate.instant('AUTH.GENERAL_ERROR') + ': ' + (auth.error as any).message;
          } else {
            this.errorMessage = this.translate.instant('AUTH.GENERAL_ERROR') + ': ' + auth.error;
          }
        } else {
          this.errorMessage = undefined;
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.store.dispatch(new InitAction());
    this.store.dispatch(new SetNavigationProcessAction(false));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onLogin(): void {
    this.spinner.show();
    this.store.dispatch(new InitStateAction());
    this.store.dispatch(
      new AuthAction.LoginAction(new Authenticate(this.username.value, this.password.value, 'en'))
    );
  }

  getUsernameErrorMessage(): string {
    return this.username.hasError('required')
      ? this.translate.instant('AUTH.EMPTY')
      : this.username.hasError('email')
      ? this.translate.instant('AUTH.NO_VALID_EMAIL')
      : '';
  }

  getPasswordErrorMessage(): string {
    return this.password.hasError('required') ? this.translate.instant('AUTH.EMPTY') : '';
  }
}
