/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DateTimeRuleViewModel { 
    /**
     * The property name
     */
    PropertyName: string;
    Operator: DateTimeRuleViewModel.OperatorEnum;
    /**
     * The value1 to check
     */
    Value1?: Date;
    /**
     * The value2 to check
     */
    Value2?: Date;
}
export namespace DateTimeRuleViewModel {
    export type OperatorEnum = 'None' | 'Equals' | 'NotEquals' | 'Smaller' | 'Larger' | 'SmallerOrEquals' | 'LargerOrEquals' | 'Between' | 'Contains' | 'IsNull' | 'IsNotNull' | 'NotContains';
    export const OperatorEnum = {
        None: 'None' as OperatorEnum,
        Equals: 'Equals' as OperatorEnum,
        NotEquals: 'NotEquals' as OperatorEnum,
        Smaller: 'Smaller' as OperatorEnum,
        Larger: 'Larger' as OperatorEnum,
        SmallerOrEquals: 'SmallerOrEquals' as OperatorEnum,
        LargerOrEquals: 'LargerOrEquals' as OperatorEnum,
        Between: 'Between' as OperatorEnum,
        Contains: 'Contains' as OperatorEnum,
        IsNull: 'IsNull' as OperatorEnum,
        IsNotNull: 'IsNotNull' as OperatorEnum,
        NotContains: 'NotContains' as OperatorEnum
    };
}
