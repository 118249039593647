/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateMandatorRequest } from '../model/createMandatorRequest';
import { MandatorViewModel } from '../model/mandatorViewModel';
import { OrganisationUnitViewModel } from '../model/organisationUnitViewModel';
import { PatchMandatorRequest } from '../model/patchMandatorRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MandatorService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Creates a mandator from another mandator,  given new properties.  After a new mandator is created, to not forget to create file shares on dev/test/prod for that mandator in terraform  and put them into the configuration. Hint: The configuration has to be updated on Octopus as well.
     * 
     * @param body required data to create the new mandator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mandatorCreateMandator(body?: CreateMandatorRequest, observe?: 'body', reportProgress?: boolean): Observable<MandatorViewModel>;
    public mandatorCreateMandator(body?: CreateMandatorRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MandatorViewModel>>;
    public mandatorCreateMandator(body?: CreateMandatorRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MandatorViewModel>>;
    public mandatorCreateMandator(body?: CreateMandatorRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MandatorViewModel>(`${this.basePath}/api/admin/mandators`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all mandators the user is responsible for
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mandatorGetAllMandators(observe?: 'body', reportProgress?: boolean): Observable<Array<MandatorViewModel>>;
    public mandatorGetAllMandators(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MandatorViewModel>>>;
    public mandatorGetAllMandators(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MandatorViewModel>>>;
    public mandatorGetAllMandators(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MandatorViewModel>>(`${this.basePath}/api/admin/mandators`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a mandator by Id
     * 
     * @param mandatorId the mandator to get
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mandatorGetMandatorById(mandatorId: number, observe?: 'body', reportProgress?: boolean): Observable<MandatorViewModel>;
    public mandatorGetMandatorById(mandatorId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MandatorViewModel>>;
    public mandatorGetMandatorById(mandatorId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MandatorViewModel>>;
    public mandatorGetMandatorById(mandatorId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandatorId === null || mandatorId === undefined) {
            throw new Error('Required parameter mandatorId was null or undefined when calling mandatorGetMandatorById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<MandatorViewModel>(`${this.basePath}/api/admin/mandators/${encodeURIComponent(String(mandatorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches a mandator, currently, only the tradetypes can be patched
     * 
     * @param mandatorId the mandator patch
     * @param body the values to patch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mandatorPatchMandator(mandatorId: number, body?: PatchMandatorRequest, observe?: 'body', reportProgress?: boolean): Observable<MandatorViewModel>;
    public mandatorPatchMandator(mandatorId: number, body?: PatchMandatorRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MandatorViewModel>>;
    public mandatorPatchMandator(mandatorId: number, body?: PatchMandatorRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MandatorViewModel>>;
    public mandatorPatchMandator(mandatorId: number, body?: PatchMandatorRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandatorId === null || mandatorId === undefined) {
            throw new Error('Required parameter mandatorId was null or undefined when calling mandatorPatchMandator.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<MandatorViewModel>(`${this.basePath}/api/admin/mandators/${encodeURIComponent(String(mandatorId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activates an organisation unit
     * 
     * @param ouId the id of the organisation unit to activate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationUnitActivateOrganisationUnit(ouId: number, observe?: 'body', reportProgress?: boolean): Observable<OrganisationUnitViewModel>;
    public organisationUnitActivateOrganisationUnit(ouId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationUnitViewModel>>;
    public organisationUnitActivateOrganisationUnit(ouId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationUnitViewModel>>;
    public organisationUnitActivateOrganisationUnit(ouId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ouId === null || ouId === undefined) {
            throw new Error('Required parameter ouId was null or undefined when calling organisationUnitActivateOrganisationUnit.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<OrganisationUnitViewModel>(`${this.basePath}/api/admin/mandators/${encodeURIComponent(String(ouId))}/activate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deactivates an organisation by id
     * 
     * @param ouId the id of the organisation unit to deactivate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationUnitDeactivateOrganisationUnit(ouId: number, observe?: 'body', reportProgress?: boolean): Observable<OrganisationUnitViewModel>;
    public organisationUnitDeactivateOrganisationUnit(ouId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationUnitViewModel>>;
    public organisationUnitDeactivateOrganisationUnit(ouId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationUnitViewModel>>;
    public organisationUnitDeactivateOrganisationUnit(ouId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ouId === null || ouId === undefined) {
            throw new Error('Required parameter ouId was null or undefined when calling organisationUnitDeactivateOrganisationUnit.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<OrganisationUnitViewModel>(`${this.basePath}/api/admin/mandators/${encodeURIComponent(String(ouId))}/deactivate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets an organisation unit of a mandator by id
     * 
     * @param mandatorId the mandator to get the organisation unit for
     * @param organisationUnitId the id of the organisation unit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationUnitGetOrganisationUnit(mandatorId: number, organisationUnitId: number, observe?: 'body', reportProgress?: boolean): Observable<OrganisationUnitViewModel>;
    public organisationUnitGetOrganisationUnit(mandatorId: number, organisationUnitId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationUnitViewModel>>;
    public organisationUnitGetOrganisationUnit(mandatorId: number, organisationUnitId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationUnitViewModel>>;
    public organisationUnitGetOrganisationUnit(mandatorId: number, organisationUnitId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandatorId === null || mandatorId === undefined) {
            throw new Error('Required parameter mandatorId was null or undefined when calling organisationUnitGetOrganisationUnit.');
        }

        if (organisationUnitId === null || organisationUnitId === undefined) {
            throw new Error('Required parameter organisationUnitId was null or undefined when calling organisationUnitGetOrganisationUnit.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrganisationUnitViewModel>(`${this.basePath}/api/admin/mandators/${encodeURIComponent(String(mandatorId))}/organisation-units/${encodeURIComponent(String(organisationUnitId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all organisation units of the given mandator
     * 
     * @param mandatorIds the mandator ids to get the organisation units for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationUnitGetOrganisationUnits(mandatorIds?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OrganisationUnitViewModel>>;
    public organisationUnitGetOrganisationUnits(mandatorIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganisationUnitViewModel>>>;
    public organisationUnitGetOrganisationUnits(mandatorIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganisationUnitViewModel>>>;
    public organisationUnitGetOrganisationUnits(mandatorIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mandatorIds !== undefined && mandatorIds !== null) {
            queryParameters = queryParameters.set('mandatorIds', <any>mandatorIds);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<OrganisationUnitViewModel>>(`${this.basePath}/api/admin/mandators/organisation-units`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds an organisation unit to a mandator
     * 
     * @param mandatorId the mandator to post the organisation unit
     * @param body the new organisation unit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationUnitPostOrganisationUnit(mandatorId: number, body?: OrganisationUnitViewModel, observe?: 'body', reportProgress?: boolean): Observable<OrganisationUnitViewModel>;
    public organisationUnitPostOrganisationUnit(mandatorId: number, body?: OrganisationUnitViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationUnitViewModel>>;
    public organisationUnitPostOrganisationUnit(mandatorId: number, body?: OrganisationUnitViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationUnitViewModel>>;
    public organisationUnitPostOrganisationUnit(mandatorId: number, body?: OrganisationUnitViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandatorId === null || mandatorId === undefined) {
            throw new Error('Required parameter mandatorId was null or undefined when calling organisationUnitPostOrganisationUnit.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrganisationUnitViewModel>(`${this.basePath}/api/admin/mandators/${encodeURIComponent(String(mandatorId))}/organisation-units`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an organisation unit of a mandator
     * 
     * @param mandatorId the mandator to post the organisation unit
     * @param organisationUnitId the id of the organisation unit to update
     * @param body the organisation unit data to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationUnitPutOrganisationUnit(mandatorId: number, organisationUnitId: number, body?: OrganisationUnitViewModel, observe?: 'body', reportProgress?: boolean): Observable<OrganisationUnitViewModel>;
    public organisationUnitPutOrganisationUnit(mandatorId: number, organisationUnitId: number, body?: OrganisationUnitViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationUnitViewModel>>;
    public organisationUnitPutOrganisationUnit(mandatorId: number, organisationUnitId: number, body?: OrganisationUnitViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationUnitViewModel>>;
    public organisationUnitPutOrganisationUnit(mandatorId: number, organisationUnitId: number, body?: OrganisationUnitViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandatorId === null || mandatorId === undefined) {
            throw new Error('Required parameter mandatorId was null or undefined when calling organisationUnitPutOrganisationUnit.');
        }

        if (organisationUnitId === null || organisationUnitId === undefined) {
            throw new Error('Required parameter organisationUnitId was null or undefined when calling organisationUnitPutOrganisationUnit.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<OrganisationUnitViewModel>(`${this.basePath}/api/admin/mandators/${encodeURIComponent(String(mandatorId))}/organisation-units/${encodeURIComponent(String(organisationUnitId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
