import cloneDeep from 'lodash/cloneDeep';
import { tassign } from 'tassign';
import { RoleViewModel } from '../../../shared/apis/advis';
import * as RoleAction from './role.action';
import {
  DeleteRoleAction,
  DeleteRoleSuccessAction,
  DeleteRoleFailedAction,
  AddRoleAction,
  AddRoleFailedAction,
  AddRoleSuccessAction,
  EditRoleAction,
  EditRoleFailedAction,
  EditRoleSuccessAction,
  GetRoleAction,
  GetRoleFailedAction,
  GetRolesAction,
  GetRolesFailedAction,
  GetRolesSuccessAction,
  GetRoleSuccessAction,
  GetAllPermissionsAction,
  GetAllPermissionsSuccessAction,
  GetAllPermissionsFailedAction,
} from './role.action';
import { initRoleState, IRoleState } from './role.state';

export function reducer(
  state: IRoleState = initRoleState(),
  action: RoleAction.Actions
): IRoleState {
  if (action instanceof GetRolesAction) {
    return tassign(state, {
      rolesLoading: true,
      rolesLoaded: false,
    } as IRoleState);
  }

  if (action instanceof GetRolesSuccessAction) {
    return tassign(state, {
      roles: action.payload,
      rolesLoading: false,
      rolesLoaded: true,
    } as IRoleState);
  }

  if (action instanceof GetRolesFailedAction) {
    return tassign(state, {
      roles: [],
      rolesLoading: false,
      rolesLoaded: false,
    } as IRoleState);
  }

  if (action instanceof GetRoleAction) {
    return tassign(state, {
      rolesLoading: true,
      rolesLoaded: false,
    } as IRoleState);
  }

  if (action instanceof GetRoleSuccessAction) {
    return tassign(state, {
      rolesLoading: false,
      rolesLoaded: true,
    } as IRoleState);
  }

  if (action instanceof GetRoleFailedAction) {
    return tassign(state, {
      rolesLoading: false,
      rolesLoaded: false,
    } as IRoleState);
  }

  if (action instanceof GetAllPermissionsAction) {
    return tassign(state, {
      permissionsLoaded: false,
      permissionsLoading: true,
    } as IRoleState);
  }

  if (action instanceof GetAllPermissionsSuccessAction) {
    return tassign(state, {
      permissions: action.payload,
      permissionsLoaded: true,
      permissionsLoading: false,
    } as IRoleState);
  }

  if (action instanceof GetAllPermissionsFailedAction) {
    return tassign(state, {
      permissions: [],
      permissionsLoaded: false,
      permissionsLoading: false,
    } as IRoleState);
  }

  if (action instanceof AddRoleAction) {
    return tassign(state, {
      rolesLoading: true,
      rolesLoaded: false,
    } as IRoleState);
  }

  if (action instanceof AddRoleSuccessAction) {
    return tassign(state, {
      rolesLoading: false,
      rolesLoaded: true,
      roles: updateAllRoles(action.payload),
    } as IRoleState);
  }

  if (action instanceof AddRoleFailedAction) {
    return tassign(state, {
      rolesLoading: false,
      rolesLoaded: false,
    } as IRoleState);
  }

  if (action instanceof EditRoleAction) {
    return tassign(state, {
      rolesLoading: true,
      rolesLoaded: false,
    } as IRoleState);
  }

  if (action instanceof EditRoleSuccessAction) {
    return tassign(state, {
      rolesLoading: false,
      rolesLoaded: true,
      roles: updateAllRoles(action.payload),
    } as IRoleState);
  }

  if (action instanceof EditRoleFailedAction) {
    return tassign(state, {
      rolesLoading: false,
      rolesLoaded: false,
    } as IRoleState);
  }

  if (action instanceof DeleteRoleAction) {
    return tassign(state, {
      rolesLoading: true,
      rolesLoaded: false,
    } as IRoleState);
  }

  if (action instanceof DeleteRoleSuccessAction) {
    return tassign(state, {
      rolesLoading: false,
      rolesLoaded: true,
      roles: updateAllRoles(action.payload),
    } as IRoleState);
  }

  if (action instanceof DeleteRoleFailedAction) {
    return tassign(state, {
      rolesLoading: false,
      rolesLoaded: false,
    } as IRoleState);
  }

  return state;

  function updateAllRoles(role: RoleViewModel): RoleViewModel[] {
    let allRoles: RoleViewModel[] = cloneDeep(state.roles);
    return allRoles.map((allRole: RoleViewModel) => {
      if (allRole.Id !== role.Id) {
        return allRole;
      }
      return role;
    });
  }
}
