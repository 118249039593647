import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '../shared/guards/role.guard';
import { DeactivationGuard } from '../shared/services/deactivation.guard';
import { RoleEditComponent } from './components/role-edit/role-edit.component';
import { RoleOverviewComponent } from './components/role-overview/role-overview.component';
import { UserFormComponent } from './components/user-main/user-edit/user-form.component';
import { UserMainComponent } from './components/user-main/user-main.component';
import { UserOverviewComponent } from './components/user-main/user-overview/user-overview.component';

const routes: Routes = [
  {
    path: '',
    component: UserMainComponent,
    children: [
      {
        path: '',
        redirectTo: 'user/overview',
        pathMatch: 'full',
      },
      {
        path: 'user/overview',
        component: UserOverviewComponent,
        canActivate: [RoleGuard],
        data: ['CanManageUsers'],
      },
      {
        path: 'user/add',
        component: UserFormComponent,
        canDeactivate: [DeactivationGuard],
        canActivate: [RoleGuard],
        data: ['CanManageUsers'],
      },
      {
        path: 'user/edit/:id',
        component: UserFormComponent,
        canDeactivate: [DeactivationGuard],
        canActivate: [RoleGuard],
        data: ['CanManageUsers'],
      },
      {
        path: 'role/overview',
        component: RoleOverviewComponent,
        canActivate: [RoleGuard],
        data: ['CanManageRoles'],
      },
      {
        path: 'role/add',
        component: RoleEditComponent,
        canDeactivate: [DeactivationGuard],
        canActivate: [RoleGuard],
        data: ['CanManageRoles'],
      },
      {
        path: 'role/edit/:id',
        component: RoleEditComponent,
        canDeactivate: [DeactivationGuard],
        canActivate: [RoleGuard],
        data: ['CanManageRoles'],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
