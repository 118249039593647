import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { MandatorViewModel, PostPutModuleRequest } from '../../../../../shared/apis/advis';
import { IGuardDeactivation } from '../../../../../shared/interfaces/guard-deactivation';
import {
  ErrorAddAction,
  ErrorTypeE,
  getMandators,
  UiElementTypeE,
} from '../../../../../shared/store';
import { IRootState } from '../../../../../root.state';
import { Store } from '@ngrx/store';
import { UntypedFormGroup } from '@angular/forms';
import {
  ADD_MODULE_SUCCESS,
  AddModuleAction,
  EDIT_MODULE_SUCCESS,
  EditModuleAction,
  GET_MODULE_SUCCESS,
  GetModuleAction,
  GetModuleSuccessAction,
} from '../../../../state/masterdata.action';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import MountingTypeEnum = PostPutModuleRequest.MountingTypeEnum;
import PriceCategoryEnum = PostPutModuleRequest.PriceCategoryEnum;
import { generateEmptyLocalizations } from '../../../../../shared/components/localized-values/generateEmptyLocalizations';
import { checkSpecialCharacters } from '../../../../../shared/utils/validation-util';

@Component({
  selector: 'ac-masterdata-modules-form',
  templateUrl: './masterdata-modules-form.component.html',
  styleUrls: ['./masterdata-modules-form.component.scss'],
})
export class MasterdataModulesFormComponent implements OnInit, OnDestroy, IGuardDeactivation {
  private subscriptions: Subscription = new Subscription();

  module: PostPutModuleRequest = {} as PostPutModuleRequest;
  initialModule: PostPutModuleRequest = {} as PostPutModuleRequest;

  moduleId: number;

  isNew: boolean = true;

  moduleFormTitle: string = '';

  @ViewChild('moduleForm')
  moduleForm: UntypedFormGroup;

  mandators: MandatorViewModel[] = [];
  mountingTypes: string[];
  priceCategories: string[];

  constructor(
    private route: ActivatedRoute,
    private store: Store<IRootState>,
    private router: Router,
    private translate: TranslateService,
    private action$: Actions
  ) {
    // empty
  }

  ngOnInit(): void {
    let url$: Observable<UrlSegment[]> = this.route.url;
    let params$: Observable<Params> = this.route.params;

    this.subscriptions.add(
      combineLatest([url$, params$]).subscribe(([url, params]: any) => {
        let urlPath: string = url[1].path.toString();

        if (urlPath === 'edit') {
          if (params && params.id) {
            this.store.dispatch(new GetModuleAction(params.id));
            this.moduleId = params.id;
            this.loadModule();
            this.moduleFormTitle = 'Edit module';
            this.isNew = false;
          }
        } else {
          this.moduleFormTitle = 'Add module';
          this.module = {
            Id: '',
            LocalizedDataSheets: generateEmptyLocalizations(),
            LocalizedTitles: generateEmptyLocalizations(),
          } as PostPutModuleRequest;
        }
      })
    );

    this.subscriptions.add(
      this.store.select(getMandators).subscribe((mandators: MandatorViewModel[]) => {
        this.mandators = mandators;
      })
    );

    this.subscriptions.add(
      this.action$
        .pipe(ofType(ADD_MODULE_SUCCESS, EDIT_MODULE_SUCCESS), take(1))
        .subscribe(() => this.onBack())
    );

    this.mountingTypes = Object.keys(MountingTypeEnum);
    this.priceCategories = Object.keys(PriceCategoryEnum);
  }

  loadModule(): void {
    this.subscriptions.add(
      this.action$
        .pipe(ofType(GET_MODULE_SUCCESS), take(1))
        .subscribe((action: GetModuleSuccessAction) => {
          this.module = cloneDeep(action.payload as PostPutModuleRequest);
          this.initialModule = cloneDeep(this.module);
        })
    );
  }

  isFormValid(): boolean {
    return this.moduleForm && this.moduleForm.valid && checkSpecialCharacters(this.module.Id);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onBack(): void {
    this.router.navigate(['/pvmasterdata/modules/overview']);
  }

  onSave(): void {
    if (+this.module.Length > +this.module.Width) {
      return this.store.dispatch(
        new ErrorAddAction({
          type: ErrorTypeE.SAVE,
          data: this.translate.instant('PV_MASTERDATA.MODULES.ERROR.WIDTH_SHOULD_MORE_THAN_LENGTH'),
          uiElement: UiElementTypeE.DIALOG,
        })
      );
    }
    this.initialModule = cloneDeep(this.module);
    if (this.isNew) {
      this.store.dispatch(new AddModuleAction(this.module));
    } else {
      this.store.dispatch(new EditModuleAction(this.module));
    }
  }

  get canDeactivateSafely(): boolean {
    return isEqual(this.module, this.initialModule);
  }
}
