/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PostPutCarRequest { 
    Id?: string;
    Model: string;
    DetailedVersion?: string;
    Manufacturer: string;
    BatteryCapacity: number;
    MaxChargingPowerAc?: number;
    MaxChargingPowerDc?: number;
    ChargingPlugAc: string;
    ChargingPlugDc?: string;
    ChargingPlugPositionAc: string;
    ChargingPlugPositionDc?: string;
    RangeAfterTwentyMinutes?: number;
    RangeAfterEightHours?: number;
    VehicleType: string;
    PowerType: string;
}
