<div *ngIf="mandator && mandatorId">
  <h2>{{ 'TASK_SETTINGS.TITLE' | translate }}</h2>
  <mat-tab-group>
    <mat-tab *ngFor="let tradeType of mandator.TradeTypeCodes" [label]="tradeType.Code">
      <div class="margin-top-20">
        <div fxLayout="column" *ngFor="let setting of taskSettings[tradeType.Code]; let i = index">
          <h3>{{ setting.settingTitle | translate }}</h3>
          <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field appearance="outline" fxFlex="20">
              <mat-label>{{ 'TASK_SETTINGS.DAYS_OFFSET' | translate }}</mat-label>
              <input
                [(ngModel)]="setting.daysOffset"
                matInput
                [required]="true"
                [min]="1"
                placeholder="{{ 'TASK_SETTINGS.DAYS_OFFSET' | translate }}"
                type="number"
                (blur)="onSettingsChanged()" />
              <mat-error *ngIf="setting.daysOffset && setting.daysOffset < 1">{{
                'Min allowed value is 1.'
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="20">
              <mat-label>{{ 'TASK_SETTINGS.NOTIFICATION_TIME' | translate }}</mat-label>
              <input
                placeholder="{{ 'TASK_SETTINGS.NOTIFICATION_TIME' | translate }}"
                matInput
                type="time"
                [(ngModel)]="setting.notificationTime"
                name="datePicker"
                [required]="true"
                (blur)="onSettingsChanged()" />
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="60">
              <mat-label>{{ 'TASK_SETTINGS.TASK_TITLE' | translate }}</mat-label>
              <input
                [(ngModel)]="setting.taskTitle"
                matInput
                [required]="true"
                placeholder="{{ 'TASK_SETTINGS.TASK_TITLE' | translate }}"
                type="text"
                (blur)="onSettingsChanged()" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
