import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import * as HtMasterdataReducer from './state/ht-masterdata.reducer';
import { HtMasterdataEffect } from './state/ht-masterdata.effects';
import { HtMasterdataMainComponent } from './components/ht-masterdata-main/ht-masterdata-main.component';
import { HtMasterdataDomesticWaterHeatingOverviewComponent } from './components/ht-masterdata-main/ht-masterdata-domestic-water-heating/ht-masterdata-domestic-water-heating-overview/ht-masterdata-domestic-water-heating-overview.component';
import { HtMasterdataHeatGeneratorsOverviewComponent } from './components/ht-masterdata-main/ht-masterdata-heat-generators/ht-masterdata-heat-generators-overview/ht-masterdata-heat-generators-overview.component';
import { HtMasterdataWaterTanksOverviewComponent } from './components/ht-masterdata-main/ht-masterdata-water-tanks/ht-masterdata-water-tanks-overview/ht-masterdata-water-tanks-overview.component';
import { HtMasterdataNavigationComponent } from './shared/ht-masterdata-navigation/ht-masterdata-navigation.component';
import { HtMasterdataRoutingModule } from './ht-masterdata-routing.module';
import { HtMasterdataDomesticWaterHeatingFormComponent } from './components/ht-masterdata-main/ht-masterdata-domestic-water-heating/ht-masterdata-domestic-water-heating-form/ht-masterdata-domestic-water-heating-form.component';
import { HtMasterdataHeatGeneratorsFormComponent } from './components/ht-masterdata-main/ht-masterdata-heat-generators/ht-masterdata-heat-generators-form/ht-masterdata-heat-generators-form.component';
import { HtMasterdataWaterTanksFormComponent } from './components/ht-masterdata-main/ht-masterdata-water-tanks/ht-masterdata-water-tanks-form/ht-masterdata-water-tanks-form.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HtMasterdataStringGenerationControlsComponent } from './components/ht-masterdata-main/ht-masterdata-string-generation-controls/ht-masterdata-string-generation-controls.component';

@NgModule({
  declarations: [
    HtMasterdataMainComponent,
    HtMasterdataDomesticWaterHeatingOverviewComponent,
    HtMasterdataHeatGeneratorsOverviewComponent,
    HtMasterdataWaterTanksOverviewComponent,
    HtMasterdataNavigationComponent,
    HtMasterdataDomesticWaterHeatingFormComponent,
    HtMasterdataHeatGeneratorsFormComponent,
    HtMasterdataWaterTanksFormComponent,
    HtMasterdataStringGenerationControlsComponent,
  ],
  imports: [
    CommonModule,
    HtMasterdataRoutingModule,
    SharedModule,
    StoreModule.forFeature('htmasterdata', HtMasterdataReducer.reducer),
    EffectsModule.forFeature([HtMasterdataEffect]),
  ],
})
export class HtMasterdataModule {}
