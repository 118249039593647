/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalizedValueViewModel } from './localizedValueViewModel';


export interface PostPutStorageRequest { 
    Id: string;
    IsActive?: boolean;
    ArticleNumber?: string;
    Description?: string;
    Capacity?: number;
    PriceIndication?: number;
    StorageType?: PostPutStorageRequest.StorageTypeEnum;
    StorageChemistry?: PostPutStorageRequest.StorageChemistryEnum;
    LocalizedTitles?: Array<LocalizedValueViewModel>;
    CompatibleInverterTypes?: Array<string>;
    CompatibleInverterManufacturers?: Array<string>;
    EturnityComponentId?: string;
    /**
     * This storage is only available for the mandators in this list
     */
    MandatorIds?: Array<number>;
    ArViewerUrl?: string;
}
export namespace PostPutStorageRequest {
    export type StorageTypeEnum = 'AcSystem' | 'DcSystem' | 'AllInOne' | 'LowVoltage';
    export const StorageTypeEnum = {
        AcSystem: 'AcSystem' as StorageTypeEnum,
        DcSystem: 'DcSystem' as StorageTypeEnum,
        AllInOne: 'AllInOne' as StorageTypeEnum,
        LowVoltage: 'LowVoltage' as StorageTypeEnum
    };
    export type StorageChemistryEnum = 'Lithium' | 'Salt';
    export const StorageChemistryEnum = {
        Lithium: 'Lithium' as StorageChemistryEnum,
        Salt: 'Salt' as StorageChemistryEnum
    };
}
