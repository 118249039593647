import { Injectable } from '@angular/core';
import { IPrincipal } from '../interfaces/principal.interface';
import * as LocalStorageSetting from './local-storage-config';
import { Observable, of } from 'rxjs';
import { ViewSettingModel } from '../models/view-setting/view-settings.model';
import merge from 'lodash/merge';
import { IViewSettingBase } from '../models/view-setting/view-setting-base.model';
import { GlobalVsModel } from '../models/view-setting/global-vs.model';
import {
  defaultPageSettings,
  IPageSettings,
  resetPageSettingsAfterLoadLoacalStorage,
} from '../interfaces/Settings';
import { SetPageSettingsAction } from '../store';
import { Store } from '@ngrx/store';
import { IRootState } from '../../root.state';

export enum ViewSettingKeyE {
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // Do not change the order of the enum
  // Do not delete entries from the enum
  // as it is used as key in the local storage
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  _AUTH = 1, // For internal use only
  _VERSION, // For internal use only
  USER_OVERVIEW_VIEW_SETTINGS,
  DOCUMENT_OVERVIEW_VIEW_SETTINGS,
  GLOBAL_VIEW_SETTINGS,
  PAGE_SETTING_KEYS = '6',
}

export class ViewSettingItem<T> {
  key: ViewSettingKeyE;
  setting: IViewSettingBase<T>;

  constructor(key: ViewSettingKeyE, setting: IViewSettingBase<T>) {
    this.key = key;
    this.setting = setting;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ViewSettingService {
  private storagePrefix: string;
  private version: number = 2; // Version of the settings, can be used for migration in the future

  constructor(private store: Store<IRootState>) {
    // empty
  }

  public getStoragePrefix(): string {
    return this.storagePrefix;
  }

  public setPrincipal(principal: IPrincipal): void {
    this.storagePrefix = principal.getUserId() + '.' + LocalStorageSetting.MAIN_KEY_VIEW_SETTING;

    // We store the principal together with the userId - maybe in the future we have to migrate a user with role X to role Y
    // therefor it's good to remember the settings. Currently unused.
    localStorage.setItem(this.storagePrefix + ViewSettingKeyE._AUTH, JSON.stringify(principal));
  }

  public load(): Observable<ViewSettingModel> {
    this.checkInitVersion();

    const viewSettings: ViewSettingModel = new ViewSettingModel();

    const globalViewSettings: GlobalVsModel = new GlobalVsModel();
    merge(
      globalViewSettings,
      JSON.parse(localStorage.getItem(this.storagePrefix + ViewSettingKeyE.GLOBAL_VIEW_SETTINGS))
    );
    if (globalViewSettings) {
      viewSettings.globalVs.tradeTypes = globalViewSettings.tradeTypes;
      viewSettings.globalVs.mandants = globalViewSettings.mandants;
    }

    this.loadSetting();
    return of(viewSettings);
  }

  public save<T>(viewSettingsItem: ViewSettingItem<T>): Observable<ViewSettingItem<T>> {
    localStorage.setItem(
      this.storagePrefix + viewSettingsItem.key,
      JSON.stringify(viewSettingsItem.setting)
    );
    return of(viewSettingsItem);
  }

  public saveSetting(pageSettings: IPageSettings): void {
    const key: string =
      this.storagePrefix + ViewSettingKeyE.PAGE_SETTING_KEYS + '.' + pageSettings.pageKey;
    if (!(key in localStorage)) {
      const pagekeys: string[] =
        JSON.parse(localStorage.getItem(this.storagePrefix + ViewSettingKeyE.PAGE_SETTING_KEYS)) ||
        [];
      pagekeys.push(pageSettings.pageKey);
      localStorage.setItem(
        this.storagePrefix + ViewSettingKeyE.PAGE_SETTING_KEYS,
        JSON.stringify(pagekeys)
      );
    }
    localStorage.setItem(key, JSON.stringify(pageSettings));
  }

  public loadSetting(): void {
    const pagekeys: string[] =
      JSON.parse(localStorage.getItem(this.storagePrefix + ViewSettingKeyE.PAGE_SETTING_KEYS)) ||
      [];
    pagekeys.forEach((pageKey: string) => {
      const key: string = this.storagePrefix + ViewSettingKeyE.PAGE_SETTING_KEYS + '.' + pageKey;
      if (key in localStorage) {
        this.store.dispatch(
          new SetPageSettingsAction(
            resetPageSettingsAfterLoadLoacalStorage(JSON.parse(localStorage.getItem(key)))
          )
        );
      } else {
        // set Default
        this.store.dispatch(new SetPageSettingsAction(defaultPageSettings(key)));
      }
    });
  }

  private checkInitVersion(): void {
    const storageVersion: number = JSON.parse(
      localStorage.getItem(this.storagePrefix + ViewSettingKeyE._VERSION)
    );
    if (!storageVersion) {
      localStorage.setItem(
        this.storagePrefix + ViewSettingKeyE._VERSION,
        JSON.stringify(this.version)
      );
    }
  }
}
