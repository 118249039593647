/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GenericObjectdataTemplateListViewModel } from './genericObjectdataTemplateListViewModel';
import { GenericPropertyValueViewModel } from './genericPropertyValueViewModel';
import { LocalizedValueViewModel } from './localizedValueViewModel';
import { ProductlineFeeViewModel } from './productlineFeeViewModel';
import { ProductlineTarifViewModel } from './productlineTarifViewModel';


export interface GenericProductlineViewModel { 
    /**
     * Identification of the productline
     */
    Id?: number;
    /**
     * Mandator of the productline
     */
    MandatorId?: number;
    /**
     * Technical name
     */
    TechnicalName?: string;
    /**
     * Localized names
     */
    LocalizedNames?: Array<LocalizedValueViewModel>;
    /**
     * Description of the productline
     */
    Description?: string;
    /**
     * whether the productline is active
     */
    IsActive?: boolean;
    /**
     * whether the productline is used for automatic matching (Nbo etc.)
     */
    UseForAutomaticMatch?: boolean;
    /**
     * How long a variant's prices are valid after recalculation
     */
    VariantValidDays?: number;
    /**
     * Purchase Price Tarif for M type positions.
     */
    PurchasePriceTarifM?: number;
    /**
     * Purchase Price Tarif for L type positions.
     */
    PurchasePriceTarifL?: number;
    /**
     * Purchase Price Tarif for F type positions.
     */
    PurchasePriceTarifF?: number;
    /**
     * The generic properties of this productline
     */
    GenericProperties?: Array<GenericPropertyValueViewModel>;
    /**
     * The objectdata templates of this productline
     */
    ObjectdataTemplates?: Array<GenericObjectdataTemplateListViewModel>;
    /**
     * The tarifs of the productline
     */
    Tarifs?: Array<ProductlineTarifViewModel>;
    /**
     * The fees of the productline
     */
    Fees?: Array<ProductlineFeeViewModel>;
}
