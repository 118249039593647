import { IRootState } from '../../root.state';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { ModuleListViewModel } from '../../shared/apis/advis';
import { IPvMasterdataState } from './masterdata.state';

export const getPvMasterdataState: (state: IRootState) => IPvMasterdataState = (
  state: IRootState
) => state.pvmasterdata;

export const getModulesFiltered: MemoizedSelector<IRootState, ModuleListViewModel[]> =
  createSelector(getPvMasterdataState, (state: IPvMasterdataState) => state.modules);

export const getModulesLoading: MemoizedSelector<IRootState, boolean> = createSelector(
  getPvMasterdataState,
  (state: IPvMasterdataState) => state.modulesLoading
);

export const getModulesLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getPvMasterdataState,
  (state: IPvMasterdataState) => state.modulesLoaded
);

export const getStorageFiltered: MemoizedSelector<IRootState, ModuleListViewModel[]> =
  createSelector(getPvMasterdataState, (state: IPvMasterdataState) => state.storage);

export const getStorageLoading: MemoizedSelector<IRootState, boolean> = createSelector(
  getPvMasterdataState,
  (state: IPvMasterdataState) => state.storageLoading
);

export const getStorageLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getPvMasterdataState,
  (state: IPvMasterdataState) => state.storageLoaded
);

export const getShadingOptimizerFiltered: MemoizedSelector<IRootState, ModuleListViewModel[]> =
  createSelector(getPvMasterdataState, (state: IPvMasterdataState) => state.shadingOptimizers);

export const getShadingOptimizerLoading: MemoizedSelector<IRootState, boolean> = createSelector(
  getPvMasterdataState,
  (state: IPvMasterdataState) => state.shadingOptimizerLoading
);

export const getShadingOptimizerLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getPvMasterdataState,
  (state: IPvMasterdataState) => state.shadingOptimizerLoaded
);

export const getInverterFiltered: MemoizedSelector<IRootState, ModuleListViewModel[]> =
  createSelector(getPvMasterdataState, (state: IPvMasterdataState) => state.inverter);

export const getInverterLoading: MemoizedSelector<IRootState, boolean> = createSelector(
  getPvMasterdataState,
  (state: IPvMasterdataState) => state.inverterLoading
);

export const getInverterLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getPvMasterdataState,
  (state: IPvMasterdataState) => state.inverterLoaded
);
