import { IFieldConfig } from './../../models/sharedInterfaces';
import { UntypedFormGroup } from '@angular/forms';
import { Component } from '@angular/core';

@Component({
  selector: 'ac-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  field: IFieldConfig;
  group: UntypedFormGroup;
}
