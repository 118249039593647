import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatPseudoCheckbox } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { AutosizeModule } from 'ngx-autosize';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FileSelectComponent } from '../ighTheiaConverter/components/file-select/file-select.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CustomFileUploadComponent } from './components/custom-file-upload/custom-file-upload.component';
import { DateComponent } from './components/date/date.component';
import { CustomCommonDialogComponent } from './components/dialogs/custom-common-dialog/custom-common-dialog.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { InputComponent } from './components/input/input.component';
import { LocalizedValuesComponent } from './components/localized-values/localized-values.component';
import { MandatorSelectorComponent } from './components/mandator-selector/mandator-selector.component';
import { SelectAllOptionComponent } from './components/mandator-selector/select-all-option/select-all-option.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { OverviewFilterComponent } from './components/overview-filter/overview-filter.component';
import { SelectComponent } from './components/select/select.component';
import { TradeTypeSelectorComponent } from './components/trade-type-selector/trade-type-selector.component';
import { VerticalDividerComponent } from './components/vertical-divider/vertical-divider.component';
import { DynamicFieldDirective } from './directives/dynamic-field.directive';
import { MatSelectNoneOptionDirective } from './directives/mat-select-none-option.directive';
import { MandatorMapPipe } from './pipes/mandator-map.pipe';
import { RolesMapPipe } from './pipes/roles-map.pipe';
import { sharedReducer } from './store';
import { SharedEffect } from './store/shared.effect';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { StringGenerationComponent } from './components/string-generation-dialog/string-generation-dialog.component';
import { StringGenerationBlockComponent } from './components/string-generation-block/string-generation-block.component';

const FLEX_LAYOUT_MODULES: any[] = [FlexLayoutModule];

const ANGULAR_MODULES: any[] = [CommonModule, FormsModule, ReactiveFormsModule];

const MATERIAL_MODULES: any[] = [
  MatButtonModule,
  MatButtonToggleModule,
  MatRadioModule,
  MatCardModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatInputModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatSnackBarModule,
  MatSidenavModule,
  MatTabsModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatGridListModule,
  MatDialogModule,
  MatTableModule,
  MatExpansionModule,
  MatSliderModule,
  MatChipsModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatPaginatorModule,
  MatSortModule,
  MatBadgeModule,
  MatDividerModule,
];

const PIPES: any[] = [MandatorMapPipe, RolesMapPipe];

const MISC_MODULES: any[] = [NgxSpinnerModule];

const SHARED_COMPONENTS: any[] = [
  MandatorSelectorComponent,
  TradeTypeSelectorComponent,
  VerticalDividerComponent,
];

@NgModule({
  imports: [
    ANGULAR_MODULES,
    MATERIAL_MODULES,
    FLEX_LAYOUT_MODULES,
    MISC_MODULES,
    StoreModule.forFeature('shared', sharedReducer),
    EffectsModule.forFeature([SharedEffect]),
    TranslateModule,
    AutosizeModule,
    MatPseudoCheckbox,
  ],
  declarations: [
    PIPES,
    SHARED_COMPONENTS,
    FileSelectComponent,
    VerticalDividerComponent,
    InputComponent,
    SelectComponent,
    CheckboxComponent,
    DateComponent,
    DynamicFormComponent,
    DynamicFieldDirective,
    MatSelectNoneOptionDirective,
    MultiSelectComponent,
    OverviewFilterComponent,
    CustomCommonDialogComponent,
    CustomFileUploadComponent,
    LocalizedValuesComponent,
    SelectAllOptionComponent,
    StringGenerationComponent,
    StringGenerationBlockComponent,
  ],
  exports: [
    ANGULAR_MODULES,
    MATERIAL_MODULES,
    FLEX_LAYOUT_MODULES,
    MISC_MODULES,
    PIPES,
    TranslatePipe,
    SHARED_COMPONENTS,
    AutosizeModule,
    DynamicFormComponent,
    MatSelectNoneOptionDirective,
    OverviewFilterComponent,
    SelectComponent,
    CustomFileUploadComponent,
    LocalizedValuesComponent,
    SelectAllOptionComponent,
    FileSelectComponent,
    StringGenerationComponent,
    StringGenerationBlockComponent,
  ],
  providers: [PIPES, TranslateModule],
})
export class SharedModule {}
