import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';
import { IRootState } from '../../root.state';
import {
  GenericRulePropertiesViewModel,
  HeatingSubProductConnectionViewModel,
  MandatorViewModel,
  SharedAddressBookMasterDataViewModel,
  TradeTypeViewModel,
  UserOrganisationUnitViewModel,
} from '../apis/advis';
import { defaultPageSettings, IPageSettings } from '../interfaces/Settings';
import { GenericPropertiesViewModel } from '../apis/advis';
import { IMandatorSelectItem, ITradeTypeSelectItem } from './shared.reducer';
import { ISharedState } from './shared.state';

export const getSharedState: (state: IRootState) => ISharedState = (state: IRootState) =>
  state.shared;

export const getMasterDataLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterDataLoaded;
  }
);

export const getOrganisationUnits: MemoizedSelector<IRootState, UserOrganisationUnitViewModel[]> =
  createSelector(
    state => state,
    getSharedState,
    (state: IRootState, sharedState: ISharedState) => {
      return state.organisationUnit.organisationUnits?.length
        ? state.organisationUnit.organisationUnits
        : sharedState.userMasterData.OrganisationUnits;
    }
  );

export const getRoles: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.userMasterData.Roles;
  }
);

export const getOrganisationUnitLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.userMasterDataLoaded;
  }
);

export const getMandators: MemoizedSelector<IRootState, MandatorViewModel[]> = createSelector(
  state => state,
  getSharedState,
  (state: IRootState, sharedState: ISharedState) => {
    return state.mandator.mandators?.length
      ? state.mandator.mandators
      : sharedState.masterData.Mandators;
  }
);

export const getMandatorsWhichAreSupportedByGloballySetTradeTypes = createSelector(
  getSharedState,
  getMandators,
  (state: ISharedState, mandators: MandatorViewModel[]) => {
    const selectedTradeTypes: ITradeTypeSelectItem[] = state.selectableTradeTypes.filter(
      (tradeTypeSelectItem: ITradeTypeSelectItem) => tradeTypeSelectItem.selected
    );

    return mandators.filter(m =>
      m.TradeTypes.some(t => selectedTradeTypes.some(st => st.tradeTypeVm.Code === t.Code))
    );
  }
);

export const getSubProductConnectionTypes: MemoizedSelector<
  IRootState,
  HeatingSubProductConnectionViewModel[]
> = createSelector(getSharedState, (state: ISharedState) => {
  return state.masterData.HeatingMasterData.SubProductConnections;
});

export const getSupportedManufacturers: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.HeatingMasterData.Manufacturers;
  }
);

export const getHeatingTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.HeatingMasterData.HeatingTypes;
  }
);

export const getCoolingFunctionTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.HeatingMasterData.CoolingFunctions;
  }
);

export const getDomesticWaterHeatingConstructionTypes: MemoizedSelector<IRootState, string[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    return state.masterData.HeatingMasterData.DomesticWaterHeatingConstructionTypes;
  });

export const getWaterHeatingConstructionTypes: MemoizedSelector<IRootState, string[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    return state.masterData.HeatingMasterData.WaterTankElectricHeatingTypes;
  });

export const getWaterTypeTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.HeatingMasterData.WaterTankTypes;
  }
);

export const getCarManufacturers: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.Manufacturers;
  }
);

export const getChargingPlugTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.ChargingPlugTypes;
  }
);

export const getChargingPlugPositionTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.ChargingPlugPositionTypes;
  }
);

export const getVehicleTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.VehicleTypes;
  }
);

export const getPoweringTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.PoweringTypes;
  }
);

export const getInverterManufacturers: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.PvMasterData.InverterManufacturers;
  }
);

export const getInverterTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.PvMasterData.InverterTypes;
  }
);

// Selects 'Product-lines' properties from shared state
export const getProductlinesProperties: MemoizedSelector<IRootState, GenericPropertiesViewModel[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    return state.masterData.ProductlineProperties;
  });

// Selects 'ObjectdataTemplateProperties' properties from shared state
export const getObjectdataTemplateProperties: MemoizedSelector<
  IRootState,
  GenericPropertiesViewModel[]
> = createSelector(getSharedState, (state: ISharedState) => {
  return state.masterData.ObjectdataTemplateProperties;
});

// Selects 'PositionBuilder' properties from shared state
export const getPositionBuilderProperties: MemoizedSelector<
  IRootState,
  GenericPropertiesViewModel[]
> = createSelector(getSharedState, (state: ISharedState) => {
  return state.masterData.PositionBuilderProperties;
});

// Selects 'Productline' properties from shared state
export const getProductlineProperties: MemoizedSelector<IRootState, GenericPropertiesViewModel[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    return state.masterData.ProductlineProperties;
  });

// Selects 'Rule' properties from shared state
export const getRuleProperties: MemoizedSelector<IRootState, GenericRulePropertiesViewModel[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    return state.masterData.RuleProperties;
  });

/**
 * @deprecated: Observables are notified at every change of state
 */
export const getSelectedMandatorsVm: MemoizedSelector<IRootState, MandatorViewModel[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    const selected: IMandatorSelectItem[] = state.selectableMandators.filter(
      (mandatorSelectItem: IMandatorSelectItem) => mandatorSelectItem.selected
    );
    return selected.map((mandatorSelectItems: IMandatorSelectItem) => {
      return mandatorSelectItems.mandatorVm;
    });
  });

export const getSelectableMandators: MemoizedSelector<IRootState, IMandatorSelectItem[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    return state.selectableMandators;
  });

/**
 * @deprecated: Observables are notified at every change of state
 */
export const getSelectedTradeTypesVm: MemoizedSelector<IRootState, TradeTypeViewModel[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    const selected: ITradeTypeSelectItem[] = state.selectableTradeTypes.filter(
      (tradeTypeSelectItem: ITradeTypeSelectItem) => tradeTypeSelectItem.selected
    );
    return selected.map((tradeTypeSelectItem: ITradeTypeSelectItem) => {
      return tradeTypeSelectItem.tradeTypeVm;
    });
  });

export const getTradeTypes: MemoizedSelector<IRootState, TradeTypeViewModel[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.allTradeTypes.map((tradeTypeSelectItem: ITradeTypeSelectItem) => {
      return tradeTypeSelectItem.tradeTypeVm;
    });
  }
);

export const getComplexityTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.ComplexityTypes;
  }
);

export const getAddressBooks: MemoizedSelector<IRootState, SharedAddressBookMasterDataViewModel[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    return state.masterData.SharedAddressBooks;
  });

/**
 * Get the selectable tradeType for the mandators
 */
export const getSelectableTradeTypes: MemoizedSelector<IRootState, ITradeTypeSelectItem[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    return state.selectableTradeTypes;
  });

export const getGlobalErrors: MemoizedSelector<IRootState, any[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.errors;
  }
);

export const getGlobalSelectedMandators: MemoizedSelector<IRootState, any[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.selectedMandatorsViewSettings;
  }
);

export const getGlobalSelectedTradeTypes: MemoizedSelector<IRootState, any[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.selectedTradeTypesViewSettings;
  }
);

export const getSelectMultipleTradeTypeCodes: MemoizedSelector<IRootState, boolean> =
  createSelector(getSharedState, (state: ISharedState) => {
    return state.selectMultipleTradeTypeCodes;
  });

export const getTradeTypeCodeFilterActive: MemoizedSelector<IRootState, boolean> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.tradeTypeCodeFilterActive;
  }
);
export const getPageSettings: MemoizedSelectorWithProps<IRootState, string, IPageSettings> =
  createSelector(getSharedState, (state: ISharedState, key: string) => {
    const pageSettings: IPageSettings[] = state.pageSettings.filter(
      (pageSetting: IPageSettings) => pageSetting.pageKey === key
    );
    if (pageSettings.length > 0) {
      return pageSettings[0];
    } else {
      return defaultPageSettings(key);
    }
  });

// Emobility Master Data - Charging Station specific selectors
export const getChargingStationManufacturers: MemoizedSelector<IRootState, string[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.ChargingStationManufacturers;
  });

export const getBusinessTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.BusinessTypes;
  }
);

export const getChargingStationTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.ChargingStationTypes;
  }
);

export const getSmartEnergyTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.SmartEnergyTypes;
  }
);

export const getAutomaticPhaseChangeoverTypes: MemoizedSelector<IRootState, string[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.AutomaticPhaseChangeoverTypes;
  });

export const getAcConnectionCableTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.AcConnectionCableTypes;
  }
);

export const getInternetConnectionTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.InternetConnectionTypes;
  }
);

export const getRfidAuthorisationTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.RfidAuthorisationTypes;
  }
);

export const getLoadManagementFunctionalityTypes: MemoizedSelector<IRootState, string[]> =
  createSelector(getSharedState, (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.LoadManagementFunctionalityTypes;
  });

export const getDcConnectionCableTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.DcConnectionCableTypes;
  }
);

export const getLoadingDirectionTypes: MemoizedSelector<IRootState, string[]> = createSelector(
  getSharedState,
  (state: ISharedState) => {
    return state.masterData.EmobilityMasterData.LoadingDirectionTypes;
  }
);

export const getIntegratedEnergyMeasurementBillingFunctionTypes: MemoizedSelector<
  IRootState,
  string[]
> = createSelector(getSharedState, (state: ISharedState) => {
  return state.masterData.EmobilityMasterData.IntegratedEnergyMeasurementBillingFunctionTypes;
});
