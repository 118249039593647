/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ProductlineSummaryViewModel } from '../model/productlineSummaryViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ExcelPortService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Export articles for a specific mandator
     * 
     * @param mandatorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelPortExportArticles(mandatorId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public excelPortExportArticles(mandatorId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public excelPortExportArticles(mandatorId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public excelPortExportArticles(mandatorId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandatorId === null || mandatorId === undefined) {
            throw new Error('Required parameter mandatorId was null or undefined when calling excelPortExportArticles.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mandatorId !== undefined && mandatorId !== null) {
            queryParameters = queryParameters.set('mandatorId', <any>mandatorId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/admin/articles/export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export all rules of a given productline
     * 
     * @param tradeTypeCode the trade type code of the productline
     * @param productlineId the id of the productline to export the rules for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelPortExportRules(tradeTypeCode: string, productlineId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public excelPortExportRules(tradeTypeCode: string, productlineId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public excelPortExportRules(tradeTypeCode: string, productlineId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public excelPortExportRules(tradeTypeCode: string, productlineId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling excelPortExportRules.');
        }

        if (productlineId === null || productlineId === undefined) {
            throw new Error('Required parameter productlineId was null or undefined when calling excelPortExportRules.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines/${encodeURIComponent(String(productlineId))}/export-rules`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a summary of all productlines of the specified trade type  Only the productlines are shown for which the user has a mandator responsibility  &lt;param name&#x3D;\&quot;tradeTypeCode\&quot;&gt;the trade type code to filter for&lt;/param&gt;
     * 
     * @param tradeTypeCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelPortGetAllProductlines(tradeTypeCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProductlineSummaryViewModel>>;
    public excelPortGetAllProductlines(tradeTypeCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProductlineSummaryViewModel>>>;
    public excelPortGetAllProductlines(tradeTypeCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProductlineSummaryViewModel>>>;
    public excelPortGetAllProductlines(tradeTypeCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling excelPortGetAllProductlines.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProductlineSummaryViewModel>>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines/summary`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import all articles for specific mandators
     * 
     * @param mandatorIds comma separated mandator ids to which this import should apply
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelPortImportArticles(mandatorIds: string, file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public excelPortImportArticles(mandatorIds: string, file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public excelPortImportArticles(mandatorIds: string, file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public excelPortImportArticles(mandatorIds: string, file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandatorIds === null || mandatorIds === undefined) {
            throw new Error('Required parameter mandatorIds was null or undefined when calling excelPortImportArticles.');
        }

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling excelPortImportArticles.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mandatorIds !== undefined && mandatorIds !== null) {
            queryParameters = queryParameters.set('mandatorIds', <any>mandatorIds);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/articles/import`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import all rules to a given productline  A backup to Azure will be made before importing
     * 
     * @param tradeTypeCode the trade type code of the productline
     * @param productlineId the id of the productline to import the rules to
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelPortImportRules(tradeTypeCode: string, productlineId: number, file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public excelPortImportRules(tradeTypeCode: string, productlineId: number, file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public excelPortImportRules(tradeTypeCode: string, productlineId: number, file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public excelPortImportRules(tradeTypeCode: string, productlineId: number, file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling excelPortImportRules.');
        }

        if (productlineId === null || productlineId === undefined) {
            throw new Error('Required parameter productlineId was null or undefined when calling excelPortImportRules.');
        }

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling excelPortImportRules.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines/${encodeURIComponent(String(productlineId))}/import-rules`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
