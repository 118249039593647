<mat-form-field appearance="outline" class="demo-full-width margin-top" [formGroup]="group">
  <mat-label>{{ field.NameAlias || field.Name }}</mat-label>
  <mat-select
    multiple
    [required]="field.IsRequired"
    [placeholder]="field.NameAlias || field.Name"
    [formControlName]="field.Name">
    <mat-option *ngFor="let item of field.Values" [value]="item">{{ item }}</mat-option>
  </mat-select>
</mat-form-field>
