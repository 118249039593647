import { Component, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { IGuardDeactivation } from '../../../../../shared/interfaces/guard-deactivation';
import {
  ADD_WATER_TANK_SUCCESS,
  AddWaterTankAction,
  EDIT_WATER_TANK_SUCCESS,
  EditWaterTankAction,
  GET_WATER_TANK_SUCCESS,
  GetWaterTankAction,
  GetWaterTankSuccessAction,
} from '../../../../state/ht-masterdata.action';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import {
  LocalizedValueViewModel,
  PostPutWaterTankRequest,
  StringGeneration,
  WaterTankViewModel,
} from '../../../../../shared/apis/advis';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { HtMasterdataBaseFormComponent } from '../../../ht-masterdata-base-form/ht-masterdata-base-form.component';
import { Actions, ofType } from '@ngrx/effects';
import cloneDeep from 'lodash/cloneDeep';
import { IRootState } from '../../../../../root.state';
import { generateEmptyLocalizations } from '../../../../../shared/components/localized-values/generateEmptyLocalizations';
import { getWaterHeatingConstructionTypes, getWaterTypeTypes } from '../../../../../shared/store';
import { checkSpecialCharacters } from '../../../../../shared/utils/validation-util';
import LanguageEnum = LocalizedValueViewModel.LanguageEnum;
import { isEqual } from 'lodash';
import { HtMasterdataStringGenerationControlsComponent } from '../../ht-masterdata-string-generation-controls/ht-masterdata-string-generation-controls.component';
import { StringGenerationMapperService } from '../../../../../shared/components/string-generation-dialog/string-generation-mapper.service';

@Component({
  selector: 'ac-ht-masterdata-domestic-water-heating-form',
  templateUrl: './ht-masterdata-water-tanks-form.component.html',
  styleUrls: ['./ht-masterdata-water-tanks-form.component.sass'],
})
export class HtMasterdataWaterTanksFormComponent
  extends HtMasterdataBaseFormComponent
  implements OnInit, IGuardDeactivation
{
  waterTank: WaterTankViewModel = {
    LocalizedTitles: generateEmptyLocalizations(),
    LocalizedDataSheets: generateEmptyLocalizations(),
  } as WaterTankViewModel;

  @ViewChild('waterTankForm')
  waterTankForm: UntypedFormGroup;

  @ViewChild(HtMasterdataStringGenerationControlsComponent)
  public stringGenerationControlsComponent: HtMasterdataStringGenerationControlsComponent;

  initialWaterTank: WaterTankViewModel = cloneDeep(this.waterTank);

  waterTankTypes: string[];

  waterTankElectricHeatingTypes: string[] = [];

  onSaveStringGenerationSanitizeCallback: Subject<boolean> = new Subject<boolean>();

  constructor(
    private action$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private stringGenerationMapper: StringGenerationMapperService,
    public store: Store<IRootState>
  ) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const constructionTypes$: Observable<string[]> = this.store.select(
      getWaterHeatingConstructionTypes
    );

    this.subscriptions.add(
      constructionTypes$.subscribe((waterTankElectricHeatingTypes: string[]) => {
        this.waterTankElectricHeatingTypes = waterTankElectricHeatingTypes;
      })
    );

    const url$: Observable<UrlSegment[]> = this.route.url;
    const params$: Observable<Params> = this.route.params;

    this.subscriptions.add(
      combineLatest([url$, params$]).subscribe(([url, params]: any) => {
        let urlPath: string = url[1].path.toString();

        if (urlPath === 'edit') {
          if (params && params.id) {
            this.formTitle = this.translate.instant('HT_MASTERDATA.WATER_TANKS.EDIT_WATER_TANK');
            this.itemId = params.id;
            this.loadWaterTank();
            this.isNew = false;
          }
        } else {
          this.formTitle = this.translate.instant('HT_MASTERDATA.WATER_TANKS.ADD_WATER_TANK');
        }
      })
    );

    const waterTankTypes$: Observable<string[]> = this.store.select(getWaterTypeTypes);

    this.subscriptions.add(
      waterTankTypes$.subscribe((waterTankTypes: string[]) => {
        this.waterTankTypes = waterTankTypes;
      })
    );

    this.subscriptions.add(
      this.action$
        .pipe(ofType(ADD_WATER_TANK_SUCCESS, EDIT_WATER_TANK_SUCCESS), take(1))
        .subscribe(() => this.onBack())
    );
  }

  onStringGenerationDialogClosed(dialogData: StringGeneration): void {
    if (!dialogData || dialogData?.Blocks?.length === 0) {
      return;
    }

    this.waterTank.LocalizedDataSheets = this.stringGenerationMapper.mapRawDataToLocalizedDataSheet(
      this.waterTank.LocalizedDataSheets,
      dialogData.Language as LanguageEnum,
      dialogData.RawData
    );

    this.waterTank.StringGenerationContainer = this.stringGenerationMapper.mapDialogDataToContainer(
      dialogData,
      this.waterTank.StringGenerationContainer
    );
  }

  isFormValid(): boolean {
    return (
      this.waterTankForm &&
      this.waterTankForm.valid &&
      this.waterTank !== undefined &&
      checkSpecialCharacters(this.waterTank.Id)
    );
  }

  onBack(): void {
    this.router.navigate(['/ht-masterdata/water-tanks/overview']);
  }

  onSave(): void {
    this.initialWaterTank = cloneDeep(this.waterTank);
    this.onSaveStringGenerationSanitizeCallback.next(true);

    const postPutWaterTankRequest: PostPutWaterTankRequest = {
      Id: this.waterTank.Id,
      TechnicalTitle: this.waterTank.TechnicalTitle,
      ArticleNumber: this.waterTank.ArticleNumber,
      LocalizedTitles: this.waterTank.LocalizedTitles,
      StringGenerationContainer: this.waterTank.StringGenerationContainer,
      MandatorIds: this.waterTank.MandatorIds,
      SupportedManufacturers: this.waterTank.SupportedManufacturers,
      SubProductConnectionTypes: this.waterTank.SubProductConnectionTypes,
      SupportedHeatingTypes: this.waterTank.SupportedHeatingTypes,
      WaterTankConstructionType: this.waterTank.WaterTankConstructionType,
      WaterTankElectricHeatingType: this.waterTank.WaterTankElectricHeatingType,
      WaterTankType: this.waterTank.WaterTankType,
      WaterTankVolume: this.waterTank.WaterTankVolume,
      IsActive: this.waterTank.IsActive,
      Description: this.waterTank.Description,
      ArViewerUrl: this.waterTank.ArViewerUrl,
    } as PostPutWaterTankRequest;

    if (this.isNew) {
      this.store.dispatch(new AddWaterTankAction(postPutWaterTankRequest));
    } else {
      this.store.dispatch(new EditWaterTankAction(postPutWaterTankRequest));
    }
  }

  private loadWaterTank(): void {
    this.store.dispatch(new GetWaterTankAction(this.itemId));

    this.subscriptions.add(
      this.action$
        .pipe(ofType(GET_WATER_TANK_SUCCESS), take(1))
        .subscribe((action: GetWaterTankSuccessAction) => {
          this.waterTank = cloneDeep(action.payload as PostPutWaterTankRequest);
          this.initialWaterTank = cloneDeep(this.waterTank);
        })
    );
  }

  get canDeactivateSafely(): boolean {
    this.initialWaterTank.StringGenerationContainer = this.stringGenerationMapper.unsetRawData(
      this.initialWaterTank.StringGenerationContainer
    );

    return isEqual(this.waterTank, this.initialWaterTank);
  }
}
