import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as HtMasterdataAction from './ht-masterdata.action';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../../shared/services/config.service';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import {
  PostPutDomesticWaterHeatingRequest,
  PostPutHeatGeneratorRequest,
  PostPutWaterTankRequest,
} from '../../shared/apis/advis';
import { ErrorAddAction, ErrorTypeE, UiElementTypeE } from '../../shared/store';
import { HeatingMasterDataService } from '../../shared/apis/advis/api/heatingMasterData.service';
import {
  AddDomesticWaterHeatingFailedAction,
  AddHeatGeneratorFailedAction,
  AddWaterTankFailedAction,
  DeleteDomesticWaterHeatingFailedAction,
  DeleteHeatGeneratorFailedAction,
  DeleteWaterTankFailedAction,
  EditDomesticWaterHeatingFailedAction,
  EditHeatGeneratorFailedAction,
  EditWaterTankFailedAction,
  GetAllDomesticWaterHeatingFailedAction,
  GetAllHeatGeneratorsFailedAction,
  GetAllWaterTanksFailedAction,
  GetDomesticWaterHeatingFailedAction,
  GetHeatGeneratorFailedAction,
  GetWaterTankFailedAction,
} from './ht-masterdata.action';

@Injectable()
export class HtMasterdataEffect {
  allDomesticWaterHeatingLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.GET_ALL_DOMESTIC_WATER_HEATING),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.GetAllDomesticWaterHeatingAction) => action.payload),
      switchMap(() => {
        return this.htMasterdataService.heatingDomesticWaterHeatingsGetAll().pipe(
          map((viewModel: any) => {
            return new HtMasterdataAction.GetAllDomesticWaterHeatingSuccessAction(viewModel);
          }),
          catchError((e: any) =>
            of(new HtMasterdataAction.GetAllDomesticWaterHeatingFailedAction(e))
          )
        );
      })
    )
  );

  domesticWaterHeatingLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.GET_DOMESTIC_WATER_HEATING),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.GetDomesticWaterHeatingAction) => action.payload),
      switchMap((payload: string) => {
        return this.htMasterdataService.heatingDomesticWaterHeatingsGetById(payload).pipe(
          map((viewModel: any) => {
            return new HtMasterdataAction.GetDomesticWaterHeatingSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new HtMasterdataAction.GetDomesticWaterHeatingFailedAction(e)))
        );
      })
    )
  );

  domesticWaterHeatingAdd$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.ADD_DOMESTIC_WATER_HEATING),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.AddDomesticWaterHeatingAction) => action.payload),
      switchMap((payload: PostPutDomesticWaterHeatingRequest) => {
        return this.htMasterdataService.heatingDomesticWaterHeatingsPost(payload).pipe(
          map((viewModel: any) => {
            return new HtMasterdataAction.AddDomesticWaterHeatingSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new HtMasterdataAction.AddDomesticWaterHeatingFailedAction(e)))
        );
      })
    )
  );

  domesticWaterHeatingEdit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.EDIT_DOMESTIC_WATER_HEATING),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.EditDomesticWaterHeatingAction) => action.payload),
      switchMap((payload: PostPutDomesticWaterHeatingRequest) => {
        return this.htMasterdataService.heatingDomesticWaterHeatingsPut(payload.Id, payload).pipe(
          map((viewModel: any) => {
            return new HtMasterdataAction.EditDomesticWaterHeatingSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new HtMasterdataAction.EditDomesticWaterHeatingFailedAction(e)))
        );
      })
    )
  );

  domesticWaterHeatingDelete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.DELETE_DOMESTIC_WATER_HEATING),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.DeleteDomesticWaterHeatingAction) => action.payload),
      switchMap((payload: string) => {
        return this.htMasterdataService.heatingDomesticWaterHeatingsDelete(payload).pipe(
          map(() => {
            return new HtMasterdataAction.DeleteDomesticWaterHeatingSuccessAction(payload);
          }),
          catchError((e: any) =>
            of(new HtMasterdataAction.DeleteDomesticWaterHeatingFailedAction(e))
          )
        );
      })
    )
  );

  allHeatGeneratorsLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.GET_ALL_HEAT_GENERATORS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.GetAllHeatGeneratorsAction) => action.payload),
      switchMap(() => {
        return this.htMasterdataService.heatingHeatGeneratorsGetAll().pipe(
          map((viewModel: any) => {
            return new HtMasterdataAction.GetAllHeatGeneratorsSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new HtMasterdataAction.GetAllHeatGeneratorsFailedAction(e)))
        );
      })
    )
  );

  heatGeneratorLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.GET_HEAT_GENERATOR),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.GetHeatGeneratorAction) => action.payload),
      switchMap((payload: string) => {
        return this.htMasterdataService.heatingHeatGeneratorsGetById(payload).pipe(
          map((viewModel: any) => {
            return new HtMasterdataAction.GetHeatGeneratorSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new HtMasterdataAction.GetHeatGeneratorFailedAction(e)))
        );
      })
    )
  );

  heatGeneratorAdd$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.ADD_HEAT_GENERATOR),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.AddHeatGeneratorAction) => action.payload),
      switchMap((payload: PostPutHeatGeneratorRequest) => {
        return this.htMasterdataService.heatingHeatGeneratorsPost(payload).pipe(
          map((viewModel: any) => {
            return new HtMasterdataAction.AddHeatGeneratorSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new HtMasterdataAction.AddHeatGeneratorFailedAction(e)))
        );
      })
    )
  );

  heatGeneratorEdit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.EDIT_HEAT_GENERATOR),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.EditHeatGeneratorAction) => action.payload),
      switchMap((payload: PostPutHeatGeneratorRequest) => {
        return this.htMasterdataService.heatingHeatGeneratorsPut(payload.Id, payload).pipe(
          map((viewModel: any) => {
            return new HtMasterdataAction.EditHeatGeneratorSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new HtMasterdataAction.EditHeatGeneratorFailedAction(e)))
        );
      })
    )
  );

  heatGeneratorDelete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.DELETE_HEAT_GENERATOR),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.DeleteHeatGeneratorAction) => action.payload),
      switchMap((payload: string) => {
        return this.htMasterdataService.heatingHeatGeneratorsDelete(payload).pipe(
          map(() => {
            return new HtMasterdataAction.DeleteHeatGeneratorSuccessAction(payload);
          }),
          catchError((e: any) => of(new HtMasterdataAction.DeleteHeatGeneratorFailedAction(e)))
        );
      })
    )
  );

  allWaterTanksLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.GET_ALL_WATER_TANKS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.GetAllWaterTanksAction) => action.payload),
      switchMap(() => {
        return this.htMasterdataService.heatingWaterTanksGetAll().pipe(
          map((viewModel: any) => {
            return new HtMasterdataAction.GetAllWaterTanksSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new HtMasterdataAction.GetAllWaterTanksFailedAction(e)))
        );
      })
    )
  );

  waterTankLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.GET_WATER_TANK),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.GetWaterTankAction) => action.payload),
      switchMap((payload: string) => {
        return this.htMasterdataService.heatingWaterTanksGetById(payload).pipe(
          map((viewModel: any) => {
            return new HtMasterdataAction.GetWaterTankSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new HtMasterdataAction.GetWaterTankFailedAction(e)))
        );
      })
    )
  );

  waterTankAdd$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.ADD_WATER_TANK),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.AddWaterTankAction) => action.payload),
      switchMap((payload: PostPutWaterTankRequest) => {
        return this.htMasterdataService.heatingWaterTanksPost(payload).pipe(
          map((viewModel: any) => {
            return new HtMasterdataAction.AddWaterTankSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new HtMasterdataAction.AddWaterTankFailedAction(e)))
        );
      })
    )
  );

  waterTankEdit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.EDIT_WATER_TANK),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.EditWaterTankAction) => action.payload),
      switchMap((payload: PostPutWaterTankRequest) => {
        return this.htMasterdataService.heatingWaterTanksPut(payload.Id, payload).pipe(
          map((viewModel: any) => {
            return new HtMasterdataAction.EditWaterTankSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new HtMasterdataAction.EditWaterTankFailedAction(e)))
        );
      })
    )
  );

  waterTankDelete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HtMasterdataAction.DELETE_WATER_TANK),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: HtMasterdataAction.DeleteWaterTankAction) => action.payload),
      switchMap((payload: string) => {
        return this.htMasterdataService.heatingWaterTanksDelete(payload).pipe(
          map(() => {
            return new HtMasterdataAction.DeleteWaterTankSuccessAction(payload);
          }),
          catchError((e: any) => of(new HtMasterdataAction.DeleteWaterTankFailedAction(e)))
        );
      })
    )
  );

  saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        HtMasterdataAction.ADD_DOMESTIC_WATER_HEATING_FAILED,
        HtMasterdataAction.EDIT_DOMESTIC_WATER_HEATING_FAILED,
        HtMasterdataAction.DELETE_DOMESTIC_WATER_HEATING_FAILED,
        HtMasterdataAction.ADD_HEAT_GENERATOR_FAILED,
        HtMasterdataAction.EDIT_HEAT_GENERATOR_FAILED,
        HtMasterdataAction.DELETE_HEAT_GENERATOR_FAILED,
        HtMasterdataAction.ADD_WATER_TANK_FAILED,
        HtMasterdataAction.EDIT_WATER_TANK_FAILED,
        HtMasterdataAction.DELETE_WATER_TANK_FAILED
      ),
      map(
        (
          action:
            | EditDomesticWaterHeatingFailedAction
            | AddDomesticWaterHeatingFailedAction
            | DeleteDomesticWaterHeatingFailedAction
            | EditHeatGeneratorFailedAction
            | AddHeatGeneratorFailedAction
            | DeleteHeatGeneratorFailedAction
            | EditWaterTankFailedAction
            | AddWaterTankFailedAction
            | DeleteWaterTankFailedAction
        ) => {
          if (action.payload.error === undefined || action.payload.error === null) {
            const message: string = action.type.substr(action.type.indexOf(']') + 1);
            return new ErrorAddAction({
              type: ErrorTypeE.SAVE,
              data: message,
              uiElement: UiElementTypeE.DIALOG,
            });
          } else {
            return new ErrorAddAction({
              type: ErrorTypeE.SAVE,
              data:
                typeof action.payload.error === 'string'
                  ? action.payload.error
                  : action.payload.error.Message,
              uiElement: UiElementTypeE.DIALOG,
            });
          }
        }
      )
    )
  );

  loadFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        HtMasterdataAction.GET_ALL_DOMESTIC_WATER_HEATING_FAILED,
        HtMasterdataAction.GET_DOMESTIC_WATER_HEATING_FAILED,
        HtMasterdataAction.GET_ALL_HEAT_GENERATORS_FAILED,
        HtMasterdataAction.GET_HEAT_GENERATOR_FAILED,
        HtMasterdataAction.GET_ALL_WATER_TANKS_FAILED,
        HtMasterdataAction.GET_WATER_TANK_FAILED
      ),
      map(
        (
          action:
            | GetAllDomesticWaterHeatingFailedAction
            | GetDomesticWaterHeatingFailedAction
            | GetAllHeatGeneratorsFailedAction
            | GetHeatGeneratorFailedAction
            | GetAllWaterTanksFailedAction
            | GetWaterTankFailedAction
        ) => {
          if (action.payload.error === undefined || action.payload.error === null) {
            const message: string = action.type.substr(action.type.indexOf(']') + 1);
            return new ErrorAddAction({
              type: ErrorTypeE.LOAD,
              data: message,
              uiElement: UiElementTypeE.DIALOG,
            });
          } else {
            return new ErrorAddAction({
              type: ErrorTypeE.LOAD,
              data: action.payload.error,
              uiElement: UiElementTypeE.DIALOG,
            });
          }
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private htMasterdataService: HeatingMasterDataService,
    private config: ConfigService
  ) {
    // empty
  }
}
