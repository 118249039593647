import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MasterdataMainComponent } from './components/masterdata-main/masterdata-main.component';
import { MasterdataModuleOverviewComponent } from './components/masterdata-main/masterdata-modules/masterdata-modules-overview/masterdata-modules-overview.component';
import { MasterdataModulesFormComponent } from './components/masterdata-main/masterdata-modules/masterdata-modules-form/masterdata-modules-form.component';
import { MasterdataStoragesOverviewComponent } from './components/masterdata-main/masterdata-storage/masterdata-storage-overview/masterdata-storages-overview.component';
import { MasterdataStoragesFormComponent } from './components/masterdata-main/masterdata-storage/masterdata-storage-form/masterdata-storages-form.component';
import { MasterdataShadingOptimizersOverviewComponent } from './components/masterdata-main/masterdata-shading-optimizer/masterdata-storage-overview/masterdata-shading-optimizers-overview.component';
import { MasterdataShadingOptimizersFormComponent } from './components/masterdata-main/masterdata-shading-optimizer/masterdata-storage-form/masterdata-shading-optimizers-form.component';
import { MasterdataInvertersOverviewComponent } from './components/masterdata-main/masterdata-inverters/masterdata-inverters-overview/masterdata-inverters-overview.component';
import { MasterdataInvertersFormComponent } from './components/masterdata-main/masterdata-inverters/masterdata-inverters-form/masterdata-inverters-form.component';
import { DeactivationGuard } from '../shared/services/deactivation.guard';

const routes: Routes = [
  {
    path: '',
    component: MasterdataMainComponent,
    children: [
      {
        path: '',
        redirectTo: 'modules/overview',
        pathMatch: 'full',
      },
      {
        path: 'modules/overview',
        component: MasterdataModuleOverviewComponent,
      },
      {
        path: 'modules/add',
        component: MasterdataModulesFormComponent,
        canDeactivate: [DeactivationGuard],
      },
      {
        path: 'modules/edit/:id',
        component: MasterdataModulesFormComponent,
        canDeactivate: [DeactivationGuard],
      },
      {
        path: 'shading-optimizers/overview',
        component: MasterdataShadingOptimizersOverviewComponent,
      },
      {
        path: 'shading-optimizers/add',
        component: MasterdataShadingOptimizersFormComponent,
        canDeactivate: [DeactivationGuard],
      },
      {
        path: 'shading-optimizers/edit/:id',
        component: MasterdataShadingOptimizersFormComponent,
        canDeactivate: [DeactivationGuard],
      },
      {
        path: 'storages/overview',
        component: MasterdataStoragesOverviewComponent,
      },
      {
        path: 'storages/add',
        component: MasterdataStoragesFormComponent,
        canDeactivate: [DeactivationGuard],
      },
      {
        path: 'storages/edit/:id',
        component: MasterdataStoragesFormComponent,
        canDeactivate: [DeactivationGuard],
      },
      {
        path: 'inverters/overview',
        component: MasterdataInvertersOverviewComponent,
      },
      {
        path: 'inverters/add',
        component: MasterdataInvertersFormComponent,
        canDeactivate: [DeactivationGuard],
      },
      {
        path: 'inverters/edit/:id',
        component: MasterdataInvertersFormComponent,
        canDeactivate: [DeactivationGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PvMasterdataRoutingModule {}
