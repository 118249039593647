import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ac-pv-masterdata-navigation',
  templateUrl: './pv-masterdata-navigation.component.html',
  styleUrls: ['./pv-masterdata-navigation.component.scss'],
})
export class PvMasterdataNavigationComponent implements OnInit {
  routeLinkToAdd: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    let url: string = this.router.url;
    this.routeLinkToAdd = `${url.substring(0, url.lastIndexOf('/') + 1)}add`;
  }
}
