import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  OnChanges,
} from '@angular/core';
import { MatPseudoCheckboxState } from '@angular/material/core';
import {
  MAT_OPTION_PARENT_COMPONENT,
  MatOptgroup,
  MatOption,
  MatOptionParentComponent,
} from '@angular/material/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'ac-select-all-option',
  template: `
    <mat-pseudo-checkbox
      class="mat-option-pseudo-checkbox"
      [state]="checkboxState"
      [disabled]="disabled"
      [ngClass]="selected ? 'bg-accent' : ''">
    </mat-pseudo-checkbox>

    <span class="mat-option-text">
      {{ title }}
    </span>
  `,
  styleUrls: ['./select-all-option.component.scss'],
})
export class SelectAllOptionComponent extends MatOption implements OnInit, OnDestroy, OnChanges {
  @Input() control: AbstractControl;
  @Input() title: string;
  @Input() values: any[] = [];
  @Input() selectedItems: any[] = [];

  @HostBinding('class') cssClass: string = 'mat-option';

  constructor(
    elementRef: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    @Optional()
    @Inject(MAT_OPTION_PARENT_COMPONENT)
    parent: MatOptionParentComponent,
    @Optional() group: MatOptgroup
  ) {
    super(elementRef, changeDetectorRef, parent, group);
  }

  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges(): void {
    if (this.selectedItems.length === 0) {
      this.refresh();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  @HostListener('click') toggleSelection(): void {
    if (!this.selected) {
      this.deselect();
    } else {
      this.select();
    }

    this.control.setValue(this.selected ? this.values : []);
  }

  get selectedItemsCount(): number {
    return this.control && Array.isArray(this.control.value)
      ? this.control.value.filter((el: any) => el !== null).length
      : 0;
  }

  get selectedAll(): boolean {
    return this.selectedItemsCount === this.values.length;
  }

  get selectedPartially(): boolean {
    const selectedItemsCount: number = this.selectedItemsCount;

    return selectedItemsCount > 0 && selectedItemsCount < this.values.length;
  }

  get checkboxState(): MatPseudoCheckboxState {
    let state: MatPseudoCheckboxState = 'unchecked';

    if (this.selectedAll) {
      state = 'checked';
    } else if (this.selectedPartially) {
      state = 'indeterminate';
    }

    return state;
  }

  refresh(): void {
    if (this.selectedItemsCount > 0) {
      this.select();
    } else {
      this.deselect();
    }
  }
}
