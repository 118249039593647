import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';

export enum TypeE {
  DEFAULT,
  PRIMARY,
  ALERT,
}

@Injectable()
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {
    // empty
  }

  public notifySimple(
    message: string,
    type: TypeE = TypeE.DEFAULT
  ): MatSnackBarRef<SimpleSnackBar> {
    return this.open(message, undefined, type, 3000);
  }

  public notifyWithAction(
    message: string,
    action: string,
    type: TypeE = TypeE.DEFAULT,
    timeout: number = 10000
  ): MatSnackBarRef<SimpleSnackBar> {
    return this.open(message, action, type, timeout);
  }

  public close(): void {
    if (this.snackBar !== undefined) {
      this.snackBar.dismiss();
    }
  }

  private open(
    message: string,
    action: string,
    type: TypeE,
    timeout: number
  ): MatSnackBarRef<SimpleSnackBar> {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.duration = timeout;

    const cssClasses: string[] = [];
    switch (type) {
      case TypeE.PRIMARY:
        cssClasses.push('snackbar-primary');
        break;

      case TypeE.ALERT:
        cssClasses.push('snackbar-alert');
        break;

      default:
        // Nothing to do
        break;
    }
    config.panelClass = cssClasses;
    return this.snackBar.open(message, action, config);
  }
}
