import { IRootState } from '../../root.state';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { OrganisationUnitViewModel } from '../../shared/apis/advis';
import { IOrganisationUnitState } from './organisation-unit.state';
import { OrganisationUnitOverviewVsModel } from '../../shared/models/view-setting/organisation-unit-vs.model';

export const getOrganisationUnitState: (state: IRootState) => IOrganisationUnitState = (
  state: IRootState
) => state.organisationUnit;

export const getOrganisationUnitsFiltered: MemoizedSelector<
  IRootState,
  OrganisationUnitViewModel[]
> = createSelector(
  getOrganisationUnitState,
  (state: IOrganisationUnitState) => state.organisationUnits
);

export const getOrganisationUnitsLoading: MemoizedSelector<IRootState, boolean> = createSelector(
  getOrganisationUnitState,
  (state: IOrganisationUnitState) => state.organisationUnitsLoading
);

export const getOrganisationUnitsLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getOrganisationUnitState,
  (state: IOrganisationUnitState) => state.organisationUnitsLoaded
);

export const geOrganisationUnitOverviewViewSettings: MemoizedSelector<
  IRootState,
  OrganisationUnitOverviewVsModel
> = createSelector(
  getOrganisationUnitState,
  (state: IOrganisationUnitState) => state.organisationUnitOverviewViewSettings
);

export const getOrganisationUnitOverviewViewSettingsLoaded: MemoizedSelector<IRootState, boolean> =
  createSelector(
    getOrganisationUnitState,
    (state: IOrganisationUnitState) => state.organisationUnitOVerviewViewSettingsLoaded
  );
