/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ProjectChecklistTemplateViewModel { 
    /**
     * Identification of the template
     */
    Id?: number;
    /**
     * Template title
     */
    Title?: string;
    /**
     * Template description
     */
    Description?: string;
    /**
     * Short trade type code, like PV or HT
     */
    TradeTypeCode?: string;
    /**
     * Inactive templates cannot be chosen in PC.
     */
    IsActive?: boolean;
    /**
     * Controls if the template is available for sop
     */
    AvailableForSop?: boolean;
    /**
     * Connects checklist template to sop list
     */
    SopListId?: number;
    /**
     * List of mandator Ids for this template
     */
    MandatorIds?: Array<number>;
}
