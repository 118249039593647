import { IFieldConfig } from './../../models/sharedInterfaces';
import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ac-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent {
  field: IFieldConfig;
  group: UntypedFormGroup;
}
