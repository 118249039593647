/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PositionGroupTemplateViewModel { 
    /**
     * The id of the group template
     */
    Id?: number;
    /**
     * The display order of the group
     */
    Order?: number;
    /**
     * The display title in German
     */
    TitleGerman?: string;
    /**
     * The display title in French
     */
    TitleFrench?: string;
    /**
     * The display title in Italian
     */
    TitleItalian?: string;
    /**
     * The technical title.
     */
    TechnicalTitle?: string;
    /**
     * Gets or sets the mandator identifier.
     */
    MandatorId?: number;
    GroupType?: PositionGroupTemplateViewModel.GroupTypeEnum;
    /**
     * The number of builders that use the group template
     */
    UsageCount?: number;
}
export namespace PositionGroupTemplateViewModel {
    export type GroupTypeEnum = 'None' | 'Installation' | 'ChargingStationHardware';
    export const GroupTypeEnum = {
        None: 'None' as GroupTypeEnum,
        Installation: 'Installation' as GroupTypeEnum,
        ChargingStationHardware: 'ChargingStationHardware' as GroupTypeEnum
    };
}
