import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProjectReportingMainComponent } from './components/project-reporting-main/project-reporting-main.component';
import { ProjectReportingOverviewComponent } from './components/project-reporting-overview/project-reporting-overview.component';
import { ProjectReportingRoutingModule } from './project-reporting-routing.module';
import { ProjectReportingEffects } from './state/project-reporting.effects';
import * as ProjectReportingReducer from './state/project-reporting.reducer';

@NgModule({
  declarations: [ProjectReportingMainComponent, ProjectReportingOverviewComponent],
  imports: [
    CommonModule,
    SharedModule,
    ProjectReportingRoutingModule,
    StoreModule.forFeature('projectReporting', ProjectReportingReducer.reducer),
    EffectsModule.forFeature([ProjectReportingEffects]),
  ],
})
export class ProjectReportingModule {}
