import { Action } from '@ngrx/store';
import { ImportProgressMessage } from '../../shared/apis/advis';

const prefix: string = '[ProjectReporting]';

export const IMPORT_PROJECT_FINANCES: string = `${prefix} Import Project finances`;
export const IMPORT_PROJECT_FINANCES_SUCCESS: string = `${prefix} Import Project finances success`;
export const IMPORT_PROJECT_FINANCES_FAILED: string = `${prefix} Import Project finances failed`;

export const ADD_PROJECT_FINANCES_PROGRESS_MESSAGE: string = `${prefix} Add Project finances progress message`;
export const GET_PROJECT_FINANCES_IMPORT_PROGRESS: string = `${prefix} Get Project finances import progress`;
export const GET_PROJECT_FINANCES_IMPORT_PROGRESS_SUCCESS: string = `${prefix} Get Project finances import progress success`;
export const GET_PROJECT_FINANCES_IMPORT_PROGRESS_FAILED: string = `${prefix} Get Project finances import progress failed`;

export class ImportProjectFinancesAction implements Action {
  readonly type: string = IMPORT_PROJECT_FINANCES;

  constructor(public payload: Blob) {}
}

export class ImportProjectFinancesSuccessAction implements Action {
  readonly type: string = IMPORT_PROJECT_FINANCES_SUCCESS;

  constructor(public payload: string) {}
}

export class ImportProjectFinancesFailedAction implements Action {
  readonly type: string = IMPORT_PROJECT_FINANCES_FAILED;

  constructor(public payload: string) {}
}

export class AddProjectFinancesProgressMessage implements Action {
  readonly type: string = ADD_PROJECT_FINANCES_PROGRESS_MESSAGE;

  constructor(public payload: ImportProgressMessage) {}
}

export class GetProjectFinancesProgressAction implements Action {
  readonly type: string = GET_PROJECT_FINANCES_IMPORT_PROGRESS;

  constructor() {}
}

export class GetProjectFinancesProgressSuccessAction implements Action {
  readonly type: string = GET_PROJECT_FINANCES_IMPORT_PROGRESS_SUCCESS;

  constructor(public payload: ImportProgressMessage[]) {}
}

export class GetProjectFinancesProgressFailedAction implements Action {
  readonly type: string = GET_PROJECT_FINANCES_IMPORT_PROGRESS_FAILED;

  constructor(public payload: string) {}
}

export type Actions =
  | ImportProjectFinancesAction
  | ImportProjectFinancesSuccessAction
  | ImportProjectFinancesFailedAction
  | AddProjectFinancesProgressMessage
  | GetProjectFinancesProgressAction
  | GetProjectFinancesProgressSuccessAction
  | GetProjectFinancesProgressFailedAction;
