<div *ngIf="isActive && (isMultiple$ | async) !== null">
  <div *ngIf="isMultiple$ | async">
    <mat-select
      class="mobile-trade-type-selector"
      placeholder="{{ 'GLOBAL.FILTER.TRADE_TYPES' | translate }}"
      multiple="true"
      data-cy="tradeTypeSelector"
      (closed)="onClose()"
      [(ngModel)]="selectedTradeTypes">
      <mat-option
        *ngFor="let item of selectableTradeTypes"
        [value]="item"
        data-cy="tradeTypeSelection">
        {{ item.tradeTypeVm.Code }}
      </mat-option>
    </mat-select>
  </div>

  <div *ngIf="!(isMultiple$ | async)">
    <mat-select
      class="mobile-trade-type-selector"
      placeholder="{{ 'GLOBAL.FILTER.TRADE_TYPES' | translate }}"
      data-cy="tradeTypeSelector"
      (closed)="onClose()"
      [ngModel]="selectedTradeTypes[0]"
      (ngModelChange)="setSingleTradeType($event)">
      <mat-option
        (onSelectionChange)="change($event)"
        *ngFor="let item of selectableTradeTypes"
        [value]="item"
        data-cy="tradeTypeSelection">
        {{ item.tradeTypeVm.Code }}
      </mat-option>
    </mat-select>
  </div>
</div>
