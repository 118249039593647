/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PositionBuilderRuleViewModel } from './positionBuilderRuleViewModel';


export interface PostPutValidationRuleRequest { 
    /**
     * Mandator id of the rule
     */
    MandatorIds?: Array<number>;
    /**
     * Description of the rule
     */
    Description?: string;
    RuleLevel?: PostPutValidationRuleRequest.RuleLevelEnum;
    /**
     * German translation
     */
    TranslationDe?: string;
    /**
     * French translation
     */
    TranslationFr?: string;
    /**
     * Italian Translation
     */
    TranslationIt?: string;
    Rule: PositionBuilderRuleViewModel;
}
export namespace PostPutValidationRuleRequest {
    export type RuleLevelEnum = 'Information' | 'Warning';
    export const RuleLevelEnum = {
        Information: 'Information' as RuleLevelEnum,
        Warning: 'Warning' as RuleLevelEnum
    };
}
