import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, filter } from 'rxjs/operators';
import {
  FileLinkViewModel,
  UserRequest,
  UserService,
  UserViewModel,
} from '../../../shared/apis/advis';
import { ConfigService } from '../../../shared/services/config.service';
import { ErrorAddAction, ErrorTypeE, UiElementTypeE } from '../../../shared/store';

import * as UserAction from './user.action';
import {
  ActivateUsersAction,
  ActivateUsersFailedAction,
  ActivateUsersSuccessAction,
  AddUserFailedAction,
  DeactivateUsersAction,
  DeactivateUsersFailedAction,
  DeactivateUsersSuccessAction,
  EditUserFailedAction,
  GetUserFailedAction,
  GetUsersFailedAction,
  IAddUser,
  IEditUserRequest,
} from './user.action';
import { isNullOrUndefined } from '../../../shared/utils/isNullOrUndefined';

@Injectable()
export class UserEffects {
  usersLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.GET_USERS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: UserAction.GetUsersAction) => action.payload),
      switchMap((filter: UserRequest) => {
        return this.userService
          .userGetUsers(
            filter.UserName,
            filter.Email,
            filter.Name,
            filter.CompanyName,
            filter.City,
            filter.ZipCode,
            filter.MandatorIds,
            filter.OrganisationUnitId,
            filter.Role,
            filter.IsActive
          )
          .pipe(
            map((viewModel: any) => {
              return new UserAction.GetUsersSuccessAction(viewModel);
            }),
            catchError((e: any) => of(new UserAction.GetUsersFailedAction(e)))
          );
      })
    )
  );

  userLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.GET_USER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: UserAction.GetUserAction) => action.payload),
      switchMap((userId: string) => {
        return this.userService.userGetUser(userId).pipe(
          map((viewModel: UserViewModel) => {
            return new UserAction.GetUserSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new UserAction.GetUserFailedAction(e)))
        );
      })
    )
  );

  getUserFiles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.GET_USER_SUCCESS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: UserAction.GetUserSuccessAction) => action.payload),
      filter((user: UserViewModel) => !isNullOrUndefined(user.Id)),
      switchMap((user: UserViewModel) => {
        return this.userService.userGetUserFiles(user.Id).pipe(
          map((res: FileLinkViewModel[]) => {
            return new UserAction.GetUserFilesSuccessAction(res);
          }),
          catchError((error: any) => of(new UserAction.GetUserFilesFailedAction(error)))
        );
      })
    )
  );

  userAdd$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.ADD_USER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: UserAction.AddUserAction) => action.payload),
      switchMap((addUserRequest: IAddUser) => {
        return this.userService
          .userPostUser(addUserRequest.userData)
          .pipe(
            switchMap((userViewModel: UserViewModel) => {
              if (isNullOrUndefined(addUserRequest.files.Picture.file)) {
                return of(userViewModel);
              }
              return this.userService.userPostUserFile(
                userViewModel.Id,
                'Picture',
                addUserRequest.files.Picture.file
              );
            })
          )
          .pipe(
            switchMap((resultSignature: UserViewModel) => {
              if (isNullOrUndefined(addUserRequest.files.signature.file)) {
                return of(resultSignature);
              }
              return this.userService.userPostUserFile(
                resultSignature.Id,
                'Signature',
                addUserRequest.files.signature.file
              );
            })
          )
          .pipe(map((result: UserViewModel) => new UserAction.AddUserSuccessAction(result)));
      }),
      catchError((error: any) => of(new UserAction.AddUserFailedAction(error)))
    )
  );

  userEdit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.EDIT_USER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: UserAction.EditUserAction) => action.payload),
      switchMap((editUserRequest: IEditUserRequest) => {
        return this.userService
          .userPutUser(editUserRequest.userId, editUserRequest.putUserRequest)
          .pipe(
            switchMap((userViewModel: UserViewModel) => {
              if (editUserRequest.files.Picture.deleted) {
                return this.userService.userDeleteUserFile(userViewModel.Id, 'Picture');
              }
              if (isNullOrUndefined(editUserRequest.files.Picture.file)) {
                return of(userViewModel);
              }
              return this.userService.userPostUserFile(
                userViewModel.Id,
                'Picture',
                editUserRequest.files.Picture.file
              );
            })
          )
          .pipe(
            switchMap((resultSignature: UserViewModel) => {
              if (editUserRequest.files.signature.deleted) {
                return this.userService.userDeleteUserFile(resultSignature.Id, 'Signature');
              }
              if (isNullOrUndefined(editUserRequest.files.signature.file)) {
                return of(resultSignature);
              }
              return this.userService.userPostUserFile(
                resultSignature.Id,
                'Signature',
                editUserRequest.files.signature.file
              );
            })
          )
          .pipe(map((result: UserViewModel) => new UserAction.EditUserSuccessAction(result)));
      }),
      catchError((error: any) => of(new UserAction.EditUserFailedAction(error)))
    )
  );

  activateUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.ACTIVATE_USERS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActivateUsersAction) => action.payload),
      switchMap((userId: string) => {
        return this.userService.userActivateUser(userId).pipe(
          map((users: any) => {
            return new ActivateUsersSuccessAction(users);
          }),
          catchError((e: any) => of(new ActivateUsersFailedAction(e)))
        );
      })
    )
  );

  deactivateUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.DEACTIVATE_USERS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: DeactivateUsersAction) => action.payload),
      switchMap((userId: string) => {
        return this.userService.userDeactivateUser(userId).pipe(
          map((users: any) => {
            return new DeactivateUsersSuccessAction(users);
          }),
          catchError((e: any) => of(new DeactivateUsersFailedAction(e)))
        );
      })
    )
  );

  saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserAction.ACTIVATE_USERS_FAILED,
        UserAction.DEACTIVATE_USERS_FAILED,
        UserAction.ADD_USER_FAILED,
        UserAction.EDIT_USER_FAILED
      ),
      map(
        (
          action:
            | ActivateUsersFailedAction
            | DeactivateUsersFailedAction
            | AddUserFailedAction
            | EditUserFailedAction
        ) => {
          if (action instanceof AddUserFailedAction && action.payload.status === 409) {
            return new ErrorAddAction({
              type: ErrorTypeE.SAVE,
              data: this.translate.instant('USERS.ERROR.USERNAME_EXISTS'),
              uiElement: UiElementTypeE.DIALOG,
            });
          } else if (action.payload.error === undefined || action.payload.error === null) {
            const message: string = action.type.substr(action.type.indexOf(']') + 1);
            return new ErrorAddAction({
              type: ErrorTypeE.SAVE,
              data: message,
              uiElement: UiElementTypeE.DIALOG,
            });
          } else {
            return new ErrorAddAction({
              type: ErrorTypeE.SAVE,
              data: action.payload.error.Message,
              uiElement: UiElementTypeE.DIALOG,
            });
          }
        }
      )
    )
  );

  loadFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.GET_USERS_FAILED, UserAction.GET_USER_FAILED),
      map((action: GetUsersFailedAction | GetUserFailedAction) => {
        if (action.payload.error === undefined || action.payload.error === null) {
          const message: string = action.type.substr(action.type.indexOf(']') + 1);
          return new ErrorAddAction({
            type: ErrorTypeE.SAVE,
            data: message,
            uiElement: UiElementTypeE.DIALOG,
          });
        } else {
          return new ErrorAddAction({
            type: ErrorTypeE.SAVE,
            data: action.payload.error,
            uiElement: UiElementTypeE.DIALOG,
          });
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private config: ConfigService,
    private translate: TranslateService
  ) {
    // empty
  }
}
