<div draggable="false" class="custom-file-upload">
  <input
    #customFileUploadInput
    type="file"
    class="custom-file-upload__input"
    [accept]="accept"
    (change)="onFileSelect($event)"
    [multiple]="isMultiple" />
  <button
    *ngIf="isIconButton"
    mat-icon-button
    class="custom-file-upload__button"
    [color]="buttonColor"
    (click)="onBrowseFiles()"
    [disabled]="disabled"
    title="Upload file">
    <mat-icon
      ><i class="material-icons">{{ iconName }}</i></mat-icon
    >
  </button>
  <button
    *ngIf="!isIconButton"
    mat-raised-button
    [color]="buttonColor"
    (click)="onBrowseFiles()"
    [disabled]="disabled"
    title="Upload file">
    <mat-icon
      ><i class="material-icons">{{ iconName }}</i></mat-icon
    >
  </button>
</div>
