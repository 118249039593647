import {
  AuthActions,
  LoginAction,
  LoginFailureAction,
  LoginSuccessAction,
  LoginSuccessSessionAction,
  LogoutAction,
  LogoutSessionAction,
} from './auth.action';
import { IAuthState, initAuthState } from './auth.state';

export function authReducer(state: IAuthState = initAuthState(), action: AuthActions): IAuthState {
  if (action instanceof LoginAction) {
    return {
      ...state,
      loggedIn: false,
      principal: undefined,
      error: undefined,
      pending: true,
    };
  }

  if (action instanceof LoginSuccessAction || action instanceof LoginSuccessSessionAction) {
    return {
      ...state,
      loggedIn: true,
      principal: action.payload,
      error: undefined,
      pending: false,
    };
  }

  if (action instanceof LoginFailureAction) {
    return {
      ...state,
      loggedIn: false,
      principal: undefined,
      error: action.payload,
      pending: false,
    };
  }

  if (action instanceof LogoutAction || action instanceof LogoutSessionAction) {
    return {
      ...state,
      loggedIn: false,
    };
  }

  return state;
}
