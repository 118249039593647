/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CopyProductlineRequest { 
    /**
     * The id of the productline to copy to  If it is null, it will create a new Productline
     */
    TargetProductlineId?: number;
    /**
     * The id of the mandator of the new productline if a new one gets created  It has to be set if TargetProductlineId is not set
     */
    TargetMandatorId?: number;
    /**
     * Whether tarifs should be copied
     */
    CopyTarifs?: boolean;
    /**
     * Whether tarifs should be copied
     */
    CopyFees?: boolean;
    /**
     * Whether rules (includes position builders and dependency rules) should be copied
     */
    CopyRules?: boolean;
    /**
     * Whether templates should be copied
     */
    CopyTemplates?: boolean;
    /**
     * Whether trade specific properties of the productline should be copied e.g.  ProductlineType (PV, EM)  Heating Power (HT)                Note that this will always be copied if a new productline is created from copy  as trade specific properties may be required.
     */
    CopyTradeSpecificProductlineProperties?: boolean;
}
