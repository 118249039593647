import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { IRootState } from '../../../root.state';
import { getUsersLoading } from '../../state/user/user.selectors';

@Component({
  selector: 'ac-user-main',
  templateUrl: './user-main.component.html',
  styleUrls: ['./user-main.component.scss'],
})
export class UserMainComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(private store: Store<IRootState>, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    const usersLoading$: Observable<boolean> = this.store.select(getUsersLoading);

    this.subscription.add(
      usersLoading$.subscribe((loading: boolean) => {
        loading ? this.spinner.show() : this.spinner.hide();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
