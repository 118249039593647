<h1 mat-dialog-title *ngIf="data.title && data.title.length > 0">{{ data.title }}</h1>
<div mat-dialog-content *ngIf="data.message && data.message.length > 0">
  <p class="confirm-dialog-message">{{ data.message }}</p>
</div>
<mat-dialog-actions *ngIf="data.isShowActionButtons" class="confirm-dialog-buttons">
  <button
    mat-button
    *ngIf="data.cancelButton && data.cancelButton.length > 0"
    color="accent"
    class="button"
    (click)="dialogClose(false)">
    {{ data.cancelButton | translate }}
  </button>
  <button
    mat-button
    *ngIf="data.acceptButton && data.acceptButton.length > 0"
    color="accent"
    class="button"
    (click)="dialogClose(true)">
    {{ data.acceptButton | translate }}
  </button>
</mat-dialog-actions>
