/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RegionalInformationService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Exports all regional information in xlsx format to download
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public regionalInformationExportRegionalInformationAsExcel(observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public regionalInformationExportRegionalInformationAsExcel(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public regionalInformationExportRegionalInformationAsExcel(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public regionalInformationExportRegionalInformationAsExcel(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/admin/regional/information/excel-export`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Exports the regional responsibility for a mandator in xlsx format to download
     * 
     * @param mandatorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public regionalInformationExportRegionalResponsibilityAsExcel(mandatorId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public regionalInformationExportRegionalResponsibilityAsExcel(mandatorId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public regionalInformationExportRegionalResponsibilityAsExcel(mandatorId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public regionalInformationExportRegionalResponsibilityAsExcel(mandatorId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandatorId === null || mandatorId === undefined) {
            throw new Error('Required parameter mandatorId was null or undefined when calling regionalInformationExportRegionalResponsibilityAsExcel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mandatorId !== undefined && mandatorId !== null) {
            queryParameters = queryParameters.set('mandatorId', <any>mandatorId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/admin/regional/responsibility/excel-export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Imports new regional information as an excel file  this overrides the existing information
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public regionalInformationImportRegionalInformationAsExcel(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public regionalInformationImportRegionalInformationAsExcel(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public regionalInformationImportRegionalInformationAsExcel(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public regionalInformationImportRegionalInformationAsExcel(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling regionalInformationImportRegionalInformationAsExcel.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/regional/information/excel-import`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Imports new regional information as an excel file  this overrides the existing information
     * 
     * @param mandatorIds comma separated mandator ids to which this import should apply
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public regionalInformationImportRegionalResponsibilityAsExcel(mandatorIds: string, file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public regionalInformationImportRegionalResponsibilityAsExcel(mandatorIds: string, file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public regionalInformationImportRegionalResponsibilityAsExcel(mandatorIds: string, file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public regionalInformationImportRegionalResponsibilityAsExcel(mandatorIds: string, file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandatorIds === null || mandatorIds === undefined) {
            throw new Error('Required parameter mandatorIds was null or undefined when calling regionalInformationImportRegionalResponsibilityAsExcel.');
        }

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling regionalInformationImportRegionalResponsibilityAsExcel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mandatorIds !== undefined && mandatorIds !== null) {
            queryParameters = queryParameters.set('mandatorIds', <any>mandatorIds);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/regional/responsibility/excel-import`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
