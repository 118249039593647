/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserCompanyAddressViewModel } from './userCompanyAddressViewModel';


export interface UserViewModel { 
    Id?: string;
    Title?: UserViewModel.TitleEnum;
    FirstName?: string;
    LastName?: string;
    JobTitle?: string;
    AdditionalName?: string;
    UserName?: string;
    Email?: string;
    PhoneNumber?: string;
    PictureFilename?: string;
    SignatureFilename?: string;
    SendEmailOnLeadAssigned?: boolean;
    MandatorId?: number;
    OrganisationUnitId?: number;
    CloudId?: string;
    IsActive?: boolean;
    Roles?: Array<string>;
    MandatorsInResponsibility?: Array<number>;
    CompanyAddress: UserCompanyAddressViewModel;
}
export namespace UserViewModel {
    export type TitleEnum = 'None' | 'Sir' | 'Madam' | 'Company' | 'Family';
    export const TitleEnum = {
        None: 'None' as TitleEnum,
        Sir: 'Sir' as TitleEnum,
        Madam: 'Madam' as TitleEnum,
        Company: 'Company' as TitleEnum,
        Family: 'Family' as TitleEnum
    };
}
