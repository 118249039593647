import { Action } from '@ngrx/store';
import {
  DomesticWaterHeatingViewModel,
  HeatGeneratorViewModel,
  PostPutDomesticWaterHeatingRequest,
  PostPutHeatGeneratorRequest,
  PostPutWaterTankRequest,
  WaterTankViewModel,
} from '../../shared/apis/advis';

const prefix: string = '[HT Masterdata]';

export const GET_ALL_DOMESTIC_WATER_HEATING: string = `${prefix} Get all domestic water heating`;
export const GET_ALL_DOMESTIC_WATER_HEATING_SUCCESS: string = `${prefix} Get all domestic water heating success`;
export const GET_ALL_DOMESTIC_WATER_HEATING_FAILED: string = `${prefix} Get all domestic water heating failed`;

export const GET_DOMESTIC_WATER_HEATING: string = `${prefix} Get domestic water heating`;
export const GET_DOMESTIC_WATER_HEATING_SUCCESS: string = `${prefix} Get domestic water heating success`;
export const GET_DOMESTIC_WATER_HEATING_FAILED: string = `${prefix} Get domestic water heating failed`;

export const ADD_DOMESTIC_WATER_HEATING: string = `${prefix} Add domestic water heating`;
export const ADD_DOMESTIC_WATER_HEATING_SUCCESS: string = `${prefix} Add domestic water heating success`;
export const ADD_DOMESTIC_WATER_HEATING_FAILED: string = `${prefix} Add domestic water heating failed`;

export const EDIT_DOMESTIC_WATER_HEATING: string = `${prefix} Edit domestic water heating`;
export const EDIT_DOMESTIC_WATER_HEATING_SUCCESS: string = `${prefix} Edit domestic water heating success`;
export const EDIT_DOMESTIC_WATER_HEATING_FAILED: string = `${prefix} Edit domestic water heating failed`;

export const DELETE_DOMESTIC_WATER_HEATING: string = `${prefix} Delete domestic water heating`;
export const DELETE_DOMESTIC_WATER_HEATING_SUCCESS: string = `${prefix} Delete domestic water heating success`;
export const DELETE_DOMESTIC_WATER_HEATING_FAILED: string = `${prefix} Delete domestic water heating failed`;

export const GET_ALL_HEAT_GENERATORS: string = `${prefix} Get all heat generators`;
export const GET_ALL_HEAT_GENERATORS_SUCCESS: string = `${prefix} Get all heat generators success`;
export const GET_ALL_HEAT_GENERATORS_FAILED: string = `${prefix} Get all heat generators failed`;

export const GET_HEAT_GENERATOR: string = `${prefix} Get heat generator`;
export const GET_HEAT_GENERATOR_SUCCESS: string = `${prefix} Get heat generator success`;
export const GET_HEAT_GENERATOR_FAILED: string = `${prefix} Get heat generator failed`;

export const ADD_HEAT_GENERATOR: string = `${prefix} Add heat generator`;
export const ADD_HEAT_GENERATOR_SUCCESS: string = `${prefix} Add heat generator success`;
export const ADD_HEAT_GENERATOR_FAILED: string = `${prefix} Add heat generator failed`;

export const EDIT_HEAT_GENERATOR: string = `${prefix} Edit heat generator`;
export const EDIT_HEAT_GENERATOR_SUCCESS: string = `${prefix} Edit heat generator success`;
export const EDIT_HEAT_GENERATOR_FAILED: string = `${prefix} Edit heat generator failed`;

export const DELETE_HEAT_GENERATOR: string = `${prefix} Delete heat generator`;
export const DELETE_HEAT_GENERATOR_SUCCESS: string = `${prefix} Delete heat generator success`;
export const DELETE_HEAT_GENERATOR_FAILED: string = `${prefix} Delete heat generator failed`;

export const GET_ALL_WATER_TANKS: string = `${prefix} Get all water tanks`;
export const GET_ALL_WATER_TANKS_SUCCESS: string = `${prefix} Get all water tanks success`;
export const GET_ALL_WATER_TANKS_FAILED: string = `${prefix} Get all water tanks failed`;

export const GET_WATER_TANK: string = `${prefix} Get water tank`;
export const GET_WATER_TANK_SUCCESS: string = `${prefix} Get water tank success`;
export const GET_WATER_TANK_FAILED: string = `${prefix} Get water tank failed`;

export const ADD_WATER_TANK: string = `${prefix} Add water tank`;
export const ADD_WATER_TANK_SUCCESS: string = `${prefix} Add water tank success`;
export const ADD_WATER_TANK_FAILED: string = `${prefix} Add water tank failed`;

export const EDIT_WATER_TANK: string = `${prefix} Edit water tank`;
export const EDIT_WATER_TANK_SUCCESS: string = `${prefix} Edit water tank success`;
export const EDIT_WATER_TANK_FAILED: string = `${prefix} Edit water tank failed`;

export const DELETE_WATER_TANK: string = `${prefix} Delete water tank`;
export const DELETE_WATER_TANK_SUCCESS: string = `${prefix} Delete water tank success`;
export const DELETE_WATER_TANK_FAILED: string = `${prefix} Delete water tank failed`;

export class GetAllDomesticWaterHeatingAction implements Action {
  readonly type: string = GET_ALL_DOMESTIC_WATER_HEATING;

  constructor(public payload: any = undefined) {}
}

export class GetAllDomesticWaterHeatingSuccessAction implements Action {
  readonly type: string = GET_ALL_DOMESTIC_WATER_HEATING_SUCCESS;

  constructor(public payload: DomesticWaterHeatingViewModel[]) {}
}

export class GetAllDomesticWaterHeatingFailedAction implements Action {
  readonly type: string = GET_ALL_DOMESTIC_WATER_HEATING_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetDomesticWaterHeatingAction implements Action {
  readonly type: string = GET_DOMESTIC_WATER_HEATING;

  constructor(public payload: string) {}
}

export class GetDomesticWaterHeatingSuccessAction implements Action {
  readonly type: string = GET_DOMESTIC_WATER_HEATING_SUCCESS;

  constructor(public payload: DomesticWaterHeatingViewModel) {}
}

export class GetDomesticWaterHeatingFailedAction implements Action {
  readonly type: string = GET_DOMESTIC_WATER_HEATING_FAILED;

  constructor(public payload: any = undefined) {}
}

export class AddDomesticWaterHeatingAction implements Action {
  readonly type: string = ADD_DOMESTIC_WATER_HEATING;

  constructor(public payload: PostPutDomesticWaterHeatingRequest) {}
}

export class AddDomesticWaterHeatingSuccessAction implements Action {
  readonly type: string = ADD_DOMESTIC_WATER_HEATING_SUCCESS;

  constructor(public payload: DomesticWaterHeatingViewModel) {}
}

export class AddDomesticWaterHeatingFailedAction implements Action {
  readonly type: string = ADD_DOMESTIC_WATER_HEATING_FAILED;

  constructor(public payload: any = undefined) {}
}

export class EditDomesticWaterHeatingAction implements Action {
  readonly type: string = EDIT_DOMESTIC_WATER_HEATING;

  constructor(public payload: PostPutDomesticWaterHeatingRequest) {}
}

export class EditDomesticWaterHeatingSuccessAction implements Action {
  readonly type: string = EDIT_DOMESTIC_WATER_HEATING_SUCCESS;

  constructor(public payload: DomesticWaterHeatingViewModel) {}
}

export class EditDomesticWaterHeatingFailedAction implements Action {
  readonly type: string = EDIT_DOMESTIC_WATER_HEATING_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeleteDomesticWaterHeatingAction implements Action {
  readonly type: string = DELETE_DOMESTIC_WATER_HEATING;

  constructor(public payload: any) {}
}

export class DeleteDomesticWaterHeatingSuccessAction implements Action {
  readonly type: string = DELETE_DOMESTIC_WATER_HEATING_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteDomesticWaterHeatingFailedAction implements Action {
  readonly type: string = DELETE_DOMESTIC_WATER_HEATING_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetAllHeatGeneratorsAction implements Action {
  readonly type: string = GET_ALL_HEAT_GENERATORS;

  constructor(public payload: any = undefined) {}
}

export class GetAllHeatGeneratorsSuccessAction implements Action {
  readonly type: string = GET_ALL_HEAT_GENERATORS_SUCCESS;

  constructor(public payload: HeatGeneratorViewModel[]) {}
}

export class GetAllHeatGeneratorsFailedAction implements Action {
  readonly type: string = GET_ALL_HEAT_GENERATORS_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetHeatGeneratorAction implements Action {
  readonly type: string = GET_HEAT_GENERATOR;

  constructor(public payload: string) {}
}

export class GetHeatGeneratorSuccessAction implements Action {
  readonly type: string = GET_HEAT_GENERATOR_SUCCESS;

  constructor(public payload: HeatGeneratorViewModel) {}
}

export class GetHeatGeneratorFailedAction implements Action {
  readonly type: string = GET_HEAT_GENERATOR_FAILED;

  constructor(public payload: any = undefined) {}
}

export class AddHeatGeneratorAction implements Action {
  readonly type: string = ADD_HEAT_GENERATOR;

  constructor(public payload: PostPutHeatGeneratorRequest) {}
}

export class AddHeatGeneratorSuccessAction implements Action {
  readonly type: string = ADD_HEAT_GENERATOR_SUCCESS;

  constructor(public payload: HeatGeneratorViewModel) {}
}

export class AddHeatGeneratorFailedAction implements Action {
  readonly type: string = ADD_HEAT_GENERATOR_FAILED;

  constructor(public payload: any = undefined) {}
}

export class EditHeatGeneratorAction implements Action {
  readonly type: string = EDIT_HEAT_GENERATOR;

  constructor(public payload: PostPutHeatGeneratorRequest) {}
}

export class EditHeatGeneratorSuccessAction implements Action {
  readonly type: string = EDIT_HEAT_GENERATOR_SUCCESS;

  constructor(public payload: HeatGeneratorViewModel) {}
}

export class EditHeatGeneratorFailedAction implements Action {
  readonly type: string = EDIT_HEAT_GENERATOR_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeleteHeatGeneratorAction implements Action {
  readonly type: string = DELETE_HEAT_GENERATOR;

  constructor(public payload: any) {}
}

export class DeleteHeatGeneratorSuccessAction implements Action {
  readonly type: string = DELETE_HEAT_GENERATOR_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteHeatGeneratorFailedAction implements Action {
  readonly type: string = DELETE_HEAT_GENERATOR_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetAllWaterTanksAction implements Action {
  readonly type: string = GET_ALL_WATER_TANKS;

  constructor(public payload: any = undefined) {}
}

export class GetAllWaterTanksSuccessAction implements Action {
  readonly type: string = GET_ALL_WATER_TANKS_SUCCESS;

  constructor(public payload: WaterTankViewModel[]) {}
}

export class GetAllWaterTanksFailedAction implements Action {
  readonly type: string = GET_ALL_WATER_TANKS_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetWaterTankAction implements Action {
  readonly type: string = GET_WATER_TANK;

  constructor(public payload: string) {}
}

export class GetWaterTankSuccessAction implements Action {
  readonly type: string = GET_WATER_TANK_SUCCESS;

  constructor(public payload: WaterTankViewModel) {}
}

export class GetWaterTankFailedAction implements Action {
  readonly type: string = GET_WATER_TANK_FAILED;

  constructor(public payload: any = undefined) {}
}

export class AddWaterTankAction implements Action {
  readonly type: string = ADD_WATER_TANK;

  constructor(public payload: PostPutWaterTankRequest) {}
}

export class AddWaterTankSuccessAction implements Action {
  readonly type: string = ADD_WATER_TANK_SUCCESS;

  constructor(public payload: WaterTankViewModel) {}
}

export class AddWaterTankFailedAction implements Action {
  readonly type: string = ADD_WATER_TANK_FAILED;

  constructor(public payload: any = undefined) {}
}

export class EditWaterTankAction implements Action {
  readonly type: string = EDIT_WATER_TANK;

  constructor(public payload: PostPutWaterTankRequest) {}
}

export class EditWaterTankSuccessAction implements Action {
  readonly type: string = EDIT_WATER_TANK_SUCCESS;

  constructor(public payload: WaterTankViewModel) {}
}

export class EditWaterTankFailedAction implements Action {
  readonly type: string = EDIT_WATER_TANK_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeleteWaterTankAction implements Action {
  readonly type: string = DELETE_WATER_TANK;

  constructor(public payload: any) {}
}

export class DeleteWaterTankSuccessAction implements Action {
  readonly type: string = DELETE_WATER_TANK_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteWaterTankFailedAction implements Action {
  readonly type: string = DELETE_WATER_TANK_FAILED;

  constructor(public payload: any = undefined) {}
}

export type Actions =
  | GetAllDomesticWaterHeatingAction
  | GetAllDomesticWaterHeatingSuccessAction
  | GetAllDomesticWaterHeatingFailedAction
  | GetDomesticWaterHeatingAction
  | GetDomesticWaterHeatingFailedAction
  | GetDomesticWaterHeatingSuccessAction
  | AddDomesticWaterHeatingAction
  | AddDomesticWaterHeatingFailedAction
  | AddDomesticWaterHeatingSuccessAction
  | EditDomesticWaterHeatingAction
  | EditDomesticWaterHeatingSuccessAction
  | EditDomesticWaterHeatingFailedAction
  | DeleteDomesticWaterHeatingAction
  | DeleteDomesticWaterHeatingSuccessAction
  | DeleteDomesticWaterHeatingFailedAction
  | GetAllHeatGeneratorsAction
  | GetAllHeatGeneratorsSuccessAction
  | GetAllHeatGeneratorsFailedAction
  | GetHeatGeneratorAction
  | GetHeatGeneratorFailedAction
  | GetHeatGeneratorSuccessAction
  | AddHeatGeneratorAction
  | AddHeatGeneratorFailedAction
  | AddHeatGeneratorSuccessAction
  | EditHeatGeneratorAction
  | EditHeatGeneratorSuccessAction
  | EditHeatGeneratorFailedAction
  | DeleteHeatGeneratorAction
  | DeleteHeatGeneratorSuccessAction
  | DeleteHeatGeneratorFailedAction
  | GetAllWaterTanksAction
  | GetAllWaterTanksSuccessAction
  | GetAllWaterTanksFailedAction
  | GetWaterTankAction
  | GetWaterTankFailedAction
  | GetWaterTankSuccessAction
  | AddWaterTankAction
  | AddWaterTankFailedAction
  | AddWaterTankSuccessAction
  | EditWaterTankAction
  | EditWaterTankSuccessAction
  | EditWaterTankFailedAction
  | DeleteWaterTankAction
  | DeleteWaterTankSuccessAction
  | DeleteWaterTankFailedAction;
