import { Component, Input } from '@angular/core';
import { LocalizedValueViewModel } from '../../apis/advis';

type LayoutType = 'row' | 'column';

@Component({
  selector: 'ac-localized-values',
  templateUrl: './localized-values.component.html',
  styleUrls: ['./localized-values.component.sass'],
})
export class LocalizedValuesComponent {
  @Input()
  localizedValues: LocalizedValueViewModel[];

  @Input()
  maxLength: number;

  @Input()
  title: string = '';

  @Input()
  layoutGap: string = '15px';

  @Input()
  layoutType: LayoutType = 'row';

  @Input()
  required: boolean = true;

  @Input()
  setReadonly: boolean = false;
}
