import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rolesMap' })
export class RolesMapPipe implements PipeTransform {
  constructor() {
    // empty
  }

  transform(rolesMap: string[]): any {
    return rolesMap.map((role: string) => role).join(', ');
  }
}
