/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PostPutSopListRequest } from '../model/postPutSopListRequest';
import { PostPutSopListStepRequest } from '../model/postPutSopListStepRequest';
import { SopListViewModel } from '../model/sopListViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class StandardOperatingProceduresSOPService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletes an existing SOP list
     * 
     * @param sopListId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminSopDeleteSopList(sopListId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public adminSopDeleteSopList(sopListId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public adminSopDeleteSopList(sopListId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public adminSopDeleteSopList(sopListId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sopListId === null || sopListId === undefined) {
            throw new Error('Required parameter sopListId was null or undefined when calling adminSopDeleteSopList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/sop/lists/${encodeURIComponent(String(sopListId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sopListId 
     * @param sopListStepId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminSopDeleteSopListStep(sopListId: number, sopListStepId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public adminSopDeleteSopListStep(sopListId: number, sopListStepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public adminSopDeleteSopListStep(sopListId: number, sopListStepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public adminSopDeleteSopListStep(sopListId: number, sopListStepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sopListId === null || sopListId === undefined) {
            throw new Error('Required parameter sopListId was null or undefined when calling adminSopDeleteSopListStep.');
        }

        if (sopListStepId === null || sopListStepId === undefined) {
            throw new Error('Required parameter sopListStepId was null or undefined when calling adminSopDeleteSopListStep.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/sop/lists/${encodeURIComponent(String(sopListId))}/steps/${encodeURIComponent(String(sopListStepId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all existing SOP lists
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminSopGetAllSopLists(observe?: 'body', reportProgress?: boolean): Observable<Array<SopListViewModel>>;
    public adminSopGetAllSopLists(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SopListViewModel>>>;
    public adminSopGetAllSopLists(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SopListViewModel>>>;
    public adminSopGetAllSopLists(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SopListViewModel>>(`${this.basePath}/api/admin/sop/lists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific SOP list by id
     * 
     * @param sopListId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminSopGetSopList(sopListId: number, observe?: 'body', reportProgress?: boolean): Observable<SopListViewModel>;
    public adminSopGetSopList(sopListId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SopListViewModel>>;
    public adminSopGetSopList(sopListId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SopListViewModel>>;
    public adminSopGetSopList(sopListId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sopListId === null || sopListId === undefined) {
            throw new Error('Required parameter sopListId was null or undefined when calling adminSopGetSopList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<SopListViewModel>(`${this.basePath}/api/admin/sop/lists/${encodeURIComponent(String(sopListId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new SOP list
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminSopPostSopList(body?: PostPutSopListRequest, observe?: 'body', reportProgress?: boolean): Observable<SopListViewModel>;
    public adminSopPostSopList(body?: PostPutSopListRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SopListViewModel>>;
    public adminSopPostSopList(body?: PostPutSopListRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SopListViewModel>>;
    public adminSopPostSopList(body?: PostPutSopListRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SopListViewModel>(`${this.basePath}/api/admin/sop/lists`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new SOP list step
     * 
     * @param body the create request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminSopPostSopListStep(body?: PostPutSopListStepRequest, observe?: 'body', reportProgress?: boolean): Observable<SopListViewModel>;
    public adminSopPostSopListStep(body?: PostPutSopListStepRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SopListViewModel>>;
    public adminSopPostSopListStep(body?: PostPutSopListStepRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SopListViewModel>>;
    public adminSopPostSopListStep(body?: PostPutSopListStepRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SopListViewModel>(`${this.basePath}/api/admin/sop/lists/steps`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an existing SOP list
     * 
     * @param sopListId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminSopPutSopList(sopListId: number, body?: PostPutSopListRequest, observe?: 'body', reportProgress?: boolean): Observable<SopListViewModel>;
    public adminSopPutSopList(sopListId: number, body?: PostPutSopListRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SopListViewModel>>;
    public adminSopPutSopList(sopListId: number, body?: PostPutSopListRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SopListViewModel>>;
    public adminSopPutSopList(sopListId: number, body?: PostPutSopListRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sopListId === null || sopListId === undefined) {
            throw new Error('Required parameter sopListId was null or undefined when calling adminSopPutSopList.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SopListViewModel>(`${this.basePath}/api/admin/sop/lists/${encodeURIComponent(String(sopListId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an existing SOP list step
     * 
     * @param body the update request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminSopPutSopListStep(body?: PostPutSopListStepRequest, observe?: 'body', reportProgress?: boolean): Observable<SopListViewModel>;
    public adminSopPutSopListStep(body?: PostPutSopListStepRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SopListViewModel>>;
    public adminSopPutSopListStep(body?: PostPutSopListStepRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SopListViewModel>>;
    public adminSopPutSopListStep(body?: PostPutSopListStepRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SopListViewModel>(`${this.basePath}/api/admin/sop/lists/steps`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
