/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DocumentTypeViewModel } from '../model/documentTypeViewModel';
import { PostPutDocumentTypeRequest } from '../model/postPutDocumentTypeRequest';
import { PostPutStageDependencyRequest } from '../model/postPutStageDependencyRequest';
import { StageDependencyOptionViewModel } from '../model/stageDependencyOptionViewModel';
import { StageDependencyViewModel } from '../model/stageDependencyViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProcessConfigurationService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletes an existing document type
     * 
     * @param documentTypeId id of the document type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTypeDeleteDocumentType(documentTypeId: number, observe?: 'body', reportProgress?: boolean): Observable<DocumentTypeViewModel>;
    public documentTypeDeleteDocumentType(documentTypeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTypeViewModel>>;
    public documentTypeDeleteDocumentType(documentTypeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTypeViewModel>>;
    public documentTypeDeleteDocumentType(documentTypeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentTypeId === null || documentTypeId === undefined) {
            throw new Error('Required parameter documentTypeId was null or undefined when calling documentTypeDeleteDocumentType.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<DocumentTypeViewModel>(`${this.basePath}/api/admin/process-configuration/document-types/${encodeURIComponent(String(documentTypeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all existing document types
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTypeGetAllDocumentTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentTypeViewModel>>;
    public documentTypeGetAllDocumentTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentTypeViewModel>>>;
    public documentTypeGetAllDocumentTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentTypeViewModel>>>;
    public documentTypeGetAllDocumentTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DocumentTypeViewModel>>(`${this.basePath}/api/admin/process-configuration/document-types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific document type by id
     * 
     * @param documentTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTypeGetDocumentType(documentTypeId: number, observe?: 'body', reportProgress?: boolean): Observable<DocumentTypeViewModel>;
    public documentTypeGetDocumentType(documentTypeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTypeViewModel>>;
    public documentTypeGetDocumentType(documentTypeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTypeViewModel>>;
    public documentTypeGetDocumentType(documentTypeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentTypeId === null || documentTypeId === undefined) {
            throw new Error('Required parameter documentTypeId was null or undefined when calling documentTypeGetDocumentType.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentTypeViewModel>(`${this.basePath}/api/admin/process-configuration/document-types/${encodeURIComponent(String(documentTypeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new document type
     * 
     * @param body the new document type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTypePostDocumentType(body?: PostPutDocumentTypeRequest, observe?: 'body', reportProgress?: boolean): Observable<DocumentTypeViewModel>;
    public documentTypePostDocumentType(body?: PostPutDocumentTypeRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTypeViewModel>>;
    public documentTypePostDocumentType(body?: PostPutDocumentTypeRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTypeViewModel>>;
    public documentTypePostDocumentType(body?: PostPutDocumentTypeRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DocumentTypeViewModel>(`${this.basePath}/api/admin/process-configuration/document-types`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an existing document type
     * 
     * @param documentTypeId id of the document type
     * @param body the new document type data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTypePutDocumentType(documentTypeId: number, body?: PostPutDocumentTypeRequest, observe?: 'body', reportProgress?: boolean): Observable<DocumentTypeViewModel>;
    public documentTypePutDocumentType(documentTypeId: number, body?: PostPutDocumentTypeRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTypeViewModel>>;
    public documentTypePutDocumentType(documentTypeId: number, body?: PostPutDocumentTypeRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTypeViewModel>>;
    public documentTypePutDocumentType(documentTypeId: number, body?: PostPutDocumentTypeRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentTypeId === null || documentTypeId === undefined) {
            throw new Error('Required parameter documentTypeId was null or undefined when calling documentTypePutDocumentType.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DocumentTypeViewModel>(`${this.basePath}/api/admin/process-configuration/document-types/${encodeURIComponent(String(documentTypeId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an existing stage dependency
     * 
     * @param stageDependencyId id of the stage dependency
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stageDependencyDeleteStageDependency(stageDependencyId: number, observe?: 'body', reportProgress?: boolean): Observable<StageDependencyViewModel>;
    public stageDependencyDeleteStageDependency(stageDependencyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StageDependencyViewModel>>;
    public stageDependencyDeleteStageDependency(stageDependencyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StageDependencyViewModel>>;
    public stageDependencyDeleteStageDependency(stageDependencyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stageDependencyId === null || stageDependencyId === undefined) {
            throw new Error('Required parameter stageDependencyId was null or undefined when calling stageDependencyDeleteStageDependency.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<StageDependencyViewModel>(`${this.basePath}/api/admin/process-configuration/stage-dependencies/${encodeURIComponent(String(stageDependencyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all existing stage dependencys
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stageDependencyGetAllStageDependencies(observe?: 'body', reportProgress?: boolean): Observable<Array<StageDependencyViewModel>>;
    public stageDependencyGetAllStageDependencies(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StageDependencyViewModel>>>;
    public stageDependencyGetAllStageDependencies(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StageDependencyViewModel>>>;
    public stageDependencyGetAllStageDependencies(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StageDependencyViewModel>>(`${this.basePath}/api/admin/process-configuration/stage-dependencies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific stage dependency by id
     * 
     * @param stageDependencyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stageDependencyGetStageDependency(stageDependencyId: number, observe?: 'body', reportProgress?: boolean): Observable<StageDependencyViewModel>;
    public stageDependencyGetStageDependency(stageDependencyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StageDependencyViewModel>>;
    public stageDependencyGetStageDependency(stageDependencyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StageDependencyViewModel>>;
    public stageDependencyGetStageDependency(stageDependencyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stageDependencyId === null || stageDependencyId === undefined) {
            throw new Error('Required parameter stageDependencyId was null or undefined when calling stageDependencyGetStageDependency.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<StageDependencyViewModel>(`${this.basePath}/api/admin/process-configuration/stage-dependencies/${encodeURIComponent(String(stageDependencyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stageDependencyGetStageDependencyOptions(observe?: 'body', reportProgress?: boolean): Observable<Array<StageDependencyOptionViewModel>>;
    public stageDependencyGetStageDependencyOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StageDependencyOptionViewModel>>>;
    public stageDependencyGetStageDependencyOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StageDependencyOptionViewModel>>>;
    public stageDependencyGetStageDependencyOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StageDependencyOptionViewModel>>(`${this.basePath}/api/admin/process-configuration/stage-dependencies/options`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new stage dependency
     * 
     * @param body the new stage dependency
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stageDependencyPostStageDependency(body?: PostPutStageDependencyRequest, observe?: 'body', reportProgress?: boolean): Observable<StageDependencyViewModel>;
    public stageDependencyPostStageDependency(body?: PostPutStageDependencyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StageDependencyViewModel>>;
    public stageDependencyPostStageDependency(body?: PostPutStageDependencyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StageDependencyViewModel>>;
    public stageDependencyPostStageDependency(body?: PostPutStageDependencyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<StageDependencyViewModel>(`${this.basePath}/api/admin/process-configuration/stage-dependencies`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an existing stage dependency
     * 
     * @param stageDependencyId id of the stage dependency
     * @param body the new stage dependency data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stageDependencyPutStageDependency(stageDependencyId: number, body?: PostPutStageDependencyRequest, observe?: 'body', reportProgress?: boolean): Observable<StageDependencyViewModel>;
    public stageDependencyPutStageDependency(stageDependencyId: number, body?: PostPutStageDependencyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StageDependencyViewModel>>;
    public stageDependencyPutStageDependency(stageDependencyId: number, body?: PostPutStageDependencyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StageDependencyViewModel>>;
    public stageDependencyPutStageDependency(stageDependencyId: number, body?: PostPutStageDependencyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stageDependencyId === null || stageDependencyId === undefined) {
            throw new Error('Required parameter stageDependencyId was null or undefined when calling stageDependencyPutStageDependency.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<StageDependencyViewModel>(`${this.basePath}/api/admin/process-configuration/stage-dependencies/${encodeURIComponent(String(stageDependencyId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
