import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FileSelectComponent } from '../../../ighTheiaConverter/components/file-select/file-select.component';
import { NotificationService, TypeE } from '../../../shared/services/notification.service';
import {
  AddProjectFinancesProgressMessage,
  GetProjectFinancesProgressAction,
  ImportProjectFinancesAction,
} from '../../state/project-reporting.action';
import * as ProjectReportingActions from '../../state/project-reporting.action';
import { ImportNotificationService } from '../../../signalR/import-notification.service';
import { getProjectReportingProgressMessages } from '../../state/project-reporting.selectors';
import { ImportProgressMessage } from '../../../shared/apis/advis';

@Component({
  selector: 'ac-project-reporting-overview',
  templateUrl: './project-reporting-overview.component.html',
  styleUrls: ['./project-reporting-overview.component.sass'],
})
export class ProjectReportingOverviewComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  public financesFile: File;
  public progressMessages: ImportProgressMessage[] = [];

  @ViewChild('financesFileSelect') financesFileSelect: FileSelectComponent;

  constructor(
    private store: Store,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private actions$: Actions,
    private importNotificationService: ImportNotificationService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.actions$
        .pipe(ofType(ProjectReportingActions.IMPORT_PROJECT_FINANCES_SUCCESS))
        .subscribe(() => {
          this.financesFile = undefined;
          this.financesFileSelect.resetFileName();
        })
    );

    this.subscriptions.add(
      this.store
        .select(getProjectReportingProgressMessages)
        .subscribe((progressMessages: ImportProgressMessage[]) => {
          this.progressMessages = progressMessages;
        })
    );

    this.subscriptions.add(
      this.importNotificationService
        .onProjectReportingImportProgress()
        .subscribe((message: ImportProgressMessage) => {
          this.store.dispatch(new AddProjectFinancesProgressMessage(message));
        })
    );

    this.store.dispatch(new GetProjectFinancesProgressAction());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onFinancesFileSelect(files: FileList | File): void {
    const file = files instanceof FileList ? files[0] : files;
    if (this.fileValid(file)) {
      this.financesFile = file;
    } else {
      this.notifyWrongFile();
      this.financesFileSelect.resetFileName();
    }
  }

  importFinances(): void {
    if (this.fileValid(this.financesFile)) {
      this.store.dispatch(new ImportProjectFinancesAction(this.financesFile));
    } else {
      this.notifyWrongFile();
      this.financesFileSelect.resetFileName();
    }
  }

  private fileValid(file: File): boolean {
    return file && file.name.substr(file.name.length - 5) === '.xlsx';
  }

  private notifyWrongFile(): void {
    this.notificationService.notifyWithAction(
      this.translate.instant('EXCELPORT.ERROR.WRONG_FILE'),
      this.translate.instant('COMMON.BTN_DISMISS'),
      TypeE.ALERT
    );
  }
}
