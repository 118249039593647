/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GenericPropertyValueViewModel } from './genericPropertyValueViewModel';
import { LocalizedValueViewModel } from './localizedValueViewModel';


export interface GenericObjectdataTemplateViewModel { 
    /**
     * Identification of the template
     */
    Id?: number;
    /**
     * Technical title
     */
    TechnicalTitle?: string;
    /**
     * Localized titles
     */
    LocalizedTitles?: Array<LocalizedValueViewModel>;
    /**
     * Whether the template is active
     */
    IsActive?: boolean;
    /**
     * The generic properties of this template
     */
    GenericProperties?: Array<GenericPropertyValueViewModel>;
}
