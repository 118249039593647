import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep, isEqual } from 'lodash';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IRootState } from '../../../root.state';
import {
  MandatorViewModel,
  PostPutRoleRequest,
  PostPutRoleTargetGroupRequest,
  RoleTargetGroupViewModel,
  RoleViewModel,
  UserViewModel,
} from '../../../shared/apis/advis';
import { IGuardDeactivation } from '../../../shared/interfaces/guard-deactivation';
import { getMandators } from '../../../shared/store';
import {
  AddRoleAction,
  ADD_ROLE_SUCCESS,
  EditRoleAction,
  EDIT_ROLE_SUCCESS,
  GetAllPermissionsAction,
  GetRoleAction,
  GetRoleSuccessAction,
  GET_ROLE_SUCCESS,
  GetTargetGroupSuccessAction,
  GetTargetGroupAction,
  GET_TARGET_GROUP_SUCCESS,
  AddTargetGroupAction,
  EditTargetGroupAction,
  AddRoleSuccessAction,
  EditRoleSuccessAction,
  ADD_TARGET_GROUP_SUCCESS,
  EDIT_TARGET_GROUP_SUCCESS,
} from '../../state/role/role.action';
import { getAllPermissions } from '../../state/role/role.selectors';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ac-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoleEditComponent implements OnInit, OnDestroy, IGuardDeactivation {
  private subscription: Subscription = new Subscription();

  role: RoleViewModel;
  initialRole: RoleViewModel;
  userPassword: string;
  roleId: string;
  isNew: boolean = false;
  roleFormTitle: string = '';

  pictureUrl: string;
  newPicture: File;
  removePicture: boolean;
  signatureUrl: string;
  newSignature: File;
  removeSignature: boolean;

  permissions: string[] = [];
  mandators: MandatorViewModel[] = [];
  isFormValid: boolean;
  isPictureChange: boolean;

  targetGroup: RoleTargetGroupViewModel = {} as RoleTargetGroupViewModel;

  @ViewChild('roleForm', { static: false })
  roleForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private store: Store<IRootState>,
    private router: Router,
    private action$: Actions,
    private snackBar: MatSnackBar
  ) {
    // empty
  }

  ngOnInit(): void {
    const url$: Observable<UrlSegment[]> = this.route.url;
    const params$: Observable<Params> = this.route.params;
    this.store.dispatch(new GetAllPermissionsAction());

    this.subscription.add(
      combineLatest([url$, params$]).subscribe(([url, params]: any) => {
        const urlPath: string = url[1].path.toString();

        if (urlPath === 'add') {
          this.roleFormTitle = 'Add role';
          this.isNew = true;
          this.role = {} as UserViewModel;
          this.initialRole = cloneDeep(this.role);
          this.subscribeToUpdateFormStatus();
        } else if (urlPath === 'edit') {
          if (params && params.id) {
            this.loadRole();
            this.store.dispatch(new GetRoleAction(params.id));
            this.store.dispatch(new GetTargetGroupAction(params.id));
            this.roleId = params.id;
            this.roleFormTitle = 'Edit role';
            this.isNew = false;
          }
        }
      })
    );

    const permissions$: Observable<string[]> = this.store.select(getAllPermissions);
    const mandators$: Observable<MandatorViewModel[]> = this.store.select(getMandators);

    this.subscription.add(
      combineLatest([permissions$, mandators$]).subscribe(([permissions, mandators]: any) => {
        this.permissions = permissions;
        this.mandators = mandators;
      })
    );

    this.subscription.add(
      this.action$.pipe(ofType(ADD_TARGET_GROUP_SUCCESS), take(1)).subscribe(() => {
        this.snackBar.open('Created new Target group successfully', 'Close', { duration: 3000 });
        this.isFormValid = false;
        setTimeout(() => {
          this.onBack();
        }, 3000);
      })
    );

    this.subscription.add(
      this.action$.pipe(ofType(EDIT_TARGET_GROUP_SUCCESS), take(1)).subscribe(() => {
        this.snackBar.open('Target group edited successfully', 'Close', { duration: 3000 });
      })
    );
  }

  loadRole(): void {
    this.subscription.add(
      this.action$
        .pipe(ofType(GET_ROLE_SUCCESS), take(1))
        .subscribe((action: GetRoleSuccessAction) => {
          this.role = cloneDeep(action.payload) as RoleViewModel;
          this.initialRole = cloneDeep(this.role);
          this.subscribeToUpdateFormStatus();
        })
    );

    this.subscription.add(
      this.action$
        .pipe(ofType(GET_TARGET_GROUP_SUCCESS), take(1))
        .subscribe((action: GetTargetGroupSuccessAction) => {
          this.targetGroup = { ...action.payload };
        })
    );
  }

  subscribeToUpdateFormStatus(): void {
    setTimeout(() => {
      this.subscription.add(
        this.roleForm.statusChanges.subscribe((status: string) => {
          this.isFormValid = status === 'VALID';
        })
      );
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onBack(): void {
    this.router.navigate(['/user/role/overview']);
  }

  onSave(): void {
    // add target group if role was added successfully
    this.subscription.add(
      this.action$
        .pipe(ofType(ADD_ROLE_SUCCESS), take(1))
        .subscribe((returnedAction: AddRoleSuccessAction) => {
          const targetGroupData: PostPutRoleTargetGroupRequest = {
            ...this.targetGroup,
          } as PostPutRoleTargetGroupRequest;
          targetGroupData.RoleId = returnedAction.payload.Id;
          this.store.dispatch(new AddTargetGroupAction(targetGroupData));
          this.snackBar.open('Role added successfully', 'Close', { duration: 3000 });
        })
    );

    // same for editing
    this.subscription.add(
      this.action$
        .pipe(ofType(EDIT_ROLE_SUCCESS), take(1))
        .subscribe((returnedAction: EditRoleSuccessAction) => {
          // if this role doesn't have an existing target group yet, we need to add it instead of edit it
          if (!this.targetGroup.RoleId) {
            const targetGroupData: PostPutRoleTargetGroupRequest = {
              ...this.targetGroup,
            } as PostPutRoleTargetGroupRequest;
            targetGroupData.RoleId = returnedAction.payload.Id;
            this.store.dispatch(new AddTargetGroupAction(targetGroupData));
          } else {
            const targetGroupData: PostPutRoleTargetGroupRequest = {
              ...this.targetGroup,
            } as PostPutRoleTargetGroupRequest;
            targetGroupData.RoleId = returnedAction.payload.Id;
            this.store.dispatch(new EditTargetGroupAction(targetGroupData));
          }
          this.snackBar.open('Role edited successfully', 'Close', { duration: 3000 });
        })
    );

    this.initialRole = cloneDeep(this.role);
    if (this.isNew) {
      const roleData: PostPutRoleRequest = { ...this.role } as PostPutRoleRequest;
      this.store.dispatch(new AddRoleAction(roleData));
    } else {
      this.store.dispatch(
        new EditRoleAction({
          roleId: this.roleId,
          putRoleRequest: this.role as PostPutRoleRequest,
        })
      );
    }
  }

  get canDeactivateSafely(): boolean {
    return isEqual(this.role, this.initialRole);
  }
}
