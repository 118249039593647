import {
  InverterListViewModel,
  ModuleListViewModel,
  ShadingOptimizerListViewModel,
  StorageListViewModel,
} from '../../shared/apis/advis';

export interface IPvMasterdataState {
  modules: ModuleListViewModel[];
  modulesLoading: boolean;
  modulesLoaded: boolean;

  storage: StorageListViewModel[];
  storageLoading: boolean;
  storageLoaded: boolean;

  shadingOptimizers: ShadingOptimizerListViewModel[];
  shadingOptimizerLoading: boolean;
  shadingOptimizerLoaded: boolean;

  inverter: InverterListViewModel[];
  inverterLoading: boolean;
  inverterLoaded: boolean;
}

export function initPvMasterdataState(): IPvMasterdataState {
  return {
    modules: [],
    modulesLoaded: false,
    modulesLoading: false,

    storage: [],
    storageLoaded: false,
    storageLoading: false,

    shadingOptimizers: [],
    shadingOptimizerLoaded: false,
    shadingOptimizerLoading: false,

    inverter: [],
    inverterLoaded: false,
    inverterLoading: false,
  };
}
