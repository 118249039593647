import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeactivationGuard } from '../shared/services/deactivation.guard';
import { HtMasterdataDomesticWaterHeatingOverviewComponent } from './components/ht-masterdata-main/ht-masterdata-domestic-water-heating/ht-masterdata-domestic-water-heating-overview/ht-masterdata-domestic-water-heating-overview.component';
import { HtMasterdataDomesticWaterHeatingFormComponent } from './components/ht-masterdata-main/ht-masterdata-domestic-water-heating/ht-masterdata-domestic-water-heating-form/ht-masterdata-domestic-water-heating-form.component';
import { HtMasterdataHeatGeneratorsOverviewComponent } from './components/ht-masterdata-main/ht-masterdata-heat-generators/ht-masterdata-heat-generators-overview/ht-masterdata-heat-generators-overview.component';
import { HtMasterdataHeatGeneratorsFormComponent } from './components/ht-masterdata-main/ht-masterdata-heat-generators/ht-masterdata-heat-generators-form/ht-masterdata-heat-generators-form.component';
import { HtMasterdataMainComponent } from './components/ht-masterdata-main/ht-masterdata-main.component';
import { HtMasterdataWaterTanksOverviewComponent } from './components/ht-masterdata-main/ht-masterdata-water-tanks/ht-masterdata-water-tanks-overview/ht-masterdata-water-tanks-overview.component';
import { HtMasterdataWaterTanksFormComponent } from './components/ht-masterdata-main/ht-masterdata-water-tanks/ht-masterdata-water-tanks-form/ht-masterdata-water-tanks-form.component';

const routes: Routes = [
  {
    path: '',
    component: HtMasterdataMainComponent,
    children: [
      {
        path: '',
        redirectTo: 'domestic-water-heating/overview',
        pathMatch: 'full',
      },
      {
        path: 'domestic-water-heating/overview',
        component: HtMasterdataDomesticWaterHeatingOverviewComponent,
      },
      {
        path: 'domestic-water-heating/add',
        component: HtMasterdataDomesticWaterHeatingFormComponent,
        canDeactivate: [DeactivationGuard],
      },
      {
        path: 'domestic-water-heating/edit/:id',
        component: HtMasterdataDomesticWaterHeatingFormComponent,
        canDeactivate: [DeactivationGuard],
      },
      {
        path: 'heat-generators/overview',
        component: HtMasterdataHeatGeneratorsOverviewComponent,
      },
      {
        path: 'heat-generators/add',
        component: HtMasterdataHeatGeneratorsFormComponent,
        canDeactivate: [DeactivationGuard],
      },
      {
        path: 'heat-generators/edit/:id',
        component: HtMasterdataHeatGeneratorsFormComponent,
        canDeactivate: [DeactivationGuard],
      },
      {
        path: 'water-tanks/overview',
        component: HtMasterdataWaterTanksOverviewComponent,
      },
      {
        path: 'water-tanks/add',
        component: HtMasterdataWaterTanksFormComponent,
        canDeactivate: [DeactivationGuard],
      },
      {
        path: 'water-tanks/edit/:id',
        component: HtMasterdataWaterTanksFormComponent,
        canDeactivate: [DeactivationGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HtMasterdataRoutingModule {}
