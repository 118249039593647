/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface EmailTemplateValidationResult { 
    ValidationType?: EmailTemplateValidationResult.ValidationTypeEnum;
    Message?: string;
}
export namespace EmailTemplateValidationResult {
    export type ValidationTypeEnum = 'Error' | 'Warning';
    export const ValidationTypeEnum = {
        Error: 'Error' as ValidationTypeEnum,
        Warning: 'Warning' as ValidationTypeEnum
    };
}
