<mat-form-field appearance="outline" [formGroup]="group">
  <mat-label>{{ field.NameAlias || field.Name }}</mat-label>
  <input
    [required]="field.IsRequired"
    matInput
    [matDatepicker]="picker"
    [formControlName]="field.Name"
    [placeholder]="field.NameAlias || field.Name" />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
