import { Action } from '@ngrx/store';
import {
  FileLinkViewModel,
  PostUserRequest,
  PutUserRequest,
  UserViewModel,
} from '../../../shared/apis/advis';

const prefix: string = '[User]';

export const GET_USERS: string = `${prefix} Get all users`;
export const GET_USERS_SUCCESS: string = `${prefix} Get all users success`;
export const GET_USERS_FAILED: string = `${prefix} Get all users failed`;

export const GET_USER: string = `${prefix} Get user`;
export const GET_USER_SUCCESS: string = `${prefix} Get user success`;
export const GET_USER_FAILED: string = `${prefix} Get user failed`;

export const ADD_USER: string = `${prefix} Add user`;
export const ADD_USER_SUCCESS: string = `${prefix} Add user success`;
export const ADD_USER_FAILED: string = `${prefix} Add user failed`;

export const EDIT_USER: string = `${prefix} Edit user`;
export const EDIT_USER_SUCCESS: string = `${prefix} Edit user success`;
export const EDIT_USER_FAILED: string = `${prefix} Edit user failed`;

export const ACTIVATE_USERS: string = `${prefix} Activate all users`;
export const ACTIVATE_USERS_SUCCESS: string = `${prefix} Activate all users success`;
export const ACTIVATE_USERS_FAILED: string = `${prefix} Activate all users failed`;

export const DEACTIVATE_USERS: string = `${prefix} Deactivate all users`;
export const DEACTIVATE_USERS_SUCCESS: string = `${prefix} Deactivate all users success`;
export const DEACTIVATE_USERS_FAILED: string = `${prefix} Deactivate all users failed`;

export class GetUsersAction implements Action {
  readonly type: string = GET_USERS;

  constructor(public payload: any = undefined) {}
}

export class GetUsersSuccessAction implements Action {
  readonly type: string = GET_USERS_SUCCESS;

  constructor(public payload: UserViewModel[]) {}
}

export class GetUsersFailedAction implements Action {
  readonly type: string = GET_USERS_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetUserAction implements Action {
  readonly type: string = GET_USER;

  constructor(public payload: string) {}
}

export class GetUserSuccessAction implements Action {
  readonly type: string = GET_USER_SUCCESS;

  constructor(public payload: UserViewModel) {}
}

export class GetUserFailedAction implements Action {
  readonly type: string = GET_USER_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetUserFilesSuccessAction implements Action {
  readonly type: string = GET_USER_SUCCESS;

  constructor(public payload: FileLinkViewModel[]) {}
}

export class GetUserFilesFailedAction implements Action {
  readonly type: string = GET_USER_FAILED;

  constructor(public payload: any = undefined) {}
}

export interface IUserFile {
  Picture: IFileData;
  signature: IFileData;
}

export interface IFileData {
  file: any;
  filename: string;
  deleted: boolean;
}

export interface IAddUser {
  userData: PostUserRequest;
  files: IUserFile;
}

export class AddUserAction implements Action {
  readonly type: string = ADD_USER;

  constructor(public payload: IAddUser) {
    console.log('add user', payload);
  }
}

export class AddUserSuccessAction implements Action {
  readonly type: string = ADD_USER_SUCCESS;

  constructor(public payload: UserViewModel) {}
}

export class AddUserFailedAction implements Action {
  readonly type: string = ADD_USER_FAILED;

  constructor(public payload: any = undefined) {}
}

export interface IEditUserRequest {
  putUserRequest: PutUserRequest;
  files: IUserFile;
  userId: string;
}

export class EditUserAction implements Action {
  readonly type: string = EDIT_USER;

  constructor(public payload: IEditUserRequest) {}
}

export class EditUserSuccessAction implements Action {
  readonly type: string = EDIT_USER_SUCCESS;

  constructor(public payload: UserViewModel) {}
}

export class EditUserFailedAction implements Action {
  readonly type: string = EDIT_USER_FAILED;

  constructor(public payload: any = undefined) {}
}

export class ActivateUsersAction implements Action {
  readonly type: string = ACTIVATE_USERS;

  constructor(public payload: string) {}
}

export class ActivateUsersSuccessAction implements Action {
  readonly type: string = ACTIVATE_USERS_SUCCESS;

  constructor(public payload: UserViewModel) {}
}

export class ActivateUsersFailedAction implements Action {
  readonly type: string = ACTIVATE_USERS_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeactivateUsersAction implements Action {
  readonly type: string = DEACTIVATE_USERS;

  constructor(public payload: string) {}
}

export class DeactivateUsersSuccessAction implements Action {
  readonly type: string = DEACTIVATE_USERS_SUCCESS;

  constructor(public payload: UserViewModel) {}
}

export class DeactivateUsersFailedAction implements Action {
  readonly type: string = DEACTIVATE_USERS_FAILED;

  constructor(public payload: any = undefined) {}
}

export type Actions =
  | GetUsersAction
  | GetUsersSuccessAction
  | GetUsersFailedAction
  | GetUserAction
  | GetUserSuccessAction
  | GetUserFailedAction
  | GetUserFilesSuccessAction
  | GetUserFilesFailedAction
  | AddUserAction
  | AddUserSuccessAction
  | AddUserFailedAction
  | EditUserAction
  | EditUserSuccessAction
  | EditUserFailedAction
  | ActivateUsersAction
  | ActivateUsersSuccessAction
  | ActivateUsersFailedAction
  | DeactivateUsersAction
  | DeactivateUsersSuccessAction
  | DeactivateUsersFailedAction;
