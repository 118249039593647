<mat-select
  class="mobile-mandator-selector"
  placeholder="{{ 'GLOBAL.FILTER.MANDATORS' | translate }}"
  multiple="true"
  data-cy="mandatSelector"
  (closed)="onClose()"
  #mandators="ngModel"
  [(ngModel)]="selectedMandators">
  <div class="mandator-selector-options">
    <ac-select-all-option
      [control]="mandators.control"
      [values]="selectableMandators"
      [selectedItems]="selectedMandators"
      [title]="'COMMON.SELECT_ALL' | translate"></ac-select-all-option>
    <mat-option *ngFor="let item of selectableMandators" [value]="item" data-cy="mandatSelection">
      {{ item.mandatorVm.Name }}
    </mat-option>
  </div>

  <mat-divider></mat-divider>
  <div class="mandator-selector-reset-all">
    <button mat-button color="accent" (click)="onResetClick()">
      {{ 'COMMON.RESET' | translate }}
    </button>
  </div>
</mat-select>
