import { Sort } from '@angular/material/sort';

export interface IPageSettings {
  pageKey: string;
  pagingSetting?: IPagingSetting;
  sortSetting?: Sort;
  overviewFilter?: IOverviewFilterSettings;
  bla?: any;
}

export interface IPagingSetting {
  /** The zero-based page index of the displayed list of items. */
  pageIndex: number;
  /** Number of items to display on a page. */
  pageSize: number;
}

export interface IOverviewFilterSettings {
  /** The filter entries which should be saved. */
  activeFilter: IOverviewFilter[];
  tableGlobalFilter: string;
  filterVisible: boolean;
}

export interface IOverviewFilter {
  /** The key of the filter. */
  key: string;

  /** The value of the filter. */
  value: string;
  isServerSideFiltering: boolean;
}

export function defaultPageSettings(pageKey: string): IPageSettings {
  return {
    pageKey: pageKey,
    sortSetting: { active: '', direction: '' },
    pagingSetting: { pageIndex: 0, pageSize: 50 },
    overviewFilter: { filterVisible: false, activeFilter: [], tableGlobalFilter: '' },
  };
}

export function resetPageSettingsAfterLoadLoacalStorage(
  pagesettings: IPageSettings
): IPageSettings {
  pagesettings.pagingSetting.pageIndex = 0;
  pagesettings.sortSetting.active = '';
  if (pagesettings.overviewFilter && pagesettings.overviewFilter.tableGlobalFilter) {
    pagesettings.overviewFilter.tableGlobalFilter = '';
  }
  return pagesettings;
}
