import {
  IFilter,
  IFilterOverviewComponent,
  IOverviewFilterDefine,
  OverviewFilterViewModel,
} from './Type';
import { getPageSettings, SetPageSettingsAction } from '../../store';
import { IOverviewFilter, IPageSettings } from '../../interfaces/Settings';
import { ViewChild, Directive } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import cloneDeep from 'lodash/cloneDeep';
import { IRootState } from '../../../root.state';
import { Observable, Subscription } from 'rxjs';

@Directive()
export abstract class OverviewBaseComponent implements IFilterOverviewComponent {
  @ViewChild('overviewFilter', { static: true }) overviewFilter: MatSidenav;
  pageSettings: IPageSettings;
  isFilterIconTurned: boolean = false;
  pageSettings$: Observable<IPageSettings>;
  displayedFilters: OverviewFilterViewModel[];

  constructor(protected store: Store<IRootState>) {}

  overviewFilterInit(
    pageSettingsName: string,
    displayedFilters: IOverviewFilterDefine[],
    subscription: Subscription
  ): void {
    this.displayedFilters = displayedFilters.map(
      (filter: IOverviewFilterDefine) => filter as OverviewFilterViewModel
    );

    this.pageSettings$ = this.store.select(getPageSettings, pageSettingsName);

    subscription.add(
      this.store
        .select(getPageSettings, pageSettingsName)
        .subscribe((pageSettings: IPageSettings) => {
          this.pageSettings = cloneDeep(pageSettings);
          if (this.pageSettings.overviewFilter) {
            this.isFilterIconTurned = this.pageSettings.overviewFilter.filterVisible;
            this.overviewFilter?.toggle(this.pageSettings.overviewFilter.filterVisible);
          }
        })
    );
  }

  isFilterActive(): boolean {
    return (
      this.pageSettings.overviewFilter && this.pageSettings.overviewFilter.activeFilter.length > 0
    );
  }

  toggleFilter(): void {
    this.overviewFilter.toggle();
    this.isFilterIconTurned = !this.isFilterIconTurned;
    this.pageSettings.overviewFilter.filterVisible = this.isFilterIconTurned;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  findIndexOfFilter(key: string): number {
    if (this.pageSettings.overviewFilter && this.pageSettings.overviewFilter.activeFilter) {
      return this.pageSettings.overviewFilter.activeFilter.findIndex(
        (filter: IOverviewFilter) => filter.key === key
      );
    }
    return -1;
  }

  /**
   *  is called from the Overview component when the Filter button has been clicked
   * @param filterValue
   */
  filtering(): void {
    if (this.pageSettings.overviewFilter && this.pageSettings.overviewFilter.activeFilter) {
      this.serverSideFiltering(
        this.pageSettings.overviewFilter.activeFilter.filter(
          (serverSideFilter: IOverviewFilter) => serverSideFilter.isServerSideFiltering === true
        )
      );
      this.clientSideFiltering(this.prepareClientSideFiltering());
    }
  }

  createRequestModelFromOverviewFilter<T>(data: T): T {
    if (this.pageSettings.overviewFilter && this.pageSettings.overviewFilter.activeFilter) {
      this.pageSettings.overviewFilter.activeFilter
        .filter(
          (serverSideFilter: IOverviewFilter) => serverSideFilter.isServerSideFiltering === true
        )
        .forEach((filter: IOverviewFilter) => {
          data[filter.key] = filter.value;
        });
    }
    return data;
  }

  /**
   * call from global table filter
   * @param filter the global filter value
   */
  filterTable(event: KeyboardEvent): void {
    const filter: string = (event.target as HTMLInputElement).value;
    this.pageSettings.overviewFilter.tableGlobalFilter = filter.trim().toLocaleLowerCase();
    this.clientSideFiltering(this.prepareClientSideFiltering());
  }

  /**
   * get the global table filter value
   */
  getGlobalTableFiltering(): string {
    return this.pageSettings.overviewFilter.tableGlobalFilter || '';
  }

  prepareClientSideFiltering(): IFilter[] {
    return this.pageSettings.overviewFilter.activeFilter
      .filter(
        (serverSideFilter: IOverviewFilter) => serverSideFilter.isServerSideFiltering === false
      )
      .map((activeFilter: IOverviewFilter) => ({
        column: activeFilter.key,
        value: activeFilter.value,
        isServerSide: activeFilter.isServerSideFiltering,
      }));
  }

  abstract clientSideFiltering(filters: IFilter[]): void;

  abstract serverSideFiltering(filters: IOverviewFilter[]): void;
}
