import { IPrincipal } from '../interfaces/principal.interface';

export class Authenticate {
  constructor(public username: string, public password: string, public language: string) {
    // empty
  }
}

export class Principal implements IPrincipal {
  constructor(
    private user: string,
    private userId: string,
    private role: string,
    private mandant: string,
    private mandantId: number
  ) {
    // empty
  }

  public getUser(): string {
    return this.user;
  }

  public getUserId(): string {
    return this.userId;
  }

  public getMandant(): string {
    return this.mandant;
  }

  public getMandantId(): number {
    return this.mandantId;
  }

  public getUserRole(): string {
    return this.role;
  }
}
