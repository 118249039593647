<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    {{ storageFormTitle }}
  </div>
  <div>
    <button mat-button color="accent" (click)="onBack()">{{ 'COMMON.CANCEL' | translate }}</button>
    <button mat-button color="primary" [disabled]="!isFormValid()" (click)="onSave()">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start" class="padding-10">
  <mat-card fxFlex="50">
    <mat-card-header>
      <mat-card-title>
        {{ storageFormTitle }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form
        fxLayout="column"
        fxLayoutGap="15px"
        name="storageForm"
        #storageForm="ngForm"
        *ngIf="storage || isNew">
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.ID' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.ID' | translate }}"
              type="text"
              name="id"
              required
              [disabled]="!isNew"
              [(ngModel)]="storage.Id"
              #id />
            <mat-hint>{{ 'HT_MASTERDATA.NO_SPACES_DOTS_AND_SLASHES_IN_ID' | translate }}</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.ARTICLE_NUMBER' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.ARTICLE_NUMBER' | translate }}"
              type="text"
              name="articleNumber"
              required
              [(ngModel)]="storage.ArticleNumber"
              #articleNumber />
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox id="isActive" name="isActive" [(ngModel)]="storage.IsActive">{{
            'COMMON.IS_ACTIVE' | translate
          }}</mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STORAGES.DESC' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'STORAGES.DESC' | translate }}"
              type="text"
              name="description"
              required
              [(ngModel)]="storage.Description"
              #description />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STORAGES.CAPACITY' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'STORAGES.CAPACITY' | translate }}"
              type="number"
              name="width"
              required
              [(ngModel)]="storage.Capacity"
              #width />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STORAGES.INDICATION' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'STORAGES.INDICATION' | translate }}"
              type="number"
              name="length"
              required
              [(ngModel)]="storage.PriceIndication"
              #length />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>Storage type</mat-label>

            <mat-select name="mountingType" [(ngModel)]="storage.StorageType" #storageType>
              <mat-option *ngFor="let storageType of storageTypes" [value]="storageType">{{
                storageType
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Storage chemistry</mat-label>

            <mat-select
              name="storageChemistry"
              [(ngModel)]="storage.StorageChemistry"
              #storageChemistry>
              <mat-option
                *ngFor="let storageChemistry of storageChemistries"
                [value]="storageChemistry"
                >{{ storageChemistry }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>Inverter type</mat-label>

            <mat-select
              name="inverterType"
              multiple
              [(ngModel)]="storage.CompatibleInverterTypes"
              #inverterType>
              <mat-option *ngFor="let inverterType of inverterTypes" [value]="inverterType">{{
                inverterType
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Inverter Manufacturer</mat-label>

            <mat-select
              name="inverterManufacturer"
              multiple
              [(ngModel)]="storage.CompatibleInverterManufacturers"
              #inverterSmartEnergy>
              <mat-option
                *ngFor="let inverterManufacturer of inverterManufacturers"
                [value]="inverterManufacturer"
                >{{ inverterManufacturer }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.ETURNITY_COMPONENT_ID' | translate }}</mat-label>

            <input
              matInput
              placeholder="{{ 'COMMON.ETURNITY_COMPONENT_ID' | translate }}"
              type="text"
              name="eturnityComponentId"
              [maxLength]="100"
              [(ngModel)]="storage.EturnityComponentId" />
          </mat-form-field>
        </div>

        <ac-localized-values
          [localizedValues]="storage.LocalizedTitles"
          [title]="'COMMON.TRANSLATION'">
        </ac-localized-values>

        <div>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>{{ 'COMMON.MANDATORS' | translate }}</mat-label>

            <mat-select name="mandatorIds" multiple [(ngModel)]="storage.MandatorIds" #mandatorIds>
              <mat-option
                *ngFor="let mandator of mandators"
                [value]="mandator.Id"
                [disabled]="!mandator.IsActive"
                >{{ mandator.Name + ' (' + mandator.ShortName + ')' }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>{{ 'COMMON.AR_VIEWER_URL' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.AR_VIEWER_URL' | translate }}"
              type="text"
              name="arViewerUrl"
              [maxLength]="2048"
              [(ngModel)]="storage.ArViewerUrl" />
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
