import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileLanguageEnum } from '../igh-theia-converter/igh-theia-converter.component';

@Component({
  selector: 'ac-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.sass'],
})
export class FileSelectComponent implements OnInit {
  @Input()
  accept: string = '.xml';

  @Input()
  fileLink: string = '';

  @Input()
  fileLanguage: FileLanguageEnum;

  @Output()
  onFileSelect: EventEmitter<File | FileList> = new EventEmitter<File | FileList>();

  @Input()
  fileName: string;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (!this.fileName) {
      this.fileName = this.translate.instant('IGH_TO_THEIA_CONVERTER.FILE_NOT_SELECTED');
    }
  }

  fileSelect(files: FileList | File): void {
    let file: File;
    if (files instanceof FileList) {
      file = files[0];
    } else {
      file = files;
    }
    this.fileName = file.name;
    this.onFileSelect.emit(files);
  }

  resetFileName(): void {
    this.fileName = this.translate.instant('IGH_TO_THEIA_CONVERTER.FILE_NOT_SELECTED');
  }
}
