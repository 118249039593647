import { Directive, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  MandatorViewModel,
  HeatingSubProductConnectionViewModel,
} from '../../../shared/apis/advis';
import {
  getHeatingTypes,
  getMandators,
  getSupportedManufacturers,
  getSubProductConnectionTypes,
  getCoolingFunctionTypes,
} from '../../../shared/store';
import { Store } from '@ngrx/store';
import { IRootState } from '../../../root.state';
import { SubProductConnectionViewModel } from '../../shared/types/ht-masterdata-types';

@Directive()
export class HtMasterdataBaseFormComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription = new Subscription();

  public mandators: MandatorViewModel[] = [];
  public supportedManufacturers: string[] = [];
  public subProductConnectionTypes: SubProductConnectionViewModel[] = [];
  public heatingTypes: string[] = [];
  public coolingFunctionTypes: string[] = [];

  public formTitle: string;
  public isNew: boolean = true;
  public itemId: string;

  constructor(public store: Store<IRootState>) {}

  public ngOnInit(): void {
    const mandators$: Observable<MandatorViewModel[]> = this.store.select(getMandators);
    const supportedManufacturers$: Observable<string[]> =
      this.store.select(getSupportedManufacturers);
    const heatingTypes$: Observable<string[]> = this.store.select(getHeatingTypes);
    const subProductConnectionTypes$: Observable<HeatingSubProductConnectionViewModel[]> =
      this.store.select(getSubProductConnectionTypes);

    const coolingFunctionTypes$: Observable<string[]> = this.store.select(getCoolingFunctionTypes);

    this.subscriptions.add(
      combineLatest([
        mandators$,
        supportedManufacturers$,
        heatingTypes$,
        subProductConnectionTypes$,
        coolingFunctionTypes$,
      ]).subscribe(
        ([
          mandators,
          supportedManufacturers,
          heatingTypes,
          subProductConnectionTypes,
          coolingFunctionTypes,
        ]: [
          MandatorViewModel[],
          string[],
          string[],
          HeatingSubProductConnectionViewModel[],
          string[]
        ]) => {
          this.mandators = mandators;
          this.supportedManufacturers = supportedManufacturers;
          this.heatingTypes = heatingTypes;
          this.subProductConnectionTypes =
            this.convertSubProductConnectionTypes(subProductConnectionTypes);
          this.coolingFunctionTypes = coolingFunctionTypes;
        }
      )
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected convertSubProductConnectionTypes(
    subProductConnectionTypes: HeatingSubProductConnectionViewModel[]
  ): SubProductConnectionViewModel[] {
    return subProductConnectionTypes.map(connection => ({
      displayValue: connection.Key,
      key: connection.Key,
    }));
  }
}
