import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import {
  BlueOfficeCacheManagementService,
  MasterDataService,
  MasterDataViewModel,
  UserMasterDataViewModel,
} from '../apis/advis';
import { ViewSettingModel } from '../models/view-setting/view-settings.model';
import { ConfigService } from '../services/config.service';
import { NotificationService, TypeE } from '../services/notification.service';
import { SpinnerService } from '../services/spinner.service';
import { ViewSettingItem, ViewSettingService } from '../services/view-setting.service';

import * as SharedActions from './shared.actions';
import {
  ErrorAddAction,
  GetGlobalViewSettingsAction,
  GetGlobalViewSettingsFailedAction,
  GetGlobalViewSettingsSuccessAction,
  MasterDataLoadFailureAction,
  OrganisationUnitsLoadFailureAction,
  ResetBoCacheFailedAction,
  SaveGlobalViewSettingsAction,
  SaveGlobalViewSettingsFailedAction,
  SaveGlobalViewSettingsSuccessAction,
  SetNavigationProcessAction,
  SuccessAction,
} from './shared.actions';
import { ErrorTypeE, UiElementTypeE } from './shared.reducer';

@Injectable()
export class SharedEffect {
  masterDataLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.MASTER_DATA_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: SharedActions.MasterDataLoadAction) => action.payload),
      switchMap(() => {
        return this.masterDataService.masterDataGetMasterData().pipe(
          map((viewModel: MasterDataViewModel) => {
            return new SharedActions.MasterDataLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new SharedActions.MasterDataLoadFailureAction(e)))
        );
      })
    )
  );

  organisationUnitsLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.ORGANISATION_UNIT_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: SharedActions.OrganisationUnitsLoadAction) => action.payload),
      switchMap(() => {
        return this.masterDataService.masterDataGetUserMasterData().pipe(
          map((viewModel: UserMasterDataViewModel) => {
            return new SharedActions.OrganisationUnitsLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new SharedActions.OrganisationUnitsLoadFailureAction(e)))
        );
      })
    )
  );

  boCacheReset$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.RESET_BO_CACHE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: SharedActions.ResetBoCacheAction) => action.payload),
      switchMap(() => {
        return this.boService.blueOfficeCacheManagementClearCache().pipe(
          map(() => {
            return new SharedActions.ResetBoCacheSuccessAction();
          }),
          catchError((e: any) => of(new SharedActions.ResetBoCacheFailedAction(e)))
        );
      })
    )
  );

  isLoading: boolean;
  // dispatch: false will not emit the returned value

  setNavigationProcess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedActions.SET_NAVIGATION_PROCESS),
        map((state: SetNavigationProcessAction) => {
          if (state.payload) {
            if (!this.isLoading) {
              this.spinner.regisgter();
              this.isLoading = true;
            }
          } else {
            if (this.isLoading) {
              this.spinner.unregister();
              this.isLoading = false;
            }
          }
          return { type: 'NOOP' };
        })
      ),
    { dispatch: false }
  );

  globalViewSettingsSave$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.SAVE_GLOBAL_VIEW_SETTINGS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: SaveGlobalViewSettingsAction<any>) => action.payload),
      switchMap((viewSettingsItem: ViewSettingItem<any>) => {
        return this.viewSettingService.save(viewSettingsItem).pipe(
          map((vsItem: ViewSettingItem<any>) => new SaveGlobalViewSettingsSuccessAction(vsItem)),
          catchError((e: any) => of(new SaveGlobalViewSettingsFailedAction(e)))
        );
      })
    )
  );

  globalViewSettingsLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.GET_GLOBAL_VIEW_SETTINGS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: GetGlobalViewSettingsAction) => action.payload),
      switchMap(() => {
        return this.viewSettingService.load().pipe(
          map(
            (overviewSettings: ViewSettingModel) =>
              new GetGlobalViewSettingsSuccessAction(overviewSettings)
          ),
          catchError((e: any) => of(new GetGlobalViewSettingsFailedAction(e)))
        );
      })
    )
  );

  success$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.SUCCESS),
      map((state: SuccessAction) => {
        if (state.payload.showWithAction) {
          this.notificationService.notifyWithAction(
            state.payload.message,
            this.translate.instant('COMMON.BTN_OK'),
            TypeE.PRIMARY
          );
          return { type: 'NOOP' };
        }

        this.notificationService.notifySimple(state.payload.message, TypeE.PRIMARY);
        return { type: 'NOOP' };
      })
    )
  );

  error$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.ERROR_ADD),
      map((state: ErrorAddAction) => {
        if (state.payload.uiElement === UiElementTypeE.ALERT) {
          this.notificationService.notifyWithAction(
            state.payload.data,
            this.translate.instant('COMMON.BTN_DISMISS'),
            TypeE.ALERT
          );
        } else if (state.payload.uiElement === UiElementTypeE.ERRORPAGE) {
          window.location.href =
            location.protocol + '//' + location.host + state.payload.errorPageUrl;
        }
        return { type: 'NOOP' };
      })
    )
  );

  saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.SAVE_GLOBAL_VIEW_SETTINGS_FAILURE, SharedActions.RESET_BO_CACHE_FAILURE),
      map((action: SaveGlobalViewSettingsFailedAction | ResetBoCacheFailedAction) => {
        return new ErrorAddAction({
          type: ErrorTypeE.SAVE,
          data: action.payload,
          uiElement: UiElementTypeE.DIALOG,
        });
      })
    )
  );

  loadFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SharedActions.GET_GLOBAL_VIEW_SETTINGS_FAILURE,
        SharedActions.MASTER_DATA_FAILURE,
        SharedActions.ORGANISATION_UNIT_LOAD_FAILURE
      ),
      map(
        (
          action:
            | GetGlobalViewSettingsFailedAction
            | MasterDataLoadFailureAction
            | OrganisationUnitsLoadFailureAction
        ) => {
          return new ErrorAddAction({
            type: ErrorTypeE.LOAD,
            data: action.payload,
            uiElement: UiElementTypeE.DIALOG,
          });
        }
      )
    )
  );

  setPageSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.SET_PAGE_SETTINGS),
      map((action: SharedActions.SetPageSettingsAction) => {
        this.viewSettings.saveSetting(action.payload);
        return { type: 'NOOP' };
      })
    )
  );

  constructor(
    private actions$: Actions,
    private masterDataService: MasterDataService,
    private boService: BlueOfficeCacheManagementService,
    private config: ConfigService,
    private spinner: SpinnerService,
    private viewSettingService: ViewSettingService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private viewSettings: ViewSettingService
  ) {}
}
