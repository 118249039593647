import { Injectable } from '@angular/core';
import { AdminUserPermissionsViewModel } from '../apis/advis';

@Injectable()
export class PermissionService {
  private _userPermissions: AdminUserPermissionsViewModel;

  constructor() {
    // empty
  }

  get userPermissions(): AdminUserPermissionsViewModel {
    return this._userPermissions;
  }

  set userPermissions(value: AdminUserPermissionsViewModel) {
    this._userPermissions = value;
    // Object.keys(this._userPermissions).forEach((x: any) => this._userPermissions[x] = true);
  }
}
