/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UserRequest { 
    /**
     * only retrieve entries with a matching UserName
     */
    UserName?: string;
    /**
     * only retrieve entries with a matching Email
     */
    Email?: string;
    /**
     * only retrieve entries with a matching Name  (FirstName + LastName in any order)
     */
    Name?: string;
    /**
     * only retrieve entries with a matching CompanyName
     */
    CompanyName?: string;
    /**
     * only retrieve entries with a matching city
     */
    City?: string;
    /**
     * only retrieve entries with a matching zip code
     */
    ZipCode?: string;
    /**
     * only retrieve entries for this mandators, comma separated
     */
    MandatorIds?: string;
    /**
     * only retrieve entries for this organisationUnit
     */
    OrganisationUnitId?: number;
    /**
     * only retrieve entries for this role
     */
    Role?: string;
    /**
     * only retrieve active/inactive users
     */
    IsActive?: boolean;
}
