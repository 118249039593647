/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalizedValueViewModel } from './localizedValueViewModel';


export interface PostPutModuleRequest { 
    Id: string;
    IsActive?: boolean;
    ArticleNumber?: string;
    Description?: string;
    Color?: string;
    Width?: number;
    Length?: number;
    Height?: number;
    WattPeak?: number;
    EturnityComponentId?: string;
    Order?: number;
    MountingType?: PostPutModuleRequest.MountingTypeEnum;
    PriceCategory?: PostPutModuleRequest.PriceCategoryEnum;
    LocalizedTitles?: Array<LocalizedValueViewModel>;
    LocalizedDataSheets?: Array<LocalizedValueViewModel>;
    /**
     * This module is only available for the mandators in this list
     */
    MandatorIds?: Array<number>;
    ArViewerUrl?: string;
}
export namespace PostPutModuleRequest {
    export type MountingTypeEnum = 'InRoof' | 'Standard';
    export const MountingTypeEnum = {
        InRoof: 'InRoof' as MountingTypeEnum,
        Standard: 'Standard' as MountingTypeEnum
    };
    export type PriceCategoryEnum = 'LowCost' | 'Standard' | 'HighPerformance' | 'Premium';
    export const PriceCategoryEnum = {
        LowCost: 'LowCost' as PriceCategoryEnum,
        Standard: 'Standard' as PriceCategoryEnum,
        HighPerformance: 'HighPerformance' as PriceCategoryEnum,
        Premium: 'Premium' as PriceCategoryEnum
    };
}
