import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ac-mandator-main',
  templateUrl: './mandator-main.component.html',
  styleUrls: ['./mandator-main.component.scss'],
})
export class MandatorMainComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor() {
    // empty
  }

  ngOnInit(): void {
    // empty
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
