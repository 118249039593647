/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OrganisationUnitAddressViewModel } from './organisationUnitAddressViewModel';


export interface OrganisationUnitViewModel { 
    Id?: number;
    Name: string;
    ShortName: string;
    MandatorId: number;
    Email?: string;
    PhoneNumber?: string;
    Url?: string;
    Address: OrganisationUnitAddressViewModel;
    AllocatedProductionOverhead: number;
    Db1ToDb6Surcharge: number;
    HourlyRateMontage: number;
    HourlyRateMontageAc: number;
    HourlyRateMontageScaffolding: number;
    HourlyRateOthers: number;
    HourlyRateProjectManagement: number;
    ProfitCenter?: number;
    PaymentScheme?: string;
    IsActive?: boolean;
}
