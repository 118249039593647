/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalizedValueViewModel } from './localizedValueViewModel';
import { StringGenerationContainer } from './stringGenerationContainer';


export interface PostPutHeatGeneratorRequest { 
    Id: string;
    TechnicalTitle: string;
    ArticleNumber: string;
    LocalizedTitles: Array<LocalizedValueViewModel>;
    StringGenerationContainer?: StringGenerationContainer;
    Description?: string;
    IsActive?: boolean;
    MandatorIds: Array<number>;
    HeatingType: string;
    Manufacturer: string;
    SubProductConnectionType?: string;
    MinRequiredRegisterSize?: number;
    MaxRequiredRegisterSize?: number;
    NoiseLevelES1Q2?: number;
    NoiseLevelES1Q4?: number;
    NoiseLevelES1Q8?: number;
    NoiseLevelES2Q2?: number;
    NoiseLevelES2Q4?: number;
    NoiseLevelES2Q8?: number;
    NoiseLevelES3Q2?: number;
    NoiseLevelES3Q4?: number;
    NoiseLevelES3Q8?: number;
    NoiseLevelES4Q2?: number;
    NoiseLevelES4Q4?: number;
    NoiseLevelES4Q8?: number;
    MaxHeatingPowerA7W35?: number;
    MaxHeatingPowerA7W55?: number;
    MaxHeatingPowerB0W35?: number;
    MaxCoolingPowerB0W35?: number;
    CoolingFunction?: string;
    MaxHeatingPowerB0W55?: number;
    MaxHeatingPowerOil5530?: number;
    MaxHeatingPowerGasP5030?: number;
    MaxHeatingPowerWood?: number;
    DomesticWaterHeatingMinVolume?: number;
    DomesticWaterHeatingMaxVolume?: number;
    WaterTanksMinVolume?: number;
    WaterTanksMaxVolume?: number;
    ArViewerUrl?: string;
}
