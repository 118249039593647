import { Injectable } from '@angular/core';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { environment } from '../../environments/environment';
import { NotificationMessages } from '../shared/apis/advis';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Dictionary } from 'lodash';
import { switchMap, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection: BehaviorSubject<HubConnection> = new BehaviorSubject(undefined);
  private subjects: Dictionary<BehaviorSubject<any>> = {};

  public getCurrentConnectionId(): string | undefined {
    return this.hubConnection.value?.connectionId;
  }

  public subscribeToEvents(): void {
    this.startConnection().then((hubConnection: HubConnection) => {
      Object.keys(NotificationMessages).forEach(message => {
        const subject = new BehaviorSubject<any>(undefined);
        hubConnection.on(message, (payload: any) => {
          subject.next(payload);
        });
        this.subjects[message] = subject;
      });
      this.hubConnection.next(hubConnection);
    });
  }

  public getSubscription<T>(event: string): Observable<BehaviorSubject<T>> {
    if (this.hubConnection.value?.state === HubConnectionState.Connected) {
      return of(this.subjects[event]);
    }
    return this.hubConnection.pipe(
      filter(() => !!this.subjects[event]),
      switchMap(() => this.subjects[event])
    );
  }

  private startConnection(): Promise<HubConnection> {
    return new Promise((resolve, reject) => {
      const hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.apiBasePath}/import-notification-hub`)
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

      hubConnection
        .start()
        .then(() => {
          console.log('SignalR connection established');
          return resolve(hubConnection);
        })
        .catch((err: any) => {
          console.log('error occured during setting up SignalR connection' + err);
          reject();
        });
    });
  }
}
