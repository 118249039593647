import { Injectable } from '@angular/core';
import {
  LocalizedValueViewModel,
  StringGeneration,
  StringGenerationContainer,
} from '../../apis/advis';
import LanguageEnum = LocalizedValueViewModel.LanguageEnum;

@Injectable()
export class StringGenerationMapperService {
  constructor() {}

  // This method deletes the raw data from the StringGenerationContainer object to conform XSS protection on the back end
  public unsetRawData(input: StringGenerationContainer): StringGenerationContainer {
    if (input) {
      Object.keys(input).forEach(key => {
        if (input[key] && input[key].RawData) {
          input[key].RawData = undefined;
        }
      });
    }
    return input;
  }

  public mapRawDataToLocalizedDataSheet(
    datasheets: LocalizedValueViewModel[],
    language: LanguageEnum,
    rawData: string
  ): LocalizedValueViewModel[] {
    datasheets.map(localizedDataSheet => {
      if (localizedDataSheet.Language === language) {
        localizedDataSheet.Value = rawData;
      }
    });

    return datasheets;
  }

  public mapDialogDataToContainer(
    dialogData: StringGeneration,
    container: StringGenerationContainer
  ): StringGenerationContainer {
    switch (dialogData.Language) {
      case LanguageEnum.DE:
        container.German = dialogData;
        break;
      case LanguageEnum.EN:
        container.English = dialogData;
        break;
      case LanguageEnum.FR:
        container.French = dialogData;
        break;
      case LanguageEnum.IT:
        container.Italian = dialogData;
        break;
    }

    return container;
  }
}
