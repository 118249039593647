import { createSelector, MemoizedSelector } from '@ngrx/store';
import { IRootState } from '../../../root.state';
import { FileLinkViewModel, UserViewModel } from '../../../shared/apis/advis';
import { MandatorOverviewVsModel } from '../../../shared/models/view-setting/user-overview-vs.model';
import { IUserState } from './user.state';

export const getUserState: (state: IRootState) => IUserState = (state: IRootState) => state.user;

export const getUsersFiltered: MemoizedSelector<IRootState, UserViewModel[]> = createSelector(
  getUserState,
  (state: IUserState) => state.users
);

export const getUsersLoading: MemoizedSelector<IRootState, boolean> = createSelector(
  getUserState,
  (state: IUserState) => state.usersLoading
);

export const getUserLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getUserState,
  (state: IUserState) => state.usersLoaded
);

export const getUserOverviewViewSettings: MemoizedSelector<IRootState, MandatorOverviewVsModel> =
  createSelector(getUserState, (state: IUserState) => state.userOverviewViewSettings);

export const getUserOverviewViewSettingsLoaded: MemoizedSelector<IRootState, boolean> =
  createSelector(getUserState, (state: IUserState) => state.userOverviewViewSettingsLoaded);

export const getUserFiles: MemoizedSelector<IRootState, FileLinkViewModel[]> = createSelector(
  getUserState,
  (state: IUserState) => state.userFiles
);

export const getUserFilesLoaded: MemoizedSelector<IRootState, FileLinkViewModel[]> = createSelector(
  getUserState,
  (state: IUserState) => state.userFiles
);
