import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import * as AuthReducer from '../auth/state/auth.reducer';
import { SharedModule } from '../shared/shared.module';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { AuthEffects } from './state/auth.effects';

@NgModule({
  declarations: [AuthComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    StoreModule.forFeature('auth', AuthReducer.authReducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [SharedModule],
  exports: [TranslatePipe],
})
export class AuthModule {}
