import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { IRootState } from '../../../root.state';
import {
  getSelectableMandators,
  getSelectableTradeTypes,
  getSelectMultipleTradeTypeCodes,
  getTradeTypeCodeFilterActive,
  IMandatorSelectItem,
  ITradeTypeSelectItem,
  SaveGlobalViewSettingsAction,
} from '../../store';
import { map } from 'rxjs/operators';
import { ViewSettingItem, ViewSettingKeyE } from '../../services/view-setting.service';
import { GlobalVsModel } from '../../models/view-setting/global-vs.model';
import { GlobalFilterUtil } from '../../utils/globalFilter.util';

@Component({
  selector: 'ac-trade-type-selector',
  templateUrl: './trade-type-selector.component.html',
  styleUrls: ['./trade-type-selector.component.scss'],
})
export class TradeTypeSelectorComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private selectableMandators: IMandatorSelectItem[];
  private selectedMandators: IMandatorSelectItem[];

  selectableTradeTypes: ITradeTypeSelectItem[] = [];
  selectedTradeTypes: ITradeTypeSelectItem[] = [];

  isActive: boolean;
  isMultiple$: Observable<boolean>;

  constructor(private store: Store<IRootState>) {
    // empty
  }

  ngOnInit(): void {
    this.isMultiple$ = this.store.select(getSelectMultipleTradeTypeCodes);

    const selectableMandators$: Observable<IMandatorSelectItem[]> =
      this.store.select(getSelectableMandators);
    const selectableTradeTypes$: Observable<ITradeTypeSelectItem[]> =
      this.store.select(getSelectableTradeTypes);
    const selectTradeTypeCodeFilterActive$: Observable<boolean> = this.store.select(
      getTradeTypeCodeFilterActive
    );

    this.subscriptions.add(
      combineLatest(selectableMandators$, selectableTradeTypes$, selectTradeTypeCodeFilterActive$)
        .pipe(
          map(
            ([selectableMandators, selectableTradeTypes, selectTradeTypeCodeFilterActive]: any) => {
              this.isActive = selectTradeTypeCodeFilterActive;

              this.selectableMandators = cloneDeep(selectableMandators);
              this.selectedMandators = this.selectableMandators.filter(
                (item: IMandatorSelectItem) => item.selected
              );

              this.selectableTradeTypes = cloneDeep(selectableTradeTypes);
              this.selectedTradeTypes = this.selectableTradeTypes.filter(
                (item: ITradeTypeSelectItem) => item.selected
              );
            }
          )
        )
        .subscribe()
    );
  }

  setSingleTradeType(value: ITradeTypeSelectItem): void {
    this.handleSingleTradeTypeCode(value);
  }

  change(event: any): void {
    this.handleSingleTradeTypeCode(event.source.value);
  }

  handleSingleTradeTypeCode(value: ITradeTypeSelectItem): void {
    this.selectedTradeTypes = [];
    this.selectedTradeTypes[0] = value;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onClose(): void {
    this.dispatchViewSettings();
  }

  private dispatchViewSettings(): void {
    this.selectableMandators = GlobalFilterUtil.filterSelectedMandatorsAccordingTradeTypes(
      this.selectedMandators,
      this.selectedTradeTypes
    );

    this.store.dispatch(
      new SaveGlobalViewSettingsAction(
        new ViewSettingItem(
          ViewSettingKeyE.GLOBAL_VIEW_SETTINGS,
          new GlobalVsModel(
            GlobalFilterUtil.mapMandatorSelectItemToIds(this.selectedMandators),
            GlobalFilterUtil.mapTradeTypeSelectItemToIds(this.selectedTradeTypes)
          )
        )
      )
    );
  }
}
