import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IPrincipal } from '../../shared/interfaces/principal.interface';
import { Authenticate } from '../../shared/models/principal.model';

export const INIT: string = '[Auth] Init';
export const LOGIN: string = '[Auth] Login';
export const LOGOUT: string = '[Auth] Logout';
export const LOGOUT_SESSION: string = '[Auth] Logout Session';
export const LOGIN_SUCCESS: string = '[Auth] Login Success';
export const LOGIN_SUCCESS_SESSION: string = '[Auth] Login Success Session';
export const LOGIN_FAILURE: string = '[Auth] Login Failure';
export const LOGIN_REDIRECT: string = '[Auth] Login Redirect';

export class InitAction implements Action {
  readonly type: string = INIT;

  constructor(public payload: any = undefined) {}
}

export class LoginAction implements Action {
  readonly type: string = LOGIN;

  constructor(public payload: Authenticate) {}
}

export class LoginSuccessAction implements Action {
  readonly type: string = LOGIN_SUCCESS;

  constructor(public payload: IPrincipal) {}
}

export class LoginSuccessSessionAction implements Action {
  readonly type: string = LOGIN_SUCCESS_SESSION;

  constructor(public payload: IPrincipal) {}
}

export class LoginFailureAction implements Action {
  readonly type: string = LOGIN_FAILURE;

  constructor(public payload: any) {}
}

export class LoginRedirectAction implements Action {
  readonly type: string = LOGIN_REDIRECT;

  constructor(public payload: any = undefined) {}
}

export class LogoutAction implements Action {
  readonly type: string = LOGOUT;

  constructor(public payload: any = undefined) {}
}

export class LogoutSessionAction implements Action {
  readonly type: string = LOGOUT_SESSION;

  constructor(public payload: HttpErrorResponse | Response) {}
}

export type AuthActions =
  | InitAction
  | LoginAction
  | LoginSuccessAction
  | LoginSuccessSessionAction
  | LoginFailureAction
  | LoginRedirectAction
  | LogoutAction
  | LogoutSessionAction;
