import { Action } from '@ngrx/store';
import {
  InverterViewModel,
  ModuleViewModel,
  PostPutInverterRequest,
  PostPutModuleRequest,
  PostPutShadingOptimizerRequest,
  PostPutStorageRequest,
  ShadingOptimizerViewModel,
  StorageViewModel,
} from '../../shared/apis/advis';

const prefix: string = '[PV Masterdata]';

export const GET_MODULES: string = `${prefix} Get all modules`;
export const GET_MODULES_SUCCESS: string = `${prefix} Get all modules success`;
export const GET_MODULES_FAILED: string = `${prefix} Get all modules failed`;

export const GET_MODULE: string = `${prefix} Get module`;
export const GET_MODULE_SUCCESS: string = `${prefix} Get module success`;
export const GET_MODULE_FAILED: string = `${prefix} Get module failed`;

export const ADD_MODULE: string = `${prefix} Add module`;
export const ADD_MODULE_SUCCESS: string = `${prefix} Add module success`;
export const ADD_MODULE_FAILED: string = `${prefix} Add module failed`;

export const EDIT_MODULE: string = `${prefix} Edit module`;
export const EDIT_MODULE_SUCCESS: string = `${prefix} Edit module success`;
export const EDIT_MODULE_FAILED: string = `${prefix} Edit module failed`;

export const DELETE_MODULE: string = `${prefix} Delete module`;
export const DELETE_MODULE_SUCCESS: string = `${prefix} Delete module success`;
export const DELETE_MODULE_FAILED: string = `${prefix} Delete module failed`;

export const GET_STORAGES: string = `${prefix} Get all storages`;
export const GET_STORAGES_SUCCESS: string = `${prefix} Get all storages success`;
export const GET_STORAGES_FAILED: string = `${prefix} Get all storages failed`;

export const GET_STORAGE: string = `${prefix} Get storage`;
export const GET_STORAGE_SUCCESS: string = `${prefix} Get storage success`;
export const GET_STORAGE_FAILED: string = `${prefix} Get storage failed`;

export const ADD_STORAGE: string = `${prefix} Add storage`;
export const ADD_STORAGE_SUCCESS: string = `${prefix} Add storage success`;
export const ADD_STORAGE_FAILED: string = `${prefix} Add storage failed`;

export const EDIT_STORAGE: string = `${prefix} Edit storage`;
export const EDIT_STORAGE_SUCCESS: string = `${prefix} Edit storage success`;
export const EDIT_STORAGE_FAILED: string = `${prefix} Edit storage failed`;

export const DELETE_STORAGE: string = `${prefix} Delete storage`;
export const DELETE_STORAGE_SUCCESS: string = `${prefix} Delete storage success`;
export const DELETE_STORAGE_FAILED: string = `${prefix} Delete storage failed`;

export const GET_INVERTERS: string = `${prefix} Get all inverters`;
export const GET_INVERTERS_SUCCESS: string = `${prefix} Get all inverters success`;
export const GET_INVERTERS_FAILED: string = `${prefix} Get all inverters failed`;

export const GET_INVERTER: string = `${prefix} Get inverter`;
export const GET_INVERTER_SUCCESS: string = `${prefix} Get inverter success`;
export const GET_INVERTER_FAILED: string = `${prefix} Get inverter failed`;

export const ADD_INVERTER: string = `${prefix} Add inverter`;
export const ADD_INVERTER_SUCCESS: string = `${prefix} Add inverter success`;
export const ADD_INVERTER_FAILED: string = `${prefix} Add inverter failed`;

export const EDIT_INVERTER: string = `${prefix} Edit inverter`;
export const EDIT_INVERTER_SUCCESS: string = `${prefix} Edit inverter success`;
export const EDIT_INVERTER_FAILED: string = `${prefix} Edit inverter failed`;

export const DELETE_INVERTER: string = `${prefix} Delete inverter`;
export const DELETE_INVERTER_SUCCESS: string = `${prefix} Delete inverter success`;
export const DELETE_INVERTER_FAILED: string = `${prefix} Delete inverter failed`;

export const GET_SHADINGOPTIMIZERS: string = `${prefix} Get all shading optimizers`;
export const GET_SHADINGOPTIMIZERS_SUCCESS: string = `${prefix} Get all shading optimizers success`;
export const GET_SHADINGOPTIMIZERS_FAILED: string = `${prefix} Get all shading optimizers failed`;

export const GET_SHADINGOPTIMIZER: string = `${prefix} Get shading optimizer`;
export const GET_SHADINGOPTIMIZER_SUCCESS: string = `${prefix} Get shading optimizer success`;
export const GET_SHADINGOPTIMIZER_FAILED: string = `${prefix} Get shading optimizer failed`;

export const ADD_SHADINGOPTIMIZER: string = `${prefix} Add shading optimizer`;
export const ADD_SHADINGOPTIMIZER_SUCCESS: string = `${prefix} Add shading optimizer success`;
export const ADD_SHADINGOPTIMIZER_FAILED: string = `${prefix} Add shading optimizer failed`;

export const EDIT_SHADINGOPTIMIZER: string = `${prefix} Edit shading optimizer`;
export const EDIT_SHADINGOPTIMIZER_SUCCESS: string = `${prefix} Edit shading optimizer success`;
export const EDIT_SHADINGOPTIMIZER_FAILED: string = `${prefix} Edit shading optimizer failed`;

export const DELETE_SHADINGOPTIMIZER: string = `${prefix} Delete shading optimizer`;
export const DELETE_SHADINGOPTIMIZER_SUCCESS: string = `${prefix} Delete shading optimizer success`;
export const DELETE_SHADINGOPTIMIZER_FAILED: string = `${prefix} Delete shading optimizer failed`;

export interface IPostPatchModuleRequest {
  patchModuleRequest: PostPutModuleRequest;
  moduleId: number;
}

export class GetModulesAction implements Action {
  readonly type: string = GET_MODULES;

  constructor(public payload: any = undefined) {}
}

export class GetModulesSuccessAction implements Action {
  readonly type: string = GET_MODULES_SUCCESS;

  constructor(public payload: ModuleViewModel[]) {}
}

export class GetModulesFailedAction implements Action {
  readonly type: string = GET_MODULES_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetModuleAction implements Action {
  readonly type: string = GET_MODULE;

  constructor(public payload: string) {}
}

export class GetModuleSuccessAction implements Action {
  readonly type: string = GET_MODULE_SUCCESS;

  constructor(public payload: ModuleViewModel) {}
}

export class GetModuleFailedAction implements Action {
  readonly type: string = GET_MODULE_FAILED;

  constructor(public payload: any = undefined) {}
}

export class AddModuleAction implements Action {
  readonly type: string = ADD_MODULE;

  constructor(public payload: PostPutModuleRequest) {}
}

export class AddModuleSuccessAction implements Action {
  readonly type: string = ADD_MODULE_SUCCESS;

  constructor(public payload: ModuleViewModel) {}
}

export class AddModuleFailedAction implements Action {
  readonly type: string = ADD_MODULE_FAILED;

  constructor(public payload: any = undefined) {}
}

export class EditModuleAction implements Action {
  readonly type: string = EDIT_MODULE;

  constructor(public payload: PostPutModuleRequest) {}
}

export class EditModuleSuccessAction implements Action {
  readonly type: string = EDIT_MODULE_SUCCESS;

  constructor(public payload: ModuleViewModel) {}
}

export class EditModuleFailedAction implements Action {
  readonly type: string = EDIT_MODULE_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeleteModuleAction implements Action {
  readonly type: string = DELETE_MODULE;

  constructor(public payload: any) {}
}

export class DeleteModuleSuccessAction implements Action {
  readonly type: string = DELETE_MODULE_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteModuleFailedAction implements Action {
  readonly type: string = DELETE_MODULE_FAILED;

  constructor(public payload: any = undefined) {}
}

export interface IPostPatchStorageRequest {
  patchStorageRequest: PostPutStorageRequest;
  storageId: number;
}

export class GetStoragesAction implements Action {
  readonly type: string = GET_STORAGES;

  constructor(public payload: any = undefined) {}
}

export class GetStoragesSuccessAction implements Action {
  readonly type: string = GET_STORAGES_SUCCESS;

  constructor(public payload: StorageViewModel[]) {}
}

export class GetStoragesFailedAction implements Action {
  readonly type: string = GET_STORAGES_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetStorageAction implements Action {
  readonly type: string = GET_STORAGE;

  constructor(public payload: string) {}
}

export class GetStorageSuccessAction implements Action {
  readonly type: string = GET_STORAGE_SUCCESS;

  constructor(public payload: StorageViewModel) {}
}

export class GetStorageFailedAction implements Action {
  readonly type: string = GET_STORAGE_FAILED;

  constructor(public payload: any = undefined) {}
}

export class AddStorageAction implements Action {
  readonly type: string = ADD_STORAGE;

  constructor(public payload: PostPutStorageRequest) {}
}

export class AddStorageSuccessAction implements Action {
  readonly type: string = ADD_STORAGE_SUCCESS;

  constructor(public payload: StorageViewModel) {}
}

export class AddStorageFailedAction implements Action {
  readonly type: string = ADD_STORAGE_FAILED;

  constructor(public payload: any = undefined) {}
}

export class EditStorageAction implements Action {
  readonly type: string = EDIT_STORAGE;

  constructor(public payload: PostPutStorageRequest) {}
}

export class EditStorageSuccessAction implements Action {
  readonly type: string = EDIT_STORAGE_SUCCESS;

  constructor(public payload: StorageViewModel) {}
}

export class EditStorageFailedAction implements Action {
  readonly type: string = EDIT_STORAGE_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeleteStorageAction implements Action {
  readonly type: string = DELETE_STORAGE;

  constructor(public payload: any) {}
}

export class DeleteStorageSuccessAction implements Action {
  readonly type: string = DELETE_STORAGE_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteStorageFailedAction implements Action {
  readonly type: string = DELETE_STORAGE_FAILED;

  constructor(public payload: any = undefined) {}
}

export interface IPostPatchShadingOptimizerRequest {
  patchShadingOptimizerRequest: PostPutShadingOptimizerRequest;
  shadingOptimizerId: number;
}

export class GetShadingOptimizersAction implements Action {
  readonly type: string = GET_SHADINGOPTIMIZERS;

  constructor(public payload: any = undefined) {}
}

export class GetShadingOptimizersSuccessAction implements Action {
  readonly type: string = GET_SHADINGOPTIMIZERS_SUCCESS;

  constructor(public payload: ShadingOptimizerViewModel[]) {}
}

export class GetShadingOptimizersFailedAction implements Action {
  readonly type: string = GET_SHADINGOPTIMIZERS_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetShadingOptimizerAction implements Action {
  readonly type: string = GET_SHADINGOPTIMIZER;

  constructor(public payload: string) {}
}

export class GetShadingOptimizerSuccessAction implements Action {
  readonly type: string = GET_SHADINGOPTIMIZER_SUCCESS;

  constructor(public payload: ShadingOptimizerViewModel) {}
}

export class GetShadingOptimizerFailedAction implements Action {
  readonly type: string = GET_SHADINGOPTIMIZER_FAILED;

  constructor(public payload: any = undefined) {}
}

export class AddShadingOptimizerAction implements Action {
  readonly type: string = ADD_SHADINGOPTIMIZER;

  constructor(public payload: PostPutShadingOptimizerRequest) {}
}

export class AddShadingOptimizerSuccessAction implements Action {
  readonly type: string = ADD_SHADINGOPTIMIZER_SUCCESS;

  constructor(public payload: ShadingOptimizerViewModel) {}
}

export class AddShadingOptimizerFailedAction implements Action {
  readonly type: string = ADD_SHADINGOPTIMIZER_FAILED;

  constructor(public payload: any = undefined) {}
}

export class EditShadingOptimizerAction implements Action {
  readonly type: string = EDIT_SHADINGOPTIMIZER;

  constructor(public payload: PostPutShadingOptimizerRequest) {}
}

export class EditShadingOptimizerSuccessAction implements Action {
  readonly type: string = EDIT_SHADINGOPTIMIZER_SUCCESS;

  constructor(public payload: ShadingOptimizerViewModel) {}
}

export class EditShadingOptimizerFailedAction implements Action {
  readonly type: string = EDIT_SHADINGOPTIMIZER_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeleteShadingOptimizerAction implements Action {
  readonly type: string = DELETE_SHADINGOPTIMIZER;

  constructor(public payload: any) {}
}

export class DeleteShadingOptimizerSuccessAction implements Action {
  readonly type: string = DELETE_SHADINGOPTIMIZER_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteShadingOptimizerFailedAction implements Action {
  readonly type: string = DELETE_SHADINGOPTIMIZER_FAILED;

  constructor(public payload: any = undefined) {}
}

export interface IPostPatchInverterRequest {
  patchInverterRequest: PostPutInverterRequest;
  storageId: number;
}

export class GetInvertersAction implements Action {
  readonly type: string = GET_INVERTERS;

  constructor(public payload: any = undefined) {}
}

export class GetInvertersSuccessAction implements Action {
  readonly type: string = GET_INVERTERS_SUCCESS;

  constructor(public payload: InverterViewModel[]) {}
}

export class GetInvertersFailedAction implements Action {
  readonly type: string = GET_INVERTERS_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetInverterAction implements Action {
  readonly type: string = GET_INVERTER;

  constructor(public payload: string) {}
}

export class GetInverterSuccessAction implements Action {
  readonly type: string = GET_INVERTER_SUCCESS;

  constructor(public payload: InverterViewModel) {}
}

export class GetInverterFailedAction implements Action {
  readonly type: string = GET_INVERTER_FAILED;

  constructor(public payload: any = undefined) {}
}

export class AddInverterAction implements Action {
  readonly type: string = ADD_INVERTER;

  constructor(public payload: PostPutInverterRequest) {}
}

export class AddInverterSuccessAction implements Action {
  readonly type: string = ADD_INVERTER_SUCCESS;

  constructor(public payload: InverterViewModel) {}
}

export class AddInverterFailedAction implements Action {
  readonly type: string = ADD_INVERTER_FAILED;

  constructor(public payload: any = undefined) {}
}

export class EditInverterAction implements Action {
  readonly type: string = EDIT_INVERTER;

  constructor(public payload: PostPutInverterRequest) {}
}

export class EditInverterSuccessAction implements Action {
  readonly type: string = EDIT_INVERTER_SUCCESS;

  constructor(public payload: InverterViewModel) {}
}

export class EditInverterFailedAction implements Action {
  readonly type: string = EDIT_INVERTER_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeleteInverterAction implements Action {
  readonly type: string = DELETE_INVERTER;

  constructor(public payload: any) {}
}

export class DeleteInverterSuccessAction implements Action {
  readonly type: string = DELETE_INVERTER_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteInverterFailedAction implements Action {
  readonly type: string = DELETE_INVERTER_FAILED;

  constructor(public payload: any = undefined) {}
}

export type Actions =
  | GetModulesAction
  | GetModulesSuccessAction
  | GetModulesFailedAction
  | GetModuleAction
  | GetModuleFailedAction
  | GetModuleSuccessAction
  | AddModuleAction
  | AddModuleFailedAction
  | AddModuleSuccessAction
  | EditModuleAction
  | EditModuleSuccessAction
  | EditModuleFailedAction
  | DeleteModuleAction
  | DeleteModuleSuccessAction
  | DeleteModuleFailedAction
  | GetStoragesAction
  | GetStoragesSuccessAction
  | GetStoragesFailedAction
  | GetStorageAction
  | GetStorageFailedAction
  | GetStorageSuccessAction
  | AddStorageAction
  | AddStorageFailedAction
  | AddStorageSuccessAction
  | EditStorageAction
  | EditStorageSuccessAction
  | EditStorageFailedAction
  | DeleteStorageAction
  | DeleteStorageSuccessAction
  | DeleteStorageFailedAction
  | GetShadingOptimizersAction
  | GetShadingOptimizersSuccessAction
  | GetShadingOptimizersFailedAction
  | GetShadingOptimizerAction
  | GetShadingOptimizerFailedAction
  | GetShadingOptimizerSuccessAction
  | AddShadingOptimizerAction
  | AddShadingOptimizerFailedAction
  | AddShadingOptimizerSuccessAction
  | EditShadingOptimizerAction
  | EditShadingOptimizerSuccessAction
  | EditShadingOptimizerFailedAction
  | DeleteShadingOptimizerAction
  | DeleteShadingOptimizerSuccessAction
  | DeleteShadingOptimizerFailedAction
  | GetInvertersAction
  | GetInvertersSuccessAction
  | GetInvertersFailedAction
  | GetInverterAction
  | GetInverterFailedAction
  | GetInverterSuccessAction
  | AddInverterAction
  | AddInverterFailedAction
  | AddInverterSuccessAction
  | EditInverterAction
  | EditInverterSuccessAction
  | EditInverterFailedAction
  | DeleteInverterAction
  | DeleteInverterSuccessAction
  | DeleteInverterFailedAction;
