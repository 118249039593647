<div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutGap="20px">
    <span
      >{{ 'IGH_TO_THEIA_CONVERTER.FILE_NAME' | translate
      }}{{ fileLanguage ? ' ' + fileLanguage + ':' : ':' }}</span
    >
    <a *ngIf="fileLink" [href]="fileLink" target="_blank">{{ fileName }}</a>
    <span *ngIf="!fileLink">{{ fileName }}</span>
  </div>
  <ac-custom-file-upload (select)="fileSelect($event)" [accept]="accept"></ac-custom-file-upload>
</div>
