import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PatchMandatorRequest, TaskSettingViewModel } from '../../../shared/apis/advis';
import { TaskSettingsUtil } from '../../../shared/utils/task-settings.util';
import moment from 'moment';
import { Dictionary } from 'lodash';
import { isNullOrUndefined } from '../../../shared/utils/isNullOrUndefined';

export interface ITaskSetting {
  id: number;
  settingTitle: string;
  taskTitle: string;
  daysOffset: number;
  notificationTime: string;
  mandatorId: number;
  settingType: TaskSettingViewModel.TaskSettingTypeEnum;
}

@Component({
  selector: 'ac-mandator-task-settings',
  templateUrl: './mandator-task-settings.component.html',
  styleUrls: ['./mandator-task-settings.component.sass'],
})
export class MandatorTaskSettingsComponent implements OnChanges {
  private taskSettingTypes: any = TaskSettingViewModel.TaskSettingTypeEnum;

  public taskSettings: Dictionary<ITaskSetting[]> = {};

  @Input() mandatorId: number;
  @Input() mandator: PatchMandatorRequest;

  @Output() settingsChanged: EventEmitter<TaskSettingViewModel[]> = new EventEmitter<
    TaskSettingViewModel[]
  >();

  ngOnChanges(): void {
    if (!this.mandator || !this.mandatorId) {
      return;
    }

    this.mandator.TradeTypeCodes.forEach(tradeType => {
      this.taskSettings[tradeType.Code] = Object.keys(this.taskSettingTypes).map<ITaskSetting>(
        (settingType: TaskSettingViewModel.TaskSettingTypeEnum) => {
          const existingSetting = this.mandator.TaskSettings.find(
            s => s.TaskSettingType === settingType && s.TradeTypeCode === tradeType.Code
          );
          const defaultSetting = TaskSettingsUtil.getDefaultTaskSettings(
            settingType,
            tradeType.Code
          );
          const daysOffset = existingSetting ? existingSetting.DaysOffset : defaultSetting.days;
          const notificationTime = existingSetting
            ? existingSetting.NotificationTime
            : moment().hours(7).minutes(0).seconds(0).milliseconds(0).toDate();

          return {
            id: existingSetting ? existingSetting.Id : undefined,
            daysOffset: daysOffset,
            notificationTime: this.getString(notificationTime),
            mandatorId: this.mandatorId,
            settingTitle: this.getTitleLocalizationKey(settingType),
            taskTitle: existingSetting ? existingSetting.TaskTitle : defaultSetting.title,
            settingType: settingType,
          };
        }
      );
    });
    this.onSettingsChanged();
  }

  public onSettingsChanged(): void {
    let viewModel: TaskSettingViewModel[] = [];
    Object.keys(this.taskSettings).forEach(tradeType => {
      const tradeTypeSettings = this.taskSettings[tradeType];
      const tradeTypeViewModel = tradeTypeSettings.map<TaskSettingViewModel>(ts => ({
        Id: ts.id,
        DaysOffset: ts.daysOffset,
        NotificationTime: moment(ts.notificationTime, 'HH:mm').utc(true).toDate(),
        TaskSettingType: ts.settingType,
        TaskTitle: ts.taskTitle,
        TradeTypeCode: tradeType,
      }));
      viewModel = [...viewModel, ...tradeTypeViewModel];
    });
    this.settingsChanged.emit(viewModel);
  }

  getString(date: Date): string {
    // dirty hack: will be refactoring with https://theia-helion.atlassian.net/browse/HP-6048
    const d = new Date(date);
    return isNullOrUndefined(d) ? '07:00' : d.toTimeString().substring(0, 5);
  }

  private getTitleLocalizationKey(
    taskSettingType: TaskSettingViewModel.TaskSettingTypeEnum
  ): string {
    switch (taskSettingType) {
      case this.taskSettingTypes.UnreachedFirstAttempt:
        return 'TASK_SETTING_TYPE.UNREACHED_FIRST_ATTEMPT';
      case this.taskSettingTypes.UnreachedSecondAttempt:
        return 'TASK_SETTING_TYPE.UNREACHED_SECOND_ATTEMPT';
      case this.taskSettingTypes.UnreachedThirdAttempt:
        return 'TASK_SETTING_TYPE.UNREACHED_THIRD_ATTEMPT';
      case this.taskSettingTypes.FollowUpBo:
        return 'TASK_SETTING_TYPE.FOLLOWUP_BO';
      case this.taskSettingTypes.FollowUpNbo:
        return 'TASK_SETTING_TYPE.FOLLOWUP_NBO';
      default:
        throw Error('not supported task setting type');
    }
  }
}
