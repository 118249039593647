import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as MandatorAction from './mandator.action';
import {
  ActivateMandatorAction,
  ActivateMandatorFailedAction,
  ActivateMandatorSuccessAction,
  DeactivateMandatorAction,
  DeactivateMandatorFailedAction,
  DeactivateMandatorSuccessAction,
  EditMandatorFailedAction,
  GetMandatorFailedAction,
  GetMandatorsFailedAction,
  IEditMandatorRequest,
} from './mandator.action';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../../shared/services/config.service';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { MandatorService, MandatorViewModel } from '../../shared/apis/advis';
import { ErrorAddAction, ErrorTypeE, UiElementTypeE } from '../../shared/store';
import { NotificationService } from '../../shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MandatorEffect {
  mandatorsLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MandatorAction.GET_MANDATORS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MandatorAction.GetMandatorsAction) => action.payload),
      switchMap(() => {
        return this.mandatorService.mandatorGetAllMandators().pipe(
          map((viewModel: any) => {
            return new MandatorAction.GetMandatorsSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MandatorAction.GetMandatorsFailedAction(e)))
        );
      })
    )
  );

  mandatorLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MandatorAction.GET_MANDATOR),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MandatorAction.GetMandatorAction) => action.payload),
      switchMap((mandatorId: number) => {
        return this.mandatorService.mandatorGetMandatorById(mandatorId).pipe(
          map((viewModel: any) => {
            return new MandatorAction.GetMandatorSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MandatorAction.GetMandatorFailedAction(e)))
        );
      })
    )
  );

  mandatorEdit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MandatorAction.EDIT_MANDATOR),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MandatorAction.EditMandatorAction) => action.payload),
      switchMap((editMandatorRequest: IEditMandatorRequest) => {
        return this.mandatorService
          .mandatorPatchMandator(
            editMandatorRequest.mandatorId,
            editMandatorRequest.patchMandatorRequest
          )
          .pipe(
            map((viewModel: MandatorViewModel) => {
              return new MandatorAction.EditMandatorSuccessAction(viewModel);
            }),
            catchError((e: any) => of(new MandatorAction.EditMandatorFailedAction(e)))
          );
      })
    )
  );

  activateMandator$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MandatorAction.ACTIVATE_MANDATOR),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActivateMandatorAction) => action.payload),
      switchMap((mandatorId: number) => {
        return this.mandatorService.mandatorPatchMandator(mandatorId, { IsActive: true }).pipe(
          map((users: any) => {
            this.notificationService.notifySimple(
              this.translate.instant('MANDATORS.NOTIFY_ACTIVATE')
            );
            return new ActivateMandatorSuccessAction(users);
          }),
          catchError((e: any) => of(new ActivateMandatorFailedAction(e)))
        );
      })
    )
  );

  deactivateMandator$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MandatorAction.DEACTIVATE_MANDATOR),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: DeactivateMandatorAction) => action.payload),
      switchMap((mandatorId: number) => {
        return this.mandatorService.mandatorPatchMandator(mandatorId, { IsActive: false }).pipe(
          map((users: any) => {
            this.notificationService.notifySimple(
              this.translate.instant('MANDATORS.NOTIFY_DEACTIVATE')
            );
            return new DeactivateMandatorSuccessAction(users);
          }),
          catchError((e: any) => of(new DeactivateMandatorFailedAction(e)))
        );
      })
    )
  );

  saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MandatorAction.ADD_MANDATOR_FAILED, MandatorAction.EDIT_MANDATOR_FAILED),
      map((action: EditMandatorFailedAction) => {
        if (action.payload.error === undefined || action.payload.error === null) {
          const message: string = action.type.substr(action.type.indexOf(']') + 1);
          return new ErrorAddAction({
            type: ErrorTypeE.SAVE,
            data: message,
            uiElement: UiElementTypeE.DIALOG,
          });
        } else {
          return new ErrorAddAction({
            type: ErrorTypeE.SAVE,
            data: action.payload.error.Message,
            uiElement: UiElementTypeE.DIALOG,
          });
        }
      })
    )
  );

  loadFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MandatorAction.GET_MANDATORS_FAILED, MandatorAction.GET_MANDATOR_FAILED),
      map((action: GetMandatorsFailedAction | GetMandatorFailedAction) => {
        if (action.payload.error === undefined || action.payload.error === null) {
          const message: string = action.type.substr(action.type.indexOf(']') + 1);
          return new ErrorAddAction({
            type: ErrorTypeE.LOAD,
            data: message,
            uiElement: UiElementTypeE.DIALOG,
          });
        } else {
          return new ErrorAddAction({
            type: ErrorTypeE.LOAD,
            data: action.payload.error,
            uiElement: UiElementTypeE.DIALOG,
          });
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private mandatorService: MandatorService,
    private config: ConfigService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {
    // empty
  }
}
