/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CarListViewModel } from '../model/carListViewModel';
import { CarViewModel } from '../model/carViewModel';
import { ChargingStationListViewModel } from '../model/chargingStationListViewModel';
import { ChargingStationViewModel } from '../model/chargingStationViewModel';
import { PostPutCarRequest } from '../model/postPutCarRequest';
import { PostPutChargingStationRequest } from '../model/postPutChargingStationRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EmobilityMasterDataService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletes a charging station
     * 
     * @param id the id of the charging station
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chargingStationMasterDataDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public chargingStationMasterDataDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public chargingStationMasterDataDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public chargingStationMasterDataDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling chargingStationMasterDataDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/em/masterdata/charging-stations/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all existing charging stations
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chargingStationMasterDataGetAll(observe?: 'body', reportProgress?: boolean): Observable<Array<ChargingStationListViewModel>>;
    public chargingStationMasterDataGetAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChargingStationListViewModel>>>;
    public chargingStationMasterDataGetAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChargingStationListViewModel>>>;
    public chargingStationMasterDataGetAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChargingStationListViewModel>>(`${this.basePath}/api/admin/em/masterdata/charging-stations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific charging station by id
     * 
     * @param id the id of the charging station
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chargingStationMasterDataGetById(id: string, observe?: 'body', reportProgress?: boolean): Observable<ChargingStationViewModel>;
    public chargingStationMasterDataGetById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChargingStationViewModel>>;
    public chargingStationMasterDataGetById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChargingStationViewModel>>;
    public chargingStationMasterDataGetById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling chargingStationMasterDataGetById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChargingStationViewModel>(`${this.basePath}/api/admin/em/masterdata/charging-stations/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a charging station
     * 
     * @param body the new charging station
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chargingStationMasterDataPost(body?: PostPutChargingStationRequest, observe?: 'body', reportProgress?: boolean): Observable<ChargingStationViewModel>;
    public chargingStationMasterDataPost(body?: PostPutChargingStationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChargingStationViewModel>>;
    public chargingStationMasterDataPost(body?: PostPutChargingStationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChargingStationViewModel>>;
    public chargingStationMasterDataPost(body?: PostPutChargingStationRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ChargingStationViewModel>(`${this.basePath}/api/admin/em/masterdata/charging-stations`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a charging station
     * 
     * @param id the id of the charging station
     * @param body the new charging station data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chargingStationMasterDataPut(id: string, body?: PostPutChargingStationRequest, observe?: 'body', reportProgress?: boolean): Observable<ChargingStationViewModel>;
    public chargingStationMasterDataPut(id: string, body?: PostPutChargingStationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChargingStationViewModel>>;
    public chargingStationMasterDataPut(id: string, body?: PostPutChargingStationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChargingStationViewModel>>;
    public chargingStationMasterDataPut(id: string, body?: PostPutChargingStationRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling chargingStationMasterDataPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ChargingStationViewModel>(`${this.basePath}/api/admin/em/masterdata/charging-stations/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a car  Be aware that cars can only be deleted if they are no longer used anywhere
     * 
     * @param id the id of the car
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emobilityCarsDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public emobilityCarsDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public emobilityCarsDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public emobilityCarsDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling emobilityCarsDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/em/masterdata/cars/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all existing cars
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emobilityCarsGetAll(observe?: 'body', reportProgress?: boolean): Observable<Array<CarListViewModel>>;
    public emobilityCarsGetAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CarListViewModel>>>;
    public emobilityCarsGetAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CarListViewModel>>>;
    public emobilityCarsGetAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CarListViewModel>>(`${this.basePath}/api/admin/em/masterdata/cars`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific car by id
     * 
     * @param id the id of the car
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emobilityCarsGetById(id: string, observe?: 'body', reportProgress?: boolean): Observable<CarViewModel>;
    public emobilityCarsGetById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CarViewModel>>;
    public emobilityCarsGetById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CarViewModel>>;
    public emobilityCarsGetById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling emobilityCarsGetById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<CarViewModel>(`${this.basePath}/api/admin/em/masterdata/cars/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a car
     * 
     * @param body the new car
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emobilityCarsPost(body?: PostPutCarRequest, observe?: 'body', reportProgress?: boolean): Observable<CarViewModel>;
    public emobilityCarsPost(body?: PostPutCarRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CarViewModel>>;
    public emobilityCarsPost(body?: PostPutCarRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CarViewModel>>;
    public emobilityCarsPost(body?: PostPutCarRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CarViewModel>(`${this.basePath}/api/admin/em/masterdata/cars`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a car
     * 
     * @param id the id of the car
     * @param body the new car data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emobilityCarsPut(id: string, body?: PostPutCarRequest, observe?: 'body', reportProgress?: boolean): Observable<CarViewModel>;
    public emobilityCarsPut(id: string, body?: PostPutCarRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CarViewModel>>;
    public emobilityCarsPut(id: string, body?: PostPutCarRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CarViewModel>>;
    public emobilityCarsPut(id: string, body?: PostPutCarRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling emobilityCarsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CarViewModel>(`${this.basePath}/api/admin/em/masterdata/cars/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
