import { MultiSelectComponent } from './../components/multi-select/multi-select.component';
import { IFieldConfig } from './../models/sharedInterfaces';
import { CheckboxComponent } from './../components/checkbox/checkbox.component';
import { DateComponent } from './../components/date/date.component';
import { SelectComponent } from './../components/select/select.component';
import { InputComponent } from './../components/input/input.component';
import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  ViewContainerRef,
  ComponentFactory,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

const componentMapper: any = {
  String: InputComponent,
  Integer: InputComponent,
  Decimal: InputComponent,
  Select: SelectComponent,
  MultiSelect: MultiSelectComponent,
  DateTime: DateComponent,
  Boolean: CheckboxComponent,
};

@Directive({
  selector: '[acDynamicField]',
})
export class DynamicFieldDirective implements OnInit {
  // Inputs
  @Input() field: IFieldConfig;
  @Input() group: UntypedFormGroup;

  componentRef: ComponentRef<any>;

  constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef) {}

  ngOnInit(): void {
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(
      componentMapper[this.field.Type]
    );
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
  }
}
