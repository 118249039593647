/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { InverterListViewModel } from '../model/inverterListViewModel';
import { InverterViewModel } from '../model/inverterViewModel';
import { ModuleListViewModel } from '../model/moduleListViewModel';
import { ModuleViewModel } from '../model/moduleViewModel';
import { PostPutInverterRequest } from '../model/postPutInverterRequest';
import { PostPutModuleRequest } from '../model/postPutModuleRequest';
import { PostPutShadingOptimizerRequest } from '../model/postPutShadingOptimizerRequest';
import { PostPutStorageRequest } from '../model/postPutStorageRequest';
import { ShadingOptimizerListViewModel } from '../model/shadingOptimizerListViewModel';
import { ShadingOptimizerViewModel } from '../model/shadingOptimizerViewModel';
import { StorageListViewModel } from '../model/storageListViewModel';
import { StorageViewModel } from '../model/storageViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PvMasterDataService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletes a inverter  Be aware that inverters can only be deleted if they are no longer used anywhere
     * 
     * @param inverterId id of the inverter to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvInverterDeleteInverter(inverterId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pvInverterDeleteInverter(inverterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pvInverterDeleteInverter(inverterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pvInverterDeleteInverter(inverterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inverterId === null || inverterId === undefined) {
            throw new Error('Required parameter inverterId was null or undefined when calling pvInverterDeleteInverter.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/pv/masterdata/inverters/${encodeURIComponent(String(inverterId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all existing inverters
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvInverterGetAllInverters(observe?: 'body', reportProgress?: boolean): Observable<Array<InverterListViewModel>>;
    public pvInverterGetAllInverters(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InverterListViewModel>>>;
    public pvInverterGetAllInverters(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InverterListViewModel>>>;
    public pvInverterGetAllInverters(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<InverterListViewModel>>(`${this.basePath}/api/admin/pv/masterdata/inverters`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific inverter by id, including additional data like usages
     * 
     * @param inverterId the id of the inverter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvInverterGetInverter(inverterId: string, observe?: 'body', reportProgress?: boolean): Observable<InverterViewModel>;
    public pvInverterGetInverter(inverterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InverterViewModel>>;
    public pvInverterGetInverter(inverterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InverterViewModel>>;
    public pvInverterGetInverter(inverterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inverterId === null || inverterId === undefined) {
            throw new Error('Required parameter inverterId was null or undefined when calling pvInverterGetInverter.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<InverterViewModel>(`${this.basePath}/api/admin/pv/masterdata/inverters/${encodeURIComponent(String(inverterId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a inverter
     * 
     * @param body the new inverter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvInverterPostInverter(body?: PostPutInverterRequest, observe?: 'body', reportProgress?: boolean): Observable<InverterViewModel>;
    public pvInverterPostInverter(body?: PostPutInverterRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InverterViewModel>>;
    public pvInverterPostInverter(body?: PostPutInverterRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InverterViewModel>>;
    public pvInverterPostInverter(body?: PostPutInverterRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InverterViewModel>(`${this.basePath}/api/admin/pv/masterdata/inverters`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a inverter
     * 
     * @param body the new inverter data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvInverterPutInverter(body?: PostPutInverterRequest, observe?: 'body', reportProgress?: boolean): Observable<InverterViewModel>;
    public pvInverterPutInverter(body?: PostPutInverterRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InverterViewModel>>;
    public pvInverterPutInverter(body?: PostPutInverterRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InverterViewModel>>;
    public pvInverterPutInverter(body?: PostPutInverterRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InverterViewModel>(`${this.basePath}/api/admin/pv/masterdata/inverters`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a module  Be aware that modules can only be deleted if they are no longer used anywhere
     * 
     * @param moduleId id of the module to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvModuleDeleteModule(moduleId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pvModuleDeleteModule(moduleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pvModuleDeleteModule(moduleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pvModuleDeleteModule(moduleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling pvModuleDeleteModule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/pv/masterdata/modules/${encodeURIComponent(String(moduleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all existing modules
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvModuleGetAllModules(observe?: 'body', reportProgress?: boolean): Observable<Array<ModuleListViewModel>>;
    public pvModuleGetAllModules(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ModuleListViewModel>>>;
    public pvModuleGetAllModules(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ModuleListViewModel>>>;
    public pvModuleGetAllModules(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ModuleListViewModel>>(`${this.basePath}/api/admin/pv/masterdata/modules`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific module by id, including additional data like usages
     * 
     * @param moduleId the id of the module
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvModuleGetModule(moduleId: string, observe?: 'body', reportProgress?: boolean): Observable<ModuleViewModel>;
    public pvModuleGetModule(moduleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModuleViewModel>>;
    public pvModuleGetModule(moduleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModuleViewModel>>;
    public pvModuleGetModule(moduleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling pvModuleGetModule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<ModuleViewModel>(`${this.basePath}/api/admin/pv/masterdata/modules/${encodeURIComponent(String(moduleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a module
     * 
     * @param body the new module
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvModulePostModule(body?: PostPutModuleRequest, observe?: 'body', reportProgress?: boolean): Observable<ModuleViewModel>;
    public pvModulePostModule(body?: PostPutModuleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModuleViewModel>>;
    public pvModulePostModule(body?: PostPutModuleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModuleViewModel>>;
    public pvModulePostModule(body?: PostPutModuleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ModuleViewModel>(`${this.basePath}/api/admin/pv/masterdata/modules`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a module
     * 
     * @param body the new module data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvModulePutModule(body?: PostPutModuleRequest, observe?: 'body', reportProgress?: boolean): Observable<ModuleViewModel>;
    public pvModulePutModule(body?: PostPutModuleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModuleViewModel>>;
    public pvModulePutModule(body?: PostPutModuleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModuleViewModel>>;
    public pvModulePutModule(body?: PostPutModuleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ModuleViewModel>(`${this.basePath}/api/admin/pv/masterdata/modules`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a shadingOptimizer  Be aware that shadingOptimizers can only be deleted if they are no longer used anywhere
     * 
     * @param shadingOptimizerId id of the shadingOptimizer to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvShadingOptimizerDeleteShadingOptimizer(shadingOptimizerId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pvShadingOptimizerDeleteShadingOptimizer(shadingOptimizerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pvShadingOptimizerDeleteShadingOptimizer(shadingOptimizerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pvShadingOptimizerDeleteShadingOptimizer(shadingOptimizerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shadingOptimizerId === null || shadingOptimizerId === undefined) {
            throw new Error('Required parameter shadingOptimizerId was null or undefined when calling pvShadingOptimizerDeleteShadingOptimizer.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/pv/masterdata/shading-optimizers/${encodeURIComponent(String(shadingOptimizerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all existing shadingOptimizers
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvShadingOptimizerGetAllShadingOptimizers(observe?: 'body', reportProgress?: boolean): Observable<Array<ShadingOptimizerListViewModel>>;
    public pvShadingOptimizerGetAllShadingOptimizers(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ShadingOptimizerListViewModel>>>;
    public pvShadingOptimizerGetAllShadingOptimizers(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ShadingOptimizerListViewModel>>>;
    public pvShadingOptimizerGetAllShadingOptimizers(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ShadingOptimizerListViewModel>>(`${this.basePath}/api/admin/pv/masterdata/shading-optimizers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific shadingOptimizer by id, including additional data like usages
     * 
     * @param shadingOptimizerId the id of the shadingOptimizer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvShadingOptimizerGetShadingOptimizer(shadingOptimizerId: string, observe?: 'body', reportProgress?: boolean): Observable<ShadingOptimizerViewModel>;
    public pvShadingOptimizerGetShadingOptimizer(shadingOptimizerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShadingOptimizerViewModel>>;
    public pvShadingOptimizerGetShadingOptimizer(shadingOptimizerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShadingOptimizerViewModel>>;
    public pvShadingOptimizerGetShadingOptimizer(shadingOptimizerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shadingOptimizerId === null || shadingOptimizerId === undefined) {
            throw new Error('Required parameter shadingOptimizerId was null or undefined when calling pvShadingOptimizerGetShadingOptimizer.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<ShadingOptimizerViewModel>(`${this.basePath}/api/admin/pv/masterdata/shading-optimizers/${encodeURIComponent(String(shadingOptimizerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a shadingOptimizer
     * 
     * @param body the new shadingOptimizer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvShadingOptimizerPostShadingOptimizer(body?: PostPutShadingOptimizerRequest, observe?: 'body', reportProgress?: boolean): Observable<ShadingOptimizerViewModel>;
    public pvShadingOptimizerPostShadingOptimizer(body?: PostPutShadingOptimizerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShadingOptimizerViewModel>>;
    public pvShadingOptimizerPostShadingOptimizer(body?: PostPutShadingOptimizerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShadingOptimizerViewModel>>;
    public pvShadingOptimizerPostShadingOptimizer(body?: PostPutShadingOptimizerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ShadingOptimizerViewModel>(`${this.basePath}/api/admin/pv/masterdata/shading-optimizers`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a shadingOptimizer
     * 
     * @param body the new shadingOptimizer data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvShadingOptimizerPutShadingOptimizer(body?: PostPutShadingOptimizerRequest, observe?: 'body', reportProgress?: boolean): Observable<ShadingOptimizerViewModel>;
    public pvShadingOptimizerPutShadingOptimizer(body?: PostPutShadingOptimizerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShadingOptimizerViewModel>>;
    public pvShadingOptimizerPutShadingOptimizer(body?: PostPutShadingOptimizerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShadingOptimizerViewModel>>;
    public pvShadingOptimizerPutShadingOptimizer(body?: PostPutShadingOptimizerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ShadingOptimizerViewModel>(`${this.basePath}/api/admin/pv/masterdata/shading-optimizers`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a storage  Be aware that storages can only be deleted if they are no longer used anywhere
     * 
     * @param storageId id of the storage to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvStorageDeleteStorage(storageId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pvStorageDeleteStorage(storageId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pvStorageDeleteStorage(storageId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pvStorageDeleteStorage(storageId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (storageId === null || storageId === undefined) {
            throw new Error('Required parameter storageId was null or undefined when calling pvStorageDeleteStorage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/pv/masterdata/storages/${encodeURIComponent(String(storageId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all existing storages
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvStorageGetAllStorages(observe?: 'body', reportProgress?: boolean): Observable<Array<StorageListViewModel>>;
    public pvStorageGetAllStorages(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StorageListViewModel>>>;
    public pvStorageGetAllStorages(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StorageListViewModel>>>;
    public pvStorageGetAllStorages(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StorageListViewModel>>(`${this.basePath}/api/admin/pv/masterdata/storages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific storage by id, including additional data like usages
     * 
     * @param storageId the id of the storage
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvStorageGetStorage(storageId: string, observe?: 'body', reportProgress?: boolean): Observable<StorageViewModel>;
    public pvStorageGetStorage(storageId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StorageViewModel>>;
    public pvStorageGetStorage(storageId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StorageViewModel>>;
    public pvStorageGetStorage(storageId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (storageId === null || storageId === undefined) {
            throw new Error('Required parameter storageId was null or undefined when calling pvStorageGetStorage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<StorageViewModel>(`${this.basePath}/api/admin/pv/masterdata/storages/${encodeURIComponent(String(storageId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a storage
     * 
     * @param body the new storage
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvStoragePostStorage(body?: PostPutStorageRequest, observe?: 'body', reportProgress?: boolean): Observable<StorageViewModel>;
    public pvStoragePostStorage(body?: PostPutStorageRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StorageViewModel>>;
    public pvStoragePostStorage(body?: PostPutStorageRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StorageViewModel>>;
    public pvStoragePostStorage(body?: PostPutStorageRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<StorageViewModel>(`${this.basePath}/api/admin/pv/masterdata/storages`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a storage
     * 
     * @param body the new storage data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvStoragePutStorage(body?: PostPutStorageRequest, observe?: 'body', reportProgress?: boolean): Observable<StorageViewModel>;
    public pvStoragePutStorage(body?: PostPutStorageRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StorageViewModel>>;
    public pvStoragePutStorage(body?: PostPutStorageRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StorageViewModel>>;
    public pvStoragePutStorage(body?: PostPutStorageRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<StorageViewModel>(`${this.basePath}/api/admin/pv/masterdata/storages`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
