import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  MandatorViewModel,
  PostPutShadingOptimizerRequest,
} from '../../../../../shared/apis/advis';
import { IGuardDeactivation } from '../../../../../shared/interfaces/guard-deactivation';
import {
  getInverterManufacturers,
  getInverterTypes,
  getMandators,
} from '../../../../../shared/store';
import { IRootState } from '../../../../../root.state';
import { Store } from '@ngrx/store';
import { UntypedFormGroup } from '@angular/forms';
import {
  ADD_SHADINGOPTIMIZER_SUCCESS,
  AddShadingOptimizerAction,
  EDIT_SHADINGOPTIMIZER_SUCCESS,
  EditShadingOptimizerAction,
  GET_SHADINGOPTIMIZER_SUCCESS,
  GetShadingOptimizerAction,
  GetShadingOptimizerSuccessAction,
} from '../../../../state/masterdata.action';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import { generateEmptyLocalizations } from '../../../../../shared/components/localized-values/generateEmptyLocalizations';
import { checkSpecialCharacters } from '../../../../../shared/utils/validation-util';

@Component({
  selector: 'ac-masterdata-shading-optimizers-form',
  templateUrl: './masterdata-shading-optimizers-form.component.html',
  styleUrls: ['./masterdata-shading-optimizers-form.component.scss'],
})
export class MasterdataShadingOptimizersFormComponent
  implements OnInit, OnDestroy, IGuardDeactivation
{
  private subscription: Subscription = new Subscription();

  shadingOptimizer: PostPutShadingOptimizerRequest = {} as PostPutShadingOptimizerRequest;
  initialShadingOptimizer: PostPutShadingOptimizerRequest = {} as PostPutShadingOptimizerRequest;

  inverterTypes: string[];
  inverterManufacturers: string[];

  shadingOptimizerId: number;

  isNew: boolean = true;

  shadingOptimizerFormTitle: string = '';

  @ViewChild('shadingOptimizerForm')
  shadingOptimizerForm: UntypedFormGroup;

  mandators: MandatorViewModel[] = [];

  constructor(
    private route: ActivatedRoute,
    private store: Store<IRootState>,
    private router: Router,
    private action$: Actions
  ) {
    // empty
  }

  ngOnInit(): void {
    let url$: Observable<UrlSegment[]> = this.route.url;
    let params$: Observable<Params> = this.route.params;
    const types$: Observable<string[]> = this.store.select(getInverterTypes);
    const manufacturers$: Observable<string[]> = this.store.select(getInverterManufacturers);

    this.subscription.add(
      combineLatest([url$, params$, manufacturers$, types$]).subscribe(
        ([url, params, manufacturers, types]: any) => {
          let urlPath: string = url[1].path.toString();

          this.inverterTypes = types;
          this.inverterManufacturers = manufacturers;

          if (urlPath === 'edit') {
            if (params && params.id) {
              this.store.dispatch(new GetShadingOptimizerAction(params.id));
              this.shadingOptimizerId = params.id;
              this.loadShadingOptimizers();
              this.shadingOptimizerFormTitle = 'Edit Shading optimizer';
              this.isNew = false;
            }
          } else {
            this.shadingOptimizerFormTitle = 'Add Shading optimizer';
            this.shadingOptimizer = {
              Id: '',
              LocalizedTitles: generateEmptyLocalizations(),
            } as PostPutShadingOptimizerRequest;
          }
        }
      )
    );

    this.subscription.add(
      this.store.select(getMandators).subscribe((mandators: MandatorViewModel[]) => {
        this.mandators = mandators; // .map((x: TradeTypeViewModel) => x.Code);
      })
    );

    this.subscription.add(
      this.action$
        .pipe(ofType(ADD_SHADINGOPTIMIZER_SUCCESS, EDIT_SHADINGOPTIMIZER_SUCCESS), take(1))
        .subscribe(() => this.onBack())
    );
  }

  loadShadingOptimizers(): void {
    this.subscription.add(
      this.action$
        .pipe(ofType(GET_SHADINGOPTIMIZER_SUCCESS), take(1))
        .subscribe((action: GetShadingOptimizerSuccessAction) => {
          this.shadingOptimizer = cloneDeep(action.payload as PostPutShadingOptimizerRequest);
          this.initialShadingOptimizer = cloneDeep(this.shadingOptimizer);
        })
    );
  }

  isFormValid(): boolean {
    return (
      this.shadingOptimizerForm &&
      this.shadingOptimizerForm.valid &&
      checkSpecialCharacters(this.shadingOptimizer.Id)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onBack(): void {
    this.router.navigate(['/pvmasterdata/shading-optimizers/overview']);
  }

  onSave(): void {
    this.initialShadingOptimizer = cloneDeep(this.shadingOptimizer);
    if (this.isNew) {
      this.store.dispatch(new AddShadingOptimizerAction(this.shadingOptimizer));
    } else {
      this.store.dispatch(new EditShadingOptimizerAction(this.shadingOptimizer));
    }
  }

  get canDeactivateSafely(): boolean {
    return isEqual(this.shadingOptimizer, this.initialShadingOptimizer);
  }
}
