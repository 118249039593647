<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    {{ formTitle }}
  </div>
  <div>
    <button mat-button color="accent" (click)="onBack()">{{ 'COMMON.CANCEL' | translate }}</button>
    <button mat-button color="primary" [disabled]="!isFormValid()" (click)="onSave()">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start" class="padding-10">
  <mat-card fxFlex="50">
    <mat-card-header>
      <mat-card-title>
        {{ formTitle }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form
        fxLayout="column"
        fxLayoutGap="15px"
        name="domesticForm"
        #domesticForm="ngForm"
        *ngIf="domesticWaterHeating || isNew">
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.ID' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.ID' | translate }}"
              type="text"
              name="id"
              required
              [disabled]="!isNew"
              [(ngModel)]="domesticWaterHeating.Id"
              #id />
            <mat-hint>{{ 'HT_MASTERDATA.NO_SPACES_DOTS_AND_SLASHES_IN_ID' | translate }}</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.ARTICLE_NUMBER' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.ARTICLE_NUMBER' | translate }}"
              type="text"
              name="articleNumber"
              required
              [(ngModel)]="domesticWaterHeating.ArticleNumber"
              #articleNumber />
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox id="isActive" name="isActive" [(ngModel)]="domesticWaterHeating.IsActive">{{
            'COMMON.IS_ACTIVE' | translate
          }}</mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.TECHNICAL_TITLE' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.TECHNICAL_TITLE' | translate }}"
              type="text"
              name="technicalTitle"
              required
              [(ngModel)]="domesticWaterHeating.TechnicalTitle"
              #technicalTitle />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.DESC' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.DESC' | translate }}"
              type="text"
              name="description"
              [(ngModel)]="domesticWaterHeating.Description"
              #description />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>{{ 'HT_MASTERDATA.SUPPORTED_HEATING_TYPES' | translate }}</mat-label>

            <mat-select
              name="heatType"
              required
              multiple
              [(ngModel)]="domesticWaterHeating.SupportedHeatingTypes"
              #heatType>
              <mat-option *ngFor="let heatingType of heatingTypes" [value]="heatingType">{{
                heatingType
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'HT_MASTERDATA.DOMESTIC_WATER_HEATING.DOMESTIC_WATER_HEATING_VOLUME' | translate
            }}</mat-label>
            <input
              matInput
              placeholder="{{
                'HT_MASTERDATA.DOMESTIC_WATER_HEATING.DOMESTIC_WATER_HEATING_VOLUME' | translate
              }}"
              type="number"
              name="domesticWaterHeatingVolume"
              required
              [(ngModel)]="domesticWaterHeating.DomesticWaterHeatingVolume"
              #domesticWaterHeatingVolume />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'HT_MASTERDATA.DOMESTIC_WATER_HEATING.DOMESTIC_WATER_HEATING_MATERIAL' | translate
            }}</mat-label>
            <input
              matInput
              placeholder="{{
                'HT_MASTERDATA.DOMESTIC_WATER_HEATING.DOMESTIC_WATER_HEATING_MATERIAL' | translate
              }}"
              type="text"
              name="domesticWaterHeatingMaterial"
              required
              [(ngModel)]="domesticWaterHeating.DomesticWaterHeatingMaterial"
              #domesticWaterHeatingMaterial />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'HT_MASTERDATA.DOMESTIC_WATER_HEATING.DOMESTIC_WATER_HEATING_CONSTRUCTION_TYPE'
                | translate
            }}</mat-label>

            <mat-select
              name="domesticWaterHeatingConstructionType"
              required
              [(ngModel)]="domesticWaterHeating.DomesticWaterHeatingConstructionType">
              <mat-option
                *ngFor="let constructionType of constructionTypes"
                [value]="constructionType"
                >{{ constructionType }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'HT_MASTERDATA.DOMESTIC_WATER_HEATING.DOMESTIC_WATER_HEATING_REGISTER_SIZE'
                | translate
            }}</mat-label>
            <input
              matInput
              placeholder="{{
                'HT_MASTERDATA.DOMESTIC_WATER_HEATING.DOMESTIC_WATER_HEATING_REGISTER_SIZE'
                  | translate
              }}"
              type="number"
              [min]="0"
              name="domesticWaterHeatingRegisterSize"
              required
              [(ngModel)]="domesticWaterHeating.DomesticWaterHeatingRegisterSize"
              #domesticWaterHeatingRegisterSize />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>{{ 'COMMON.MANDATORS' | translate }}</mat-label>

            <mat-select
              name="mandatorIds"
              multiple
              required
              [(ngModel)]="domesticWaterHeating.MandatorIds"
              #mandatorIds>
              <mat-option
                *ngFor="let mandator of mandators"
                [value]="mandator.Id"
                [disabled]="!mandator.IsActive"
                >{{ mandator.Name + ' (' + mandator.ShortName + ')' }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>{{ 'HT_MASTERDATA.SUPPORTED_MANUFACTURES' | translate }}</mat-label>
            <mat-select
              name="supportedManufacturersMap"
              multiple
              required
              [(ngModel)]="domesticWaterHeating.SupportedManufacturers"
              #supportedManufacturersMap>
              <mat-option
                *ngFor="let manufacturer of supportedManufacturers"
                [value]="manufacturer"
                >{{ manufacturer }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>{{ 'HT_MASTERDATA.SUB_PRODUCT_CONNECTION' | translate }}</mat-label>
            <mat-select
              name="subProductConnection"
              multiple
              [(ngModel)]="domesticWaterHeating.SubProductConnectionTypes"
              #subProductConnectionTypesMap>
              <mat-option
                *ngFor="let connection of subProductConnectionTypes"
                [value]="connection.key">
                {{ connection.key }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <h4>Translation</h4>
        <ac-localized-values
          [localizedValues]="domesticWaterHeating.LocalizedTitles"
          title="COMMON.TRANSLATION">
        </ac-localized-values>
        <ac-localized-values
          [localizedValues]="domesticWaterHeating.LocalizedDataSheets"
          [title]="'COMMON.DATASHEET'"
          [required]="false"
          [setReadonly]="true">
        </ac-localized-values>

        <ac-ht-masterdata-string-generation-controls
          [dialogData]="domesticWaterHeating.StringGenerationContainer"
          [required]="false"
          [mandators]="mandators"
          [preSelectedMandatorIds]="domesticWaterHeating.MandatorIds"
          [onSanitizeXssForSaveCallback]="onSaveStringGenerationSanitizeCallback"
          [displayGeneratedString]="false"
          (dialogClosedEvent)="onStringGenerationDialogClosed($event)">
        </ac-ht-masterdata-string-generation-controls>

        <div>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>{{ 'COMMON.AR_VIEWER_URL' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.AR_VIEWER_URL' | translate }}"
              type="text"
              name="arViewerUrl"
              [maxLength]="2048"
              [(ngModel)]="domesticWaterHeating.ArViewerUrl" />
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
