import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ErrorAddAction, ErrorTypeE, UiElementTypeE } from '../store';
import { IRootState } from '../../root.state';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store<IRootState>) {
    // empty
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        () => {
          // empty
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
                console.error(
                  `onResponseError status "${error.status}" error "${error.error}" redirect to login page`
                );
                window.location.href =
                  location.protocol + '//' + location.host + '/login?timeout=1';
                break;
              case 403:
                console.error(`onResponseError status "${error.status}" error "${error.error}"`);
                this.store.dispatch(
                  new ErrorAddAction({
                    type: ErrorTypeE.PERMISSION,
                    data: error.error,
                    uiElement: UiElementTypeE.DIALOG,
                  })
                );
                break;
              case 503:
                console.error(`onResponseError status "${error.status}" error "${error.error}"`);
                window.location.href = location.protocol + '//' + location.host + '/maintenance';
                break;
              default:
                // empty
                break;
            }
          }
        }
      )
    );
  }
}
