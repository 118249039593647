import { OrganisationUnitOverviewVsModel } from '../../shared/models/view-setting/organisation-unit-vs.model';
import { OrganisationUnitViewModel } from '../../shared/apis/advis';

export interface IOrganisationUnitState {
  organisationUnits: OrganisationUnitViewModel[];
  organisationUnitsLoading: boolean;
  organisationUnitsLoaded: boolean;
  organisationUnitOverviewViewSettings: OrganisationUnitOverviewVsModel;
  organisationUnitOVerviewViewSettingsLoaded: boolean;
}

export function initOrganisationUnitState(): IOrganisationUnitState {
  return {
    organisationUnits: [],
    organisationUnitsLoading: false,
    organisationUnitsLoaded: false,
    organisationUnitOverviewViewSettings: new OrganisationUnitOverviewVsModel(),
    organisationUnitOVerviewViewSettingsLoaded: false,
  };
}
