/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FileLinkViewModel } from '../model/fileLinkViewModel';
import { PostUserRequest } from '../model/postUserRequest';
import { PutUserRequest } from '../model/putUserRequest';
import { UserViewModel } from '../model/userViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activates an user by id
     * 
     * @param userId the id of the user to activate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userActivateUser(userId: string, observe?: 'body', reportProgress?: boolean): Observable<UserViewModel>;
    public userActivateUser(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserViewModel>>;
    public userActivateUser(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserViewModel>>;
    public userActivateUser(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userActivateUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<UserViewModel>(`${this.basePath}/api/admin/users/${encodeURIComponent(String(userId))}/activate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deactivates an user by id
     * 
     * @param userId the id of the user to deactivate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userDeactivateUser(userId: string, observe?: 'body', reportProgress?: boolean): Observable<UserViewModel>;
    public userDeactivateUser(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserViewModel>>;
    public userDeactivateUser(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserViewModel>>;
    public userDeactivateUser(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userDeactivateUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<UserViewModel>(`${this.basePath}/api/admin/users/${encodeURIComponent(String(userId))}/deactivate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove a user file
     * 
     * @param userId the id of the user to activate
     * @param fileType the type of the file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userDeleteUserFile(userId: string, fileType: 'Signature' | 'Picture', observe?: 'body', reportProgress?: boolean): Observable<UserViewModel>;
    public userDeleteUserFile(userId: string, fileType: 'Signature' | 'Picture', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserViewModel>>;
    public userDeleteUserFile(userId: string, fileType: 'Signature' | 'Picture', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserViewModel>>;
    public userDeleteUserFile(userId: string, fileType: 'Signature' | 'Picture', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userDeleteUserFile.');
        }

        if (fileType === null || fileType === undefined) {
            throw new Error('Required parameter fileType was null or undefined when calling userDeleteUserFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<UserViewModel>(`${this.basePath}/api/admin/users/${encodeURIComponent(String(userId))}/files/${encodeURIComponent(String(fileType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific user by id
     * 
     * @param userId the id of the user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGetUser(userId: string, observe?: 'body', reportProgress?: boolean): Observable<UserViewModel>;
    public userGetUser(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserViewModel>>;
    public userGetUser(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserViewModel>>;
    public userGetUser(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userGetUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserViewModel>(`${this.basePath}/api/admin/users/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the user files
     * 
     * @param userId the id of the user to activate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGetUserFiles(userId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FileLinkViewModel>>;
    public userGetUserFiles(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FileLinkViewModel>>>;
    public userGetUserFiles(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FileLinkViewModel>>>;
    public userGetUserFiles(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userGetUserFiles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FileLinkViewModel>>(`${this.basePath}/api/admin/users/${encodeURIComponent(String(userId))}/files`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets filtered users
     * 
     * @param UserName only retrieve entries with a matching UserName
     * @param Email only retrieve entries with a matching Email
     * @param Name only retrieve entries with a matching Name  (FirstName + LastName in any order)
     * @param CompanyName only retrieve entries with a matching CompanyName
     * @param City only retrieve entries with a matching city
     * @param ZipCode only retrieve entries with a matching zip code
     * @param MandatorIds only retrieve entries for this mandators, comma separated
     * @param OrganisationUnitId only retrieve entries for this organisationUnit
     * @param Role only retrieve entries for this role
     * @param IsActive only retrieve active/inactive users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGetUsers(UserName?: string, Email?: string, Name?: string, CompanyName?: string, City?: string, ZipCode?: string, MandatorIds?: string, OrganisationUnitId?: number, Role?: string, IsActive?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<UserViewModel>>;
    public userGetUsers(UserName?: string, Email?: string, Name?: string, CompanyName?: string, City?: string, ZipCode?: string, MandatorIds?: string, OrganisationUnitId?: number, Role?: string, IsActive?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserViewModel>>>;
    public userGetUsers(UserName?: string, Email?: string, Name?: string, CompanyName?: string, City?: string, ZipCode?: string, MandatorIds?: string, OrganisationUnitId?: number, Role?: string, IsActive?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserViewModel>>>;
    public userGetUsers(UserName?: string, Email?: string, Name?: string, CompanyName?: string, City?: string, ZipCode?: string, MandatorIds?: string, OrganisationUnitId?: number, Role?: string, IsActive?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (UserName !== undefined && UserName !== null) {
            queryParameters = queryParameters.set('UserName', <any>UserName);
        }
        if (Email !== undefined && Email !== null) {
            queryParameters = queryParameters.set('Email', <any>Email);
        }
        if (Name !== undefined && Name !== null) {
            queryParameters = queryParameters.set('Name', <any>Name);
        }
        if (CompanyName !== undefined && CompanyName !== null) {
            queryParameters = queryParameters.set('CompanyName', <any>CompanyName);
        }
        if (City !== undefined && City !== null) {
            queryParameters = queryParameters.set('City', <any>City);
        }
        if (ZipCode !== undefined && ZipCode !== null) {
            queryParameters = queryParameters.set('ZipCode', <any>ZipCode);
        }
        if (MandatorIds !== undefined && MandatorIds !== null) {
            queryParameters = queryParameters.set('MandatorIds', <any>MandatorIds);
        }
        if (OrganisationUnitId !== undefined && OrganisationUnitId !== null) {
            queryParameters = queryParameters.set('OrganisationUnitId', <any>OrganisationUnitId);
        }
        if (Role !== undefined && Role !== null) {
            queryParameters = queryParameters.set('Role', <any>Role);
        }
        if (IsActive !== undefined && IsActive !== null) {
            queryParameters = queryParameters.set('IsActive', <any>IsActive);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserViewModel>>(`${this.basePath}/api/admin/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates an user
     * 
     * @param body the new user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userPostUser(body?: PostUserRequest, observe?: 'body', reportProgress?: boolean): Observable<UserViewModel>;
    public userPostUser(body?: PostUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserViewModel>>;
    public userPostUser(body?: PostUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserViewModel>>;
    public userPostUser(body?: PostUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserViewModel>(`${this.basePath}/api/admin/users`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload new user file
     * 
     * @param userId the id of the user to activate
     * @param fileType the type of the file
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userPostUserFile(userId: string, fileType: 'Signature' | 'Picture', file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<UserViewModel>;
    public userPostUserFile(userId: string, fileType: 'Signature' | 'Picture', file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserViewModel>>;
    public userPostUserFile(userId: string, fileType: 'Signature' | 'Picture', file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserViewModel>>;
    public userPostUserFile(userId: string, fileType: 'Signature' | 'Picture', file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userPostUserFile.');
        }

        if (fileType === null || fileType === undefined) {
            throw new Error('Required parameter fileType was null or undefined when calling userPostUserFile.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<UserViewModel>(`${this.basePath}/api/admin/users/${encodeURIComponent(String(userId))}/files/${encodeURIComponent(String(fileType))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an user
     * 
     * @param userId the id of the user to update
     * @param body the user data to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userPutUser(userId: string, body?: PutUserRequest, observe?: 'body', reportProgress?: boolean): Observable<UserViewModel>;
    public userPutUser(userId: string, body?: PutUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserViewModel>>;
    public userPutUser(userId: string, body?: PutUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserViewModel>>;
    public userPutUser(userId: string, body?: PutUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userPutUser.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UserViewModel>(`${this.basePath}/api/admin/users/${encodeURIComponent(String(userId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
