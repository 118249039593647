import { IFieldConfig } from './../../models/sharedInterfaces';
import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ac-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  field: IFieldConfig;
  group: UntypedFormGroup;
}
