/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GenericPropertyValueViewModel } from './genericPropertyValueViewModel';
import { PositionBuilderRuleViewModel } from './positionBuilderRuleViewModel';


export interface GenericPositionBuilderViewModel { 
    /**
     * Identification of the position builder rule
     */
    Id?: number;
    /**
     * Productline id of the builder
     */
    ProductlineId?: number;
    /**
     * Article number of the builder
     */
    ArticleNumber: string;
    /**
     * Article name of that article number, readonly
     */
    ArticleName?: string;
    /**
     * A short description of the builder's rule
     */
    Note?: string;
    /**
     * The amount of this article to be added
     */
    Amount?: number;
    /**
     * whether the article should be added as an option
     */
    IsOption?: boolean;
    /**
     * whether the article should be added as selected
     */
    IsSelected?: boolean;
    /**
     * The id of the position group template this position gets added to  The templates are available per trade type in their own endpoint
     */
    PositionGroupTemplateId?: number;
    /**
     * The custom amount formula calculates the custom amount factor to multiply the custom amount  Default is 1
     */
    CustomAmountFormula?: string;
    /**
     * The apply order of the builder
     */
    Order?: number;
    /**
     * The generic properties of this builder
     */
    GenericProperties?: Array<GenericPropertyValueViewModel>;
    Rule: PositionBuilderRuleViewModel;
}
