import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { IRootState } from '../../../root.state';
import {
  getSelectableMandators,
  getSelectableTradeTypes,
  IMandatorSelectItem,
  ITradeTypeSelectItem,
  SaveGlobalViewSettingsAction,
} from '../../store';
import { map } from 'rxjs/operators';
import { ViewSettingItem, ViewSettingKeyE } from '../../services/view-setting.service';
import { GlobalVsModel } from '../../models/view-setting/global-vs.model';
import { GlobalFilterUtil } from '../../utils/globalFilter.util';
import { NotificationService, TypeE } from '../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ac-mandator-selector',
  templateUrl: './mandator-selector.component.html',
  styleUrls: ['./mandator-selector.component.scss'],
})
export class MandatorSelectorComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private selectableTradeTypes: ITradeTypeSelectItem[];
  private selectedTradeTypes: ITradeTypeSelectItem[];

  selectableMandators: IMandatorSelectItem[];
  selectedMandators: IMandatorSelectItem[];

  constructor(
    private store: Store<IRootState>,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {
    // empty
  }

  ngOnInit(): void {
    const selectableMandators$: Observable<IMandatorSelectItem[]> =
      this.store.select(getSelectableMandators);
    const selectableTradeTypes$: Observable<ITradeTypeSelectItem[]> =
      this.store.select(getSelectableTradeTypes);

    this.subscriptions.add(
      combineLatest(selectableMandators$, selectableTradeTypes$)
        .pipe(
          map(([selectableMandators, selectableTradeTypes]: any) => {
            this.selectableMandators = cloneDeep(selectableMandators);
            this.selectedMandators = this.selectableMandators.filter(
              (item: IMandatorSelectItem) => item.selected
            );

            this.selectableTradeTypes = cloneDeep(selectableTradeTypes);
            this.selectedTradeTypes = this.selectableTradeTypes.filter(
              (item: ITradeTypeSelectItem) => item.selected
            );

            if (this.selectedMandators.length === 0) {
              this.notificationService.notifyWithAction(
                this.translate.instant('COMMON.SELECT_MANDANTS'),
                this.translate.instant('COMMON.BTN_DISMISS'),
                TypeE.ALERT
              );
            } else if (this.selectedTradeTypes.length === 0) {
              this.notificationService.notifyWithAction(
                this.translate.instant('COMMON.SELECT_TRADETYPE'),
                this.translate.instant('COMMON.BTN_DISMISS'),
                TypeE.ALERT
              );
            } else if (this.selectedMandators.length > 0 && this.selectedTradeTypes.length > 0) {
              this.notificationService.close();
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onClose(): void {
    this.dispatchViewSettings();
  }

  public onResetClick(): void {
    this.selectedMandators = [];
  }

  private dispatchViewSettings(): void {
    this.selectedTradeTypes = GlobalFilterUtil.filterSelectedTradeTypesAccordingMandators(
      this.selectedTradeTypes,
      this.selectedMandators
    );

    this.store.dispatch(
      new SaveGlobalViewSettingsAction(
        new ViewSettingItem(
          ViewSettingKeyE.GLOBAL_VIEW_SETTINGS,
          new GlobalVsModel(
            GlobalFilterUtil.mapMandatorSelectItemToIds(this.selectedMandators),
            GlobalFilterUtil.mapTradeTypeSelectItemToIds(this.selectedTradeTypes)
          )
        )
      )
    );
  }
}
