<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    {{ organisationUnitFormTitle }}
  </div>
  <div>
    <button mat-button color="accent" (click)="onBack()">{{ 'COMMON.CANCEL' | translate }}</button>
    <button mat-button color="primary" [disabled]="!isFormValid()" (click)="onSave()">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start" class="padding-10">
  <mat-card fxFlex="50">
    <mat-card-header>
      <mat-card-title>
        {{ organisationUnitFormTitle }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form
        fxLayout="column"
        fxLayoutGap="15px"
        name="organisationUnitForm"
        #organisationUnitForm="ngForm"
        *ngIf="organisationUnit || isNew">
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>Mandator</mat-label>

            <mat-select name="mandator" required [(ngModel)]="organisationUnit.MandatorId">
              <mat-option
                *ngFor="let mandator of mandators"
                [value]="mandator.Id"
                [disabled]="!mandator.IsActive"
                >{{ mandator.Name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'MANDATORS.SHORT_NAME' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'MANDATORS.SHORT_NAME' | translate }}"
              type="text"
              name="shortName"
              required
              [maxlength]="15"
              [(ngModel)]="organisationUnit.ShortName"
              #shortName />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'MANDATORS.NAME' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'MANDATORS.NAME' | translate }}"
              type="text"
              name="name"
              required
              [maxlength]="50"
              [(ngModel)]="organisationUnit.Name"
              #name />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ORGANISATION_UNIT.EMAIL' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.EMAIL' | translate }}"
              type="text"
              name="email"
              [maxlength]="100"
              [(ngModel)]="organisationUnit.Email"
              #email />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ORGANISATION_UNIT.PHONE_NUMBER' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.PHONE_NUMBER' | translate }}"
              type="text"
              name="phoneNumber"
              [maxlength]="50"
              [(ngModel)]="organisationUnit.PhoneNumber"
              #phoneNumber />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ORGANISATION_UNIT.URL' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.URL' | translate }}"
              type="text"
              name="url"
              [maxlength]="255"
              [(ngModel)]="organisationUnit.Url"
              #url />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ORGANISATION_UNIT.STREET' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.STREET' | translate }}"
              type="text"
              name="street"
              [maxlength]="100"
              [(ngModel)]="organisationUnit.Address.Street"
              #street />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ORGANISATION_UNIT.ZIP_CODE' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.ZIP_CODE' | translate }}"
              type="text"
              name="zipCode"
              [maxlength]="15"
              [(ngModel)]="organisationUnit.Address.ZipCode"
              #zipCode />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ORGANISATION_UNIT.CITY' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.CITY' | translate }}"
              type="text"
              name="city"
              [maxlength]="100"
              [(ngModel)]="organisationUnit.Address.City"
              #city />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ORGANISATION_UNIT.HOURLY_RATE_MONTAGE' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.HOURLY_RATE_MONTAGE' | translate }}"
              type="number"
              name="hourlyRateMontage"
              required
              [(ngModel)]="organisationUnit.HourlyRateMontage"
              #hourlyRateMontage />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ORGANISATION_UNIT.HOURLY_RATE_MONTAGE_AC' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.HOURLY_RATE_MONTAGE_AC' | translate }}"
              type="number"
              name="hourlyRateMontageAc"
              required
              [(ngModel)]="organisationUnit.HourlyRateMontageAc"
              #hourlyRateMontageAc />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'ORGANISATION_UNIT.HOURLY_RATE_MONTAGE_SCAFFOLDING' | translate
            }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.HOURLY_RATE_MONTAGE_SCAFFOLDING' | translate }}"
              type="number"
              name="hourlyRateMontageScaffolding"
              required
              [(ngModel)]="organisationUnit.HourlyRateMontageScaffolding"
              #hourlyRateMontageScaffolding />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'ORGANISATION_UNIT.HOURLY_RATE_PROJECT_MANAGEMENT' | translate
            }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.HOURLY_RATE_PROJECT_MANAGEMENT' | translate }}"
              type="number"
              name="hourlyRateProjectManagement"
              required
              [(ngModel)]="organisationUnit.HourlyRateProjectManagement"
              #hourlyRateProjectManagement />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ORGANISATION_UNIT.HOURLY_RATE_OTHERS' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.HOURLY_RATE_OTHERS' | translate }}"
              type="number"
              name="hourlyRateOthers"
              required
              [(ngModel)]="organisationUnit.HourlyRateOthers"
              #hourlyRateOthers />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'ORGANISATION_UNIT.ALLOCATED_PRODUCTION_OVERHEAD' | translate
            }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.ALLOCATED_PRODUCTION_OVERHEAD' | translate }}"
              type="number"
              name="allocatedProductionOverhead"
              required
              [(ngModel)]="organisationUnit.AllocatedProductionOverhead"
              #allocatedProductionOverhead />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ORGANISATION_UNIT.DB1_TO_DB6_SURCARGE' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.DB1_TO_DB6_SURCARGE' | translate }}"
              type="number"
              name="db1ToDb6Surcharge"
              required
              [(ngModel)]="organisationUnit.Db1ToDb6Surcharge"
              #db1ToDb6Surcharge />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ORGANISATION_UNIT.PROFIT_CENTER' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ORGANISATION_UNIT.PROFIT_CENTER' | translate }}"
              type="number"
              name="profitCenter"
              [(ngModel)]="organisationUnit.ProfitCenter"
              #profitCenter />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <div fxLayout="column" fxLayoutGap="2px">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'ORGANISATION_UNIT.PAYMENT_SCHEME' | translate }}</mat-label>
              <input
                matInput
                placeholder="{{ 'ORGANISATION_UNIT.PAYMENT_SCHEME' | translate }}"
                type="text"
                name="paymentScheme"
                [(ngModel)]="organisationUnit.PaymentScheme"
                #paymentScheme />
            </mat-form-field>
            <div
              innerHTML="{{ 'ORGANISATION_UNIT.PAYMENT_SCHEME_HELP_CONTENT' | translate }}"></div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
