/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface RoleViewModel { 
    /**
     * Role Id
     */
    Id?: string;
    /**
     * Role name
     */
    Name?: string;
    /**
     * The name of the icon that is displayed in the AC, next to the user's name
     */
    IconName?: string;
    /**
     * The role's permissions list
     */
    Permissions?: Array<string>;
    /**
     * The role's mandator list
     */
    MandatorIds?: Array<number>;
    /**
     * Number of users with that role
     */
    UsagesCount?: number;
}
