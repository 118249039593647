import { Action } from '@ngrx/store';
import {
  PostPutRoleRequest,
  PostPutRoleTargetGroupRequest,
  RoleTargetGroupViewModel,
  RoleViewModel,
} from '../../../shared/apis/advis';

const prefix: string = '[Role]';

export const GET_ROLES: string = `${prefix} Get all roles`;
export const GET_ROLES_SUCCESS: string = `${prefix} Get all roles success`;
export const GET_ROLES_FAILED: string = `${prefix} Get all roles failed`;

export const GET_ROLE: string = `${prefix} Get role`;
export const GET_ROLE_SUCCESS: string = `${prefix} Get role success`;
export const GET_ROLE_FAILED: string = `${prefix} Get role failed`;

export const ADD_ROLE: string = `${prefix} Add role`;
export const ADD_ROLE_SUCCESS: string = `${prefix} Add role success`;
export const ADD_ROLE_FAILED: string = `${prefix} Add role failed`;

export const EDIT_ROLE: string = `${prefix} Edit role`;
export const EDIT_ROLE_SUCCESS: string = `${prefix} Edit role success`;
export const EDIT_ROLE_FAILED: string = `${prefix} Edit role failed`;

export const DELETE_ROLE: string = `${prefix} Delete roles`;
export const DELETE_ROLE_SUCCESS: string = `${prefix} Delete role success`;
export const DELETE_ROLE_FAILED: string = `${prefix} Delete role failed`;

export const GET_ALL_PERMISSIONS: string = `${prefix} Get all permissions`;
export const GET_ALL_PERMISSIONS_SUCCESS: string = `${prefix} Get all permissions success`;
export const GET_ALL_PERMISSIONS_FAILED: string = `${prefix} Get all permissions failed`;

export const GET_TARGET_GROUPS: string = `${prefix} Get target groups`;
export const GET_TARGET_GROUPS_SUCCESS: string = `${prefix} Get target groups success`;
export const GET_TARGET_GROUPS_FAILED: string = `${prefix} Get target groups failed`;

export const GET_TARGET_GROUP: string = `${prefix} Get target group`;
export const GET_TARGET_GROUP_SUCCESS: string = `${prefix} Get target group success`;
export const GET_TARGET_GROUP_FAILED: string = `${prefix} Get target group failed`;

export const ADD_TARGET_GROUP: string = `${prefix} Add target group`;
export const ADD_TARGET_GROUP_SUCCESS: string = `${prefix} Add target group success`;
export const ADD_TARGET_GROUP_FAILED: string = `${prefix} Add target group failed`;

export const EDIT_TARGET_GROUP: string = `${prefix} Edit target group`;
export const EDIT_TARGET_GROUP_SUCCESS: string = `${prefix} Edit target group success`;
export const EDIT_TARGET_GROUP_FAILED: string = `${prefix} Edit target group failed`;

export const DELETE_TARGET_GROUP: string = `${prefix} Delete target group`;
export const DELETE_TARGET_GROUP_SUCCESS: string = `${prefix} Delete target group success`;
export const DELETE_TARGET_GROUP_FAILED: string = `${prefix} Delete target group failed`;

export class GetRolesAction implements Action {
  readonly type: string = GET_ROLES;

  constructor(public payload: any = undefined) {}
}

export class GetRolesSuccessAction implements Action {
  readonly type: string = GET_ROLES_SUCCESS;

  constructor(public payload: RoleViewModel[]) {}
}

export class GetRolesFailedAction implements Action {
  readonly type: string = GET_ROLES_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetRoleAction implements Action {
  readonly type: string = GET_ROLE;

  constructor(public payload: string) {}
}

export class GetRoleSuccessAction implements Action {
  readonly type: string = GET_ROLE_SUCCESS;

  constructor(public payload: RoleViewModel) {}
}

export class GetRoleFailedAction implements Action {
  readonly type: string = GET_ROLE_FAILED;

  constructor(public payload: any = undefined) {}
}

export class AddRoleAction implements Action {
  readonly type: string = ADD_ROLE;

  constructor(public payload: PostPutRoleRequest) {}
}

export class AddRoleSuccessAction implements Action {
  readonly type: string = ADD_ROLE_SUCCESS;

  constructor(public payload: RoleViewModel) {}
}

export class AddRoleFailedAction implements Action {
  readonly type: string = ADD_ROLE_FAILED;

  constructor(public payload: any = undefined) {}
}

export interface IEditRoleRequest {
  putRoleRequest: PostPutRoleRequest;
  roleId: string;
}

export class EditRoleAction implements Action {
  readonly type: string = EDIT_ROLE;

  constructor(public payload: IEditRoleRequest) {}
}

export class EditRoleSuccessAction implements Action {
  readonly type: string = EDIT_ROLE_SUCCESS;

  constructor(public payload: RoleViewModel) {}
}

export class EditRoleFailedAction implements Action {
  readonly type: string = EDIT_ROLE_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeleteRoleAction implements Action {
  readonly type: string = DELETE_ROLE;

  constructor(public payload: string) {}
}

export class DeleteRoleSuccessAction implements Action {
  readonly type: string = DELETE_ROLE_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteRoleFailedAction implements Action {
  readonly type: string = DELETE_ROLE_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetAllPermissionsAction implements Action {
  readonly type: string = GET_ALL_PERMISSIONS;

  constructor(public payload: any = undefined) {}
}

export class GetAllPermissionsSuccessAction implements Action {
  readonly type: string = GET_ALL_PERMISSIONS_SUCCESS;

  constructor(public payload: string[]) {}
}

export class GetAllPermissionsFailedAction implements Action {
  readonly type: string = GET_ALL_PERMISSIONS_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetTargetGroupsAction implements Action {
  readonly type: string = GET_TARGET_GROUPS;

  constructor() {}
}

export class GetTargetGroupsSuccessAction implements Action {
  readonly type: string = GET_TARGET_GROUPS_SUCCESS;

  constructor(public payload: RoleTargetGroupViewModel[]) {}
}

export class GetTargetGroupsFailedAction implements Action {
  readonly type: string = GET_TARGET_GROUPS_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetTargetGroupAction implements Action {
  readonly type: string = GET_TARGET_GROUP;

  constructor(public payload: string) {}
}

export class GetTargetGroupSuccessAction implements Action {
  readonly type: string = GET_TARGET_GROUP_SUCCESS;

  constructor(public payload: RoleTargetGroupViewModel) {}
}

export class GetTargetGroupFailedAction implements Action {
  readonly type: string = GET_TARGET_GROUP_FAILED;

  constructor(public payload: any = undefined) {}
}

export class AddTargetGroupAction implements Action {
  readonly type: string = ADD_TARGET_GROUP;

  constructor(public payload: PostPutRoleTargetGroupRequest) {}
}

export class AddTargetGroupSuccessAction implements Action {
  readonly type: string = ADD_TARGET_GROUP_SUCCESS;

  constructor(public payload: RoleTargetGroupViewModel) {}
}

export class AddTargetGroupFailedAction implements Action {
  readonly type: string = ADD_TARGET_GROUP_FAILED;

  constructor(public payload: any = undefined) {}
}

export class EditTargetGroupAction implements Action {
  readonly type: string = EDIT_TARGET_GROUP;

  constructor(public payload: PostPutRoleTargetGroupRequest) {}
}

export class EditTargetGroupSuccessAction implements Action {
  readonly type: string = EDIT_TARGET_GROUP_SUCCESS;

  constructor(public payload: RoleTargetGroupViewModel) {}
}

export class EditTargetGroupFailedAction implements Action {
  readonly type: string = EDIT_TARGET_GROUP_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeleteTargetGroupAction implements Action {
  readonly type: string = DELETE_TARGET_GROUP;

  constructor(public payload: string) {}
}

export class DeleteTargetGroupSuccessAction implements Action {
  readonly type: string = DELETE_TARGET_GROUP_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteTargetGroupFailedAction implements Action {
  readonly type: string = DELETE_TARGET_GROUP_FAILED;

  constructor(public payload: any = undefined) {}
}

export type Actions =
  | GetRolesAction
  | GetRolesSuccessAction
  | GetRolesFailedAction
  | GetRoleAction
  | GetRoleSuccessAction
  | GetRoleFailedAction
  | AddRoleAction
  | AddRoleSuccessAction
  | AddRoleFailedAction
  | EditRoleAction
  | EditRoleSuccessAction
  | EditRoleFailedAction
  | DeleteRoleAction
  | DeleteRoleSuccessAction
  | DeleteRoleFailedAction
  | GetAllPermissionsAction
  | GetAllPermissionsSuccessAction
  | GetAllPermissionsFailedAction
  | GetTargetGroupsAction
  | GetTargetGroupsSuccessAction
  | GetTargetGroupsFailedAction
  | GetTargetGroupAction
  | GetTargetGroupSuccessAction
  | GetTargetGroupFailedAction
  | AddTargetGroupAction
  | AddTargetGroupSuccessAction
  | AddTargetGroupFailedAction
  | EditTargetGroupAction
  | EditTargetGroupSuccessAction
  | EditTargetGroupFailedAction
  | DeleteTargetGroupAction
  | DeleteTargetGroupSuccessAction
  | DeleteTargetGroupFailedAction;
