import { IRootState } from '../../root.state';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { IIghTheiaConverterState } from './ighTheiaConverter.state';

export const getIghTheiaConverterState: (state: IRootState) => IIghTheiaConverterState = (
  state: IRootState
) => state.ighTheiaConverter;

export const getIghTheiaConverterFilesUploading: MemoizedSelector<IRootState, boolean> =
  createSelector(getIghTheiaConverterState, (state: IIghTheiaConverterState) => state.uploading);

export const getIghTheiaConverterResultFile: MemoizedSelector<IRootState, Blob> = createSelector(
  getIghTheiaConverterState,
  (state: IIghTheiaConverterState) => state.fileForDownload
);
