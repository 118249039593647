import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { LogoutAction } from '../auth/state/auth.action';
import { getPrincipal } from '../auth/state/auth.selectors';
import { IRootState } from '../root.state';
import { MandatorViewModel } from '../shared/apis/advis';
import { IPrincipal } from '../shared/interfaces/principal.interface';
import { DialogService } from '../shared/services/dialog.service';
import { EnvironmentService } from '../shared/services/environment.service';
import { PermissionService } from '../shared/services/permission.service';
import {
  ErrorClearAction,
  ErrorTypeE,
  getGlobalErrors,
  getMandators,
  getSelectableTradeTypes,
  IErrorInfo,
  ITradeTypeSelectItem,
  ResetBoCacheAction,
} from '../shared/store';
import * as SharedActions from '../shared/store/shared.actions';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum DebugClass {
  DEVELOPMENT = <any>'development',
  TESTING = <any>'testing',
  PRODUCTION = <any>'production',
}

export class DebugInfo {
  readonly debugText: string;
  readonly debugClass: DebugClass;
  readonly isDisplayed: boolean;

  constructor(text: string, clazz: DebugClass, display: boolean) {
    this.debugText = text;
    this.debugClass = clazz;
    this.isDisplayed = display;
  }
}

@Component({
  selector: 'ac-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  private errorDialogVisible: boolean = false;

  events: string[] = [];
  opened: boolean;

  debugInfo: DebugInfo;

  currentUser: string = '';

  subscription: Subscription = new Subscription();

  hasTradeTypePv: boolean = false;
  hasTradeTypeHt: boolean = false;
  hasTradeTypeEm: boolean = false;

  mandators: MandatorViewModel[] = [];

  constructor(
    private environment: EnvironmentService,
    private titleService: Title,
    private translate: TranslateService,
    private store: Store<IRootState>,
    private spinner: NgxSpinnerService,
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    public permissionService: PermissionService
  ) {
    // empty
  }

  ngOnInit(): void {
    this.titleService.setTitle('AC - ' + this.translate.instant('APP.TITLE'));
    this.createDebugInfo();

    this.subscription.add(
      this.store.select(getMandators).subscribe((m: MandatorViewModel[]) => (this.mandators = m))
    );

    this.subscription.add(
      this.store
        .select(getSelectableTradeTypes)
        .subscribe((selectableTradeTypes: ITradeTypeSelectItem[]) => {
          this.hasTradeTypePv =
            selectableTradeTypes.findIndex((t: ITradeTypeSelectItem) => t.id === 'PV') >= 0 &&
            this.permissionService.userPermissions.CanManagePvMasterData;
          this.hasTradeTypeHt =
            selectableTradeTypes.findIndex((t: ITradeTypeSelectItem) => t.id === 'HT') >= 0; // && this.permissionService.userPermissions.CanManageHtMasterData;
          this.hasTradeTypeEm =
            selectableTradeTypes.findIndex((t: ITradeTypeSelectItem) => t.id === 'EM') >= 0;
        })
    );

    this.subscription.add(
      this.store.select(getPrincipal).subscribe((principal: IPrincipal) => {
        this.currentUser = principal.getUser();
      })
    );

    this.subscription.add(
      this.store.select(getGlobalErrors).subscribe((errors: any[]) => {
        if (errors.length > 0) {
          this.showErrorDialog(errors);
        }
      })
    );

    this.subscription.add(
      this.actions$.pipe(ofType(SharedActions.RESET_BO_CACHE_SUCCESS)).subscribe(() => {
        this.spinner.hide();
        this.snackBar.open(this.translate.instant('SNACKBAR.BO_CACHE_CLEARED.TITLE'), undefined, {
          duration: 3000,
          panelClass: ['blue-snackbar'],
        });
      })
    );
  }

  resetBoCache(): void {
    this.spinner.show();
    this.store.dispatch(new ResetBoCacheAction());
  }

  createDebugInfo(): void {
    switch (this.environment.getEnvironmentType()) {
      case this.environment.getDev():
        this.debugInfo = new DebugInfo(
          this.environment.getDev().toString(),
          DebugClass.DEVELOPMENT,
          true
        );
        break;
      case this.environment.getTest():
        this.debugInfo = new DebugInfo(
          this.environment.getTest().toString(),
          DebugClass.TESTING,
          true
        );
        break;
      case this.environment.getProd():
        this.debugInfo = new DebugInfo('', DebugClass.PRODUCTION, false);
        break;
      default:
        this.debugInfo = new DebugInfo('', DebugClass.PRODUCTION, false);
        break;
    }
  }

  showErrorDialog(errors: IErrorInfo[]): void {
    let message: string = '';
    if (errors.length === 1) {
      const error: IErrorInfo = errors[0];
      if (
        error.data !== undefined &&
        error.data !== null &&
        typeof error.data &&
        error.data !== 'An error has occurred.'
      ) {
        message = error.data;
      } else {
        switch (error.type) {
          case ErrorTypeE.SAVE:
            message = this.translate.instant('ERROR.SAVE_FAILED');
            break;

          case ErrorTypeE.LOAD:
            message = this.translate.instant('ERROR.LOAD_FAILED');
            break;

          case ErrorTypeE.PERMISSION:
            message = this.translate.instant('ERROR.PERMISSION');
            break;

          case ErrorTypeE.SESSION_TIMEOUT:
            message = this.translate.instant('ERROR.SESSION_TIMEOUT');
            break;

          case ErrorTypeE.UNDEF:
          default:
            message = this.translate.instant('ERROR.GENERAL_ERRORS');
            break;
        }
      }
    } else if (errors.length > 1) {
      message = this.translate.instant('ERROR.GENERAL_ERRORS');
    }

    // todo make dis nice
    if (!this.errorDialogVisible) {
      this.errorDialogVisible = true;
      this.dialogService
        .openAlert(message)
        .afterClosed()
        .subscribe((accept: boolean) => {
          this.errorDialogVisible = false;
          if (accept) {
            // empty
          } else {
            this.store.dispatch(new ErrorClearAction());
          }
        });
    }
  }

  onLogout(): void {
    this.store.dispatch(new LogoutAction());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
