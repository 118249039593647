/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserCompanyAddressViewModel } from './userCompanyAddressViewModel';


export interface PostUserRequest { 
    Title?: PostUserRequest.TitleEnum;
    FirstName?: string;
    LastName?: string;
    JobTitle?: string;
    AdditionalName?: string;
    Email: string;
    UserName: string;
    PhoneNumber?: string;
    SendEmailOnLeadAssigned?: boolean;
    MandatorId?: number;
    OrganisationUnitId?: number;
    MandatorsInResponsibility?: Array<number>;
    Roles?: Array<string>;
    CompanyAddress: UserCompanyAddressViewModel;
    Password: string;
    CloudId?: string;
}
export namespace PostUserRequest {
    export type TitleEnum = 'None' | 'Sir' | 'Madam' | 'Company' | 'Family';
    export const TitleEnum = {
        None: 'None' as TitleEnum,
        Sir: 'Sir' as TitleEnum,
        Madam: 'Madam' as TitleEnum,
        Company: 'Company' as TitleEnum,
        Family: 'Family' as TitleEnum
    };
}
