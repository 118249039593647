import { Component } from '@angular/core';

@Component({
  selector: 'ac-vertical-divider',
  templateUrl: './vertical-divider.component.html',
  styleUrls: ['./vertical-divider.component.scss'],
})
export class VerticalDividerComponent {
  constructor() {
    // empty
  }
}
