import { ImportProgressMessage } from '../../shared/apis/advis';

export interface IProjectReportingState {
  importing: boolean;
  imported: boolean;
  progressMessages: ImportProgressMessage[];
}

export function initProjectReportingState(): IProjectReportingState {
  return {
    importing: false,
    imported: false,
    progressMessages: [],
  };
}
