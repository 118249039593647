import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as ProjectReportingAction from './project-reporting.action';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../../shared/services/config.service';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as SharedAction from '../../shared/store';
import { ErrorAddAction, ErrorTypeE, UiElementTypeE } from '../../shared/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectReportingService } from '../../shared/apis/advis';

@Injectable()
export class ProjectReportingEffects {
  importProjectFinances$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectReportingAction.IMPORT_PROJECT_FINANCES),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ProjectReportingAction.ImportProjectFinancesAction) => action.payload),
      switchMap((file: Blob) =>
        this.projectReportingService.projectReportingImportProjectFinancesAsExcel(file).pipe(
          map(
            () =>
              new ProjectReportingAction.ImportProjectFinancesSuccessAction(
                this.translate.instant('PROJECT_REPORTING.MESSAGE.UPLOAD_SUCCESSFUL')
              )
          ),
          catchError((e: HttpErrorResponse) => {
            if (e.status === 400 || e.status === 409) {
              return of(new ProjectReportingAction.ImportProjectFinancesFailedAction(e.error));
            } else {
              return of(
                new ProjectReportingAction.ImportProjectFinancesFailedAction(
                  this.translate.instant('PROJECT_REPORTING.ERROR.UPLOAD')
                )
              );
            }
          })
        )
      )
    )
  );

  importProjectFinancesSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectReportingAction.IMPORT_PROJECT_FINANCES_SUCCESS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ProjectReportingAction.ImportProjectFinancesSuccessAction) => action.payload),
      switchMap(message =>
        of(new SharedAction.SuccessAction({ message: message, showWithAction: true }))
      )
    )
  );

  importProjectFinancesFailed$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectReportingAction.IMPORT_PROJECT_FINANCES_FAILED),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ProjectReportingAction.ImportProjectFinancesFailedAction) => action.payload),
      switchMap((data: string) =>
        of(
          new ErrorAddAction({ type: ErrorTypeE.SAVE, data: data, uiElement: UiElementTypeE.ALERT })
        )
      )
    )
  );

  getProjectFinancesProgress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectReportingAction.GET_PROJECT_FINANCES_IMPORT_PROGRESS),
      debounceTime(this.config.apiDebounceTimeMs),
      switchMap(() =>
        this.projectReportingService.projectReportingGetProjectFinanceImportProgress().pipe(
          map(
            (progressMessages: any) =>
              new ProjectReportingAction.GetProjectFinancesProgressSuccessAction(progressMessages)
          ),
          catchError((e: HttpErrorResponse) => {
            if (e.status === 400) {
              return of(new ProjectReportingAction.GetProjectFinancesProgressFailedAction(e.error));
            } else {
              return of(
                new ProjectReportingAction.GetProjectFinancesProgressFailedAction(
                  this.translate.instant('PROJECT_REPORTING.ERROR.GET_PROGRESS')
                )
              );
            }
          })
        )
      )
    )
  );

  getProjectFinancesProgressFailed$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectReportingAction.GET_PROJECT_FINANCES_IMPORT_PROGRESS_FAILED),
      debounceTime(this.config.apiDebounceTimeMs),
      map(
        (action: ProjectReportingAction.GetProjectFinancesProgressFailedAction) => action.payload
      ),
      switchMap((data: string) =>
        of(
          new ErrorAddAction({ type: ErrorTypeE.LOAD, data: data, uiElement: UiElementTypeE.ALERT })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private projectReportingService: ProjectReportingService,
    private config: ConfigService,
    private translate: TranslateService
  ) {
    // empty
  }
}
