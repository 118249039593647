/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalizedValueViewModel } from './localizedValueViewModel';


export interface PostPutInverterRequest { 
    Id: string;
    IsActive?: boolean;
    ArticleNumber?: string;
    Description?: string;
    MinRange?: number;
    MaxRange?: number;
    NominalPower?: number;
    LocalizedDataSheets: Array<LocalizedValueViewModel>;
    Manufacturer?: string;
    InverterType?: PostPutInverterRequest.InverterTypeEnum;
    InverterSmartEnergy?: PostPutInverterRequest.InverterSmartEnergyEnum;
    InverterSurgeProtection?: PostPutInverterRequest.InverterSurgeProtectionEnum;
    EturnityComponentId?: string;
    LocalizedTitles?: Array<LocalizedValueViewModel>;
    /**
     * This inverter is only available for the mandators in this list
     */
    MandatorIds?: Array<number>;
    ArViewerUrl?: string;
}
export namespace PostPutInverterRequest {
    export type InverterTypeEnum = 'Standard' | 'Hybrid' | 'AllInOne';
    export const InverterTypeEnum = {
        Standard: 'Standard' as InverterTypeEnum,
        Hybrid: 'Hybrid' as InverterTypeEnum,
        AllInOne: 'AllInOne' as InverterTypeEnum
    };
    export type InverterSmartEnergyEnum = 'NotAvailable' | 'HelionSolution';
    export const InverterSmartEnergyEnum = {
        NotAvailable: 'NotAvailable' as InverterSmartEnergyEnum,
        HelionSolution: 'HelionSolution' as InverterSmartEnergyEnum
    };
    export type InverterSurgeProtectionEnum = 'NotAvailable' | 'AC' | 'DC' | 'ACDC';
    export const InverterSurgeProtectionEnum = {
        NotAvailable: 'NotAvailable' as InverterSurgeProtectionEnum,
        AC: 'AC' as InverterSurgeProtectionEnum,
        DC: 'DC' as InverterSurgeProtectionEnum,
        ACDC: 'ACDC' as InverterSurgeProtectionEnum
    };
}
