import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  LocalizedValueViewModel,
  MandatorViewModel,
  StringGeneration,
  StringGenerationContainer,
} from '../../../../shared/apis/advis';
import { DialogService } from '../../../../shared/services/dialog.service';
import { HtMasterdataBaseFormComponent } from '../../ht-masterdata-base-form/ht-masterdata-base-form.component';
import { Store } from '@ngrx/store';
import { IRootState } from '../../../../root.state';
import { StringGenerationComponent } from '../../../../shared/components/string-generation-dialog/string-generation-dialog.component';
import LanguageEnum = LocalizedValueViewModel.LanguageEnum;
import { isNullOrUndefined } from '../../../../shared/utils/isNullOrUndefined';
import { Subject } from 'rxjs';
import { StringGenerationMapperService } from '../../../../shared/components/string-generation-dialog/string-generation-mapper.service';

@Component({
  selector: 'ac-ht-masterdata-string-generation-controls',
  templateUrl: './ht-masterdata-string-generation-controls.component.html',
  styleUrls: ['./ht-masterdata-string-generation-controls.component.sass'],
})
export class HtMasterdataStringGenerationControlsComponent
  extends HtMasterdataBaseFormComponent
  implements OnInit
{
  @Input()
  dialogData: StringGenerationContainer = {} as StringGenerationContainer;

  @Input()
  title: string = '';

  @Input()
  required: boolean = true;

  @Input()
  mandators: MandatorViewModel[] = [];

  @Input()
  preSelectedMandatorIds: number[] = [];

  @Input()
  onSanitizeXssForSaveCallback: Subject<boolean>;

  @Input()
  displayGeneratedString: boolean = false;

  @Output()
  dialogClosedEvent = new EventEmitter<StringGeneration>();

  languages: string[] = Object.keys(LanguageEnum);

  ngOnInit() {
    super.ngOnInit();
    this.subscriptions.add(
      this.onSanitizeXssForSaveCallback.subscribe(() => this.sanitizeContentForXssProtection())
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  constructor(
    store: Store<IRootState>,
    private dialogService: DialogService,
    private stringGenerationMapperService: StringGenerationMapperService
  ) {
    super(store);
  }

  sanitizeContentForXssProtection(): void {
    this.dialogData = this.stringGenerationMapperService.unsetRawData(this.dialogData);
  }

  getRawDataForLanguage(language: string): string {
    if (this.dialogData == null || isNullOrUndefined(language)) {
      return '';
    }

    switch (language) {
      case LanguageEnum.EN:
        return this.dialogData.English?.RawData ?? '';
      case LanguageEnum.DE:
        return this.dialogData.German?.RawData ?? '';
      case LanguageEnum.FR:
        return this.dialogData.French?.RawData ?? '';
      case LanguageEnum.IT:
        return this.dialogData.Italian?.RawData ?? '';
      default:
        return '';
    }
  }

  openStringGenerationDialog(language: string, mandators: MandatorViewModel[]) {
    this.subscriptions.add(
      this.dialogService
        .open(StringGenerationComponent, {
          language: language,
          mandators: mandators,
          dialogData: this.dialogData,
          preSelectedMandatorIds: this.preSelectedMandatorIds,
        })
        .afterClosed()
        .subscribe((data: StringGeneration) => {
          this.dialogClosedEvent.emit(data);
        })
    );
  }
}
