/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalizedValueViewModel } from './localizedValueViewModel';


export interface ModuleViewModel { 
    Id?: string;
    IsActive?: boolean;
    ArticleNumber?: string;
    Description?: string;
    Color?: string;
    Width?: number;
    Length?: number;
    Height?: number;
    WattPeak?: number;
    EturnityComponentId?: string;
    Order?: number;
    MountingType?: ModuleViewModel.MountingTypeEnum;
    PriceCategory?: ModuleViewModel.PriceCategoryEnum;
    LocalizedTitles?: Array<LocalizedValueViewModel>;
    LocalizedDataSheets?: Array<LocalizedValueViewModel>;
    /**
     * How many times it is used in object data of variants
     */
    ObjectDataUsages?: number;
    /**
     * How many times it is used in productline templates
     */
    ObjectDataTemplateUsages?: number;
    /**
     * How many times it is used in position builder rules
     */
    PositionBuilderUsages?: number;
    /**
     * This module is only available for the mandators in this list  Mandators for which the user is not responsible are ignored
     */
    MandatorIds?: Array<number>;
    ArViewerUrl?: string;
}
export namespace ModuleViewModel {
    export type MountingTypeEnum = 'InRoof' | 'Standard';
    export const MountingTypeEnum = {
        InRoof: 'InRoof' as MountingTypeEnum,
        Standard: 'Standard' as MountingTypeEnum
    };
    export type PriceCategoryEnum = 'LowCost' | 'Standard' | 'HighPerformance' | 'Premium';
    export const PriceCategoryEnum = {
        LowCost: 'LowCost' as PriceCategoryEnum,
        Standard: 'Standard' as PriceCategoryEnum,
        HighPerformance: 'HighPerformance' as PriceCategoryEnum,
        Premium: 'Premium' as PriceCategoryEnum
    };
}
