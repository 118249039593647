import * as MandatorAction from './mandator.action';
import { initMandatorState, IMandatorState } from './mandator.state';
import {
  ActivateMandatorAction,
  ActivateMandatorFailedAction,
  ActivateMandatorSuccessAction,
  DeactivateMandatorAction,
  DeactivateMandatorFailedAction,
  DeactivateMandatorSuccessAction,
  EditMandatorAction,
  EditMandatorFailedAction,
  EditMandatorSuccessAction,
  GetMandatorAction,
  GetMandatorFailedAction,
  GetMandatorsAction,
  GetMandatorsFailedAction,
  GetMandatorsSuccessAction,
  GetMandatorSuccessAction,
} from './mandator.action';
import { tassign } from 'tassign';
import cloneDeep from 'lodash/cloneDeep';
import { MandatorViewModel } from '../../shared/apis/advis';

export function reducer(
  state: IMandatorState = initMandatorState(),
  action: MandatorAction.Actions
): IMandatorState {
  if (action instanceof GetMandatorsAction) {
    return tassign(state, {
      mandatorsLoading: true,
      mandatorsLoaded: false,
    } as IMandatorState);
  }

  if (action instanceof GetMandatorsSuccessAction) {
    return tassign(state, {
      mandators: action.payload,
      mandatorsLoading: false,
      mandatorsLoaded: true,
    } as IMandatorState);
  }

  if (action instanceof GetMandatorsFailedAction) {
    return tassign(state, {
      mandators: [],
      mandatorsLoading: false,
      mandatorsLoaded: false,
    } as IMandatorState);
  }

  if (action instanceof GetMandatorAction) {
    return tassign(state, {
      mandatorsLoading: true,
      mandatorsLoaded: false,
    } as IMandatorState);
  }

  if (action instanceof GetMandatorSuccessAction) {
    return tassign(state, {
      mandatorsLoading: false,
      mandatorsLoaded: true,
    } as IMandatorState);
  }

  if (action instanceof GetMandatorFailedAction) {
    return tassign(state, {
      mandatorsLoading: false,
      mandatorsLoaded: false,
    } as IMandatorState);
  }

  if (action instanceof EditMandatorAction) {
    return tassign(state, {
      mandatorsLoading: true,
      mandatorsLoaded: false,
    } as IMandatorState);
  }

  if (action instanceof EditMandatorSuccessAction) {
    return tassign(state, {
      mandatorsLoading: false,
      mandatorsLoaded: true,
      mandators: action.payload,
    } as IMandatorState);
  }

  if (action instanceof EditMandatorFailedAction) {
    return tassign(state, {
      mandatorsLoading: false,
      mandatorsLoaded: false,
    } as IMandatorState);
  }

  if (action instanceof ActivateMandatorAction) {
    return tassign(state, {
      mandatorsLoading: true,
    } as IMandatorState);
  }

  if (action instanceof ActivateMandatorSuccessAction) {
    return tassign(state, {
      mandatorsLoading: false,
      mandatorsLoaded: true,
      mandators: updateAllMandators(action.payload),
    } as IMandatorState);
  }

  if (action instanceof ActivateMandatorFailedAction) {
    return tassign(state, {
      mandatorsLoading: false,
      mandatorsLoaded: false,
    } as IMandatorState);
  }

  if (action instanceof DeactivateMandatorAction) {
    return tassign(state, {
      mandatorsLoading: true,
    } as IMandatorState);
  }

  if (action instanceof DeactivateMandatorSuccessAction) {
    return tassign(state, {
      mandatorsLoading: false,
      mandatorsLoaded: true,
      mandators: updateAllMandators(action.payload),
    } as IMandatorState);
  }

  if (action instanceof DeactivateMandatorFailedAction) {
    return tassign(state, {
      mandatorsLoading: false,
      mandatorsLoaded: false,
    } as IMandatorState);
  }

  function updateAllMandators(mandator: MandatorViewModel): MandatorViewModel[] {
    return cloneDeep(state.mandators).map((allMandator: MandatorViewModel) =>
      allMandator.Id !== mandator.Id ? allMandator : mandator
    );
  }

  return state;
}
