<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    <ng-container *ngIf="permissionService.userPermissions.CanManageUsers">
      <a routerLink="/user/user/overview">{{ 'MAIN.USERS' | translate }}</a>
      <ac-vertical-divider></ac-vertical-divider>
    </ng-container>
    <a class="active-view-button">{{ 'MAIN.ROLES' | translate }}</a>
  </div>
  <div>
    <button color="primary" mat-button (click)="navigateToRoleAdd()">
      <mat-icon>add</mat-icon>
      {{ 'USERS.ADD' | translate }}
    </button>
  </div>
</mat-toolbar>
<mat-sidenav-container class="filter-sidenav-container">
  <mat-sidenav-content>
    <mat-card class="mat-list-card">
      <mat-card-content>
        <div class="overview-table-with-filter mat-elevation-z8">
          <div class="common-filter-padding">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'USERS.FILTER.FILTER' | translate }}</mat-label>
              <input
                matInput
                (keyup)="filterTable($event)"
                [value]="this.pageSettings.overviewFilter.tableGlobalFilter"
                placeholder="{{ 'USERS.FILTER.FILTER' | translate }}" />
            </mat-form-field>
          </div>
          <div>
            <mat-table
              [dataSource]="dataSource"
              matSort
              [matSortActive]="pageSettings.sortSetting.active"
              [matSortDirection]="pageSettings.sortSetting.direction"
              matSortDisableClear>
              <ng-container [matColumnDef]="displayedColumns[0]">
                <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header>
                  {{ 'MANDATORS.NAME' | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="15" class="table-cell-bold-text-pointer" *matCellDef="let row"
                  ><span (click)="navigateToEditAdd(row.Id)"> {{ row.Name }} </span></mat-cell
                >
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[1]">
                <mat-header-cell fxFlex="50" *matHeaderCellDef mat-sort-header>
                  {{ 'ROLE.PERMISSIONS' | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="50" *matCellDef="let row">
                  {{ getPermissions(row.Permissions) }}
                </mat-cell>
              </ng-container>

              <ng-container fxFlex="20" [matColumnDef]="displayedColumns[2]">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'COMMON.MANDATORS' | translate }}
                </mat-header-cell>
                <mat-cell
                  fxFlex="20"
                  *matCellDef="let row"
                  [innerHtml]="getMandatorNamesForId(row.MandatorIds, mandators)">
                </mat-cell>
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[3]">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'ROLE.USAGES_COUNT' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.UsagesCount }} </mat-cell>
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[4]">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'ROLE.IS_ENABLED_FOR_NOTES_TARGET_GROUP' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.IsEnabledForNotesTargetGroup }} </mat-cell>
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[5]">
                <mat-header-cell fxFlex="2" *matHeaderCellDef></mat-header-cell>
                <mat-cell fxFlex="2" *matCellDef="let row">
                  <a
                    class="table-actions cursor-pointer"
                    fxLayoutAlign="end center"
                    [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </a>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="navigateToEditAdd(row.Id)">
                      <span>{{ 'USERS.EDIT' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="deleteRole(row.Id)">
                      <span>{{ 'COMMON.DELETE' | translate }}</span>
                    </button>
                  </mat-menu>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>

            <mat-paginator
              [pageSize]="pageSettings.pagingSetting.pageSize"
              [pageIndex]="pageSettings.pagingSetting.pageIndex"
              [pageSizeOptions]="[5, 10, 25, 100]"
              (page)="changePaging($event)"></mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
