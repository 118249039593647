/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MandatorTradeTypeViewModel } from './mandatorTradeTypeViewModel';
import { TaskSettingViewModel } from './taskSettingViewModel';


export interface MandatorViewModel { 
    Id?: number;
    Name?: string;
    ShortName?: string;
    GlobalAddressBookId?: number;
    ExecutingMandatorId?: number;
    /**
     * A comma separated list of Configured Origins that are displayed in the PC
     */
    ConfiguredOrigins?: string;
    /**
     * A comma separated list of Configured Tracers that are displayed in the PC
     */
    ConfiguredTracers?: string;
    /**
     * All available trade types for the Mandator
     */
    TradeTypes?: Array<MandatorTradeTypeViewModel>;
    ProductSupportEmail?: string;
    TechnicalSupportEmail?: string;
    UseExtendedSupportDialog?: boolean;
    StringGenerationBaseUrl?: string;
    TaskSettings?: Array<TaskSettingViewModel>;
    IsActive?: boolean;
}
