import { IViewSettingBase } from './view-setting-base.model';

export class OrganisationUnitOverviewVsModel {
  public organisationUnitFilter: OrganisationUnitOverviewFilterVsModel =
    new OrganisationUnitOverviewFilterVsModel();
  public organisationUnitFilterVisible: boolean = false;
  public pageSize: number = 10;

  clone(): OrganisationUnitOverviewVsModel {
    const model: OrganisationUnitOverviewVsModel = new OrganisationUnitOverviewVsModel();
    model.organisationUnitFilter = this.organisationUnitFilter.clone();
    model.organisationUnitFilterVisible = this.organisationUnitFilterVisible;
    model.pageSize = this.pageSize;

    return model;
  }
}

export class OrganisationUnitOverviewFilterVsModel
  implements IViewSettingBase<OrganisationUnitOverviewFilterVsModel>
{
  public id: number = undefined;
  public name: string = '';
  public shortName: string = '';
  public email: string = '';
  public phoneNumber: string = '';
  public url: string = '';
  public street: string = '';
  public zipCode: string = '';
  public city: string = '';

  clone(): OrganisationUnitOverviewFilterVsModel {
    const filter: OrganisationUnitOverviewFilterVsModel =
      new OrganisationUnitOverviewFilterVsModel();
    filter.id = this.id;
    filter.name = this.name;
    filter.shortName = this.shortName;
    filter.email = this.email;
    filter.phoneNumber = this.phoneNumber;
    filter.url = this.url;
    filter.street = this.street;
    filter.zipCode = this.zipCode;
    filter.city = this.city;

    return filter;
  }
}
