import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { IRootState } from '../../root.state';
import { AccountService, UserInfo } from '../apis/advis';
import { PermissionService } from '../services/permission.service';
import { SpinnerService } from '../services/spinner.service';
import {
  GetGlobalViewSettingsAction,
  getMasterDataLoaded,
  getOrganisationUnitLoaded,
  MasterDataLoadAction,
  OrganisationUnitsLoadAction,
} from '../store';

@Injectable()
export class AppInitializerGuard {
  constructor(
    private store: Store<IRootState>,
    private accountApi: AccountService,
    private permissionService: PermissionService,
    private spinner: SpinnerService
  ) {
    // empty
  }

  canActivate(): Observable<boolean> {
    this.spinner.regisgter();
    const userInfo$: Observable<boolean> = this.accountApi.accountGetUserInfo().pipe(
      tap(
        (userInfo: UserInfo) => (this.permissionService.userPermissions = userInfo.UserPermissions)
      ),
      map(() => true),
      catchError(() => of(false))
    );

    this.store.dispatch(new MasterDataLoadAction());
    const masterDataLoaded$: Observable<boolean> = this.store.select(getMasterDataLoaded).pipe(
      filter((loaded: boolean) => loaded),
      tap(() => this.store.dispatch(new GetGlobalViewSettingsAction())),
      take(1)
    );

    this.store.dispatch(new OrganisationUnitsLoadAction());
    const organisationUnitsLoaded$: Observable<boolean> = this.store
      .select(getOrganisationUnitLoaded)
      .pipe(
        filter((loaded: boolean) => loaded),
        take(1)
      );

    return combineLatest(organisationUnitsLoaded$, masterDataLoaded$, userInfo$).pipe(
      map(([]: boolean[]) => true),
      take(1),
      tap(() => {
        console.info('**** Application initialized ****');
        this.spinner.unregister();
        // this.versionCheckService.initVersionCheck(environment.checkVersionUrl);
      })
    );
  }
}
