import { IRootState } from '../../root.state';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { IProjectReportingState } from './project-reporting.state';
import { ImportProgressMessage } from '../../shared/apis/advis';

export const getProjectReportingState: (state: IRootState) => IProjectReportingState = (
  state: IRootState
) => state.projectReporting;

export const getImporting: MemoizedSelector<IRootState, boolean> = createSelector(
  getProjectReportingState,
  (state: IProjectReportingState) => state.importing
);

export const getImported: MemoizedSelector<IRootState, boolean> = createSelector(
  getProjectReportingState,
  (state: IProjectReportingState) => state.imported
);

export const getProjectReportingProgressMessages: MemoizedSelector<
  IRootState,
  ImportProgressMessage[]
> = createSelector(
  getProjectReportingState,
  (state: IProjectReportingState) => state.progressMessages
);
