import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, exhaustMap, map, tap } from 'rxjs/operators';
import { IPrincipal } from '../../shared/interfaces/principal.interface';
import { Authenticate } from '../../shared/models/principal.model';
import { AuthService } from '../../shared/services/auth.service';
import { ConfigService } from '../../shared/services/config.service';
import { ViewSettingService } from '../../shared/services/view-setting.service';

import * as Auth from './auth.action';

@Injectable()
export class AuthEffects {
  login$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(Auth.LOGIN),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: Auth.LoginAction) => action.payload),
      exhaustMap((auth: Authenticate) =>
        this.authService.login(auth).pipe(
          map((principal: IPrincipal) => new Auth.LoginSuccessAction(principal)),
          catchError((error: any) => of(new Auth.LoginFailureAction(error)))
        )
      )
    )
  );

  loginSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Auth.LOGIN_SUCCESS),
        debounceTime(this.config.apiDebounceTimeMs),
        tap((action: Auth.LoginSuccessAction) => {
          this.viewSettingService.setPrincipal(action.payload); // That's a little bit weired
        }),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  loginSuccessSession$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Auth.LOGIN_SUCCESS_SESSION),
        debounceTime(this.config.apiDebounceTimeMs),
        tap((action: Auth.LoginSuccessAction) => {
          this.viewSettingService.setPrincipal(action.payload); // That's a little bit weired
        })
      ),
    { dispatch: false }
  );

  loginRedirect$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Auth.LOGIN_REDIRECT),
        debounceTime(this.config.apiDebounceTimeMs),
        tap(() => {
          this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  logout$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Auth.LOGOUT, Auth.LOGOUT_SESSION),
        debounceTime(this.config.apiDebounceTimeMs),
        exhaustMap(() => {
          return this.authService.logout();
        }),
        tap((authed: any) => {
          console.log(authed);
          this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private viewSettingService: ViewSettingService,
    private router: Router,
    private config: ConfigService
  ) {}
}
