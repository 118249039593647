<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    <a class="active-view-button">{{ 'MAIN.USERS' | translate }}</a>
    <ng-container *ngIf="permissionService.userPermissions.CanManageRoles">
      <ac-vertical-divider></ac-vertical-divider>
      <a routerLink="/user/role/overview">{{ 'MAIN.ROLES' | translate }}</a>
    </ng-container>
    <mat-icon
      matBadge="0"
      matBadgeSize="small"
      matBadgePosition="above after"
      matBadgeColor="warn"
      matBadgeHidden="{{ !isFilterActive() }}"
      class="filter-toggle-icon"
      (click)="toggleFilter()"
      [class.turned]="isFilterIconTurned"
      >filter_list
    </mat-icon>
  </div>
  <div>
    <button color="primary" mat-button (click)="navigateToUserAdd()">
      <mat-icon>add</mat-icon>
      {{ 'USERS.ADD' | translate }}
    </button>
  </div>
</mat-toolbar>
<mat-sidenav-container class="filter-sidenav-container">
  <mat-sidenav
    mode="side"
    class="main-side-nav"
    [fixedInViewport]="true"
    #overviewFilter
    [fixedTopGap]="130">
    <ac-overview-filter
      [displayedFilters]="displayedFilters"
      [pageSettings]="pageSettings$"
      (filter)="filtering()"></ac-overview-filter>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-card class="mat-list-card">
      <mat-card-content>
        <div class="overview-table-with-filter mat-elevation-z8">
          <div class="common-filter-padding">
            <mat-form-field appearance="outline">
              <!--todo ac-setup reset on action-->
              <mat-label>{{ 'USERS.FILTER.FILTER' | translate }}</mat-label>
              <input
                matInput
                (keyup)="filterTable($event)"
                [value]="this.pageSettings.overviewFilter.tableGlobalFilter"
                placeholder="{{ 'USERS.FILTER.FILTER' | translate }}" />
            </mat-form-field>
          </div>
          <div>
            <mat-table
              [dataSource]="dataSource"
              matSort
              [matSortActive]="pageSettings.sortSetting.active"
              [matSortDirection]="pageSettings.sortSetting.direction"
              matSortDisableClear
              (matSortChange)="changeSort($event)">
              <ng-container [matColumnDef]="displayedColumns[0]">
                <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header>
                  {{ 'USERS.USERNAME' | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="15" class="table-cell-bold-text-pointer" *matCellDef="let row"
                  ><span (click)="navigateToEditAdd(row.Id)"> {{ row.UserName }} </span></mat-cell
                >
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[1]">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'USERS.FIRSTNAME' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.FirstName }} </mat-cell>
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[2]">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'USERS.LASTNAME' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.LastName }} </mat-cell>
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[3]">
                <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header>
                  {{ 'USERS.EMAIL' | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="15" *matCellDef="let row"> {{ row.Email }} </mat-cell>
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[4]">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Mandator </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  [ngClass]="{ 'deactivated-color': !getMandatorActiveForId(row.MandatorId) }">
                  {{ getMandatorNameForId(row.MandatorId) }}
                </mat-cell>
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[5]">
                <mat-header-cell fxFlex="20" *matHeaderCellDef mat-sort-header>
                  Mandators in responsibility
                </mat-header-cell>
                <mat-cell
                  fxFlex="20"
                  class="small-font-table-cell"
                  *matCellDef="let row"
                  [innerHtml]="getMandatorNamesForId(row.MandatorsInResponsibility, mandators)">
                </mat-cell>
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[6]">
                <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header>
                  {{ 'USERS.ORG_UNIT' | translate }}
                </mat-header-cell>
                <mat-cell
                  fxFlex="15"
                  class="small-font-table-cell"
                  *matCellDef="let row"
                  [innerHTML]="getOrgUnitNameForId(row.OrganisationUnitId)">
                </mat-cell>
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[7]">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'USERS.ROLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.Roles | rolesMap }} </mat-cell>
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[8]">
                <mat-header-cell fxFlex="2" *matHeaderCellDef mat-sort-header>
                  {{ 'COMMON.IS_ACTIVE' | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="2" *matCellDef="let row"> {{ row.IsActive }} </mat-cell>
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[9]">
                <mat-header-cell fxFlex="2" *matHeaderCellDef></mat-header-cell>
                <mat-cell fxFlex="2" *matCellDef="let row">
                  <a
                    class="table-actions cursor-pointer"
                    fxLayoutAlign="end center"
                    [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </a>
                  <mat-menu #menu="matMenu">
                    <button *ngIf="row.IsActive" mat-menu-item (click)="deactivateUser(row.Id)">
                      <span>{{ 'COMMON.DEACTIVATE' | translate }}</span>
                    </button>
                    <button *ngIf="!row.IsActive" mat-menu-item (click)="activateUser(row.Id)">
                      <span>{{ 'COMMON.ACTIVATE' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="navigateToEditAdd(row.Id)">
                      <span>{{ 'USERS.EDIT' | translate }}</span>
                    </button>
                  </mat-menu>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
            </mat-table>

            <mat-paginator
              [pageSize]="pageSettings.pagingSetting.pageSize"
              [pageIndex]="pageSettings.pagingSetting.pageIndex"
              [pageSizeOptions]="[5, 10, 25, 100]"
              (page)="changePaging($event)"></mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
