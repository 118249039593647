import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { IOverviewFilter, IPageSettings } from '../../interfaces/Settings';
import { SetPageSettingsAction } from '../../store';
import { Store } from '@ngrx/store';
import { IRootState } from '../../../root.state';
import { Observable, Subscription } from 'rxjs';
import { IFilter, IOverviewFilterDefine, OverviewControl, OverviewFilterViewModel } from './Type';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'ac-overview-filter',
  templateUrl: './overview-filter.component.html',
  styleUrls: ['./overview-filter.component.scss'],
})
export class OverviewFilterComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  readonly statusOverviewControl: typeof OverviewControl = OverviewControl;

  @Input() displayedFilters: OverviewFilterViewModel[];
  @Input('pageSettings') pageSettings$: Observable<IPageSettings>;
  @Output() filter: EventEmitter<IFilter[]> = new EventEmitter<IFilter[]>();

  pageSettings: IPageSettings;

  constructor(private store: Store<IRootState>, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.pageSettings$.subscribe((pageSettings: IPageSettings) => {
        this.pageSettings = cloneDeep(pageSettings);
        this.updateViewModel();
      })
    );
  }

  selectAll(select: MatSelect, multiply: boolean): void {
    if (multiply) {
      select.ngControl.control.setValue([undefined]);
    }
  }

  filterGlobal(): void {
    if (!this.pageSettings.overviewFilter) {
      this.pageSettings.overviewFilter = {
        filterVisible: false,
        activeFilter: [],
        tableGlobalFilter: '',
      };
    }
    this.pageSettings.overviewFilter.activeFilter = [];

    this.displayedFilters.forEach((filter: OverviewFilterViewModel) => {
      if (isNotNullOrUndefined(filter.value)) {
        if (
          filter.control !== this.statusOverviewControl.STRING ||
          (filter.value as string).length > 0
        ) {
          if (filter.control === this.statusOverviewControl.BOOLEAN && !filter.value) {
            return;
          }
          this.pageSettings.overviewFilter.activeFilter.push({
            key: filter.key,
            value: filter.value,
            isServerSideFiltering: filter.isServerSide,
          });
        }
      }
    });
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
    const filterEmit: IFilter[] = this.pageSettings.overviewFilter.activeFilter.map(
      (activeFilter: IOverviewFilter) => ({
        column: activeFilter.key,
        value: activeFilter.value,
        isServerSide: this.isServerSideFiltering(activeFilter.key),
      })
    );
    this.filter.emit(filterEmit);
  }

  isServerSideFiltering(key: string): boolean {
    return (
      this.displayedFilters.filter(
        (filterdefine: IOverviewFilterDefine) =>
          filterdefine.key === key && filterdefine.isServerSide
      ).length > 0
    );
  }

  resetFilter(): void {
    if (this.pageSettings.overviewFilter.activeFilter) {
      this.pageSettings.overviewFilter.activeFilter = [];
      this.updateViewModel();
      this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
    }
    this.filter.emit([]);
    this.displayedFilters.forEach(this.changeDisplayedFilter.bind(this));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public changeDisplayedFilter(filter: OverviewFilterViewModel) {
    if (filter.changeFilter) {
      this.displayedFilters = filter.changeFilter(this.displayedFilters);
      this.cdr.markForCheck();
    }
  }

  private updateViewModel(): void {
    if (this.pageSettings.overviewFilter) {
      this.displayedFilters.forEach((displayedFilter: OverviewFilterViewModel) => {
        const index: number = this.pageSettings.overviewFilter.activeFilter.findIndex(
          (filter: IOverviewFilter) => filter.key === displayedFilter.key
        );
        if (index !== -1) {
          displayedFilter.value = this.pageSettings.overviewFilter.activeFilter[index].value;
        } else {
          displayedFilter.value = undefined;
        }
      });
    }
  }
}
