/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalizedValueViewModel } from './localizedValueViewModel';


export interface StorageViewModel { 
    Id?: string;
    IsActive?: boolean;
    ArticleNumber?: string;
    Description?: string;
    Capacity?: number;
    PriceIndication?: number;
    StorageType?: StorageViewModel.StorageTypeEnum;
    StorageChemistry?: StorageViewModel.StorageChemistryEnum;
    LocalizedTitles?: Array<LocalizedValueViewModel>;
    /**
     * How many times it is used in object data of variants
     */
    ObjectDataUsages?: number;
    /**
     * How many times it is used in productline templates
     */
    ObjectDataTemplateUsages?: number;
    /**
     * How many times it is used in position builder rules
     */
    PositionBuilderUsages?: number;
    /**
     * This storage is only available for the mandators in this list  Mandators for which the user is not responsible are ignored
     */
    MandatorIds?: Array<number>;
    CompatibleInverterTypes?: Array<string>;
    CompatibleInverterManufacturers?: Array<string>;
    EturnityComponentId?: string;
    ArViewerUrl?: string;
}
export namespace StorageViewModel {
    export type StorageTypeEnum = 'AcSystem' | 'DcSystem' | 'AllInOne' | 'LowVoltage';
    export const StorageTypeEnum = {
        AcSystem: 'AcSystem' as StorageTypeEnum,
        DcSystem: 'DcSystem' as StorageTypeEnum,
        AllInOne: 'AllInOne' as StorageTypeEnum,
        LowVoltage: 'LowVoltage' as StorageTypeEnum
    };
    export type StorageChemistryEnum = 'Lithium' | 'Salt';
    export const StorageChemistryEnum = {
        Lithium: 'Lithium' as StorageChemistryEnum,
        Salt: 'Salt' as StorageChemistryEnum
    };
}
