<mat-nav-list fxLayout="column" fxLayoutAlign="stretch" (keyup.enter)="filterGlobal()">
  <div *ngFor="let filter of displayedFilters">
    <mat-form-field appearance="outline" *ngIf="filter.control === statusOverviewControl.STRING">
      <mat-label>{{ filter.translateKey | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ filter.translateKey | translate }}"
        [(ngModel)]="filter.value" />
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="filter.control === statusOverviewControl.NUMBER">
      <mat-label>{{ filter.translateKey | translate }}</mat-label>
      <input
        matInput
        type="number"
        placeholder="{{ filter.translateKey | translate }}"
        [(ngModel)]="filter.value" />
    </mat-form-field>

    <mat-checkbox
      *ngIf="filter.control === statusOverviewControl.BOOLEAN"
      [(ngModel)]="filter.value"
      >{{ filter.translateKey | translate }}</mat-checkbox
    >

    <mat-form-field appearance="outline" *ngIf="filter.control === statusOverviewControl.SELECT">
      <mat-label>{{ filter.translateKey | translate }}</mat-label>
      <mat-select
        [(ngModel)]="filter.value"
        (ngModelChange)="changeDisplayedFilter(filter)"
        #matSelect
        placeholder="{{ filter.translateKey | translate }}"
        [multiple]="filter.multiple">
        <mat-option
          #matOption
          [value]="undefined"
          (onSelectionChange)="selectAll(matSelect, filter.multiple)">
          All
        </mat-option>
        <mat-option *ngFor="let option of filter.options" [value]="option.id">
          {{ option.key }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-nav-list>
<div fxLayout="row" fxLayoutAlign="end">
  <button mat-button color="accent" (click)="resetFilter()">
    {{ 'USERS.FILTER.RESET' | translate }}
  </button>
  <button mat-button color="primary" (click)="filterGlobal()">
    {{ 'USERS.FILTER.FILTER' | translate }}
  </button>
</div>
