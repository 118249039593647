/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EmobilityMasterDataViewModel } from './emobilityMasterDataViewModel';
import { GenericPropertiesViewModel } from './genericPropertiesViewModel';
import { GenericRulePropertiesViewModel } from './genericRulePropertiesViewModel';
import { HeatingMasterDataViewModel } from './heatingMasterDataViewModel';
import { MandatorViewModel } from './mandatorViewModel';
import { PvMasterDataViewModel } from './pvMasterDataViewModel';
import { SharedAddressBookMasterDataViewModel } from './sharedAddressBookMasterDataViewModel';
import { TradeTypeViewModel } from './tradeTypeViewModel';


export interface MasterDataViewModel { 
    /**
     * All available mandators
     */
    Mandators?: Array<MandatorViewModel>;
    /**
     * All available trade types
     */
    TradeTypes?: Array<TradeTypeViewModel>;
    /**
     * A map from trade type to generic productline properties
     */
    ProductlineProperties?: Array<GenericPropertiesViewModel>;
    /**
     * A map from trade type to generic objectdata templates properties
     */
    ObjectdataTemplateProperties?: Array<GenericPropertiesViewModel>;
    /**
     * A map from trade type to generic position builder (rule) properties, the properties on the rule
     */
    PositionBuilderProperties?: Array<GenericPropertiesViewModel>;
    /**
     * A map from trade type to rule properties, the ones that can be used in rules
     */
    RuleProperties?: Array<GenericRulePropertiesViewModel>;
    HeatingMasterData?: HeatingMasterDataViewModel;
    EmobilityMasterData?: EmobilityMasterDataViewModel;
    PvMasterData?: PvMasterDataViewModel;
    SharedAddressBooks?: Array<SharedAddressBookMasterDataViewModel>;
    /**
     * All available complexity types
     */
    ComplexityTypes?: Array<string>;
}
