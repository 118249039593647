/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ProductlineFeeViewModel { 
    /**
     * Identification of the fee, make sure that new fees have Id 0
     */
    Id?: number;
    /**
     * Percentage applied on the basic positions
     */
    BasicPositionsPercentage?: number;
    /**
     * Percentage applied on the options
     */
    OptionsPercentage?: number;
}
