import { IViewSettingBase } from './view-setting-base.model';

export class RoleOverviewVsModel {
  public roleFilter: RoleOverviewFilterVsModel = new RoleOverviewFilterVsModel();
  public roleFilterVisible: boolean = false;
  public pageSize: number = 10;

  clone(): RoleOverviewVsModel {
    const model: RoleOverviewVsModel = new RoleOverviewVsModel();
    model.roleFilter = this.roleFilter.clone();
    model.roleFilterVisible = this.roleFilterVisible;
    model.pageSize = this.pageSize;

    return model;
  }
}

export class RoleOverviewFilterVsModel implements IViewSettingBase<RoleOverviewFilterVsModel> {
  public name: string = '';
  public usagesCount: string = '';
  public permissions: string[] = [];

  clone(): RoleOverviewFilterVsModel {
    const filter: RoleOverviewFilterVsModel = new RoleOverviewFilterVsModel();
    filter.name = this.name;
    filter.usagesCount = this.usagesCount;
    filter.permissions = this.permissions;

    return filter;
  }
}
