import { IRootState } from '../../root.state';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { IHtMasterdataState } from './ht-masterdata.state';
import {
  DomesticWaterHeatingListViewModel,
  HeatGeneratorListViewModel,
  WaterTankListViewModel,
} from '../../shared/apis/advis';

export const getHtMasterdataState: (state: IRootState) => IHtMasterdataState = (
  state: IRootState
) => state.htmasterdata;

export const getDomesticWaterHeating: MemoizedSelector<
  IRootState,
  DomesticWaterHeatingListViewModel[]
> = createSelector(getHtMasterdataState, (state: IHtMasterdataState) => state.domesticWaterHeating);

export const getDomesticWaterHeatingLoading: MemoizedSelector<IRootState, boolean> = createSelector(
  getHtMasterdataState,
  (state: IHtMasterdataState) => state.domesticWaterHeatingLoading
);

export const getDomesticWaterHeatingLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getHtMasterdataState,
  (state: IHtMasterdataState) => state.domesticWaterHeatingLoaded
);

export const getHeatGenerators: MemoizedSelector<IRootState, HeatGeneratorListViewModel[]> =
  createSelector(getHtMasterdataState, (state: IHtMasterdataState) => state.heatGenerators);

export const getHeatGeneratorLoading: MemoizedSelector<IRootState, boolean> = createSelector(
  getHtMasterdataState,
  (state: IHtMasterdataState) => state.heatGeneratorLoading
);

export const getHeatGeneratorLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getHtMasterdataState,
  (state: IHtMasterdataState) => state.heatGeneratorLoaded
);

export const getWaterTanks: MemoizedSelector<IRootState, WaterTankListViewModel[]> = createSelector(
  getHtMasterdataState,
  (state: IHtMasterdataState) => state.waterTanks
);

export const getWaterTankLoading: MemoizedSelector<IRootState, boolean> = createSelector(
  getHtMasterdataState,
  (state: IHtMasterdataState) => state.waterTankLoading
);

export const getWaterTankLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getHtMasterdataState,
  (state: IHtMasterdataState) => state.waterTankLoaded
);
