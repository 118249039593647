/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PositionDependencyRuleRequest { 
    /**
     * source article number of this dependency rule, substring search works
     */
    SourceArticleNumber?: string;
    /**
     * target article number of this dependency rule, substring search works
     */
    TargetArticleNumber?: string;
    /**
     * the note of this dependency rule, substring search works
     */
    Note?: string;
    /**
     * only retrieve entries with a matching mandator id (you can provide multiple mandators by separating them with a ',' or ';')
     */
    MandatorIds?: string;
    /**
     * only retrieve entries with a matching productline ids (you can provide multiple productline ids by separating them with a ',' or ';')
     */
    ProductlineIds?: string;
    /**
     * the index of the current page(starts at 1)
     */
    PageIndex?: number;
    /**
     * the amount of entries per page
     */
    PageSize?: number;
    SortField?: PositionDependencyRuleRequest.SortFieldEnum;
    SortOrder?: PositionDependencyRuleRequest.SortOrderEnum;
}
export namespace PositionDependencyRuleRequest {
    export type SortFieldEnum = 'ProductlineId' | 'SourceArticleNumber' | 'TargetArticleNumber' | 'Note';
    export const SortFieldEnum = {
        ProductlineId: 'ProductlineId' as SortFieldEnum,
        SourceArticleNumber: 'SourceArticleNumber' as SortFieldEnum,
        TargetArticleNumber: 'TargetArticleNumber' as SortFieldEnum,
        Note: 'Note' as SortFieldEnum
    };
    export type SortOrderEnum = 'None' | 'Asc' | 'Desc';
    export const SortOrderEnum = {
        None: 'None' as SortOrderEnum,
        Asc: 'Asc' as SortOrderEnum,
        Desc: 'Desc' as SortOrderEnum
    };
}
