import { Component, OnDestroy, OnInit, reflectComponentType, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  ACTIVATE_ORGANISATION_UNITS_FAILED,
  ACTIVATE_ORGANISATION_UNITS_SUCCESS,
  ActivateOrganisationUnitAction,
  DEACTIVATE_ORGANISATION_UNITS_FAILED,
  DEACTIVATE_ORGANISATION_UNITS_SUCCESS,
  DeactivateOrganisationUnitAction,
  GetOrganisationUnitsAction,
} from '../../../state/organisation-unit.action';
import { getOrganisationUnitsFiltered } from '../../../state/organisation-unit.selectors';
import { IRootState } from '../../../../root.state';
import { OrganisationUnitViewModel, TradeTypeViewModel } from '../../../../shared/apis/advis';
import { OverviewBaseComponent } from '../../../../shared/components/overview-filter/overviewBaseComponent';
import { OverviewControl } from '../../../../shared/components/overview-filter/Type';
import { IPageSettings } from '../../../../shared/interfaces/Settings';
import { OrganisationUnitOverviewVsModel } from '../../../../shared/models/view-setting/organisation-unit-vs.model';
import {
  getSelectableMandators,
  IMandatorSelectItem,
  SetPageSettingsAction,
} from '../../../../shared/store';
import { PermissionService } from '../../../../shared/services/permission.service';
import { Actions, ofType } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ac-organisation-unit-overview',
  templateUrl: './organisation-unit-overview.component.html',
  styleUrls: ['./organisation-unit-overview.component.sass'],
})
export class OrganisationUnitOverviewComponent
  extends OverviewBaseComponent
  implements OnInit, OnDestroy
{
  private subscription: Subscription = new Subscription();

  organisationUnitsFiltered: Array<OrganisationUnitViewModel & { MandatorIsActive: boolean }> = [];

  displayedColumns: string[] = [
    'ShortName',
    'Name',
    'Mandator',
    'Email',
    'PhoneNumber',
    'Url',
    'IsActive',
    'More',
  ];

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  organisationUnitViewSettings: OrganisationUnitOverviewVsModel =
    new OrganisationUnitOverviewVsModel();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageSettings: IPageSettings;

  constructor(
    store: Store<IRootState>,
    private router: Router,
    private action$: Actions,
    public permissionService: PermissionService,
    private snackBarService: MatSnackBar
  ) {
    super(store);
  }

  ngOnInit(): void {
    const selectableMandators$: Observable<IMandatorSelectItem[]> =
      this.store.select(getSelectableMandators);
    this.subscription.add(
      selectableMandators$
        .pipe(
          map(mandators =>
            mandators
              .filter(m => m.selected)
              .map(m => m.mandatorVm.Id)
              .join(',')
          )
        )
        .subscribe(mandatorIds => {
          this.refresh(mandatorIds);
        })
    );

    this.overviewFilterInit(
      reflectComponentType(OrganisationUnitOverviewComponent).selector,
      [
        { key: 'Id', translateKey: 'Id', control: OverviewControl.NUMBER, isServerSide: true },
        { key: 'Name', translateKey: 'Name', control: OverviewControl.STRING, isServerSide: true },
        {
          key: 'ShortName',
          translateKey: 'Short name',
          control: OverviewControl.STRING,
          isServerSide: true,
        },
        {
          key: 'Mandator',
          translateKey: 'Mandator',
          control: OverviewControl.STRING,
          isServerSide: true,
        },
        {
          key: 'Email',
          translateKey: 'Email',
          control: OverviewControl.STRING,
          isServerSide: true,
        },
        {
          key: 'PhoneNumber',
          translateKey: 'Phone number',
          control: OverviewControl.STRING,
          isServerSide: true,
          options: [],
        },
        {
          key: 'Url',
          translateKey: 'Url',
          control: OverviewControl.STRING,
          isServerSide: true,
          options: [],
        },
      ],
      this.subscription
    );

    const organisationUnits$ = this.store.select(getOrganisationUnitsFiltered);
    const mandators$ = this.store.select(getSelectableMandators);
    this.subscription.add(
      combineLatest([organisationUnits$, mandators$]).subscribe(
        ([organisationUnits, mandators]) => {
          this.organisationUnitsFiltered = organisationUnits.map(ou => ({
            ...ou,
            Mandator: mandators.find(m => m.mandatorVm.Id === ou.MandatorId)?.mandatorVm?.ShortName,
            MandatorIsActive: mandators.find(m => m.mandatorVm.Id === ou.MandatorId)?.mandatorVm
              ?.IsActive,
          }));

          this.dataSource = new MatTableDataSource(this.organisationUnitsFiltered);
          this.dataSource.paginator = this.paginator;

          this.dataSource.sortingDataAccessor = (item: any, property: any) => {
            switch (property) {
              default:
                return item[property];
            }
          };
          this.dataSource.sort = this.sort;
          this.clientSideFiltering();
        }
      )
    );
  }

  mapTradeTypesToCode(tradeTypes: TradeTypeViewModel[]): string {
    return tradeTypes.map((x: TradeTypeViewModel) => x.Code).join(', ');
  }

  changePaging(e: PageEvent): void {
    this.pageSettings.pagingSetting.pageIndex = e.pageIndex;
    this.pageSettings.pagingSetting.pageSize = e.pageSize;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  changeSort(e: Sort): void {
    this.pageSettings.sortSetting = e;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  refresh(mandatorIds: string): void {
    this.store.dispatch(new GetOrganisationUnitsAction(mandatorIds));
  }

  navigateToOrganisationUnitAdd(): void {
    this.router.navigate(['/mandator/organisation-unit/add']);
  }

  navigateToEdit(id: number, mandatorId: number): void {
    this.router.navigate(['/mandator/organisation-unit/edit', mandatorId, id]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onPagingChange(event: PageEvent): void {
    this.organisationUnitViewSettings.pageSize = event.pageSize;
  }

  clientSideFiltering(): void {
    this.dataSource.filter = this.pageSettings.overviewFilter.tableGlobalFilter || '';
  }

  serverSideFiltering(): void {
    // empty
  }

  deactivateOrganisationUnit(Id: number) {
    this.subscription.add(
      this.action$.pipe(ofType(DEACTIVATE_ORGANISATION_UNITS_SUCCESS), take(1)).subscribe(() => {
        this.organisationUnitsFiltered.find(ou => ou.Id === Id).IsActive = false;
        this.dataSource = new MatTableDataSource(this.organisationUnitsFiltered);
        this.snackBarService.open('Organisation unit ' + Id + ' deactivated', 'Close', {
          duration: 3000,
          panelClass: 'snackbar-primary',
        });
      })
    );

    this.subscription.add(
      this.action$.pipe(ofType(DEACTIVATE_ORGANISATION_UNITS_FAILED), take(1)).subscribe(() => {
        this.snackBarService.open('Failed to deactivate organisation unit ' + Id, 'Close', {
          duration: 3000,
          panelClass: 'snackbar-primary',
        });
      })
    );

    this.store.dispatch(new DeactivateOrganisationUnitAction(Id));
  }

  activateOorganisationUnit(Id: number) {
    this.subscription.add(
      this.action$.pipe(ofType(ACTIVATE_ORGANISATION_UNITS_SUCCESS), take(1)).subscribe(() => {
        this.organisationUnitsFiltered.find(ou => ou.Id === Id).IsActive = true;
        this.dataSource = new MatTableDataSource(this.organisationUnitsFiltered);
        this.snackBarService.open('Organisation unit ' + Id + ' activated', 'Close', {
          duration: 3000,
          panelClass: 'snackbar-primary',
        });
      })
    );

    this.subscription.add(
      this.action$.pipe(ofType(ACTIVATE_ORGANISATION_UNITS_FAILED), take(1)).subscribe(() => {
        this.snackBarService.open('Failed to activate organisation unit ' + Id, 'Close', {
          duration: 3000,
          panelClass: 'snackbar-primary',
        });
      })
    );

    this.store.dispatch(new ActivateOrganisationUnitAction(Id));
  }
}
