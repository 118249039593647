/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HeatingSubProductConnectionViewModel } from './heatingSubProductConnectionViewModel';


export interface HeatingMasterDataViewModel { 
    /**
     * The heating manufacturers
     */
    Manufacturers?: Array<string>;
    /**
     * The heating types
     */
    HeatingTypes?: Array<string>;
    /**
     * The water tank types
     */
    WaterTankTypes?: Array<string>;
    /**
     * The sub product connections
     */
    SubProductConnections?: Array<HeatingSubProductConnectionViewModel>;
    /**
     * The water tank types
     */
    DomesticWaterHeatingConstructionTypes?: Array<string>;
    /**
     * The water heating construction types for the water tanks
     */
    WaterTankElectricHeatingTypes?: Array<string>;
    /**
     * The cooling function desired/undesired/unspecified types for HeatGenerators
     */
    CoolingFunctions?: Array<string>;
}
