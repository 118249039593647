import { Dictionary } from 'lodash';
import { TaskSettingViewModel } from '../apis/advis';

export interface ITaskDefaultSetting {
  tradeType: string;
  days: number;
  title: string;
}

export class TaskSettingsUtil {
  private static readonly tasksOffsets: Dictionary<ITaskDefaultSetting[]> = {
    [TaskSettingViewModel.TaskSettingTypeEnum.UnreachedFirstAttempt]: [
      {
        days: 1,
        tradeType: 'EM',
        title: 'Unreached',
      },
      {
        days: 28,
        tradeType: '',
        title: 'Unreached',
      },
    ],
    [TaskSettingViewModel.TaskSettingTypeEnum.UnreachedSecondAttempt]: [
      {
        days: 1,
        tradeType: 'EM',
        title: 'Unreached',
      },
      {
        days: 3,
        tradeType: '',
        title: 'Unreached',
      },
    ],
    [TaskSettingViewModel.TaskSettingTypeEnum.UnreachedThirdAttempt]: [
      {
        days: 30,
        tradeType: 'EM',
        title: 'Unreached',
      },
      {
        days: 5,
        tradeType: '',
        title: 'Unreached',
      },
    ],
    [TaskSettingViewModel.TaskSettingTypeEnum.FollowUpNbo]: [
      {
        days: 1,
        tradeType: '',
        title: 'FollowUp',
      },
    ],
    [TaskSettingViewModel.TaskSettingTypeEnum.FollowUpBo]: [
      {
        days: 14,
        tradeType: '',
        title: 'FollowUp',
      },
    ],
  };

  public static getDefaultTaskSettings(
    taskSettingType: TaskSettingViewModel.TaskSettingTypeEnum,
    tradeTypeCode: string
  ): ITaskDefaultSetting {
    const statusConfiguration = this.tasksOffsets[taskSettingType];
    if (!statusConfiguration) {
      return {
        days: 1,
        title: taskSettingType.toLocaleLowerCase().includes('unreached') ? 'Unreached' : 'FollowUp',
      } as ITaskDefaultSetting;
    }
    const tradeSpecificConfiguration = statusConfiguration.find(x => x.tradeType === tradeTypeCode);
    const configuration = tradeSpecificConfiguration
      ? tradeSpecificConfiguration
      : statusConfiguration.find(x => !x.tradeType);
    return configuration;
  }
}
