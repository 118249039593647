/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PositionBuilderRuleViewModel } from './positionBuilderRuleViewModel';


export interface AggregateRuleViewModel { 
    Operator: AggregateRuleViewModel.OperatorEnum;
    /**
     * The recursive sub-rules
     */
    SubRules: Array<PositionBuilderRuleViewModel>;
}
export namespace AggregateRuleViewModel {
    export type OperatorEnum = 'And' | 'Or';
    export const OperatorEnum = {
        And: 'And' as OperatorEnum,
        Or: 'Or' as OperatorEnum
    };
}
