import { IViewSettingBase } from './view-setting-base.model';

export class GlobalVsModel implements IViewSettingBase<GlobalVsModel> {
  public mandants: string[];
  public tradeTypes: string[];

  constructor(mandants: string[] = [], tradeTypes: string[] = []) {
    this.mandants = mandants;
    this.tradeTypes = tradeTypes;
  }

  clone(): GlobalVsModel {
    const model: GlobalVsModel = new GlobalVsModel(this.mandants, this.tradeTypes);
    return model;
  }
}
