import {
  DomesticWaterHeatingListViewModel,
  HeatGeneratorListViewModel,
  WaterTankListViewModel,
} from '../../shared/apis/advis';

export interface IHtMasterdataState {
  domesticWaterHeating: DomesticWaterHeatingListViewModel[];
  domesticWaterHeatingLoading: boolean;
  domesticWaterHeatingLoaded: boolean;

  heatGenerators: HeatGeneratorListViewModel[];
  heatGeneratorLoading: boolean;
  heatGeneratorLoaded: boolean;

  waterTanks: WaterTankListViewModel[];
  waterTankLoading: boolean;
  waterTankLoaded: boolean;
}

export function initHtMasterdataState(): IHtMasterdataState {
  return {
    domesticWaterHeating: [],
    domesticWaterHeatingLoaded: false,
    domesticWaterHeatingLoading: false,

    heatGenerators: [],
    heatGeneratorLoaded: false,
    heatGeneratorLoading: false,

    waterTanks: [],
    waterTankLoaded: false,
    waterTankLoading: false,
  };
}
