/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ProductlineTarifViewModel { 
    /**
     * Identification of the tarif, make sure that new tarifs have Id 0
     */
    Id?: number;
    /**
     * Name of the tarif
     */
    Name: string;
    /**
     * factor of the tarif
     */
    Factor?: number;
    /**
     * the factor that gets displayed
     */
    DisplayFactor: string;
    /**
     * whether it gets displayed
     */
    Display?: boolean;
    /**
     * display order
     */
    Order?: number;
    Scope?: ProductlineTarifViewModel.ScopeEnum;
    /**
     * whether to display text
     */
    DisplayFreetext?: boolean;
    /**
     * some description
     */
    FreetextPlaceholder?: string;
}
export namespace ProductlineTarifViewModel {
    export type ScopeEnum = 'Undefined' | 'BasisPositionVariantScope' | 'OptionVariantScope' | 'BasisPositionPositionScope' | 'OptionPositionScope';
    export const ScopeEnum = {
        Undefined: 'Undefined' as ScopeEnum,
        BasisPositionVariantScope: 'BasisPositionVariantScope' as ScopeEnum,
        OptionVariantScope: 'OptionVariantScope' as ScopeEnum,
        BasisPositionPositionScope: 'BasisPositionPositionScope' as ScopeEnum,
        OptionPositionScope: 'OptionPositionScope' as ScopeEnum
    };
}
