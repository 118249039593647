import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { IRootState } from './root.state';
import { getGlobalErrors, IErrorInfo } from './shared/store';
import { Subscription } from 'rxjs';
import { SignalRService } from './signalR/signalr-notification.service';

@Component({
  selector: 'ac-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  constructor(
    private store: Store<IRootState>,
    private translate: TranslateService,
    private signalRService: SignalRService
  ) {
    this.signalRService.subscribeToEvents();
  }

  ngOnInit(): void {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('de');

    this.subscription.add(
      this.store.select(getGlobalErrors).subscribe((errors: IErrorInfo[]) => {
        if (errors.length > 0) {
          // this.showErrorDialog(errors);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
