/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SendGridTemplateData } from './sendGridTemplateData';


export interface CreateMandatorRequest { 
    MandatorIdToCopy?: number;
    MandatorName: string;
    MandatorShortName: string;
    OrganizationUnitName: string;
    OrganizationUnitShortName: string;
    AppKey: string;
    TechnicalUserEmail: string;
    NboSendGridTemplates: Array<SendGridTemplateData>;
    TechnicalSupportEmail?: string;
    ProductSupportEmail?: string;
    UseExtendedSupportDialog?: boolean;
    IsActive?: boolean;
}
