<ng-container *ngIf="languages">
  <div fxLayout="row" *ngFor="let language of languages" fxFlex>
    <div fxLayout="column">
      <p *ngIf="title">{{ this.title + ' - ' + language }}</p>
      <mat-form-field appearance="outline" *ngIf="displayGeneratedString">
        <mat-label>Generated string:</mat-label>
        <textarea matInput readonly style="resize: none">{{
          getRawDataForLanguage(language)
        }}</textarea>
      </mat-form-field>

      <button
        mat-icon-button
        style="width: 100px"
        (click)="openStringGenerationDialog(language, mandators)">
        {{ 'Generate - ' + language }}
      </button>
    </div>
  </div>
</ng-container>
