/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PostPutRoleTargetGroupRequest } from '../model/postPutRoleTargetGroupRequest';
import { RoleTargetGroupViewModel } from '../model/roleTargetGroupViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RoleTargetGroupService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRoleTargetGroupCreateRoleTargetGroup(body?: PostPutRoleTargetGroupRequest, observe?: 'body', reportProgress?: boolean): Observable<RoleTargetGroupViewModel>;
    public adminRoleTargetGroupCreateRoleTargetGroup(body?: PostPutRoleTargetGroupRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoleTargetGroupViewModel>>;
    public adminRoleTargetGroupCreateRoleTargetGroup(body?: PostPutRoleTargetGroupRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoleTargetGroupViewModel>>;
    public adminRoleTargetGroupCreateRoleTargetGroup(body?: PostPutRoleTargetGroupRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RoleTargetGroupViewModel>(`${this.basePath}/api/admin/role-target-groups`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param roleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRoleTargetGroupDeleteRoleTargetGroup(roleId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public adminRoleTargetGroupDeleteRoleTargetGroup(roleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public adminRoleTargetGroupDeleteRoleTargetGroup(roleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public adminRoleTargetGroupDeleteRoleTargetGroup(roleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (roleId === null || roleId === undefined) {
            throw new Error('Required parameter roleId was null or undefined when calling adminRoleTargetGroupDeleteRoleTargetGroup.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/role-target-groups/${encodeURIComponent(String(roleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRoleTargetGroupGetAllRoleTargetGroups(observe?: 'body', reportProgress?: boolean): Observable<Array<RoleTargetGroupViewModel>>;
    public adminRoleTargetGroupGetAllRoleTargetGroups(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RoleTargetGroupViewModel>>>;
    public adminRoleTargetGroupGetAllRoleTargetGroups(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RoleTargetGroupViewModel>>>;
    public adminRoleTargetGroupGetAllRoleTargetGroups(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<RoleTargetGroupViewModel>>(`${this.basePath}/api/admin/role-target-groups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param roleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRoleTargetGroupGetRoleTargetGroupById(roleId: string, observe?: 'body', reportProgress?: boolean): Observable<RoleTargetGroupViewModel>;
    public adminRoleTargetGroupGetRoleTargetGroupById(roleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoleTargetGroupViewModel>>;
    public adminRoleTargetGroupGetRoleTargetGroupById(roleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoleTargetGroupViewModel>>;
    public adminRoleTargetGroupGetRoleTargetGroupById(roleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (roleId === null || roleId === undefined) {
            throw new Error('Required parameter roleId was null or undefined when calling adminRoleTargetGroupGetRoleTargetGroupById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<RoleTargetGroupViewModel>(`${this.basePath}/api/admin/role-target-groups/${encodeURIComponent(String(roleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param roleId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRoleTargetGroupUpdateRoleTargetGroup(roleId: string, body?: PostPutRoleTargetGroupRequest, observe?: 'body', reportProgress?: boolean): Observable<RoleTargetGroupViewModel>;
    public adminRoleTargetGroupUpdateRoleTargetGroup(roleId: string, body?: PostPutRoleTargetGroupRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoleTargetGroupViewModel>>;
    public adminRoleTargetGroupUpdateRoleTargetGroup(roleId: string, body?: PostPutRoleTargetGroupRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoleTargetGroupViewModel>>;
    public adminRoleTargetGroupUpdateRoleTargetGroup(roleId: string, body?: PostPutRoleTargetGroupRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (roleId === null || roleId === undefined) {
            throw new Error('Required parameter roleId was null or undefined when calling adminRoleTargetGroupUpdateRoleTargetGroup.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<RoleTargetGroupViewModel>(`${this.basePath}/api/admin/role-target-groups/${encodeURIComponent(String(roleId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
