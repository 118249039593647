import { IFieldConfig } from './../../models/sharedInterfaces';
import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ac-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  field: IFieldConfig;
  group: UntypedFormGroup;

  getCorrectHtmlType(fieldType: string): string {
    switch (fieldType) {
      case 'String':
        return 'text';
      case 'Integer':
      case 'Decimal':
        return 'number';
      default:
        return 'text';
    }
  }
}
