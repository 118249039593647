import { Component, Input, OnInit } from '@angular/core';
import { StringGenerationBlock } from '../../apis/advis';
import BlockTypeEnum = StringGenerationBlock.BlockTypeEnum;

@Component({
  selector: 'ac-string-generation-block',
  templateUrl: './string-generation-block.component.html',
  styleUrls: ['./string-generation-block.component.sass'],
})
export class StringGenerationBlockComponent implements OnInit {
  blockTypesSelectable: string[] = Object.keys(StringGenerationBlock.BlockTypeEnum);

  @Input()
  block: StringGenerationBlock;

  @Input()
  removeBlock: (index: number) => void;

  @Input()
  index: any;

  constructor() {}

  ngOnInit(): void {}

  protected readonly StringGenerationBlock = StringGenerationBlock;
  protected readonly BlockTypeEnum = BlockTypeEnum;

  removeLink(index: any) {
    this.removeBlock(index);
  }
}
