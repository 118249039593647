/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PositionGroupTemplateViewModel } from '../model/positionGroupTemplateViewModel';
import { PostPutGroupTemplateRequest } from '../model/postPutGroupTemplateRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PositionGroupTemplateService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletes a position group template
     * 
     * @param tradeTypeCode trade type code of the position group template
     * @param groupTemplateId the id of the position group template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionGroupTemplateDeleteTemplate(tradeTypeCode: string, groupTemplateId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public positionGroupTemplateDeleteTemplate(tradeTypeCode: string, groupTemplateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public positionGroupTemplateDeleteTemplate(tradeTypeCode: string, groupTemplateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public positionGroupTemplateDeleteTemplate(tradeTypeCode: string, groupTemplateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionGroupTemplateDeleteTemplate.');
        }

        if (groupTemplateId === null || groupTemplateId === undefined) {
            throw new Error('Required parameter groupTemplateId was null or undefined when calling positionGroupTemplateDeleteTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/positiongrouptemplates/${encodeURIComponent(String(groupTemplateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all position group templates of the specified trade type.
     * 
     * @param tradeTypeCode trade type code of the position group templates
     * @param mandatorIds The mandator ids.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionGroupTemplateGetAllTemplates(tradeTypeCode: string, mandatorIds?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PositionGroupTemplateViewModel>>;
    public positionGroupTemplateGetAllTemplates(tradeTypeCode: string, mandatorIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PositionGroupTemplateViewModel>>>;
    public positionGroupTemplateGetAllTemplates(tradeTypeCode: string, mandatorIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PositionGroupTemplateViewModel>>>;
    public positionGroupTemplateGetAllTemplates(tradeTypeCode: string, mandatorIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionGroupTemplateGetAllTemplates.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mandatorIds !== undefined && mandatorIds !== null) {
            queryParameters = queryParameters.set('mandatorIds', <any>mandatorIds);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PositionGroupTemplateViewModel>>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/positiongrouptemplates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific position group template by id.
     * 
     * @param tradeTypeCode trade type code of the position group template
     * @param groupTemplateId the id of the position group template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionGroupTemplateGetTemplate(tradeTypeCode: string, groupTemplateId: number, observe?: 'body', reportProgress?: boolean): Observable<PositionGroupTemplateViewModel>;
    public positionGroupTemplateGetTemplate(tradeTypeCode: string, groupTemplateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PositionGroupTemplateViewModel>>;
    public positionGroupTemplateGetTemplate(tradeTypeCode: string, groupTemplateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PositionGroupTemplateViewModel>>;
    public positionGroupTemplateGetTemplate(tradeTypeCode: string, groupTemplateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionGroupTemplateGetTemplate.');
        }

        if (groupTemplateId === null || groupTemplateId === undefined) {
            throw new Error('Required parameter groupTemplateId was null or undefined when calling positionGroupTemplateGetTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<PositionGroupTemplateViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/positiongrouptemplates/${encodeURIComponent(String(groupTemplateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a position group template
     * 
     * @param tradeTypeCode trade type code of the position group template
     * @param body the new position group template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionGroupTemplatePostTemplate(tradeTypeCode: string, body?: PostPutGroupTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<PositionGroupTemplateViewModel>;
    public positionGroupTemplatePostTemplate(tradeTypeCode: string, body?: PostPutGroupTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PositionGroupTemplateViewModel>>;
    public positionGroupTemplatePostTemplate(tradeTypeCode: string, body?: PostPutGroupTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PositionGroupTemplateViewModel>>;
    public positionGroupTemplatePostTemplate(tradeTypeCode: string, body?: PostPutGroupTemplateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionGroupTemplatePostTemplate.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PositionGroupTemplateViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/positiongrouptemplates`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a position group template
     * 
     * @param tradeTypeCode trade type code of the position group template
     * @param groupTemplateId the id of the position group template
     * @param body the position group template data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public positionGroupTemplatePutTemplate(tradeTypeCode: string, groupTemplateId: number, body?: PostPutGroupTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<PositionGroupTemplateViewModel>;
    public positionGroupTemplatePutTemplate(tradeTypeCode: string, groupTemplateId: number, body?: PostPutGroupTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PositionGroupTemplateViewModel>>;
    public positionGroupTemplatePutTemplate(tradeTypeCode: string, groupTemplateId: number, body?: PostPutGroupTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PositionGroupTemplateViewModel>>;
    public positionGroupTemplatePutTemplate(tradeTypeCode: string, groupTemplateId: number, body?: PostPutGroupTemplateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling positionGroupTemplatePutTemplate.');
        }

        if (groupTemplateId === null || groupTemplateId === undefined) {
            throw new Error('Required parameter groupTemplateId was null or undefined when calling positionGroupTemplatePutTemplate.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PositionGroupTemplateViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/positiongrouptemplates/${encodeURIComponent(String(groupTemplateId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
