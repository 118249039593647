/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CopyProductlineRequest } from '../model/copyProductlineRequest';
import { GenericObjectdataTemplateViewModel } from '../model/genericObjectdataTemplateViewModel';
import { GenericProductlineListViewModel } from '../model/genericProductlineListViewModel';
import { GenericProductlineViewModel } from '../model/genericProductlineViewModel';
import { PostPutObjectdataTemplateRequest } from '../model/postPutObjectdataTemplateRequest';
import { PostPutProductlineRequest } from '../model/postPutProductlineRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProductlineService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletes a template
     * 
     * @param tradeTypeCode trade type code of the productline
     * @param productlineId id of the productline of this template
     * @param templateId the id of the template to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public objectdataTemplateDeleteTemplate(tradeTypeCode: string, productlineId: number, templateId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public objectdataTemplateDeleteTemplate(tradeTypeCode: string, productlineId: number, templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public objectdataTemplateDeleteTemplate(tradeTypeCode: string, productlineId: number, templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public objectdataTemplateDeleteTemplate(tradeTypeCode: string, productlineId: number, templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling objectdataTemplateDeleteTemplate.');
        }

        if (productlineId === null || productlineId === undefined) {
            throw new Error('Required parameter productlineId was null or undefined when calling objectdataTemplateDeleteTemplate.');
        }

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling objectdataTemplateDeleteTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines/${encodeURIComponent(String(productlineId))}/templates/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific template by id
     * 
     * @param tradeTypeCode trade type code of the productline
     * @param productlineId the id of the productline
     * @param templateId the id of the template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public objectdataTemplateGetTemplate(tradeTypeCode: string, productlineId: number, templateId: number, observe?: 'body', reportProgress?: boolean): Observable<GenericObjectdataTemplateViewModel>;
    public objectdataTemplateGetTemplate(tradeTypeCode: string, productlineId: number, templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericObjectdataTemplateViewModel>>;
    public objectdataTemplateGetTemplate(tradeTypeCode: string, productlineId: number, templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericObjectdataTemplateViewModel>>;
    public objectdataTemplateGetTemplate(tradeTypeCode: string, productlineId: number, templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling objectdataTemplateGetTemplate.');
        }

        if (productlineId === null || productlineId === undefined) {
            throw new Error('Required parameter productlineId was null or undefined when calling objectdataTemplateGetTemplate.');
        }

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling objectdataTemplateGetTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<GenericObjectdataTemplateViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines/${encodeURIComponent(String(productlineId))}/templates/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates template
     * 
     * @param tradeTypeCode trade type code of the productline
     * @param productlineId the id of the productline
     * @param body the new template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public objectdataTemplatePostTemplate(tradeTypeCode: string, productlineId: number, body?: PostPutObjectdataTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericObjectdataTemplateViewModel>;
    public objectdataTemplatePostTemplate(tradeTypeCode: string, productlineId: number, body?: PostPutObjectdataTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericObjectdataTemplateViewModel>>;
    public objectdataTemplatePostTemplate(tradeTypeCode: string, productlineId: number, body?: PostPutObjectdataTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericObjectdataTemplateViewModel>>;
    public objectdataTemplatePostTemplate(tradeTypeCode: string, productlineId: number, body?: PostPutObjectdataTemplateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling objectdataTemplatePostTemplate.');
        }

        if (productlineId === null || productlineId === undefined) {
            throw new Error('Required parameter productlineId was null or undefined when calling objectdataTemplatePostTemplate.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericObjectdataTemplateViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines/${encodeURIComponent(String(productlineId))}/templates`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates template
     * 
     * @param tradeTypeCode trade type code of the productline
     * @param productlineId id of the productline of this template
     * @param templateId the id of the template to update
     * @param body the new template data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public objectdataTemplatePutTemplate(tradeTypeCode: string, productlineId: number, templateId: number, body?: PostPutObjectdataTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericObjectdataTemplateViewModel>;
    public objectdataTemplatePutTemplate(tradeTypeCode: string, productlineId: number, templateId: number, body?: PostPutObjectdataTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericObjectdataTemplateViewModel>>;
    public objectdataTemplatePutTemplate(tradeTypeCode: string, productlineId: number, templateId: number, body?: PostPutObjectdataTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericObjectdataTemplateViewModel>>;
    public objectdataTemplatePutTemplate(tradeTypeCode: string, productlineId: number, templateId: number, body?: PostPutObjectdataTemplateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling objectdataTemplatePutTemplate.');
        }

        if (productlineId === null || productlineId === undefined) {
            throw new Error('Required parameter productlineId was null or undefined when calling objectdataTemplatePutTemplate.');
        }

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling objectdataTemplatePutTemplate.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<GenericObjectdataTemplateViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines/${encodeURIComponent(String(productlineId))}/templates/${encodeURIComponent(String(templateId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Copies productline data to an existing one or  Creates productline from copy
     * 
     * @param tradeTypeCode trade type code of the productline
     * @param productlineId id of the productline to copy
     * @param body describes which data will be copied
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productlineCopyProductline(tradeTypeCode: string, productlineId: number, body?: CopyProductlineRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericProductlineViewModel>;
    public productlineCopyProductline(tradeTypeCode: string, productlineId: number, body?: CopyProductlineRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericProductlineViewModel>>;
    public productlineCopyProductline(tradeTypeCode: string, productlineId: number, body?: CopyProductlineRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericProductlineViewModel>>;
    public productlineCopyProductline(tradeTypeCode: string, productlineId: number, body?: CopyProductlineRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling productlineCopyProductline.');
        }

        if (productlineId === null || productlineId === undefined) {
            throw new Error('Required parameter productlineId was null or undefined when calling productlineCopyProductline.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericProductlineViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines/${encodeURIComponent(String(productlineId))}/copy`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes productline
     * 
     * @param tradeTypeCode trade type code of the productline
     * @param productlineId id of the productline to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productlineDeleteProductline(tradeTypeCode: string, productlineId: number, observe?: 'body', reportProgress?: boolean): Observable<GenericProductlineViewModel>;
    public productlineDeleteProductline(tradeTypeCode: string, productlineId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericProductlineViewModel>>;
    public productlineDeleteProductline(tradeTypeCode: string, productlineId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericProductlineViewModel>>;
    public productlineDeleteProductline(tradeTypeCode: string, productlineId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling productlineDeleteProductline.');
        }

        if (productlineId === null || productlineId === undefined) {
            throw new Error('Required parameter productlineId was null or undefined when calling productlineDeleteProductline.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<GenericProductlineViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines/${encodeURIComponent(String(productlineId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all productlines of mandators the logged in user is responsible for.  The productlines are limited to the specific trade type
     * 
     * @param tradeTypeCode trade type code of the productlines
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productlineGetAllProductlines(tradeTypeCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GenericProductlineListViewModel>>;
    public productlineGetAllProductlines(tradeTypeCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GenericProductlineListViewModel>>>;
    public productlineGetAllProductlines(tradeTypeCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GenericProductlineListViewModel>>>;
    public productlineGetAllProductlines(tradeTypeCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling productlineGetAllProductlines.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GenericProductlineListViewModel>>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific productline by id, including details for fees, tarifs and objectdata template (id, name)
     * 
     * @param tradeTypeCode trade type code of the productline
     * @param productlineId the id of the productline
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productlineGetProductline(tradeTypeCode: string, productlineId: number, observe?: 'body', reportProgress?: boolean): Observable<GenericProductlineViewModel>;
    public productlineGetProductline(tradeTypeCode: string, productlineId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericProductlineViewModel>>;
    public productlineGetProductline(tradeTypeCode: string, productlineId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericProductlineViewModel>>;
    public productlineGetProductline(tradeTypeCode: string, productlineId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling productlineGetProductline.');
        }

        if (productlineId === null || productlineId === undefined) {
            throw new Error('Required parameter productlineId was null or undefined when calling productlineGetProductline.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<GenericProductlineViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines/${encodeURIComponent(String(productlineId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates productline
     * 
     * @param tradeTypeCode trade type code of the productline
     * @param body the new productline
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productlinePostProductline(tradeTypeCode: string, body?: PostPutProductlineRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericProductlineViewModel>;
    public productlinePostProductline(tradeTypeCode: string, body?: PostPutProductlineRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericProductlineViewModel>>;
    public productlinePostProductline(tradeTypeCode: string, body?: PostPutProductlineRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericProductlineViewModel>>;
    public productlinePostProductline(tradeTypeCode: string, body?: PostPutProductlineRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling productlinePostProductline.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericProductlineViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates productline
     * 
     * @param tradeTypeCode trade type code of the productline
     * @param productlineId id of the productline to update
     * @param body the new productline data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productlinePutProductline(tradeTypeCode: string, productlineId: number, body?: PostPutProductlineRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericProductlineViewModel>;
    public productlinePutProductline(tradeTypeCode: string, productlineId: number, body?: PostPutProductlineRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericProductlineViewModel>>;
    public productlinePutProductline(tradeTypeCode: string, productlineId: number, body?: PostPutProductlineRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericProductlineViewModel>>;
    public productlinePutProductline(tradeTypeCode: string, productlineId: number, body?: PostPutProductlineRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling productlinePutProductline.');
        }

        if (productlineId === null || productlineId === undefined) {
            throw new Error('Required parameter productlineId was null or undefined when calling productlinePutProductline.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<GenericProductlineViewModel>(`${this.basePath}/api/admin/${encodeURIComponent(String(tradeTypeCode))}/productlines/${encodeURIComponent(String(productlineId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
