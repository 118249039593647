/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface StringGenerationBlock { 
    RawData?: string;
    BlockType?: StringGenerationBlock.BlockTypeEnum;
    Content?: string;
    AdditionalContent?: string;
}
export namespace StringGenerationBlock {
    export type BlockTypeEnum = 'Text' | 'Link' | 'BreakLine';
    export const BlockTypeEnum = {
        Text: 'Text' as BlockTypeEnum,
        Link: 'Link' as BlockTypeEnum,
        BreakLine: 'BreakLine' as BlockTypeEnum
    };
}
