import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PvMasterdataEffect } from './state/masterdata.effects';
import * as MasterdataReducer from './state/masterdata.reducer';
import { MasterdataMainComponent } from './components/masterdata-main/masterdata-main.component';
import { MasterdataModulesFormComponent } from './components/masterdata-main/masterdata-modules/masterdata-modules-form/masterdata-modules-form.component';
import { MasterdataModuleOverviewComponent } from './components/masterdata-main/masterdata-modules/masterdata-modules-overview/masterdata-modules-overview.component';
import { PvMasterdataRoutingModule } from './pv-masterdata-routing.module';
import { MasterdataStoragesOverviewComponent } from './components/masterdata-main/masterdata-storage/masterdata-storage-overview/masterdata-storages-overview.component';
import { MasterdataStoragesFormComponent } from './components/masterdata-main/masterdata-storage/masterdata-storage-form/masterdata-storages-form.component';
import { MasterdataShadingOptimizersFormComponent } from './components/masterdata-main/masterdata-shading-optimizer/masterdata-storage-form/masterdata-shading-optimizers-form.component';
import { MasterdataShadingOptimizersOverviewComponent } from './components/masterdata-main/masterdata-shading-optimizer/masterdata-storage-overview/masterdata-shading-optimizers-overview.component';
import { PvMasterdataNavigationComponent } from './shared/pv-masterdata-navigation/pv-masterdata-navigation.component';
import { MasterdataInvertersOverviewComponent } from './components/masterdata-main/masterdata-inverters/masterdata-inverters-overview/masterdata-inverters-overview.component';
import { MasterdataInvertersFormComponent } from './components/masterdata-main/masterdata-inverters/masterdata-inverters-form/masterdata-inverters-form.component';

@NgModule({
  declarations: [
    MasterdataMainComponent,
    MasterdataModulesFormComponent,
    MasterdataModuleOverviewComponent,
    MasterdataShadingOptimizersFormComponent,
    MasterdataShadingOptimizersOverviewComponent,
    MasterdataStoragesFormComponent,
    MasterdataStoragesOverviewComponent,
    PvMasterdataNavigationComponent,
    MasterdataInvertersOverviewComponent,
    MasterdataInvertersFormComponent,
  ],
  imports: [
    CommonModule,
    PvMasterdataRoutingModule,
    SharedModule,
    StoreModule.forFeature('pvmasterdata', MasterdataReducer.reducer),
    EffectsModule.forFeature([PvMasterdataEffect]),
  ],
})
export class PvMasterdataModule {}
