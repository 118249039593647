import { Component, OnInit, ViewChild } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable, Subject } from 'rxjs';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { IRootState } from '../../../../../root.state';
import { Store } from '@ngrx/store';
import { IGuardDeactivation } from '../../../../../shared/interfaces/guard-deactivation';
import {
  ADD_DOMESTIC_WATER_HEATING_SUCCESS,
  AddDomesticWaterHeatingAction,
  EDIT_DOMESTIC_WATER_HEATING_SUCCESS,
  EditDomesticWaterHeatingAction,
  GET_DOMESTIC_WATER_HEATING_SUCCESS,
  GetDomesticWaterHeatingAction,
  GetDomesticWaterHeatingSuccessAction,
} from '../../../../state/ht-masterdata.action';
import {
  DomesticWaterHeatingViewModel,
  LocalizedValueViewModel,
  PostPutDomesticWaterHeatingRequest,
  StringGeneration,
} from '../../../../../shared/apis/advis';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { HtMasterdataBaseFormComponent } from '../../../ht-masterdata-base-form/ht-masterdata-base-form.component';
import { generateEmptyLocalizations } from '../../../../../shared/components/localized-values/generateEmptyLocalizations';
import { getDomesticWaterHeatingConstructionTypes } from '../../../../../shared/store';
import { checkSpecialCharacters } from '../../../../../shared/utils/validation-util';
import { HtMasterdataStringGenerationControlsComponent } from '../../ht-masterdata-string-generation-controls/ht-masterdata-string-generation-controls.component';
import LanguageEnum = LocalizedValueViewModel.LanguageEnum;
import { StringGenerationMapperService } from '../../../../../shared/components/string-generation-dialog/string-generation-mapper.service';

@Component({
  selector: 'ac-ht-masterdata-domestic-water-heating-form',
  templateUrl: './ht-masterdata-domestic-water-heating-form.component.html',
  styleUrls: ['./ht-masterdata-domestic-water-heating-form.component.sass'],
})
export class HtMasterdataDomesticWaterHeatingFormComponent
  extends HtMasterdataBaseFormComponent
  implements OnInit, IGuardDeactivation
{
  domesticWaterHeating: DomesticWaterHeatingViewModel = {
    LocalizedTitles: generateEmptyLocalizations(),
    LocalizedDataSheets: generateEmptyLocalizations(),
  } as DomesticWaterHeatingViewModel;
  initialDomesticWaterHeating: DomesticWaterHeatingViewModel = cloneDeep(this.domesticWaterHeating);

  @ViewChild('domesticForm')
  domesticForm: UntypedFormGroup;

  @ViewChild(HtMasterdataStringGenerationControlsComponent)
  public stringGenerationControlsComponent: HtMasterdataStringGenerationControlsComponent;

  onSaveStringGenerationSanitizeCallback: Subject<boolean> = new Subject<boolean>();

  constructionTypes: string[] = [];

  constructor(
    private action$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private stringGenerationMapper: StringGenerationMapperService,
    public store: Store<IRootState>
  ) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const constructionTypes: Observable<string[]> = this.store.select(
      getDomesticWaterHeatingConstructionTypes
    );

    this.subscriptions.add(
      combineLatest([constructionTypes]).subscribe(([heatingTypes]: [string[]]) => {
        this.constructionTypes = heatingTypes;
      })
    );

    const url$: Observable<UrlSegment[]> = this.route.url;
    const params$: Observable<Params> = this.route.params;

    this.subscriptions.add(
      combineLatest([url$, params$]).subscribe(([url, params]: any) => {
        let urlPath: string = url[1].path.toString();

        if (urlPath === 'edit') {
          if (params && params.id) {
            this.formTitle = this.translate.instant(
              'HT_MASTERDATA.DOMESTIC_WATER_HEATING.EDIT_DOMESTIC_WATER_HEATING'
            );
            this.itemId = params.id;
            this.loadDomesticWaterHeating();
            this.isNew = false;
          }
        } else {
          this.formTitle = this.translate.instant(
            'HT_MASTERDATA.DOMESTIC_WATER_HEATING.ADD_DOMESTIC_WATER_HEATING'
          );
        }
      })
    );

    this.subscriptions.add(
      this.action$
        .pipe(
          ofType(ADD_DOMESTIC_WATER_HEATING_SUCCESS, EDIT_DOMESTIC_WATER_HEATING_SUCCESS),
          take(1)
        )
        .subscribe(() => this.onBack())
    );
  }

  onStringGenerationDialogClosed(dialogData: StringGeneration): void {
    if (!dialogData || dialogData?.Blocks?.length === 0) {
      return;
    }

    this.domesticWaterHeating.LocalizedDataSheets =
      this.stringGenerationMapper.mapRawDataToLocalizedDataSheet(
        this.domesticWaterHeating.LocalizedDataSheets,
        dialogData.Language as LanguageEnum,
        dialogData.RawData
      );

    this.domesticWaterHeating.StringGenerationContainer =
      this.stringGenerationMapper.mapDialogDataToContainer(
        dialogData,
        this.domesticWaterHeating.StringGenerationContainer
      );
  }

  isFormValid(): boolean {
    return (
      this.domesticForm &&
      this.domesticForm.valid &&
      this.domesticWaterHeating !== undefined &&
      checkSpecialCharacters(this.domesticWaterHeating.Id)
    );
  }

  onBack(): void {
    this.router.navigate(['/ht-masterdata/domestic-water-heating/overview']);
  }

  onSave(): void {
    this.initialDomesticWaterHeating = cloneDeep(this.domesticWaterHeating);
    this.onSaveStringGenerationSanitizeCallback.next(true);

    const postPutDomesticWaterHeatingRequest: PostPutDomesticWaterHeatingRequest = {
      Id: this.domesticWaterHeating.Id,
      TechnicalTitle: this.domesticWaterHeating.TechnicalTitle,
      ArticleNumber: this.domesticWaterHeating.ArticleNumber,
      Description: this.domesticWaterHeating.Description,
      LocalizedTitles: this.domesticWaterHeating.LocalizedTitles,
      StringGenerationContainer: this.domesticWaterHeating.StringGenerationContainer,
      MandatorIds: this.domesticWaterHeating.MandatorIds,
      SupportedHeatingTypes: this.domesticWaterHeating.SupportedHeatingTypes,
      SupportedManufacturers: this.domesticWaterHeating.SupportedManufacturers,
      IsActive: this.domesticWaterHeating.IsActive,
      DomesticWaterHeatingConstructionType:
        this.domesticWaterHeating.DomesticWaterHeatingConstructionType,
      SubProductConnectionTypes: this.domesticWaterHeating.SubProductConnectionTypes,
      DomesticWaterHeatingMaterial: this.domesticWaterHeating.DomesticWaterHeatingMaterial,
      DomesticWaterHeatingVolume: this.domesticWaterHeating.DomesticWaterHeatingVolume,
      DomesticWaterHeatingRegisterSize: this.domesticWaterHeating.DomesticWaterHeatingRegisterSize,
      ArViewerUrl: this.domesticWaterHeating.ArViewerUrl,
    } as PostPutDomesticWaterHeatingRequest;

    if (this.isNew) {
      this.store.dispatch(new AddDomesticWaterHeatingAction(postPutDomesticWaterHeatingRequest));
    } else {
      this.store.dispatch(new EditDomesticWaterHeatingAction(postPutDomesticWaterHeatingRequest));
    }
  }

  private loadDomesticWaterHeating(): void {
    this.store.dispatch(new GetDomesticWaterHeatingAction(this.itemId));

    this.subscriptions.add(
      this.action$
        .pipe(ofType(GET_DOMESTIC_WATER_HEATING_SUCCESS), take(1))
        .subscribe((action: GetDomesticWaterHeatingSuccessAction) => {
          this.domesticWaterHeating = cloneDeep(
            action.payload as PostPutDomesticWaterHeatingRequest
          );
          this.initialDomesticWaterHeating = cloneDeep(this.domesticWaterHeating);
        })
    );
  }

  get canDeactivateSafely(): boolean {
    this.initialDomesticWaterHeating.StringGenerationContainer =
      this.stringGenerationMapper.unsetRawData(
        this.initialDomesticWaterHeating.StringGenerationContainer
      );
    return isEqual(this.domesticWaterHeating, this.initialDomesticWaterHeating);
  }
}
