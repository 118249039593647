import { Action } from '@ngrx/store';
import { MandatorViewModel, PatchMandatorRequest } from '../../shared/apis/advis';

const prefix: string = '[Mandator]';

export const GET_MANDATORS: string = `${prefix} Get all mandators`;
export const GET_MANDATORS_SUCCESS: string = `${prefix} Get all mandators success`;
export const GET_MANDATORS_FAILED: string = `${prefix} Get all mandators failed`;

export const GET_MANDATOR: string = `${prefix} Get mandator`;
export const GET_MANDATOR_SUCCESS: string = `${prefix} Get mandator success`;
export const GET_MANDATOR_FAILED: string = `${prefix} Get mandator failed`;

export const ADD_MANDATOR: string = `${prefix} Add mandator`;
export const ADD_MANDATOR_SUCCESS: string = `${prefix} Add mandator success`;
export const ADD_MANDATOR_FAILED: string = `${prefix} Add mandator failed`;

export const EDIT_MANDATOR: string = `${prefix} Edit mandator`;
export const EDIT_MANDATOR_SUCCESS: string = `${prefix} Edit mandator success`;
export const EDIT_MANDATOR_FAILED: string = `${prefix} Edit mandator failed`;

export const ACTIVATE_MANDATOR: string = `${prefix} Activate mandator`;
export const ACTIVATE_MANDATOR_SUCCESS: string = `${prefix} Activate mandator success`;
export const ACTIVATE_MANDATOR_FAILED: string = `${prefix} Activate mandator failed`;

export const DEACTIVATE_MANDATOR: string = `${prefix} Deactivate mandator`;
export const DEACTIVATE_MANDATOR_SUCCESS: string = `${prefix} Deactivate mandator success`;
export const DEACTIVATE_MANDATOR_FAILED: string = `${prefix} Deactivate mandator failed`;

export class GetMandatorsAction implements Action {
  readonly type: string = GET_MANDATORS;

  constructor(public payload: any = undefined) {}
}

export class GetMandatorsSuccessAction implements Action {
  readonly type: string = GET_MANDATORS_SUCCESS;

  constructor(public payload: MandatorViewModel[]) {}
}

export class GetMandatorsFailedAction implements Action {
  readonly type: string = GET_MANDATORS_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetMandatorAction implements Action {
  readonly type: string = GET_MANDATOR;

  constructor(public payload: number) {}
}

export class GetMandatorSuccessAction implements Action {
  readonly type: string = GET_MANDATOR_SUCCESS;

  constructor(public payload: MandatorViewModel) {}
}

export class GetMandatorFailedAction implements Action {
  readonly type: string = GET_MANDATOR_FAILED;

  constructor(public payload: any = undefined) {}
}

export interface IEditMandatorRequest {
  patchMandatorRequest: PatchMandatorRequest;
  mandatorId: number;
}

export class EditMandatorAction implements Action {
  readonly type: string = EDIT_MANDATOR;

  constructor(public payload: IEditMandatorRequest) {}
}

export class EditMandatorSuccessAction implements Action {
  readonly type: string = EDIT_MANDATOR_SUCCESS;

  constructor(public payload: MandatorViewModel) {}
}

export class EditMandatorFailedAction implements Action {
  readonly type: string = EDIT_MANDATOR_FAILED;

  constructor(public payload: any = undefined) {}
}

export class ActivateMandatorAction implements Action {
  readonly type: string = ACTIVATE_MANDATOR;

  constructor(public payload: number) {}
}

export class ActivateMandatorSuccessAction implements Action {
  readonly type: string = ACTIVATE_MANDATOR_SUCCESS;

  constructor(public payload: MandatorViewModel) {}
}

export class ActivateMandatorFailedAction implements Action {
  readonly type: string = ACTIVATE_MANDATOR_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeactivateMandatorAction implements Action {
  readonly type: string = DEACTIVATE_MANDATOR;

  constructor(public payload: number) {}
}

export class DeactivateMandatorSuccessAction implements Action {
  readonly type: string = DEACTIVATE_MANDATOR_SUCCESS;

  constructor(public payload: MandatorViewModel) {}
}

export class DeactivateMandatorFailedAction implements Action {
  readonly type: string = DEACTIVATE_MANDATOR_FAILED;

  constructor(public payload: any = undefined) {}
}

export type Actions =
  | GetMandatorsAction
  | GetMandatorsSuccessAction
  | GetMandatorsFailedAction
  | GetMandatorAction
  | GetMandatorSuccessAction
  | GetMandatorFailedAction
  | EditMandatorAction
  | EditMandatorSuccessAction
  | EditMandatorFailedAction
  | ActivateMandatorAction
  | ActivateMandatorSuccessAction
  | ActivateMandatorFailedAction
  | DeactivateMandatorAction
  | DeactivateMandatorSuccessAction
  | DeactivateMandatorFailedAction;
