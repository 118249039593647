import { tassign } from 'tassign';
import * as ProjectReportingAction from '../../project-reporting/state/project-reporting.action';
import {
  ImportProjectFinancesAction,
  ImportProjectFinancesSuccessAction,
  ImportProjectFinancesFailedAction,
  GetProjectFinancesProgressAction,
  GetProjectFinancesProgressFailedAction,
  GetProjectFinancesProgressSuccessAction,
  AddProjectFinancesProgressMessage,
} from './project-reporting.action';
import { initProjectReportingState, IProjectReportingState } from './project-reporting.state';

export function reducer(
  state: IProjectReportingState = initProjectReportingState(),
  action: ProjectReportingAction.Actions
): IProjectReportingState {
  if (action instanceof ImportProjectFinancesAction) {
    return tassign(state, {
      importing: true,
      imported: false,
    } as IProjectReportingState);
  }

  if (action instanceof ImportProjectFinancesSuccessAction) {
    return tassign(state, {
      importing: false,
      imported: true,
    } as IProjectReportingState);
  }

  if (action instanceof ImportProjectFinancesFailedAction) {
    return tassign(state, {
      importing: false,
      imported: false,
    } as IProjectReportingState);
  }

  if (action instanceof GetProjectFinancesProgressAction) {
    return tassign(state, {
      importing: false,
      imported: false,
    } as IProjectReportingState);
  }

  if (action instanceof GetProjectFinancesProgressSuccessAction) {
    return tassign(state, {
      importing: false,
      imported: false,
      progressMessages: action.payload,
    } as IProjectReportingState);
  }

  if (action instanceof GetProjectFinancesProgressFailedAction) {
    return tassign(state, {
      importing: false,
      imported: false,
    } as IProjectReportingState);
  }

  if (action instanceof AddProjectFinancesProgressMessage) {
    return tassign(state, {
      importing: false,
      imported: false,
      progressMessages: [...state.progressMessages, action.payload],
    } as IProjectReportingState);
  }

  return state;
}
