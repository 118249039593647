import { RoleViewModel } from '../../../shared/apis/advis';

export interface IRoleState {
  roles: RoleViewModel[];
  rolesLoading: boolean;
  rolesLoaded: boolean;
  permissions: string[];
  permissionsLoading: boolean;
  permissionsLoaded: boolean;
}

export function initRoleState(): IRoleState {
  return {
    roles: [],
    rolesLoading: false,
    rolesLoaded: false,
    permissions: [],
    permissionsLoading: false,
    permissionsLoaded: false,
  };
}
