import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from '../services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  constructor(private permissionService: PermissionService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role = route.data[0] as string;
    const hasAccess = !role || (role && this.permissionService.userPermissions[role]);

    if (!hasAccess) {
      this.router.navigate(['home']);
      return false;
    }

    return hasAccess;
  }
}
