/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ValidationRuleRequest { 
    /**
     * description of the rule, substring search works
     */
    Description?: string;
    RuleLevel?: ValidationRuleRequest.RuleLevelEnum;
    /**
     * only retrieve entries with a matching mandator id (you can provide multiple mandators by separating them with a ',' or ';')
     */
    MandatorIds?: string;
    /**
     * the index of the current page(starts at 1)
     */
    PageIndex?: number;
    /**
     * the amount of entries per page
     */
    PageSize?: number;
    SortField?: ValidationRuleRequest.SortFieldEnum;
    SortOrder?: ValidationRuleRequest.SortOrderEnum;
}
export namespace ValidationRuleRequest {
    export type RuleLevelEnum = 'Information' | 'Warning';
    export const RuleLevelEnum = {
        Information: 'Information' as RuleLevelEnum,
        Warning: 'Warning' as RuleLevelEnum
    };
    export type SortFieldEnum = 'Description' | 'RuleLevel';
    export const SortFieldEnum = {
        Description: 'Description' as SortFieldEnum,
        RuleLevel: 'RuleLevel' as SortFieldEnum
    };
    export type SortOrderEnum = 'None' | 'Asc' | 'Desc';
    export const SortOrderEnum = {
        None: 'None' as SortOrderEnum,
        Asc: 'Asc' as SortOrderEnum,
        Desc: 'Desc' as SortOrderEnum
    };
}
