import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as MasterdataAction from './masterdata.action';
import {
  AddInverterFailedAction,
  AddModuleFailedAction,
  AddShadingOptimizerFailedAction,
  AddStorageFailedAction,
  DeleteInverterFailedAction,
  DeleteModuleFailedAction,
  DeleteShadingOptimizerFailedAction,
  DeleteStorageFailedAction,
  EditInverterFailedAction,
  EditModuleFailedAction,
  EditShadingOptimizerFailedAction,
  EditStorageFailedAction,
  GetInverterFailedAction,
  GetInvertersFailedAction,
  GetModuleFailedAction,
  GetModulesFailedAction,
  GetShadingOptimizerFailedAction,
  GetShadingOptimizersFailedAction,
  GetStorageFailedAction,
  GetStoragesFailedAction,
} from './masterdata.action';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../../shared/services/config.service';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import {
  PostPutInverterRequest,
  PostPutModuleRequest,
  PostPutShadingOptimizerRequest,
  PostPutStorageRequest,
  PvMasterDataService,
} from '../../shared/apis/advis';
import { ErrorAddAction, ErrorTypeE, UiElementTypeE } from '../../shared/store';

@Injectable()
export class PvMasterdataEffect {
  modulesLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.GET_MODULES),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.GetModulesAction) => action.payload),
      switchMap(() => {
        return this.pvMasterdataService.pvModuleGetAllModules().pipe(
          map((viewModel: any) => {
            return new MasterdataAction.GetModulesSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.GetModulesFailedAction(e)))
        );
      })
    )
  );

  moduleLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.GET_MODULE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.GetModuleAction) => action.payload),
      switchMap((payload: string) => {
        return this.pvMasterdataService.pvModuleGetModule(payload).pipe(
          map((viewModel: any) => {
            return new MasterdataAction.GetModuleSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.GetModuleFailedAction(e)))
        );
      })
    )
  );

  moduleAdd$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.ADD_MODULE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.AddModuleAction) => action.payload),
      switchMap((payload: PostPutModuleRequest) => {
        return this.pvMasterdataService.pvModulePostModule(payload).pipe(
          map((viewModel: any) => {
            return new MasterdataAction.AddModuleSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.AddModuleFailedAction(e)))
        );
      })
    )
  );

  moduleEdit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.EDIT_MODULE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.EditModuleAction) => action.payload),
      switchMap((payload: PostPutModuleRequest) => {
        return this.pvMasterdataService.pvModulePutModule(payload).pipe(
          map((viewModel: any) => {
            return new MasterdataAction.EditModuleSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.EditModuleFailedAction(e)))
        );
      })
    )
  );

  moduleDelete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.DELETE_MODULE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.DeleteModuleAction) => action.payload),
      switchMap((payload: string) => {
        return this.pvMasterdataService.pvModuleDeleteModule(payload).pipe(
          map(() => {
            return new MasterdataAction.DeleteModuleSuccessAction(payload);
          }),
          catchError((e: any) => of(new MasterdataAction.DeleteModuleFailedAction(e)))
        );
      })
    )
  );

  storagesLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.GET_STORAGES),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.GetStoragesAction) => action.payload),
      switchMap(() => {
        return this.pvMasterdataService.pvStorageGetAllStorages().pipe(
          map((viewModel: any) => {
            return new MasterdataAction.GetStoragesSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.GetStoragesFailedAction(e)))
        );
      })
    )
  );

  storageLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.GET_STORAGE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.GetStorageAction) => action.payload),
      switchMap((payload: string) => {
        return this.pvMasterdataService.pvStorageGetStorage(payload).pipe(
          map((viewModel: any) => {
            return new MasterdataAction.GetStorageSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.GetStorageFailedAction(e)))
        );
      })
    )
  );

  storageAdd$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.ADD_STORAGE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.AddStorageAction) => action.payload),
      switchMap((payload: PostPutStorageRequest) => {
        return this.pvMasterdataService.pvStoragePostStorage(payload).pipe(
          map((viewModel: any) => {
            return new MasterdataAction.AddStorageSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.AddStorageFailedAction(e)))
        );
      })
    )
  );

  storageEdit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.EDIT_STORAGE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.EditStorageAction) => action.payload),
      switchMap((payload: PostPutStorageRequest) => {
        return this.pvMasterdataService.pvStoragePutStorage(payload).pipe(
          map((viewModel: any) => {
            return new MasterdataAction.EditStorageSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.EditStorageFailedAction(e)))
        );
      })
    )
  );

  storageDelete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.DELETE_STORAGE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.DeleteStorageAction) => action.payload),
      switchMap((payload: string) => {
        return this.pvMasterdataService.pvStorageDeleteStorage(payload).pipe(
          map(() => {
            return new MasterdataAction.DeleteStorageSuccessAction(payload);
          }),
          catchError((e: any) => of(new MasterdataAction.DeleteStorageFailedAction(e)))
        );
      })
    )
  );

  shadingOptimizersLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.GET_SHADINGOPTIMIZERS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.GetShadingOptimizersAction) => action.payload),
      switchMap(() => {
        return this.pvMasterdataService.pvShadingOptimizerGetAllShadingOptimizers().pipe(
          map((viewModel: any) => {
            return new MasterdataAction.GetShadingOptimizersSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.GetShadingOptimizersFailedAction(e)))
        );
      })
    )
  );

  shadingOptimizerLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.GET_SHADINGOPTIMIZER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.GetShadingOptimizerAction) => action.payload),
      switchMap((payload: string) => {
        return this.pvMasterdataService.pvShadingOptimizerGetShadingOptimizer(payload).pipe(
          map((viewModel: any) => {
            return new MasterdataAction.GetShadingOptimizerSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.GetShadingOptimizerFailedAction(e)))
        );
      })
    )
  );

  shadingOptimizerAdd$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.ADD_SHADINGOPTIMIZER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.AddShadingOptimizerAction) => action.payload),
      switchMap((payload: PostPutShadingOptimizerRequest) => {
        return this.pvMasterdataService.pvShadingOptimizerPostShadingOptimizer(payload).pipe(
          map((viewModel: any) => {
            return new MasterdataAction.AddShadingOptimizerSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.AddShadingOptimizerFailedAction(e)))
        );
      })
    )
  );

  shadingOptimizerEdit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.EDIT_SHADINGOPTIMIZER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.EditShadingOptimizerAction) => action.payload),
      switchMap((payload: PostPutShadingOptimizerRequest) => {
        return this.pvMasterdataService.pvShadingOptimizerPutShadingOptimizer(payload).pipe(
          map((viewModel: any) => {
            return new MasterdataAction.EditShadingOptimizerSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.EditShadingOptimizerFailedAction(e)))
        );
      })
    )
  );

  shadingOptimizerDelete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.DELETE_SHADINGOPTIMIZER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.DeleteShadingOptimizerAction) => action.payload),
      switchMap((payload: string) => {
        return this.pvMasterdataService.pvShadingOptimizerDeleteShadingOptimizer(payload).pipe(
          map(() => {
            return new MasterdataAction.DeleteShadingOptimizerSuccessAction(payload);
          }),
          catchError((e: any) => of(new MasterdataAction.DeleteShadingOptimizerFailedAction(e)))
        );
      })
    )
  );

  invertersLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.GET_INVERTERS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.GetInvertersAction) => action.payload),
      switchMap(() => {
        return this.pvMasterdataService.pvInverterGetAllInverters().pipe(
          map((viewModel: any) => {
            return new MasterdataAction.GetInvertersSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.GetInvertersFailedAction(e)))
        );
      })
    )
  );

  inverterLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.GET_INVERTER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.GetInverterAction) => action.payload),
      switchMap((payload: string) => {
        return this.pvMasterdataService.pvInverterGetInverter(payload).pipe(
          map((viewModel: any) => {
            return new MasterdataAction.GetInverterSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.GetInverterFailedAction(e)))
        );
      })
    )
  );

  inverterAdd$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.ADD_INVERTER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.AddInverterAction) => action.payload),
      switchMap((payload: PostPutInverterRequest) => {
        return this.pvMasterdataService.pvInverterPostInverter(payload).pipe(
          map((viewModel: any) => {
            return new MasterdataAction.AddInverterSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.AddInverterFailedAction(e)))
        );
      })
    )
  );

  inverterEdit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.EDIT_INVERTER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.EditInverterAction) => action.payload),
      switchMap((payload: PostPutInverterRequest) => {
        return this.pvMasterdataService.pvInverterPutInverter(payload).pipe(
          map((viewModel: any) => {
            return new MasterdataAction.EditInverterSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new MasterdataAction.EditInverterFailedAction(e)))
        );
      })
    )
  );

  inverterDelete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterdataAction.DELETE_INVERTER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: MasterdataAction.DeleteInverterAction) => action.payload),
      switchMap((payload: string) => {
        return this.pvMasterdataService.pvInverterDeleteInverter(payload).pipe(
          map(() => {
            return new MasterdataAction.DeleteInverterSuccessAction(payload);
          }),
          catchError((e: any) => of(new MasterdataAction.DeleteInverterFailedAction(e)))
        );
      })
    )
  );

  saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MasterdataAction.ADD_MODULE_FAILED,
        MasterdataAction.EDIT_MODULE_FAILED,
        MasterdataAction.DELETE_MODULE_FAILED,
        MasterdataAction.ADD_STORAGE_FAILED,
        MasterdataAction.EDIT_STORAGE_FAILED,
        MasterdataAction.DELETE_STORAGE_FAILED,
        MasterdataAction.ADD_SHADINGOPTIMIZER_FAILED,
        MasterdataAction.EDIT_SHADINGOPTIMIZER_FAILED,
        MasterdataAction.DELETE_SHADINGOPTIMIZER_FAILED,
        MasterdataAction.ADD_INVERTER_FAILED,
        MasterdataAction.EDIT_INVERTER_FAILED,
        MasterdataAction.DELETE_INVERTER_FAILED
      ),
      map(
        (
          action:
            | EditModuleFailedAction
            | AddModuleFailedAction
            | DeleteModuleFailedAction
            | EditStorageFailedAction
            | AddStorageFailedAction
            | DeleteStorageFailedAction
            | EditShadingOptimizerFailedAction
            | AddShadingOptimizerFailedAction
            | DeleteShadingOptimizerFailedAction
            | EditInverterFailedAction
            | AddInverterFailedAction
            | DeleteInverterFailedAction
        ) => {
          // if (action.payload.status === 409) {
          // } else    //   return new ErrorAddAction({type: ErrorTypeE.SAVE, data: this.translate.instant('MANDATORS.ERROR.MANDATORNAME_EXISTS'), uiElement: UiElementTypeE.DIALOG});

          if (action.payload.error === undefined || action.payload.error === null) {
            const message: string = action.type.substr(action.type.indexOf(']') + 1);
            return new ErrorAddAction({
              type: ErrorTypeE.SAVE,
              data: message,
              uiElement: UiElementTypeE.DIALOG,
            });
          } else {
            return new ErrorAddAction({
              type: ErrorTypeE.SAVE,
              data:
                typeof action.payload.error === 'string'
                  ? action.payload.error
                  : action.payload.error.Message,
              uiElement: UiElementTypeE.DIALOG,
            });
          }
        }
      )
    )
  );

  loadFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MasterdataAction.GET_MODULES_FAILED,
        MasterdataAction.GET_MODULE_FAILED,
        MasterdataAction.GET_STORAGES_FAILED,
        MasterdataAction.GET_STORAGE_FAILED,
        MasterdataAction.GET_SHADINGOPTIMIZERS_FAILED,
        MasterdataAction.GET_SHADINGOPTIMIZER_FAILED,
        MasterdataAction.GET_INVERTERS_FAILED,
        MasterdataAction.GET_INVERTER_FAILED
      ),
      map(
        (
          action:
            | GetModulesFailedAction
            | GetModuleFailedAction
            | GetStoragesFailedAction
            | GetStorageFailedAction
            | GetShadingOptimizersFailedAction
            | GetShadingOptimizerFailedAction
            | GetInvertersFailedAction
            | GetInverterFailedAction
        ) => {
          if (action.payload.error === undefined || action.payload.error === null) {
            const message: string = action.type.substr(action.type.indexOf(']') + 1);
            return new ErrorAddAction({
              type: ErrorTypeE.LOAD,
              data: message,
              uiElement: UiElementTypeE.DIALOG,
            });
          } else {
            return new ErrorAddAction({
              type: ErrorTypeE.LOAD,
              data: action.payload.error,
              uiElement: UiElementTypeE.DIALOG,
            });
          }
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private pvMasterdataService: PvMasterDataService,
    private config: ConfigService
  ) {
    // empty
  }
}
