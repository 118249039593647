import { UntypedFormGroup } from '@angular/forms';
import { IFieldConfig } from './../../models/sharedInterfaces';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ac-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit {
  field: IFieldConfig;
  group: UntypedFormGroup;

  ngOnInit(): void {
    if (this.group.get(this.field.Name).value) {
      this.group
        .get(this.field.Name)
        .patchValue(this.group.get(this.field.Name).value.split(','), { onlySelf: true });
    }
  }
}
