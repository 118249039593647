import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MandatorRoutingModule } from './mandator-routing.module';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MandatorEffect } from './state/mandator.effects';
import { MandatorOverviewComponent } from './components/mandator-main/mandator-overview/mandator-overview.component';
import { MandatorFormComponent } from './components/mandator-main/mandator-edit/mandator-form.component';
import { MandatorMainComponent } from './components/mandator-main/mandator-main.component';
import * as MandatorReducer from './state/mandator.reducer';
import * as OrganisationUnitReducer from './state/organisation-unit.reducer';
import { MandatorTaskSettingsComponent } from './components/mandator-task-settings/mandator-task-settings.component';
import { OrganisationUnitFormComponent } from './components/organisation-unit/organisation-unit-form/organisation-unit-form.component';
import { OrganisationUnitOverviewComponent } from './components/organisation-unit/organisation-unit-overview/organisation-unit-overview.component';
import { OrganisationUnitEffect } from './state/organisation-unit.effects';

@NgModule({
  declarations: [
    MandatorOverviewComponent,
    MandatorFormComponent,
    MandatorMainComponent,
    MandatorTaskSettingsComponent,
    OrganisationUnitFormComponent,
    OrganisationUnitOverviewComponent,
  ],
  imports: [
    CommonModule,
    MandatorRoutingModule,
    SharedModule,
    StoreModule.forFeature('mandator', MandatorReducer.reducer),
    StoreModule.forFeature('organisationUnit', OrganisationUnitReducer.reducer),
    EffectsModule.forFeature([MandatorEffect]),
    EffectsModule.forFeature([OrganisationUnitEffect]),
  ],
})
export class MandatorModule {}
