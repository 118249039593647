/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PositionBuilderRequest { 
    /**
     * article number of this builder, substring search works
     */
    ArticleNumber?: string;
    /**
     * whether this builder should be added as an option
     */
    IsOption?: boolean;
    /**
     * whether this builder should be added as selected
     */
    IsSelected?: boolean;
    /**
     * the note of this builder, substring search works
     */
    Note?: string;
    /**
     * the property name of the top rule (ignoring sub rules). substring search works
     */
    TopRulePropertyName?: string;
    /**
     * only retrieve entries with a matching mandator id (you can provide multiple mandators by separating them with a ',' or ';')
     */
    MandatorIds?: string;
    /**
     * only retrieve entries with a matching productline ids (you can provide multiple productline ids by separating them with a ',' or ';')
     */
    ProductlineIds?: string;
    /**
     * the index of the current page(starts at 1)
     */
    PageIndex?: number;
    /**
     * the amount of entries per page
     */
    PageSize?: number;
    SortField?: PositionBuilderRequest.SortFieldEnum;
    SortOrder?: PositionBuilderRequest.SortOrderEnum;
}
export namespace PositionBuilderRequest {
    export type SortFieldEnum = 'ProductlineId' | 'ArticleNumber' | 'Order' | 'Amount' | 'IsOption' | 'IsSelected' | 'Note' | 'PositionGroupTemplateId';
    export const SortFieldEnum = {
        ProductlineId: 'ProductlineId' as SortFieldEnum,
        ArticleNumber: 'ArticleNumber' as SortFieldEnum,
        Order: 'Order' as SortFieldEnum,
        Amount: 'Amount' as SortFieldEnum,
        IsOption: 'IsOption' as SortFieldEnum,
        IsSelected: 'IsSelected' as SortFieldEnum,
        Note: 'Note' as SortFieldEnum,
        PositionGroupTemplateId: 'PositionGroupTemplateId' as SortFieldEnum
    };
    export type SortOrderEnum = 'None' | 'Asc' | 'Desc';
    export const SortOrderEnum = {
        None: 'None' as SortOrderEnum,
        Asc: 'Asc' as SortOrderEnum,
        Desc: 'Desc' as SortOrderEnum
    };
}
