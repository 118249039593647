/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface EmobilityMasterDataViewModel { 
    Manufacturers?: Array<string>;
    ChargingPlugTypes?: Array<string>;
    ChargingPlugPositionTypes?: Array<string>;
    VehicleTypes?: Array<string>;
    PoweringTypes?: Array<string>;
    ChargingStationManufacturers?: Array<string>;
    BusinessTypes?: Array<string>;
    ChargingStationTypes?: Array<string>;
    SmartEnergyTypes?: Array<string>;
    AutomaticPhaseChangeoverTypes?: Array<string>;
    AcConnectionCableTypes?: Array<string>;
    InternetConnectionTypes?: Array<string>;
    RfidAuthorisationTypes?: Array<string>;
    LoadManagementFunctionalityTypes?: Array<string>;
    DcConnectionCableTypes?: Array<string>;
    LoadingDirectionTypes?: Array<string>;
    IntegratedEnergyMeasurementBillingFunctionTypes?: Array<string>;
}
