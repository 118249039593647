import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { UserFormComponent } from './components/user-main/user-edit/user-form.component';
import { UserPictureComponent } from './components/user-main/user-edit/user-picture/user-picture.component';
import { UserMainComponent } from './components/user-main/user-main.component';
import { UserOverviewComponent } from './components/user-main/user-overview/user-overview.component';
import { UserEffects } from './state/user/user.effects';
import * as UserReducer from './state/user/user.reducer';
import * as RoleReducer from './state/role/role.reducer';
import { UserRoutingModule } from './user-routing.module';
import { RoleOverviewComponent } from './components/role-overview/role-overview.component';
import { RoleEditComponent } from './components/role-edit/role-edit.component';
import { RoleEffects } from './state/role/role.effects';

@NgModule({
  declarations: [
    UserOverviewComponent,
    UserFormComponent,
    UserMainComponent,
    UserPictureComponent,
    RoleOverviewComponent,
    RoleEditComponent,
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    SharedModule,
    StoreModule.forFeature('user', UserReducer.reducer),
    EffectsModule.forFeature([UserEffects]),
    StoreModule.forFeature('role', RoleReducer.reducer),
    EffectsModule.forFeature([RoleEffects]),
  ],
})
export class UserModule {}
