import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ac-ht-masterdata-main',
  templateUrl: './ht-masterdata-main.component.html',
  styleUrls: ['./ht-masterdata-main.component.sass'],
})
export class HtMasterdataMainComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
