/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface GenericPositionBuilderListViewModel { 
    /**
     * Identification of the position builder rule
     */
    Id?: number;
    /**
     * Productline id of the builder
     */
    ProductlineId?: number;
    /**
     * Article number of the builder
     */
    ArticleNumber?: string;
    /**
     * A short description of the builder's rule
     */
    Note?: string;
    /**
     * A short, readonly summary of the builder's rule
     */
    Summary?: string;
    /**
     * Apply order of the builder
     */
    Order?: number;
}
