/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PostPutChargingStationRequest { 
    Id?: string;
    ArticleNumber: string;
    Manufacturer: string;
    IsActive?: boolean;
    BusinessType?: string;
    ChargingStationTypes: Array<string>;
    MaximumChargingPower?: number;
    SmartEnergy: string;
    AutomaticPhaseChangeover: string;
    AcConnectionCable: string;
    InternetConnections: Array<string>;
    RfidAuthorisation: string;
    LoadManagementFunctionality: string;
    DcConnectionCable?: string;
    LoadingDirectionType?: string;
    IntegratedEnergyMeasurementBillingFunction?: string;
    MandatorIds: Array<number>;
    ArViewerUrl?: string;
}
