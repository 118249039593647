/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DomesticWaterHeatingListViewModel } from '../model/domesticWaterHeatingListViewModel';
import { DomesticWaterHeatingViewModel } from '../model/domesticWaterHeatingViewModel';
import { HeatGeneratorListViewModel } from '../model/heatGeneratorListViewModel';
import { HeatGeneratorViewModel } from '../model/heatGeneratorViewModel';
import { PostPutDomesticWaterHeatingRequest } from '../model/postPutDomesticWaterHeatingRequest';
import { PostPutHeatGeneratorRequest } from '../model/postPutHeatGeneratorRequest';
import { PostPutWaterTankRequest } from '../model/postPutWaterTankRequest';
import { WaterTankListViewModel } from '../model/waterTankListViewModel';
import { WaterTankViewModel } from '../model/waterTankViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class HeatingMasterDataService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletes a domestic water heating  Be aware that domestic water heatings can only be deleted if they are no longer used anywhere
     * 
     * @param id the id of the domestic water heating
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingDomesticWaterHeatingsDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public heatingDomesticWaterHeatingsDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public heatingDomesticWaterHeatingsDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public heatingDomesticWaterHeatingsDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling heatingDomesticWaterHeatingsDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/ht/masterdata/domesticwaterheatings/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all existing domestic water heatings
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingDomesticWaterHeatingsGetAll(observe?: 'body', reportProgress?: boolean): Observable<Array<DomesticWaterHeatingListViewModel>>;
    public heatingDomesticWaterHeatingsGetAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DomesticWaterHeatingListViewModel>>>;
    public heatingDomesticWaterHeatingsGetAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DomesticWaterHeatingListViewModel>>>;
    public heatingDomesticWaterHeatingsGetAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DomesticWaterHeatingListViewModel>>(`${this.basePath}/api/admin/ht/masterdata/domesticwaterheatings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific domestic water heating by id, including additional data like usages
     * 
     * @param id the id of the domestic water heating
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingDomesticWaterHeatingsGetById(id: string, observe?: 'body', reportProgress?: boolean): Observable<DomesticWaterHeatingViewModel>;
    public heatingDomesticWaterHeatingsGetById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DomesticWaterHeatingViewModel>>;
    public heatingDomesticWaterHeatingsGetById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DomesticWaterHeatingViewModel>>;
    public heatingDomesticWaterHeatingsGetById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling heatingDomesticWaterHeatingsGetById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<DomesticWaterHeatingViewModel>(`${this.basePath}/api/admin/ht/masterdata/domesticwaterheatings/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a domestic water heating
     * 
     * @param body the new domestic water heating
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingDomesticWaterHeatingsPost(body?: PostPutDomesticWaterHeatingRequest, observe?: 'body', reportProgress?: boolean): Observable<DomesticWaterHeatingViewModel>;
    public heatingDomesticWaterHeatingsPost(body?: PostPutDomesticWaterHeatingRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DomesticWaterHeatingViewModel>>;
    public heatingDomesticWaterHeatingsPost(body?: PostPutDomesticWaterHeatingRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DomesticWaterHeatingViewModel>>;
    public heatingDomesticWaterHeatingsPost(body?: PostPutDomesticWaterHeatingRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DomesticWaterHeatingViewModel>(`${this.basePath}/api/admin/ht/masterdata/domesticwaterheatings`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a domestic water heating
     * 
     * @param id the id of the domestic water heating
     * @param body the new domestic water heating data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingDomesticWaterHeatingsPut(id: string, body?: PostPutDomesticWaterHeatingRequest, observe?: 'body', reportProgress?: boolean): Observable<DomesticWaterHeatingViewModel>;
    public heatingDomesticWaterHeatingsPut(id: string, body?: PostPutDomesticWaterHeatingRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DomesticWaterHeatingViewModel>>;
    public heatingDomesticWaterHeatingsPut(id: string, body?: PostPutDomesticWaterHeatingRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DomesticWaterHeatingViewModel>>;
    public heatingDomesticWaterHeatingsPut(id: string, body?: PostPutDomesticWaterHeatingRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling heatingDomesticWaterHeatingsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DomesticWaterHeatingViewModel>(`${this.basePath}/api/admin/ht/masterdata/domesticwaterheatings/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a heat generator  Be aware that heat generators can only be deleted if they are no longer used anywhere
     * 
     * @param id the id of the heat generator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingHeatGeneratorsDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public heatingHeatGeneratorsDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public heatingHeatGeneratorsDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public heatingHeatGeneratorsDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling heatingHeatGeneratorsDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/ht/masterdata/heatgenerators/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all existing heat generators
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingHeatGeneratorsGetAll(observe?: 'body', reportProgress?: boolean): Observable<Array<HeatGeneratorListViewModel>>;
    public heatingHeatGeneratorsGetAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HeatGeneratorListViewModel>>>;
    public heatingHeatGeneratorsGetAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HeatGeneratorListViewModel>>>;
    public heatingHeatGeneratorsGetAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<HeatGeneratorListViewModel>>(`${this.basePath}/api/admin/ht/masterdata/heatgenerators`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific heat generator by id, including additional data like usages
     * 
     * @param id the id of the heat generator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingHeatGeneratorsGetById(id: string, observe?: 'body', reportProgress?: boolean): Observable<HeatGeneratorViewModel>;
    public heatingHeatGeneratorsGetById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HeatGeneratorViewModel>>;
    public heatingHeatGeneratorsGetById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HeatGeneratorViewModel>>;
    public heatingHeatGeneratorsGetById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling heatingHeatGeneratorsGetById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<HeatGeneratorViewModel>(`${this.basePath}/api/admin/ht/masterdata/heatgenerators/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a heat generator
     * 
     * @param body the new heat generator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingHeatGeneratorsPost(body?: PostPutHeatGeneratorRequest, observe?: 'body', reportProgress?: boolean): Observable<HeatGeneratorViewModel>;
    public heatingHeatGeneratorsPost(body?: PostPutHeatGeneratorRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HeatGeneratorViewModel>>;
    public heatingHeatGeneratorsPost(body?: PostPutHeatGeneratorRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HeatGeneratorViewModel>>;
    public heatingHeatGeneratorsPost(body?: PostPutHeatGeneratorRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<HeatGeneratorViewModel>(`${this.basePath}/api/admin/ht/masterdata/heatgenerators`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a heat generator
     * 
     * @param id the id of the heat generator
     * @param body the new heat generator data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingHeatGeneratorsPut(id: string, body?: PostPutHeatGeneratorRequest, observe?: 'body', reportProgress?: boolean): Observable<HeatGeneratorViewModel>;
    public heatingHeatGeneratorsPut(id: string, body?: PostPutHeatGeneratorRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HeatGeneratorViewModel>>;
    public heatingHeatGeneratorsPut(id: string, body?: PostPutHeatGeneratorRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HeatGeneratorViewModel>>;
    public heatingHeatGeneratorsPut(id: string, body?: PostPutHeatGeneratorRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling heatingHeatGeneratorsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<HeatGeneratorViewModel>(`${this.basePath}/api/admin/ht/masterdata/heatgenerators/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a water tank  Be aware that water tanks can only be deleted if they are no longer used anywhere
     * 
     * @param id the id of the water tank
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingWaterTanksDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public heatingWaterTanksDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public heatingWaterTanksDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public heatingWaterTanksDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling heatingWaterTanksDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/ht/masterdata/watertanks/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all existing water tanks
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingWaterTanksGetAll(observe?: 'body', reportProgress?: boolean): Observable<Array<WaterTankListViewModel>>;
    public heatingWaterTanksGetAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterTankListViewModel>>>;
    public heatingWaterTanksGetAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterTankListViewModel>>>;
    public heatingWaterTanksGetAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterTankListViewModel>>(`${this.basePath}/api/admin/ht/masterdata/watertanks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific water tank by id, including additional data like usages
     * 
     * @param id the id of the water tank
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingWaterTanksGetById(id: string, observe?: 'body', reportProgress?: boolean): Observable<WaterTankViewModel>;
    public heatingWaterTanksGetById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterTankViewModel>>;
    public heatingWaterTanksGetById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterTankViewModel>>;
    public heatingWaterTanksGetById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling heatingWaterTanksGetById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<WaterTankViewModel>(`${this.basePath}/api/admin/ht/masterdata/watertanks/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a water tank
     * 
     * @param body the new water tank
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingWaterTanksPost(body?: PostPutWaterTankRequest, observe?: 'body', reportProgress?: boolean): Observable<WaterTankViewModel>;
    public heatingWaterTanksPost(body?: PostPutWaterTankRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterTankViewModel>>;
    public heatingWaterTanksPost(body?: PostPutWaterTankRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterTankViewModel>>;
    public heatingWaterTanksPost(body?: PostPutWaterTankRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<WaterTankViewModel>(`${this.basePath}/api/admin/ht/masterdata/watertanks`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a water tank
     * 
     * @param id the id of the water tank
     * @param body the new water tank data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingWaterTanksPut(id: string, body?: PostPutWaterTankRequest, observe?: 'body', reportProgress?: boolean): Observable<WaterTankViewModel>;
    public heatingWaterTanksPut(id: string, body?: PostPutWaterTankRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterTankViewModel>>;
    public heatingWaterTanksPut(id: string, body?: PostPutWaterTankRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterTankViewModel>>;
    public heatingWaterTanksPut(id: string, body?: PostPutWaterTankRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling heatingWaterTanksPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<WaterTankViewModel>(`${this.basePath}/api/admin/ht/masterdata/watertanks/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
