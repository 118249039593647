import * as MasterdataAction from './masterdata.action';
import {
  AddInverterAction,
  AddInverterFailedAction,
  AddInverterSuccessAction,
  AddModuleAction,
  AddModuleFailedAction,
  AddModuleSuccessAction,
  AddShadingOptimizerAction,
  AddShadingOptimizerFailedAction,
  AddShadingOptimizerSuccessAction,
  AddStorageAction,
  AddStorageFailedAction,
  AddStorageSuccessAction,
  DeleteInverterAction,
  DeleteInverterFailedAction,
  DeleteInverterSuccessAction,
  DeleteModuleAction,
  DeleteModuleFailedAction,
  DeleteModuleSuccessAction,
  DeleteShadingOptimizerAction,
  DeleteShadingOptimizerFailedAction,
  DeleteShadingOptimizerSuccessAction,
  DeleteStorageAction,
  DeleteStorageFailedAction,
  DeleteStorageSuccessAction,
  EditInverterAction,
  EditInverterFailedAction,
  EditInverterSuccessAction,
  EditModuleAction,
  EditModuleFailedAction,
  EditModuleSuccessAction,
  EditShadingOptimizerAction,
  EditShadingOptimizerFailedAction,
  EditShadingOptimizerSuccessAction,
  EditStorageAction,
  EditStorageFailedAction,
  EditStorageSuccessAction,
  GetInverterAction,
  GetInverterFailedAction,
  GetInvertersAction,
  GetInvertersFailedAction,
  GetInvertersSuccessAction,
  GetInverterSuccessAction,
  GetModuleAction,
  GetModuleFailedAction,
  GetModulesAction,
  GetModulesFailedAction,
  GetModulesSuccessAction,
  GetModuleSuccessAction,
  GetShadingOptimizerAction,
  GetShadingOptimizerFailedAction,
  GetShadingOptimizersAction,
  GetShadingOptimizersFailedAction,
  GetShadingOptimizersSuccessAction,
  GetShadingOptimizerSuccessAction,
  GetStorageAction,
  GetStorageFailedAction,
  GetStoragesAction,
  GetStoragesFailedAction,
  GetStoragesSuccessAction,
  GetStorageSuccessAction,
} from './masterdata.action';
import { initPvMasterdataState, IPvMasterdataState } from './masterdata.state';
import { tassign } from 'tassign';
import {
  InverterListViewModel,
  ModuleListViewModel,
  ModuleViewModel,
  ShadingOptimizerListViewModel,
  StorageListViewModel,
} from '../../shared/apis/advis';
import cloneDeep from 'lodash/cloneDeep';

export function reducer(
  state: IPvMasterdataState = initPvMasterdataState(),
  action: MasterdataAction.Actions
): IPvMasterdataState {
  if (action instanceof GetModulesAction) {
    return tassign(state, {
      modulesLoading: true,
      modulesLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetModulesSuccessAction) {
    return tassign(state, {
      modules: action.payload,
      modulesLoading: false,
      modulesLoaded: true,
    } as IPvMasterdataState);
  }

  if (action instanceof GetModulesFailedAction) {
    return tassign(state, {
      modules: [],
      modulesLoading: false,
      modulesLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetModuleAction) {
    return tassign(state, {
      modulesLoading: true,
      modulesLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetModuleSuccessAction) {
    return tassign(state, {
      modulesLoading: false,
      modulesLoaded: true,
    } as IPvMasterdataState);
  }

  if (action instanceof GetModuleFailedAction) {
    return tassign(state, {
      modulesLoading: false,
      modulesLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof AddModuleAction) {
    return tassign(state, {
      modulesLoading: true,
      modulesLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof AddModuleSuccessAction) {
    return tassign(state, {
      modulesLoading: false,
      modulesLoaded: true,
      modules: updateModules(action.payload),
    } as IPvMasterdataState);
  }

  if (action instanceof AddModuleFailedAction) {
    return tassign(state, {
      modulesLoading: false,
      modulesLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof EditModuleAction) {
    return tassign(state, {
      modulesLoading: true,
      modulesLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof EditModuleSuccessAction) {
    return tassign(state, {
      modulesLoading: false,
      modulesLoaded: true,
      modules: updateModules(action.payload),
    } as IPvMasterdataState);
  }

  if (action instanceof EditModuleFailedAction) {
    return tassign(state, {
      modulesLoading: false,
      modulesLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof DeleteModuleAction) {
    return tassign(state, {
      modulesLoading: true,
      modulesLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof DeleteModuleSuccessAction) {
    return tassign(state, {
      modulesLoading: false,
      modulesLoaded: true,
      modules: updateModulesAfterDelete(action.payload),
    } as IPvMasterdataState);
  }

  if (action instanceof DeleteModuleFailedAction) {
    return tassign(state, {
      modulesLoading: false,
      modulesLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetStoragesAction) {
    return tassign(state, {
      storageLoading: true,
      storageLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetStoragesSuccessAction) {
    return tassign(state, {
      storage: action.payload,
      storageLoading: false,
      storageLoaded: true,
    } as IPvMasterdataState);
  }

  if (action instanceof GetStoragesFailedAction) {
    return tassign(state, {
      storage: [],
      storageLoading: false,
      storageLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetStorageAction) {
    return tassign(state, {
      storageLoading: true,
      storageLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetStorageSuccessAction) {
    return tassign(state, {
      storageLoading: false,
      storageLoaded: true,
    } as IPvMasterdataState);
  }

  if (action instanceof GetStorageFailedAction) {
    return tassign(state, {
      storageLoading: false,
      storageLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof AddStorageAction) {
    return tassign(state, {
      storageLoading: true,
      storageLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof AddStorageSuccessAction) {
    return tassign(state, {
      storageLoading: false,
      storageLoaded: true,
      storage: updateStorage(action.payload),
    } as IPvMasterdataState);
  }

  if (action instanceof AddStorageFailedAction) {
    return tassign(state, {
      storageLoading: false,
      storageLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof EditStorageAction) {
    return tassign(state, {
      storageLoading: true,
      storageLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof EditStorageSuccessAction) {
    return tassign(state, {
      storageLoading: false,
      storageLoaded: true,
      storage: updateStorage(action.payload),
    } as IPvMasterdataState);
  }

  if (action instanceof EditStorageFailedAction) {
    return tassign(state, {
      storageLoading: false,
      storageLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof DeleteStorageAction) {
    return tassign(state, {
      storageLoading: true,
      storageLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof DeleteStorageSuccessAction) {
    return tassign(state, {
      storageLoading: false,
      storageLoaded: true,
      storage: updateStoragesAfterDelete(action.payload),
    } as IPvMasterdataState);
  }

  if (action instanceof DeleteStorageFailedAction) {
    return tassign(state, {
      storageLoading: false,
      storageLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetShadingOptimizersAction) {
    return tassign(state, {
      shadingOptimizerLoading: true,
      shadingOptimizerLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetShadingOptimizersSuccessAction) {
    return tassign(state, {
      shadingOptimizers: action.payload,
      shadingOptimizerLoading: false,
      shadingOptimizerLoaded: true,
    } as IPvMasterdataState);
  }

  if (action instanceof GetShadingOptimizersFailedAction) {
    return tassign(state, {
      shadingOptimizers: [],
      shadingOptimizerLoading: false,
      shadingOptimizerLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetShadingOptimizerAction) {
    return tassign(state, {
      shadingOptimizerLoading: true,
      shadingOptimizerLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetShadingOptimizerSuccessAction) {
    return tassign(state, {
      shadingOptimizerLoading: false,
      shadingOptimizerLoaded: true,
    } as IPvMasterdataState);
  }

  if (action instanceof GetShadingOptimizerFailedAction) {
    return tassign(state, {
      shadingOptimizerLoading: false,
      shadingOptimizerLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof AddShadingOptimizerAction) {
    return tassign(state, {
      shadingOptimizerLoading: true,
      shadingOptimizerLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof AddShadingOptimizerSuccessAction) {
    return tassign(state, {
      shadingOptimizerLoading: false,
      shadingOptimizerLoaded: true,
      shadingOptimizers: updateShadingOptimizer(action.payload),
    } as IPvMasterdataState);
  }

  if (action instanceof AddShadingOptimizerFailedAction) {
    return tassign(state, {
      shadingOptimizerLoading: false,
      shadingOptimizerLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof EditShadingOptimizerAction) {
    return tassign(state, {
      shadingOptimizerLoading: true,
      shadingOptimizerLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof EditShadingOptimizerSuccessAction) {
    return tassign(state, {
      shadingOptimizerLoading: false,
      shadingOptimizerLoaded: true,
      shadingOptimizers: updateShadingOptimizer(action.payload),
    } as IPvMasterdataState);
  }

  if (action instanceof EditShadingOptimizerFailedAction) {
    return tassign(state, {
      shadingOptimizerLoading: false,
      shadingOptimizerLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof DeleteShadingOptimizerAction) {
    return tassign(state, {
      shadingOptimizerLoading: true,
      shadingOptimizerLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof DeleteShadingOptimizerSuccessAction) {
    return tassign(state, {
      shadingOptimizerLoading: false,
      shadingOptimizerLoaded: true,
      shadingOptimizers: updateShadingOptimizersAfterDelete(action.payload),
    } as IPvMasterdataState);
  }

  if (action instanceof DeleteShadingOptimizerFailedAction) {
    return tassign(state, {
      shadingOptimizerLoading: false,
      shadingOptimizerLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetInvertersAction) {
    return tassign(state, {
      inverterLoading: true,
      inverterLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetInvertersSuccessAction) {
    return tassign(state, {
      inverter: action.payload,
      inverterLoading: false,
      inverterLoaded: true,
    } as IPvMasterdataState);
  }

  if (action instanceof GetInvertersFailedAction) {
    return tassign(state, {
      inverter: [],
      inverterLoading: false,
      inverterLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetInverterAction) {
    return tassign(state, {
      inverterLoading: true,
      inverterLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof GetInverterSuccessAction) {
    return tassign(state, {
      inverterLoading: false,
      inverterLoaded: true,
    } as IPvMasterdataState);
  }

  if (action instanceof GetInverterFailedAction) {
    return tassign(state, {
      inverterLoading: false,
      inverterLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof AddInverterAction) {
    return tassign(state, {
      inverterLoading: true,
      inverterLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof AddInverterSuccessAction) {
    return tassign(state, {
      inverterLoading: false,
      inverterLoaded: true,
      inverter: updateInverter(action.payload),
    } as IPvMasterdataState);
  }

  if (action instanceof AddInverterFailedAction) {
    return tassign(state, {
      inverterLoading: false,
      inverterLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof EditInverterAction) {
    return tassign(state, {
      inverterLoading: true,
      inverterLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof EditInverterSuccessAction) {
    return tassign(state, {
      inverterLoading: false,
      inverterLoaded: true,
      inverter: updateInverter(action.payload),
    } as IPvMasterdataState);
  }

  if (action instanceof EditInverterFailedAction) {
    return tassign(state, {
      inverterLoading: false,
      inverterLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof DeleteInverterAction) {
    return tassign(state, {
      inverterLoading: true,
      inverterLoaded: false,
    } as IPvMasterdataState);
  }

  if (action instanceof DeleteInverterSuccessAction) {
    return tassign(state, {
      inverterLoading: false,
      inverterLoaded: true,
      inverter: updateInvertersAfterDelete(action.payload),
    } as IPvMasterdataState);
  }

  if (action instanceof DeleteInverterFailedAction) {
    return tassign(state, {
      inverterLoading: false,
      inverterLoaded: false,
    } as IPvMasterdataState);
  }

  function updateModules(module: ModuleViewModel): ModuleListViewModel[] {
    let modules: ModuleListViewModel[] = cloneDeep(state.modules);
    return modules.map((x: ModuleListViewModel) => {
      if (x.Id !== module.Id) {
        return x;
      }
      return {
        Description: module.Description,
        Id: module.Id,
        IsActive: module.IsActive,
      } as ModuleListViewModel;
    });
  }

  function updateStorage(storage: ModuleViewModel): StorageListViewModel[] {
    let storages: StorageListViewModel[] = cloneDeep(state.storage);
    return storages.map((x: StorageListViewModel) => {
      if (x.Id !== storage.Id) {
        return x;
      }
      return {
        Description: storage.Description,
        Id: storage.Id,
        IsActive: storage.IsActive,
      } as StorageListViewModel;
    });
  }

  function updateInverter(inverter: ModuleViewModel): InverterListViewModel[] {
    let inverters: InverterListViewModel[] = cloneDeep(state.inverter);
    return inverters.map((x: InverterListViewModel) => {
      if (x.Id !== inverter.Id) {
        return x;
      }
      return {
        Description: inverter.Description,
        Id: inverter.Id,
        IsActive: inverter.IsActive,
      } as InverterListViewModel;
    });
  }

  function updateShadingOptimizer(
    shadingOptimizers: ModuleViewModel
  ): ShadingOptimizerListViewModel[] {
    let shadingOptimizerss: ShadingOptimizerListViewModel[] = cloneDeep(state.shadingOptimizers);
    return shadingOptimizerss.map((x: ShadingOptimizerListViewModel) => {
      if (x.Id !== shadingOptimizers.Id) {
        return x;
      }
      return {
        Description: shadingOptimizers.Description,
        Id: shadingOptimizers.Id,
        IsActive: shadingOptimizers.IsActive,
      } as ShadingOptimizerListViewModel;
    });
  }

  function updateModulesAfterDelete(moduleId: string): ModuleListViewModel[] {
    let modules: ModuleListViewModel[] = cloneDeep(state.modules);
    let returnVal: ModuleListViewModel[] = [];
    modules.forEach((x: ModuleListViewModel) => {
      if (x.Id !== moduleId) {
        returnVal.push(x);
      }
    });

    return returnVal;
  }

  function updateStoragesAfterDelete(storageId: string): StorageListViewModel[] {
    let storage: StorageListViewModel[] = cloneDeep(state.storage);
    let returnVal: StorageListViewModel[] = [];
    storage.forEach((x: StorageListViewModel) => {
      if (x.Id !== storageId) {
        returnVal.push(x);
      }
    });

    return returnVal;
  }

  function updateInvertersAfterDelete(inverterId: string): InverterListViewModel[] {
    let inverter: InverterListViewModel[] = cloneDeep(state.inverter);
    let returnVal: InverterListViewModel[] = [];
    inverter.forEach((x: InverterListViewModel) => {
      if (x.Id !== inverterId) {
        returnVal.push(x);
      }
    });

    return returnVal;
  }

  function updateShadingOptimizersAfterDelete(
    shadingOptimizerId: string
  ): ShadingOptimizerListViewModel[] {
    let shadingOptimizer: ShadingOptimizerListViewModel[] = cloneDeep(state.shadingOptimizers);
    let returnVal: ShadingOptimizerListViewModel[] = [];
    shadingOptimizer.forEach((x: ShadingOptimizerListViewModel) => {
      if (x.Id !== shadingOptimizerId) {
        returnVal.push(x);
      }
    });

    return returnVal;
  }

  return state;
}
