// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { EnvironmentTypeEnum, IEnvironmentInterface } from './environment.interface';

/* eslint-disable @typescript-eslint/no-require-imports */
export const environment: IEnvironmentInterface = {
  production: false,
  apiBasePath: 'https://admin-api-dev.offerten-rechner.ch',
  protectDevelopmentPage: false,
  versionInfo: 'Azure-Dev: ' + require('../../package.json').version,
  version: require('../../package.json').version,
  dropboxApiKey: 'Wk4ppSVjl2AAAAAAAAARCmbmQeR8Xs5AvLlZ8-qpxF918JX17RDcYpF0t_jTKvn8',
  dropboxApiEnv: 'DEV',
  googleMapsApiKey: 'AIzaSyBpZYbrftI-ohPhVbwAD6eySG8LcXvOlO4',
  googleMapsApiKeyNEW: 'AIzaSyC7mk_EyPLGAumjCrLu32r4P08e-0C1v3U',
  veseApiKey: '102x9gqm80cyff0cf9xdrc888dsf7gu1ywndc6qa',
  instrumentStoreDevtools: true,
  environmentType: EnvironmentTypeEnum.DEV,
  checkVersionUrl: 'version.json',
};
