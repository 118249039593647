/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AggregateRuleViewModel } from './aggregateRuleViewModel';
import { BooleanRuleViewModel } from './booleanRuleViewModel';
import { DateTimeRuleViewModel } from './dateTimeRuleViewModel';
import { DecimalRuleViewModel } from './decimalRuleViewModel';
import { IntegerRuleViewModel } from './integerRuleViewModel';
import { MultiStringRuleViewModel } from './multiStringRuleViewModel';
import { StringRuleViewModel } from './stringRuleViewModel';


export interface PositionBuilderRuleViewModel { 
    StringRule?: StringRuleViewModel;
    MultiStringRule?: MultiStringRuleViewModel;
    DecimalRule?: DecimalRuleViewModel;
    IntegerRule?: IntegerRuleViewModel;
    BooleanRule?: BooleanRuleViewModel;
    DateTimeRule?: DateTimeRuleViewModel;
    AggregateRule?: AggregateRuleViewModel;
}
