/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StringGenerationBlock } from './stringGenerationBlock';


export interface StringGeneration { 
    RawData?: string;
    MandatorId?: string;
    Language?: string;
    Blocks?: Array<StringGenerationBlock>;
}
