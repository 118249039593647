import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DialogService } from '../../services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'ac-custom-file-upload',
  templateUrl: './custom-file-upload.component.html',
  styleUrls: ['./custom-file-upload.component.scss'],
})
export class CustomFileUploadComponent implements OnInit {
  @Input()
  accept: string = '';

  @Input()
  capture: string;

  @Input()
  isMultiple: boolean = false;

  @Input()
  iconName: string = 'cloud_upload';

  @Input()
  buttonColor: ThemePalette = 'accent';

  @Input()
  disabled: boolean = false;

  @Input()
  isIconButton: boolean = false;

  @Output()
  select: EventEmitter<File | FileList> = new EventEmitter<File | FileList>();

  @ViewChild('customFileUploadInput') customFileUploadInput: ElementRef;

  constructor(private dialogService: DialogService, private translate: TranslateService) {}

  ngOnInit(): void {
    if (this.capture) {
      this.accept = `${this.accept};capture=${this.capture}`;
    }
  }

  onBrowseFiles(): void {
    this.customFileUploadInput.nativeElement.click();
  }

  onFileSelect(evt: any): void {
    let fileList: FileList = evt.target.files;
    if (fileList.length > 0) {
      for (let i: number = 0; i < fileList.length; i++) {
        let file: File = fileList[i];
        if (!this.verifyAccept(file.type, this.accept)) {
          this.showWrongFileTypeWarningDialog();
          return;
        }
      }

      this.select.emit(fileList.length === 1 ? fileList[0] : fileList);
      this.customFileUploadInput.nativeElement.value = '';
    }
  }

  // Clear FileList on upload cancel
  cancelUpload(): void {
    this.customFileUploadInput.nativeElement.value = '';
  }

  verifyAccept(fileType: string, accept: string): boolean {
    const typeRegExp: RegExp = new RegExp(accept.replace(/\*/g, '.*').replace(/\,/g, '|'));
    return typeRegExp.test(fileType);
  }

  showWrongFileTypeWarningDialog(): void {
    this.dialogService.openAlert(
      `${this.translate.instant('DIALOG.FILE_UPLOAD.WRONG_FILE_TYPE')} ${this.accept}`
    );
  }
}
