import { Action } from '@ngrx/store';
import { MasterDataViewModel, UserMasterDataViewModel } from '../apis/advis';
import { IPageSettings } from '../interfaces/Settings';
import { ViewSettingModel } from '../models/view-setting/view-settings.model';
import { ViewSettingItem } from '../services/view-setting.service';
import { IActionSuccess, IErrorInfo } from './shared.reducer';

const prefix: string = '[Global]';

export const SUCCESS: string = `${prefix} success`;

export const SET_NAVIGATION_PROCESS: string = `${prefix} Set navigation process`;
export const ERROR_ADD: string = `${prefix} Error - Add`;
export const ERROR_CLEAR: string = `${prefix} Error - Clear`;

export const INIT_STATE: string = `${prefix} InitState - Success`;

export const MASTER_DATA_LOAD: string = `${prefix} MasterData - Load`;
export const MASTER_DATA_SUCCESS: string = `${prefix} MasterData - Load Success`;
export const MASTER_DATA_FAILURE: string = `${prefix} MasterData - Load Failure`;

export const ORGANISATION_UNIT_LOAD: string = `${prefix} Organisation units - Load`;
export const ORGANISATION_UNIT_LOAD_SUCCESS: string = `${prefix} Organisation units - Load Success`;
export const ORGANISATION_UNIT_LOAD_FAILURE: string = `${prefix} Organisation units - Load Failure`;

export const RESET_BO_CACHE: string = `${prefix} Bo Cache Reset`;
export const RESET_BO_CACHE_SUCCESS: string = `${prefix} Bo Cache Reset - Success`;
export const RESET_BO_CACHE_FAILURE: string = `${prefix} Bo Cache Reset - Failure`;

export const GET_GLOBAL_VIEW_SETTINGS: string = `${prefix} Global view settings - Load`;
export const GET_GLOBAL_VIEW_SETTINGS_SUCCESS: string = `${prefix} Global view settings - Load Success`;
export const GET_GLOBAL_VIEW_SETTINGS_FAILURE: string = `${prefix} Global view settings - Load Failure`;

export const SAVE_GLOBAL_VIEW_SETTINGS: string = `${prefix} Global view settings - Save`;
export const SAVE_GLOBAL_VIEW_SETTINGS_SUCCESS: string = `${prefix} Global view settings - Save Success`;
export const SAVE_GLOBAL_VIEW_SETTINGS_FAILURE: string = `${prefix} Global view settings - Save Failure`;

export const TOGGLE_SELECT_MULTIPLE_TRADETYPECODES: string = `${prefix} Toggle SelectMultipleTradetypeCodes`;

export const SET_TRADE_TYPE_CODE_FILTER_TO_ACTIVE: string = `${prefix} Set TradeTypeCodeFilterToActive`;

// get page settings
export const LOAD_PAGE_SETTINGS: string = `${prefix} ${prefix} Get the page settings`;
export const LOAD_PAGE_SETTINGS_SUCCESS: string = `${prefix} ${prefix} Get the page settings success`;
export const LOAD_PAGE_SETTINGS_FAILED: string = `${prefix} ${prefix} Get the page settings failed`;

// set page settings
export const SET_PAGE_SETTINGS: string = `${prefix} ${prefix} Set the page settings`;
export const SET_PAGE_SETTINGS_SUCCESS: string = `${prefix} ${prefix} Set the page settings success`;
export const SET_PAGE_SETTINGS_FAILED: string = `${prefix} ${prefix} Set the page settings failed`;

export class SetNavigationProcessAction implements Action {
  readonly type: string = SET_NAVIGATION_PROCESS;

  constructor(public payload: boolean) {}
}

export class MasterDataLoadAction implements Action {
  readonly type: string = MASTER_DATA_LOAD;

  constructor(public payload: any = undefined) {}
}

export class InitStateAction implements Action {
  readonly type: string = INIT_STATE;

  constructor(public payload: any = undefined) {}
}

export class MasterDataLoadSuccessAction implements Action {
  readonly type: string = MASTER_DATA_SUCCESS;

  constructor(public payload: MasterDataViewModel) {}
}

export class MasterDataLoadFailureAction implements Action {
  readonly type: string = MASTER_DATA_FAILURE;

  constructor(public payload: any) {}
}

export class OrganisationUnitsLoadAction implements Action {
  readonly type: string = ORGANISATION_UNIT_LOAD;

  constructor(public payload: any = undefined) {}
}

export class OrganisationUnitsLoadSuccessAction implements Action {
  readonly type: string = ORGANISATION_UNIT_LOAD_SUCCESS;

  constructor(public payload: UserMasterDataViewModel) {}
}

export class OrganisationUnitsLoadFailureAction implements Action {
  readonly type: string = ORGANISATION_UNIT_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class SuccessAction implements Action {
  readonly type: string = SUCCESS;

  constructor(public payload: IActionSuccess) {}
}

export class ErrorAddAction implements Action {
  readonly type: string = ERROR_ADD;

  constructor(public payload: IErrorInfo) {}
}

export class ErrorClearAction implements Action {
  readonly type: string = ERROR_CLEAR;

  constructor(public payload: any = undefined) {}
}

export class SussessAction implements Action {
  readonly type: string = SUCCESS;

  constructor(public payload: string) {}
}

export class ResetBoCacheAction implements Action {
  readonly type: string = RESET_BO_CACHE;

  constructor(public payload: any = undefined) {}
}

export class ResetBoCacheSuccessAction implements Action {
  readonly type: string = RESET_BO_CACHE_SUCCESS;

  constructor(public payload: any = undefined) {}
}

export class ResetBoCacheFailedAction implements Action {
  readonly type: string = RESET_BO_CACHE_FAILURE;

  constructor(public payload: any = undefined) {}
}

export class GetGlobalViewSettingsAction implements Action {
  readonly type: string = GET_GLOBAL_VIEW_SETTINGS;

  constructor(public payload: any = undefined) {}
}

export class GetGlobalViewSettingsSuccessAction implements Action {
  readonly type: string = GET_GLOBAL_VIEW_SETTINGS_SUCCESS;

  constructor(public payload: any = ViewSettingModel) {}
}

export class GetGlobalViewSettingsFailedAction implements Action {
  readonly type: string = GET_GLOBAL_VIEW_SETTINGS_FAILURE;

  constructor(public payload: any = undefined) {}
}

export class SaveGlobalViewSettingsAction<T> implements Action {
  readonly type: string = SAVE_GLOBAL_VIEW_SETTINGS;

  constructor(public payload: ViewSettingItem<T>) {}
}

export class SaveGlobalViewSettingsSuccessAction<T> implements Action {
  readonly type: string = SAVE_GLOBAL_VIEW_SETTINGS_SUCCESS;

  constructor(public payload: ViewSettingItem<T>) {}
}

export class SaveGlobalViewSettingsFailedAction implements Action {
  readonly type: string = SAVE_GLOBAL_VIEW_SETTINGS_FAILURE;

  constructor(public payload: any = undefined) {}
}

export class ToggleSelectMultipleTradetypeCodes implements Action {
  readonly type: string = TOGGLE_SELECT_MULTIPLE_TRADETYPECODES;

  constructor(public payload: boolean = undefined) {}
}

export class SetTradeTypeCodeFilterToActive implements Action {
  readonly type: string = SET_TRADE_TYPE_CODE_FILTER_TO_ACTIVE;

  constructor(public payload: boolean = undefined) {}
}

// get page settings ACTIONS
export class LoadPageSettingsAction implements Action {
  readonly type: string = LOAD_PAGE_SETTINGS;

  constructor(public payload: IPageSettings) {}
}

export class LoadPageSettingsSuccessAction implements Action {
  readonly type: string = LOAD_PAGE_SETTINGS_SUCCESS;

  constructor(public payload: any = undefined) {}
}

export class LoadPageSettingsFailedAction implements Action {
  readonly type: string = LOAD_PAGE_SETTINGS_FAILED;

  constructor(public payload: any = undefined) {}
}

// get page settings ACTIONS
export class SetPageSettingsAction implements Action {
  readonly type: string = SET_PAGE_SETTINGS;

  constructor(public payload: IPageSettings) {}
}

export class SetPageSettingsSuccessAction implements Action {
  readonly type: string = SET_PAGE_SETTINGS_SUCCESS;

  constructor(public payload: any = undefined) {}
}

export class SetPageSettingsFailedAction implements Action {
  readonly type: string = SET_PAGE_SETTINGS_FAILED;

  constructor(public payload: any = undefined) {}
}

export type SharedActions =
  | InitStateAction
  | MasterDataLoadAction
  | MasterDataLoadSuccessAction
  | MasterDataLoadFailureAction
  | SetNavigationProcessAction
  | OrganisationUnitsLoadAction
  | OrganisationUnitsLoadSuccessAction
  | OrganisationUnitsLoadFailureAction
  | ErrorAddAction
  | ErrorClearAction
  | ResetBoCacheAction
  | ResetBoCacheSuccessAction
  | ResetBoCacheFailedAction
  | GetGlobalViewSettingsAction
  | GetGlobalViewSettingsSuccessAction
  | GetGlobalViewSettingsFailedAction
  | SaveGlobalViewSettingsAction<any>
  | SaveGlobalViewSettingsSuccessAction<any>
  | SaveGlobalViewSettingsFailedAction
  | ToggleSelectMultipleTradetypeCodes
  | SetTradeTypeCodeFilterToActive
  | LoadPageSettingsAction
  | LoadPageSettingsSuccessAction
  | LoadPageSettingsFailedAction
  | SetPageSettingsAction
  | SetPageSettingsSuccessAction
  | SetPageSettingsFailedAction;
