/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TaskSettingViewModel { 
    Id?: number;
    TradeTypeCode?: string;
    TaskTitle: string;
    NotificationTime: Date;
    DaysOffset: number;
    TaskSettingType?: TaskSettingViewModel.TaskSettingTypeEnum;
}
export namespace TaskSettingViewModel {
    export type TaskSettingTypeEnum = 'FollowUpNbo' | 'FollowUpBo' | 'UnreachedFirstAttempt' | 'UnreachedSecondAttempt' | 'UnreachedThirdAttempt';
    export const TaskSettingTypeEnum = {
        FollowUpNbo: 'FollowUpNbo' as TaskSettingTypeEnum,
        FollowUpBo: 'FollowUpBo' as TaskSettingTypeEnum,
        UnreachedFirstAttempt: 'UnreachedFirstAttempt' as TaskSettingTypeEnum,
        UnreachedSecondAttempt: 'UnreachedSecondAttempt' as TaskSettingTypeEnum,
        UnreachedThirdAttempt: 'UnreachedThirdAttempt' as TaskSettingTypeEnum
    };
}
