/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ProjectChecklistTemplatesRequest { 
    Title?: string;
    /**
     * >only retrieve entries with a matching mandator id (you can provide multiple mandators by separating them with a ','
     */
    MandatorIds?: string;
    /**
     * only retrieve entries with a matching trade type code (you can provide multiple codes by separating them with a ',')
     */
    TradeTypeCodes?: string;
    /**
     * the index of the current page(starts at 1)
     */
    PageIndex?: number;
    /**
     * the amount of entries per page (15 per default)
     */
    PageSize?: number;
    SortField?: ProjectChecklistTemplatesRequest.SortFieldEnum;
    SortOrder?: ProjectChecklistTemplatesRequest.SortOrderEnum;
}
export namespace ProjectChecklistTemplatesRequest {
    export type SortFieldEnum = 'None' | 'Id' | 'Title' | 'TradeTypeCode';
    export const SortFieldEnum = {
        None: 'None' as SortFieldEnum,
        Id: 'Id' as SortFieldEnum,
        Title: 'Title' as SortFieldEnum,
        TradeTypeCode: 'TradeTypeCode' as SortFieldEnum
    };
    export type SortOrderEnum = 'None' | 'Asc' | 'Desc';
    export const SortOrderEnum = {
        None: 'None' as SortOrderEnum,
        Asc: 'Asc' as SortOrderEnum,
        Desc: 'Desc' as SortOrderEnum
    };
}
