import { Pipe, PipeTransform } from '@angular/core';
import { MandatorViewModel } from '../apis/advis';

@Pipe({ name: 'mandatorMap' })
export class MandatorMapPipe implements PipeTransform {
  constructor() {
    // empty
  }

  transform(mandatorMap: MandatorViewModel[]): any {
    return mandatorMap.map((mandator: MandatorViewModel) => mandator.Name).join(', ');
  }
}
