/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PostPutProjectChecklistTemplateRequest { 
    Title: string;
    TradeTypeCode: string;
    AvailableForSop?: boolean;
    SopListId?: number;
    Description?: string;
    IsActive?: boolean;
    MandatorIds: Array<number>;
}
