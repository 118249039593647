import { Injectable } from '@angular/core';
import { SignalRService } from './signalr-notification.service';
import { Observable } from 'rxjs';
import { ImportProgressMessage, NotificationMessages } from '../shared/apis/advis';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImportNotificationService {
  constructor(private signalRService: SignalRService) {}

  public onProjectReportingImportProgress(): Observable<ImportProgressMessage> {
    return this.signalRService
      .getSubscription<ImportProgressMessage>(NotificationMessages.ProjectReportingImportProgress)
      .pipe(switchMap(s => s.pipe(filter(l => !!l))));
  }
}
