import { LocalizedValueViewModel } from '../../apis/advis';

export function generateEmptyLocalizations(): LocalizedValueViewModel[] {
  return Object.keys(LocalizedValueViewModel.LanguageEnum).map(
    (k: string): LocalizedValueViewModel => ({
      Value: '',
      Language: LocalizedValueViewModel.LanguageEnum[k],
    })
  );
}
