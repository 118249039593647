/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GenericPropertyValueViewModel } from './genericPropertyValueViewModel';


export interface GenericProductlineListViewModel { 
    /**
     * Identification of the productline
     */
    Id?: number;
    /**
     * Mandator of the productline
     */
    MandatorId?: number;
    /**
     * Technical name
     */
    TechnicalName?: string;
    /**
     * Description of the productline
     */
    Description?: string;
    /**
     * whether the productline is active
     */
    IsActive?: boolean;
    /**
     * whether the productline is used for automatic matching (Nbo etc.)
     */
    UseForAutomaticMatch?: boolean;
    /**
     * The count of the fees this productline has
     */
    FeeCount?: number;
    /**
     * The count of the tarifs this productline has
     */
    TarifCount?: number;
    /**
     * whether the productline is in use by any variant
     */
    IsUsed?: boolean;
    /**
     * The generic properties of this productline
     */
    GenericProperties?: Array<GenericPropertyValueViewModel>;
}
