import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ac-masterdata-main',
  templateUrl: './masterdata-main.component.html',
  styleUrls: ['./masterdata-main.component.scss'],
})
export class MasterdataMainComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor() {
    // empty
  }

  ngOnInit(): void {
    // empty
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
