import { IViewSettingBase } from './view-setting-base.model';

export class UserOverviewFilterVsModel implements IViewSettingBase<UserOverviewFilterVsModel> {
  public username: string = '';
  public email: string = '';
  public name: string = '';
  public companyName: string = '';
  public city: string = '';
  public zipCode: string = '';
  public mandatorId: number = undefined;
  public organisationUnitId: number = undefined;
  public role: string = '';
  public isActive: boolean = undefined;

  clone(): UserOverviewFilterVsModel {
    const filter: UserOverviewFilterVsModel = new UserOverviewFilterVsModel();
    filter.username = this.username;
    filter.email = this.email;
    filter.name = this.name;
    filter.companyName = this.companyName;
    filter.city = this.city;
    filter.zipCode = this.zipCode;
    filter.mandatorId = this.mandatorId;
    filter.organisationUnitId = this.organisationUnitId;
    filter.role = this.role;
    filter.isActive = this.isActive;

    return filter;
  }
}

export class MandatorOverviewVsModel {
  public userFilter: UserOverviewFilterVsModel = new UserOverviewFilterVsModel();
  public userFilterVisible: boolean = false;
  public pageSize: number = 10;

  clone(): MandatorOverviewVsModel {
    const model: MandatorOverviewVsModel = new MandatorOverviewVsModel();
    model.userFilter = this.userFilter.clone();
    model.userFilterVisible = this.userFilterVisible;
    model.pageSize = this.pageSize;

    return model;
  }
}
