import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ac-user-picture',
  templateUrl: './user-picture.component.html',
  styleUrls: ['./user-picture.component.scss'],
})
export class UserPictureComponent {
  @Input() picture: File;
  @Input() pictureUrl: string;
  @Input() titleKey: string;
  @Output() pictureChange: EventEmitter<File> = new EventEmitter<File>();
  @Output() pictureUrlChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public sanitizer: DomSanitizer) {}

  onPictureSelect(logo: File | FileList): void {
    this.picture = logo instanceof FileList ? logo[0] : logo;
    this.pictureUrl = URL.createObjectURL(this.picture);
    this.onDelete.emit(false);
    this.pictureChange.emit(this.picture);
    this.pictureUrlChange.emit(this.pictureUrl);
  }

  onPictureDelete(): void {
    this.picture = undefined;
    this.pictureUrl = undefined;
    this.onDelete.emit(true);
  }
}
