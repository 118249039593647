import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';
import { MandatorViewModel } from '../apis/advis';
import { isNullOrUndefined } from './isNullOrUndefined';

export class CommonUtil {
  public static createDateAndAddDays(daysToAdd: number): Date {
    const date: Date = new Date();
    date.setDate(date.getDate() + daysToAdd);
    return date;
  }

  public static contains(searchString: string, filterString: string): boolean {
    return (
      !isNullOrUndefined(searchString) &&
      searchString.toLowerCase().indexOf(filterString.toLowerCase()) !== -1
    );
  }

  public static containsPhoneNumber(str1: string, str2: string): boolean {
    const nr1: string = str1.replace(/[^0-9]/g, '');
    const nr2: string = str2.replace(/[^0-9]/g, '');

    return nr2.length === 0 ? false : CommonUtil.contains(nr1, nr2);
  }

  public static getLanguageFromIndex(index: any): string {
    switch (index) {
      case 0:
        return 'DE';
      case 1:
        return 'FR';
      case 2:
        return 'IT';
      default:
        return undefined;
    }
  }

  public static getMandatorNamesForId(
    mandatorIds: number[],
    mandators: MandatorViewModel[]
  ): string {
    let tempMandators: string = mandators
      .filter((x: MandatorViewModel) => mandatorIds.indexOf(x.Id) !== -1)
      .map(
        (x: MandatorViewModel) =>
          `<span ${x.IsActive ? '' : 'class="deactivated-color"'}>${x.Name}</span>`
      )
      .join(', ');
    return tempMandators;
  }

  public static precisionRound(theNumber: number, precision: number): number {
    const factor: number = Math.pow(10, precision);
    return Math.round(theNumber * factor) / factor;
  }

  public static getInt32Max(): number {
    return Math.pow(2, 31) - 1;
  }

  public static getNotAvailable(): string {
    return 'n/a';
  }

  public static getBrowserLanguageCode(): string {
    return navigator.language;
  }

  public static reloadPage(): void {
    console.info('Perform page reload');
    location.reload();
  }

  /**
   * Trim all string properties of the passed object
   * @param obj
   */
  public static trimAllStrings(obj: any): any {
    const clone: any = cloneDeep(obj);
    forOwn(clone, (value: any, key: any) => {
      if (typeof value === 'string') {
        if (!isNullOrUndefined(value)) {
          clone[key] = value.trim();
        }
      }
    });
    return clone;
  }

  public static checkPropertiesForNullAndEmptyString(obj: any): boolean {
    for (let key in obj) {
      if (obj[key] !== null && obj[key] !== '') {
        return false;
      }
    }
    return true;
  }
}
