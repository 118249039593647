/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalizedValueViewModel } from './localizedValueViewModel';


export interface PostPutDocumentTypeRequest { 
    TechnicalName: string;
    MetaTag?: number;
    LocalizedNames: Array<LocalizedValueViewModel>;
    LocalizedTexts: Array<LocalizedValueViewModel>;
    LocalizedSources: Array<LocalizedValueViewModel>;
}
