import { Directive, HostListener, Renderer2 } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({ selector: '[acMatSelectNoneOption]' })
export class MatSelectNoneOptionDirective {
  constructor(private matSelect: MatSelect, private renderer: Renderer2) {}

  @HostListener('openedChange', ['$event'])
  onOpenedChange(isOpened: boolean) {
    if (!isOpened || this.matSelect.required) {
      return;
    }

    const matOption = this.renderer.createElement('mat-option');
    matOption.textContent = '';
    this.renderer.setAttribute(matOption, 'class', 'mat-option');

    this.renderer.listen(matOption, 'click', () => {
      this.matSelect.value = '';
      this.matSelect.close();
    });

    const panel = document.querySelector('.mat-mdc-select-panel');

    if (!panel) {
      throw new Error('Cannot find mat select panel');
    }

    this.renderer.insertBefore(panel, matOption, panel.firstChild);
  }
}
