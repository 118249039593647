import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  MandatorViewModel,
  PostPutStorageRequest,
  StorageViewModel,
} from '../../../../../shared/apis/advis';
import { IGuardDeactivation } from '../../../../../shared/interfaces/guard-deactivation';
import {
  getInverterManufacturers,
  getInverterTypes,
  getMandators,
} from '../../../../../shared/store';
import { IRootState } from '../../../../../root.state';
import { Store } from '@ngrx/store';
import { UntypedFormGroup } from '@angular/forms';
import {
  ADD_STORAGE_SUCCESS,
  AddStorageAction,
  EDIT_STORAGE_SUCCESS,
  EditStorageAction,
  GET_STORAGE_SUCCESS,
  GetStorageAction,
  GetStorageSuccessAction,
} from '../../../../state/masterdata.action';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import StorageTypeEnum = StorageViewModel.StorageTypeEnum;
import StorageChemistryEnum = PostPutStorageRequest.StorageChemistryEnum;
import { generateEmptyLocalizations } from '../../../../../shared/components/localized-values/generateEmptyLocalizations';
import { checkSpecialCharacters } from '../../../../../shared/utils/validation-util';

@Component({
  selector: 'ac-masterdata-storage-form',
  templateUrl: './masterdata-storages-form.component.html',
  styleUrls: ['./masterdata-storages-form.component.scss'],
})
export class MasterdataStoragesFormComponent implements OnInit, OnDestroy, IGuardDeactivation {
  private subscription: Subscription = new Subscription();

  storage: PostPutStorageRequest = {} as PostPutStorageRequest;
  initialStorage: PostPutStorageRequest = {} as PostPutStorageRequest;

  inverterTypes: string[];
  inverterManufacturers: string[];

  storageId: number;

  isNew: boolean = true;

  storageFormTitle: string = '';

  @ViewChild('storageForm')
  storageForm: UntypedFormGroup;

  mandators: MandatorViewModel[] = [];
  storageTypes: string[];
  storageChemistries: string[];

  constructor(
    private route: ActivatedRoute,
    private store: Store<IRootState>,
    private router: Router,
    private action$: Actions
  ) {
    // empty
  }

  ngOnInit(): void {
    let url$: Observable<UrlSegment[]> = this.route.url;
    let params$: Observable<Params> = this.route.params;
    const types$: Observable<string[]> = this.store.select(getInverterTypes);
    const manufacturers$: Observable<string[]> = this.store.select(getInverterManufacturers);

    this.subscription.add(
      combineLatest([url$, params$, manufacturers$, types$]).subscribe(
        ([url, params, manufacturers, types]: any) => {
          let urlPath: string = url[1].path.toString();

          this.inverterTypes = types;
          this.inverterManufacturers = manufacturers;

          if (urlPath === 'edit') {
            if (params && params.id) {
              this.store.dispatch(new GetStorageAction(params.id));
              this.storageId = params.id;
              this.loadStorage();
              this.storageFormTitle = 'Edit storage';
              this.isNew = false;
            }
          } else {
            this.storageFormTitle = 'Add storage';
            this.storage = {
              Id: '',
              LocalizedTitles: generateEmptyLocalizations(),
            } as PostPutStorageRequest;
          }
        }
      )
    );

    this.subscription.add(
      this.store.select(getMandators).subscribe((mandators: MandatorViewModel[]) => {
        this.mandators = mandators; // .map((x: TradeTypeViewModel) => x.Code);
      })
    );

    this.subscription.add(
      this.action$
        .pipe(ofType(ADD_STORAGE_SUCCESS, EDIT_STORAGE_SUCCESS), take(1))
        .subscribe(() => this.onBack())
    );

    this.storageTypes = Object.keys(StorageTypeEnum);
    this.storageChemistries = Object.keys(StorageChemistryEnum);
  }

  loadStorage(): void {
    this.subscription.add(
      this.action$
        .pipe(ofType(GET_STORAGE_SUCCESS), take(1))
        .subscribe((action: GetStorageSuccessAction) => {
          this.storage = cloneDeep(action.payload as PostPutStorageRequest);
          this.initialStorage = cloneDeep(this.storage);
        })
    );
  }

  isFormValid(): boolean {
    return this.storageForm && this.storageForm.valid && checkSpecialCharacters(this.storage.Id);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onBack(): void {
    this.router.navigate(['/pvmasterdata/storages/overview']);
  }

  onSave(): void {
    this.initialStorage = cloneDeep(this.storage);
    if (this.isNew) {
      this.store.dispatch(new AddStorageAction(this.storage));
    } else {
      this.store.dispatch(new EditStorageAction(this.storage));
    }
  }

  get canDeactivateSafely(): boolean {
    return isEqual(this.storage, this.initialStorage);
  }
}
