import * as HtMasterdataAction from './ht-masterdata.action';
import {
  AddDomesticWaterHeatingAction,
  AddDomesticWaterHeatingFailedAction,
  AddDomesticWaterHeatingSuccessAction,
  AddHeatGeneratorAction,
  AddHeatGeneratorFailedAction,
  AddHeatGeneratorSuccessAction,
  AddWaterTankAction,
  AddWaterTankFailedAction,
  AddWaterTankSuccessAction,
  DeleteDomesticWaterHeatingAction,
  DeleteDomesticWaterHeatingFailedAction,
  DeleteDomesticWaterHeatingSuccessAction,
  DeleteHeatGeneratorAction,
  DeleteHeatGeneratorFailedAction,
  DeleteHeatGeneratorSuccessAction,
  DeleteWaterTankAction,
  DeleteWaterTankFailedAction,
  DeleteWaterTankSuccessAction,
  EditDomesticWaterHeatingAction,
  EditDomesticWaterHeatingFailedAction,
  EditDomesticWaterHeatingSuccessAction,
  EditHeatGeneratorAction,
  EditHeatGeneratorFailedAction,
  EditHeatGeneratorSuccessAction,
  EditWaterTankAction,
  EditWaterTankFailedAction,
  EditWaterTankSuccessAction,
  GetAllDomesticWaterHeatingAction,
  GetAllDomesticWaterHeatingFailedAction,
  GetAllDomesticWaterHeatingSuccessAction,
  GetAllHeatGeneratorsAction,
  GetAllHeatGeneratorsFailedAction,
  GetAllHeatGeneratorsSuccessAction,
  GetAllWaterTanksAction,
  GetAllWaterTanksFailedAction,
  GetAllWaterTanksSuccessAction,
  GetDomesticWaterHeatingAction,
  GetDomesticWaterHeatingFailedAction,
  GetDomesticWaterHeatingSuccessAction,
  GetHeatGeneratorAction,
  GetHeatGeneratorFailedAction,
  GetHeatGeneratorSuccessAction,
  GetWaterTankAction,
  GetWaterTankFailedAction,
  GetWaterTankSuccessAction,
} from './ht-masterdata.action';
import { IHtMasterdataState, initHtMasterdataState } from './ht-masterdata.state';
import { tassign } from 'tassign';
import {
  DomesticWaterHeatingListViewModel,
  DomesticWaterHeatingViewModel,
  HeatGeneratorListViewModel,
  HeatGeneratorViewModel,
  WaterTankListViewModel,
  WaterTankViewModel,
} from '../../shared/apis/advis';
import cloneDeep from 'lodash/cloneDeep';

export function reducer(
  state: IHtMasterdataState = initHtMasterdataState(),
  action: HtMasterdataAction.Actions
): IHtMasterdataState {
  if (action instanceof GetAllDomesticWaterHeatingAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: true,
      domesticWaterHeatingLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof GetAllDomesticWaterHeatingSuccessAction) {
    return tassign(state, {
      domesticWaterHeating: action.payload,
      domesticWaterHeatingLoading: false,
      domesticWaterHeatingLoaded: true,
    } as IHtMasterdataState);
  }

  if (action instanceof GetAllDomesticWaterHeatingFailedAction) {
    return tassign(state, {
      domesticWaterHeating: [],
      domesticWaterHeatingLoading: false,
      domesticWaterHeatingLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof GetDomesticWaterHeatingAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: true,
      domesticWaterHeatingLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof GetDomesticWaterHeatingSuccessAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: false,
      domesticWaterHeatingLoaded: true,
    } as IHtMasterdataState);
  }

  if (action instanceof GetDomesticWaterHeatingFailedAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: false,
      domesticWaterHeatingLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof AddDomesticWaterHeatingAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: true,
      domesticWaterHeatingLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof AddDomesticWaterHeatingSuccessAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: false,
      domesticWaterHeatingLoaded: true,
      domesticWaterHeating: updateDomesticWaterHeatings(action.payload),
    } as IHtMasterdataState);
  }

  if (action instanceof AddDomesticWaterHeatingFailedAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: false,
      domesticWaterHeatingLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof EditDomesticWaterHeatingAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: true,
      domesticWaterHeatingLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof EditDomesticWaterHeatingSuccessAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: false,
      domesticWaterHeatingLoaded: true,
      domesticWaterHeating: updateDomesticWaterHeatings(action.payload),
    } as IHtMasterdataState);
  }

  if (action instanceof EditDomesticWaterHeatingFailedAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: false,
      domesticWaterHeatingLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof DeleteDomesticWaterHeatingAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: true,
      domesticWaterHeatingLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof DeleteDomesticWaterHeatingSuccessAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: false,
      domesticWaterHeatingLoaded: true,
      domesticWaterHeating: updateDomesticWaterHeatingAfterDelete(action.payload),
    } as IHtMasterdataState);
  }

  if (action instanceof DeleteDomesticWaterHeatingFailedAction) {
    return tassign(state, {
      domesticWaterHeatingLoading: false,
      domesticWaterHeatingLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof GetAllHeatGeneratorsAction) {
    return tassign(state, {
      heatGeneratorLoading: true,
      heatGeneratorLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof GetAllHeatGeneratorsSuccessAction) {
    return tassign(state, {
      heatGenerators: action.payload,
      heatGeneratorLoading: false,
      heatGeneratorLoaded: true,
    } as IHtMasterdataState);
  }

  if (action instanceof GetAllHeatGeneratorsFailedAction) {
    return tassign(state, {
      heatGenerators: [],
      heatGeneratorLoading: false,
      heatGeneratorLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof GetHeatGeneratorAction) {
    return tassign(state, {
      heatGeneratorLoading: true,
      heatGeneratorLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof GetHeatGeneratorSuccessAction) {
    return tassign(state, {
      heatGeneratorLoading: false,
      heatGeneratorLoaded: true,
    } as IHtMasterdataState);
  }

  if (action instanceof GetHeatGeneratorFailedAction) {
    return tassign(state, {
      heatGeneratorLoading: false,
      heatGeneratorLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof AddHeatGeneratorAction) {
    return tassign(state, {
      heatGeneratorLoading: true,
      heatGeneratorLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof AddHeatGeneratorSuccessAction) {
    return tassign(state, {
      heatGeneratorLoading: false,
      heatGeneratorLoaded: true,
      heatGenerators: updateHeatGenerator(action.payload),
    } as IHtMasterdataState);
  }

  if (action instanceof AddHeatGeneratorFailedAction) {
    return tassign(state, {
      heatGeneratorLoading: false,
      heatGeneratorLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof EditHeatGeneratorAction) {
    return tassign(state, {
      heatGeneratorLoading: true,
      heatGeneratorLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof EditHeatGeneratorSuccessAction) {
    return tassign(state, {
      heatGeneratorLoading: false,
      heatGeneratorLoaded: true,
      heatGenerators: updateHeatGenerator(action.payload),
    } as IHtMasterdataState);
  }

  if (action instanceof EditHeatGeneratorFailedAction) {
    return tassign(state, {
      heatGeneratorLoading: false,
      heatGeneratorLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof DeleteHeatGeneratorAction) {
    return tassign(state, {
      heatGeneratorLoading: true,
      heatGeneratorLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof DeleteHeatGeneratorSuccessAction) {
    return tassign(state, {
      heatGeneratorLoading: false,
      heatGeneratorLoaded: true,
      heatGenerators: updateHeatGeneratorsAfterDelete(action.payload),
    } as IHtMasterdataState);
  }

  if (action instanceof DeleteHeatGeneratorFailedAction) {
    return tassign(state, {
      heatGeneratorLoading: false,
      heatGeneratorLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof GetAllWaterTanksAction) {
    return tassign(state, {
      waterTankLoading: true,
      waterTankLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof GetAllWaterTanksSuccessAction) {
    return tassign(state, {
      waterTanks: action.payload,
      waterTankLoading: false,
      waterTankLoaded: true,
    } as IHtMasterdataState);
  }

  if (action instanceof GetAllWaterTanksFailedAction) {
    return tassign(state, {
      waterTanks: [],
      waterTankLoading: false,
      waterTankLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof GetWaterTankAction) {
    return tassign(state, {
      waterTankLoading: true,
      waterTankLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof GetWaterTankSuccessAction) {
    return tassign(state, {
      waterTankLoading: false,
      waterTankLoaded: true,
    } as IHtMasterdataState);
  }

  if (action instanceof GetWaterTankFailedAction) {
    return tassign(state, {
      waterTankLoading: false,
      waterTankLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof AddWaterTankAction) {
    return tassign(state, {
      waterTankLoading: true,
      waterTankLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof AddWaterTankSuccessAction) {
    return tassign(state, {
      waterTankLoading: false,
      waterTankLoaded: true,
      waterTanks: updateShadingOptimizer(action.payload),
    } as IHtMasterdataState);
  }

  if (action instanceof AddWaterTankFailedAction) {
    return tassign(state, {
      waterTankLoading: false,
      waterTankLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof EditWaterTankAction) {
    return tassign(state, {
      waterTankLoading: true,
      waterTankLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof EditWaterTankSuccessAction) {
    return tassign(state, {
      waterTankLoading: false,
      waterTankLoaded: true,
      waterTanks: updateShadingOptimizer(action.payload),
    } as IHtMasterdataState);
  }

  if (action instanceof EditWaterTankFailedAction) {
    return tassign(state, {
      waterTankLoading: false,
      waterTankLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof DeleteWaterTankAction) {
    return tassign(state, {
      waterTankLoading: true,
      waterTankLoaded: false,
    } as IHtMasterdataState);
  }

  if (action instanceof DeleteWaterTankSuccessAction) {
    return tassign(state, {
      waterTankLoading: false,
      waterTankLoaded: true,
      waterTanks: updateShadingOptimizersAfterDelete(action.payload),
    } as IHtMasterdataState);
  }

  if (action instanceof DeleteWaterTankFailedAction) {
    return tassign(state, {
      waterTankLoading: false,
      waterTankLoaded: false,
    } as IHtMasterdataState);
  }

  function updateDomesticWaterHeatings(
    domesticWaterHeating: DomesticWaterHeatingViewModel
  ): DomesticWaterHeatingListViewModel[] {
    const domesticWaterHeatings: DomesticWaterHeatingListViewModel[] = cloneDeep(
      state.domesticWaterHeating
    );
    return domesticWaterHeatings.map((x: DomesticWaterHeatingListViewModel) => {
      if (x.Id !== domesticWaterHeating.Id) {
        return x;
      }
      return {
        Id: domesticWaterHeating.Id,
        MandatorIds: domesticWaterHeating.MandatorIds,
        TechnicalTitle: domesticWaterHeating.TechnicalTitle,
      } as DomesticWaterHeatingListViewModel;
    });
  }

  function updateHeatGenerator(
    heatGenerator: HeatGeneratorViewModel
  ): HeatGeneratorListViewModel[] {
    const heatGenerators: HeatGeneratorListViewModel[] = cloneDeep(state.heatGenerators);
    return heatGenerators.map((x: HeatGeneratorListViewModel) => {
      if (x.Id !== heatGenerator.Id) {
        return x;
      }
      return {
        Id: heatGenerator.Id,
        TechnicalTitle: heatGenerator.TechnicalTitle,
        IsActive: heatGenerator.IsActive,
        Description: heatGenerator.Description,
      } as HeatGeneratorListViewModel;
    });
  }

  function updateShadingOptimizer(waterTank: WaterTankViewModel): WaterTankListViewModel[] {
    let waterTanks: WaterTankListViewModel[] = cloneDeep(state.waterTanks);
    return waterTanks.map((x: WaterTankListViewModel) => {
      if (x.Id !== waterTank.Id) {
        return x;
      }
      return {
        Description: waterTank.Description,
        Id: waterTank.Id,
        IsActive: waterTank.IsActive,
      } as WaterTankListViewModel;
    });
  }

  function updateDomesticWaterHeatingAfterDelete(
    domesticWaterHeatingId: string
  ): DomesticWaterHeatingListViewModel[] {
    const domesticWaterHeatings: DomesticWaterHeatingListViewModel[] = cloneDeep(
      state.domesticWaterHeating
    );
    const returnVal: DomesticWaterHeatingListViewModel[] = [];
    domesticWaterHeatings.forEach((x: DomesticWaterHeatingListViewModel) => {
      if (x.Id !== domesticWaterHeatingId) {
        returnVal.push(x);
      }
    });

    return returnVal;
  }

  function updateHeatGeneratorsAfterDelete(heatGeneratorId: string): HeatGeneratorListViewModel[] {
    const heatGenerators: HeatGeneratorListViewModel[] = cloneDeep(state.heatGenerators);
    const returnVal: HeatGeneratorListViewModel[] = [];
    heatGenerators.forEach((x: HeatGeneratorListViewModel) => {
      if (x.Id !== heatGeneratorId) {
        returnVal.push(x);
      }
    });

    return returnVal;
  }

  function updateShadingOptimizersAfterDelete(waterTankId: string): WaterTankListViewModel[] {
    let waterTank: WaterTankListViewModel[] = cloneDeep(state.waterTanks);
    let returnVal: WaterTankListViewModel[] = [];
    waterTank.forEach((x: WaterTankListViewModel) => {
      if (x.Id !== waterTankId) {
        returnVal.push(x);
      }
    });

    return returnVal;
  }

  return state;
}
