import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { IGuardDeactivation } from '../../../../../shared/interfaces/guard-deactivation';
import {
  ADD_INVERTER_SUCCESS,
  AddInverterAction,
  EDIT_INVERTER_FAILED,
  EditInverterAction,
  GET_INVERTER_SUCCESS,
  GetInverterAction,
  GetInverterSuccessAction,
} from '../../../../state/masterdata.action';
import { MandatorViewModel, PostPutInverterRequest } from '../../../../../shared/apis/advis';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  getInverterManufacturers,
  getInverterTypes,
  getMandators,
} from '../../../../../shared/store';
import { take } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import cloneDeep from 'lodash/cloneDeep';
import { IRootState } from '../../../../../root.state';

import InverterSmartEnergyEnum = PostPutInverterRequest.InverterSmartEnergyEnum;
import InverterSurgeProtectionEnum = PostPutInverterRequest.InverterSurgeProtectionEnum;
import { generateEmptyLocalizations } from '../../../../../shared/components/localized-values/generateEmptyLocalizations';
import { checkSpecialCharacters } from '../../../../../shared/utils/validation-util';

@Component({
  selector: 'ac-masterdata-inverters-form',
  templateUrl: './masterdata-inverters-form.component.html',
  styleUrls: ['./masterdata-inverters-form.component.scss'],
})
export class MasterdataInvertersFormComponent implements OnInit, OnDestroy, IGuardDeactivation {
  private subscription: Subscription = new Subscription();

  inverter: PostPutInverterRequest;
  initialInverter: PostPutInverterRequest = {} as PostPutInverterRequest;

  inverterId: number;

  isNew: boolean = true;

  inverterFormTitle: string = '';

  @ViewChild('inverterForm')
  inverterForm: UntypedFormGroup;

  mandators: MandatorViewModel[] = [];
  inverterTypes: string[];
  inverterManufacturer: string[];
  inverterSmartEnergyTypes: string[];
  inverterSurgeProtectionTypes: string[];

  constructor(
    private route: ActivatedRoute,
    private store: Store<IRootState>,
    private router: Router,
    private action$: Actions
  ) {}

  ngOnInit(): void {
    let url$: Observable<UrlSegment[]> = this.route.url;
    let params$: Observable<Params> = this.route.params;

    const types$: Observable<string[]> = this.store.select(getInverterTypes);
    const manufacturers$: Observable<string[]> = this.store.select(getInverterManufacturers);

    this.subscription.add(
      combineLatest([url$, params$, manufacturers$, types$]).subscribe(
        ([url, params, manufacturers, types]: any) => {
          this.inverterTypes = types;
          this.inverterManufacturer = manufacturers;

          let urlPath: string = url[1].path.toString();

          if (urlPath === 'edit') {
            if (params && params.id) {
              this.store.dispatch(new GetInverterAction(params.id));
              this.inverterId = params.id;
              this.loadInverter();
              this.inverterFormTitle = 'Edit inverter';
              this.isNew = false;
            }
          } else {
            this.inverterFormTitle = 'Add inverter';
            this.inverter = {
              Id: '',
              LocalizedDataSheets: generateEmptyLocalizations(),
              LocalizedTitles: generateEmptyLocalizations(),
            } as PostPutInverterRequest;
          }
        }
      )
    );

    this.subscription.add(
      this.store.select(getMandators).subscribe((mandators: MandatorViewModel[]) => {
        this.mandators = mandators;
      })
    );

    this.subscription.add(
      this.action$
        .pipe(ofType(ADD_INVERTER_SUCCESS, EDIT_INVERTER_FAILED), take(1))
        .subscribe(() => this.onBack())
    );

    this.inverterSmartEnergyTypes = Object.keys(InverterSmartEnergyEnum);
    this.inverterSurgeProtectionTypes = Object.keys(InverterSurgeProtectionEnum);
  }

  loadInverter(): void {
    this.subscription.add(
      this.action$
        .pipe(ofType(GET_INVERTER_SUCCESS), take(1))
        .subscribe((action: GetInverterSuccessAction) => {
          this.inverter = cloneDeep(action.payload as PostPutInverterRequest);
          this.initialInverter = cloneDeep(this.inverter);
        })
    );
  }

  isFormValid(): boolean {
    return this.inverterForm && this.inverterForm.valid && checkSpecialCharacters(this.inverter.Id);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onBack(): void {
    this.router.navigate(['/pvmasterdata/inverters/overview']);
  }

  onSave(): void {
    this.initialInverter = cloneDeep(this.inverter);
    if (this.isNew) {
      this.store.dispatch(new AddInverterAction(this.inverter));
    } else {
      this.store.dispatch(new EditInverterAction(this.inverter));
    }
  }

  get canDeactivateSafely(): boolean {
    return isEqual(this.inverter, this.initialInverter);
  }
}
