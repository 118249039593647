<ac-pv-masterdata-navigation></ac-pv-masterdata-navigation>
<mat-sidenav-container class="filter-sidenav-container">
  <mat-sidenav-content>
    <mat-card class="mat-list-card">
      <mat-card-content>
        <div class="overview-table-with-filter mat-elevation-z8">
          <div class="common-filter-padding">
            <mat-form-field appearance="outline">
              <!--todo ac-setup reset on action-->
              <mat-label>{{ 'USERS.FILTER.FILTER' | translate }}</mat-label>
              <input
                matInput
                (keyup)="filterTable($event)"
                [value]="this.pageSettings.overviewFilter.tableGlobalFilter"
                placeholder="{{ 'USERS.FILTER.FILTER' | translate }}" />
            </mat-form-field>
          </div>
          <div>
            <mat-table
              [dataSource]="dataSource"
              matSort
              [matSortActive]="pageSettings.sortSetting.active"
              [matSortDirection]="pageSettings.sortSetting.direction"
              matSortDisableClear
              (matSortChange)="changeSort($event)">
              <ng-container [matColumnDef]="displayedColumns[0]">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'COMMON.ID' | translate }}
                </mat-header-cell>
                <mat-cell class="table-cell-bold-text-pointer" *matCellDef="let row"
                  ><span (click)="navigateToEdit(row.Id)"> {{ row.Id }} </span></mat-cell
                >
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[1]">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'COMMON.DESC' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"
                  ><span (click)="navigateToEdit(row.Id)"> {{ row.Description }} </span></mat-cell
                >
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[2]">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'COMMON.IS_ACTIVE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.IsActive }} </mat-cell>
              </ng-container>

              <ng-container [matColumnDef]="displayedColumns[3]">
                <mat-header-cell fxFlex="3" *matHeaderCellDef></mat-header-cell>
                <mat-cell fxFlex="3" *matCellDef="let row">
                  <a
                    class="table-actions cursor-pointer"
                    fxLayoutAlign="end center"
                    [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </a>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="navigateToEdit(row.Id)">
                      <span>{{ 'COMMON.EDIT' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="delete(row.Id)">
                      <span>{{ 'COMMON.DELETE' | translate }}</span>
                    </button>
                  </mat-menu>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
            </mat-table>

            <mat-paginator
              [pageSize]="pageSettings.pagingSetting.pageSize"
              [pageIndex]="pageSettings.pagingSetting.pageIndex"
              [pageSizeOptions]="[5, 10, 25, 100]"
              (page)="changePaging($event)"></mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
