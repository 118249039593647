<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    {{ shadingOptimizerFormTitle }}
  </div>
  <div>
    <button mat-button color="accent" (click)="onBack()">{{ 'COMMON.CANCEL' | translate }}</button>
    <button mat-button color="primary" [disabled]="!isFormValid()" (click)="onSave()">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start" class="padding-10">
  <mat-card fxFlex="50">
    <mat-card-header>
      <mat-card-title>
        {{ shadingOptimizerFormTitle }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form
        fxLayout="column"
        fxLayoutGap="15px"
        name="shadingOptimizerForm"
        #shadingOptimizerForm="ngForm"
        *ngIf="shadingOptimizer || isNew">
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.ID' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.ID' | translate }}"
              type="text"
              name="id"
              required
              [disabled]="!isNew"
              [(ngModel)]="shadingOptimizer.Id"
              #id />
            <mat-hint>{{ 'HT_MASTERDATA.NO_SPACES_DOTS_AND_SLASHES_IN_ID' | translate }}</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.ARTICLE_NUMBER' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.ARTICLE_NUMBER' | translate }}"
              type="text"
              name="articleNumber"
              required
              [(ngModel)]="shadingOptimizer.ArticleNumber"
              #articleNumber />
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox id="isActive" name="isActive" [(ngModel)]="shadingOptimizer.IsActive">{{
            'COMMON.IS_ACTIVE' | translate
          }}</mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'SHADING_OPTIMIZERS.DESC' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'SHADING_OPTIMIZERS.DESC' | translate }}"
              type="text"
              name="description"
              required
              [(ngModel)]="shadingOptimizer.Description"
              #description />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field appearance="outline">
            <mat-label>Inverter type</mat-label>

            <mat-select
              name="inverterType"
              multiple
              [(ngModel)]="shadingOptimizer.CompatibleInverterTypes"
              #inverterType>
              <mat-option *ngFor="let inverterType of inverterTypes" [value]="inverterType">{{
                inverterType
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Inverter Manufacturer</mat-label>

            <mat-select
              name="inverterManufacturer"
              multiple
              [(ngModel)]="shadingOptimizer.CompatibleInverterManufacturers"
              #inverterSmartEnergy>
              <mat-option
                *ngFor="let inverterManufacturer of inverterManufacturers"
                [value]="inverterManufacturer"
                >{{ inverterManufacturer }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <ac-localized-values
          [localizedValues]="shadingOptimizer.LocalizedTitles"
          [title]="'COMMON.TRANSLATION'">
        </ac-localized-values>

        <div>
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label>{{ 'COMMON.MANDATORS' | translate }}</mat-label>

            <mat-select
              name="mandatorIds"
              multiple
              [(ngModel)]="shadingOptimizer.MandatorIds"
              #mandatorIds>
              <mat-option
                *ngFor="let mandator of mandators"
                [value]="mandator.Id"
                [disabled]="!mandator.IsActive"
                >{{ mandator.Name + ' (' + mandator.ShortName + ')' }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
