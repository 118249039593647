import { createSelector, MemoizedSelector } from '@ngrx/store';
import { IRootState } from '../../root.state';
import { IPrincipal } from '../../shared/interfaces/principal.interface';
import { IAuthState } from './auth.state';

export const getAuthState: (state: IRootState) => IAuthState = (state: IRootState) => state.auth;

export const getAuthLoggedIn: MemoizedSelector<IRootState, boolean> = createSelector(
  getAuthState,
  (state: IAuthState) => {
    return state.loggedIn;
  }
);

export const getPrincipal: MemoizedSelector<IRootState, IPrincipal> = createSelector(
  getAuthState,
  (state: IAuthState) => {
    return state.principal;
  }
);
