<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    <a
      routerLinkActive="active-view-button"
      [routerLink]="['/ht-masterdata/domestic-water-heating/overview']"
      >{{ 'HT_MASTERDATA.NAVIGATION.DOMESTIC_WATER_HEATING' | translate }}</a
    >
    <ac-vertical-divider></ac-vertical-divider>
    <a
      routerLinkActive="active-view-button"
      [routerLink]="['/ht-masterdata/heat-generators/overview']"
      >{{ 'HT_MASTERDATA.NAVIGATION.HEAT_GENERATORS' | translate }}</a
    >
    <ac-vertical-divider></ac-vertical-divider>
    <a
      routerLinkActive="active-view-button"
      [routerLink]="['/ht-masterdata/water-tanks/overview']"
      >{{ 'HT_MASTERDATA.NAVIGATION.WATER_TANKS' | translate }}</a
    >
  </div>
  <div>
    <button [routerLink]="[routeLinkToAdd]" color="primary" mat-button>
      <mat-icon>add</mat-icon>
      {{ 'COMMON.ADD' | translate }}
    </button>
  </div>
</mat-toolbar>
