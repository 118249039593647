/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalizedValueViewModel } from './localizedValueViewModel';
import { StringGenerationContainer } from './stringGenerationContainer';


export interface DomesticWaterHeatingViewModel { 
    ArticleNumber?: string;
    Description?: string;
    Id?: string;
    TechnicalTitle?: string;
    SupportedHeatingTypes?: Array<string>;
    SupportedManufacturers?: Array<string>;
    SubProductConnectionTypes?: Array<string>;
    LocalizedTitles?: Array<LocalizedValueViewModel>;
    LocalizedDataSheets?: Array<LocalizedValueViewModel>;
    StringGenerationContainer?: StringGenerationContainer;
    MandatorIds?: Array<number>;
    DomesticWaterHeatingConstructionType?: string;
    DomesticWaterHeatingMaterial?: string;
    DomesticWaterHeatingVolume?: number;
    DomesticWaterHeatingRegisterSize?: number;
    IsActive?: boolean;
    ArViewerUrl?: string;
}
