/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { DocumentTemplateViewModel } from '../model/documentTemplateViewModel';
import { DocumentTemplatesRequest } from '../model/documentTemplatesRequest';
import { PageResultViewModelDocumentTemplateViewModel } from '../model/pageResultViewModelDocumentTemplateViewModel';
import { PostPutDocumentTemplateRequest } from '../model/postPutDocumentTemplateRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DocumentTemplateService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Delete a document template
     * 
     * @param templateId the id of the document template to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTemplateDeleteTemplate(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public documentTemplateDeleteTemplate(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public documentTemplateDeleteTemplate(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public documentTemplateDeleteTemplate(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling documentTemplateDeleteTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/documenttemplates/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific template by id
     * 
     * @param templateId the id of the document template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTemplateGetTemplate(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<DocumentTemplateViewModel>;
    public documentTemplateGetTemplate(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTemplateViewModel>>;
    public documentTemplateGetTemplate(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTemplateViewModel>>;
    public documentTemplateGetTemplate(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling documentTemplateGetTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentTemplateViewModel>(`${this.basePath}/api/admin/documenttemplates/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets filtered document templates
     * 
     * @param Title 
     * @param Language 
     * @param MandatorIds &gt;only retrieve entries with a matching mandator id (you can provide multiple mandants by separating them with a &#39;,&#39;
     * @param Type 
     * @param TradeTypeCodes only retrieve entries with a matching trade type code (you can provide multiple codes by separating them with a &#39;,&#39;)
     * @param ShowForNboPhase 
     * @param ShowForBoPhases 
     * @param ShowForProjectPhases 
     * @param PageIndex the index of the current page(starts at 1)
     * @param PageSize the amount of entries per page (15 per default)
     * @param SortField the field used for sorting (Title per default)
     * @param SortOrder the sorting order (desc per default)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTemplateGetTemplates(Title?: string, Language?: 'DE' | 'FR' | 'IT' | 'EN', MandatorIds?: string, Type?: 'DocumentTemplate' | 'Anlage' | 'Report' | 'Invoice' | 'DefaultBo' | 'DefaultNbo' | 'DefaultProject' | 'AddressAddedTemplate', TradeTypeCodes?: string, ShowForNboPhase?: boolean, ShowForBoPhases?: boolean, ShowForProjectPhases?: boolean, PageIndex?: number, PageSize?: number, SortField?: 'None' | 'Id' | 'Title' | 'Language' | 'Type' | 'TradeTypeCode' | 'ShowForNboPhase' | 'ShowForBoPhases' | 'ShowForProjectPhases', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'body', reportProgress?: boolean): Observable<PageResultViewModelDocumentTemplateViewModel>;
    public documentTemplateGetTemplates(Title?: string, Language?: 'DE' | 'FR' | 'IT' | 'EN', MandatorIds?: string, Type?: 'DocumentTemplate' | 'Anlage' | 'Report' | 'Invoice' | 'DefaultBo' | 'DefaultNbo' | 'DefaultProject' | 'AddressAddedTemplate', TradeTypeCodes?: string, ShowForNboPhase?: boolean, ShowForBoPhases?: boolean, ShowForProjectPhases?: boolean, PageIndex?: number, PageSize?: number, SortField?: 'None' | 'Id' | 'Title' | 'Language' | 'Type' | 'TradeTypeCode' | 'ShowForNboPhase' | 'ShowForBoPhases' | 'ShowForProjectPhases', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageResultViewModelDocumentTemplateViewModel>>;
    public documentTemplateGetTemplates(Title?: string, Language?: 'DE' | 'FR' | 'IT' | 'EN', MandatorIds?: string, Type?: 'DocumentTemplate' | 'Anlage' | 'Report' | 'Invoice' | 'DefaultBo' | 'DefaultNbo' | 'DefaultProject' | 'AddressAddedTemplate', TradeTypeCodes?: string, ShowForNboPhase?: boolean, ShowForBoPhases?: boolean, ShowForProjectPhases?: boolean, PageIndex?: number, PageSize?: number, SortField?: 'None' | 'Id' | 'Title' | 'Language' | 'Type' | 'TradeTypeCode' | 'ShowForNboPhase' | 'ShowForBoPhases' | 'ShowForProjectPhases', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageResultViewModelDocumentTemplateViewModel>>;
    public documentTemplateGetTemplates(Title?: string, Language?: 'DE' | 'FR' | 'IT' | 'EN', MandatorIds?: string, Type?: 'DocumentTemplate' | 'Anlage' | 'Report' | 'Invoice' | 'DefaultBo' | 'DefaultNbo' | 'DefaultProject' | 'AddressAddedTemplate', TradeTypeCodes?: string, ShowForNboPhase?: boolean, ShowForBoPhases?: boolean, ShowForProjectPhases?: boolean, PageIndex?: number, PageSize?: number, SortField?: 'None' | 'Id' | 'Title' | 'Language' | 'Type' | 'TradeTypeCode' | 'ShowForNboPhase' | 'ShowForBoPhases' | 'ShowForProjectPhases', SortOrder?: 'None' | 'Asc' | 'Desc', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Title !== undefined && Title !== null) {
            queryParameters = queryParameters.set('Title', <any>Title);
        }
        if (Language !== undefined && Language !== null) {
            queryParameters = queryParameters.set('Language', <any>Language);
        }
        if (MandatorIds !== undefined && MandatorIds !== null) {
            queryParameters = queryParameters.set('MandatorIds', <any>MandatorIds);
        }
        if (Type !== undefined && Type !== null) {
            queryParameters = queryParameters.set('Type', <any>Type);
        }
        if (TradeTypeCodes !== undefined && TradeTypeCodes !== null) {
            queryParameters = queryParameters.set('TradeTypeCodes', <any>TradeTypeCodes);
        }
        if (ShowForNboPhase !== undefined && ShowForNboPhase !== null) {
            queryParameters = queryParameters.set('ShowForNboPhase', <any>ShowForNboPhase);
        }
        if (ShowForBoPhases !== undefined && ShowForBoPhases !== null) {
            queryParameters = queryParameters.set('ShowForBoPhases', <any>ShowForBoPhases);
        }
        if (ShowForProjectPhases !== undefined && ShowForProjectPhases !== null) {
            queryParameters = queryParameters.set('ShowForProjectPhases', <any>ShowForProjectPhases);
        }
        if (PageIndex !== undefined && PageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>PageIndex);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (SortField !== undefined && SortField !== null) {
            queryParameters = queryParameters.set('SortField', <any>SortField);
        }
        if (SortOrder !== undefined && SortOrder !== null) {
            queryParameters = queryParameters.set('SortOrder', <any>SortOrder);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageResultViewModelDocumentTemplateViewModel>(`${this.basePath}/api/admin/documenttemplates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTemplatePostDocumentTemplatesRequestMock(body?: DocumentTemplatesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public documentTemplatePostDocumentTemplatesRequestMock(body?: DocumentTemplatesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public documentTemplatePostDocumentTemplatesRequestMock(body?: DocumentTemplatesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public documentTemplatePostDocumentTemplatesRequestMock(body?: DocumentTemplatesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/documenttemplates/documentTemplatesRequestmock`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a document template with file template
     * 
     * @param Title 
     * @param TradeTypeCode 
     * @param MandatorIds 
     * @param templateDocument 
     * @param Type 
     * @param Language 
     * @param Comment 
     * @param Path 
     * @param Sequence 
     * @param ShowForNboPhase 
     * @param ShowForBoPhases 
     * @param ShowForProjectPhases 
     * @param Scopes 
     * @param AddressType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTemplatePostFileTemplate(Title: string, TradeTypeCode: string, MandatorIds: Array<number>, templateDocument: Blob, Type?: any, Language?: any, Comment?: string, Path?: string, Sequence?: number, ShowForNboPhase?: boolean, ShowForBoPhases?: boolean, ShowForProjectPhases?: boolean, Scopes?: Array<DocumentTemplateViewModel.ScopesEnum>, AddressType?: any, observe?: 'body', reportProgress?: boolean): Observable<DocumentTemplateViewModel>;
    public documentTemplatePostFileTemplate(Title: string, TradeTypeCode: string, MandatorIds: Array<number>, templateDocument: Blob, Type?: any, Language?: any, Comment?: string, Path?: string, Sequence?: number, ShowForNboPhase?: boolean, ShowForBoPhases?: boolean, ShowForProjectPhases?: boolean, Scopes?: Array<DocumentTemplateViewModel.ScopesEnum>, AddressType?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTemplateViewModel>>;
    public documentTemplatePostFileTemplate(Title: string, TradeTypeCode: string, MandatorIds: Array<number>, templateDocument: Blob, Type?: any, Language?: any, Comment?: string, Path?: string, Sequence?: number, ShowForNboPhase?: boolean, ShowForBoPhases?: boolean, ShowForProjectPhases?: boolean, Scopes?: Array<DocumentTemplateViewModel.ScopesEnum>, AddressType?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTemplateViewModel>>;
    public documentTemplatePostFileTemplate(Title: string, TradeTypeCode: string, MandatorIds: Array<number>, templateDocument: Blob, Type?: any, Language?: any, Comment?: string, Path?: string, Sequence?: number, ShowForNboPhase?: boolean, ShowForBoPhases?: boolean, ShowForProjectPhases?: boolean, Scopes?: Array<DocumentTemplateViewModel.ScopesEnum>, AddressType?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Title === null || Title === undefined) {
            throw new Error('Required parameter Title was null or undefined when calling documentTemplatePostFileTemplate.');
        }

        if (TradeTypeCode === null || TradeTypeCode === undefined) {
            throw new Error('Required parameter TradeTypeCode was null or undefined when calling documentTemplatePostFileTemplate.');
        }

        if (MandatorIds === null || MandatorIds === undefined) {
            throw new Error('Required parameter MandatorIds was null or undefined when calling documentTemplatePostFileTemplate.');
        }

        if (templateDocument === null || templateDocument === undefined) {
            throw new Error('Required parameter templateDocument was null or undefined when calling documentTemplatePostFileTemplate.');
        }











        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (Title !== undefined) {
            formParams = formParams.append('Title', <any>Title) || formParams;
        }
        if (Type !== undefined) {
            formParams = formParams.append('Type', <any>Type) || formParams;
        }
        if (Language !== undefined) {
            formParams = formParams.append('Language', <any>Language) || formParams;
        }
        if (TradeTypeCode !== undefined) {
            formParams = formParams.append('TradeTypeCode', <any>TradeTypeCode) || formParams;
        }
        if (Comment !== undefined) {
            formParams = formParams.append('Comment', <any>Comment) || formParams;
        }
        if (Path !== undefined) {
            formParams = formParams.append('Path', <any>Path) || formParams;
        }
        if (Sequence !== undefined) {
            formParams = formParams.append('Sequence', <any>Sequence) || formParams;
        }
        if (MandatorIds) {
            MandatorIds.forEach((id, i) => {formParams = formParams.append(`MandatorIds[${i}]`, id) || formParams;})
        }
        if (ShowForNboPhase !== undefined) {
            formParams = formParams.append('ShowForNboPhase', <any>ShowForNboPhase) || formParams;
        }
        if (ShowForBoPhases !== undefined) {
            formParams = formParams.append('ShowForBoPhases', <any>ShowForBoPhases) || formParams;
        }
        if (ShowForProjectPhases !== undefined) {
            formParams = formParams.append('ShowForProjectPhases', <any>ShowForProjectPhases) || formParams;
        }
        if (Scopes) {
            Scopes.forEach((scope, i) => {formParams = formParams.append(`Scopes[${i}]`, scope) || formParams;})
        }
        if (AddressType !== undefined) {
            formParams = formParams.append('AddressType', <any>AddressType) || formParams;
        }
        if (templateDocument !== undefined) {
            formParams = formParams.append('templateDocument', <any>templateDocument) || formParams;
        }

        return this.httpClient.post<DocumentTemplateViewModel>(`${this.basePath}/api/admin/documenttemplates/file`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a document template with reference template
     * 
     * @param body the new document template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTemplatePostTemplate(body?: PostPutDocumentTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<DocumentTemplateViewModel>;
    public documentTemplatePostTemplate(body?: PostPutDocumentTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTemplateViewModel>>;
    public documentTemplatePostTemplate(body?: PostPutDocumentTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTemplateViewModel>>;
    public documentTemplatePostTemplate(body?: PostPutDocumentTemplateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DocumentTemplateViewModel>(`${this.basePath}/api/admin/documenttemplates`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a document template from file
     * 
     * @param templateId the id of the document template to update
     * @param Title 
     * @param TradeTypeCode 
     * @param MandatorIds 
     * @param templateDocument 
     * @param Type 
     * @param Language 
     * @param Comment 
     * @param Path 
     * @param Sequence 
     * @param ShowForNboPhase 
     * @param ShowForBoPhases 
     * @param ShowForProjectPhases 
     * @param Scopes 
     * @param AddressType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTemplatePutFileTemplate(templateId: number, Title: string, TradeTypeCode: string, MandatorIds: Array<number>, templateDocument?: Blob, Type?: any, Language?: any, Comment?: string, Path?: string, Sequence?: number, ShowForNboPhase?: boolean, ShowForBoPhases?: boolean, ShowForProjectPhases?: boolean, Scopes?: Array<DocumentTemplateViewModel.ScopesEnum>, AddressType?: any, observe?: 'body', reportProgress?: boolean): Observable<DocumentTemplateViewModel>;
    public documentTemplatePutFileTemplate(templateId: number, Title: string, TradeTypeCode: string, MandatorIds: Array<number>, templateDocument?: Blob, Type?: any, Language?: any, Comment?: string, Path?: string, Sequence?: number, ShowForNboPhase?: boolean, ShowForBoPhases?: boolean, ShowForProjectPhases?: boolean, Scopes?: Array<DocumentTemplateViewModel.ScopesEnum>, AddressType?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTemplateViewModel>>;
    public documentTemplatePutFileTemplate(templateId: number, Title: string, TradeTypeCode: string, MandatorIds: Array<number>, templateDocument?: Blob, Type?: any, Language?: any, Comment?: string, Path?: string, Sequence?: number, ShowForNboPhase?: boolean, ShowForBoPhases?: boolean, ShowForProjectPhases?: boolean, Scopes?: Array<DocumentTemplateViewModel.ScopesEnum>, AddressType?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTemplateViewModel>>;
    public documentTemplatePutFileTemplate(templateId: number, Title: string, TradeTypeCode: string, MandatorIds: Array<number>, templateDocument?: Blob, Type?: any, Language?: any, Comment?: string, Path?: string, Sequence?: number, ShowForNboPhase?: boolean, ShowForBoPhases?: boolean, ShowForProjectPhases?: boolean, Scopes?: Array<DocumentTemplateViewModel.ScopesEnum>, AddressType?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling documentTemplatePutFileTemplate.');
        }

        if (Title === null || Title === undefined) {
            throw new Error('Required parameter Title was null or undefined when calling documentTemplatePutFileTemplate.');
        }

        if (TradeTypeCode === null || TradeTypeCode === undefined) {
            throw new Error('Required parameter TradeTypeCode was null or undefined when calling documentTemplatePutFileTemplate.');
        }

        if (MandatorIds === null || MandatorIds === undefined) {
            throw new Error('Required parameter MandatorIds was null or undefined when calling documentTemplatePutFileTemplate.');
        }












        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (templateDocument !== undefined) {
            formParams = formParams.append('templateDocument', <any>templateDocument) || formParams;
        }
        if (Title !== undefined) {
            formParams = formParams.append('Title', <any>Title) || formParams;
        }
        if (Type !== undefined) {
            formParams = formParams.append('Type', <any>Type) || formParams;
        }
        if (Language !== undefined) {
            formParams = formParams.append('Language', <any>Language) || formParams;
        }
        if (TradeTypeCode !== undefined) {
            formParams = formParams.append('TradeTypeCode', <any>TradeTypeCode) || formParams;
        }
        if (Comment !== undefined) {
            formParams = formParams.append('Comment', <any>Comment) || formParams;
        }
        if (Path !== undefined) {
            formParams = formParams.append('Path', <any>Path) || formParams;
        }
        if (Sequence !== undefined) {
            formParams = formParams.append('Sequence', <any>Sequence) || formParams;
        }
        if (MandatorIds) {
            MandatorIds.forEach((id, i) => {formParams = formParams.append(`MandatorIds[${i}]`, id) || formParams;})
        }
        if (ShowForNboPhase !== undefined) {
            formParams = formParams.append('ShowForNboPhase', <any>ShowForNboPhase) || formParams;
        }
        if (ShowForBoPhases !== undefined) {
            formParams = formParams.append('ShowForBoPhases', <any>ShowForBoPhases) || formParams;
        }
        if (ShowForProjectPhases !== undefined) {
            formParams = formParams.append('ShowForProjectPhases', <any>ShowForProjectPhases) || formParams;
        }
        if (Scopes) {
            Scopes.forEach((scope, i) => {formParams = formParams.append(`Scopes[${i}]`, scope) || formParams;})
        }
        if (AddressType !== undefined) {
            formParams = formParams.append('AddressType', <any>AddressType) || formParams;
        }

        return this.httpClient.put<DocumentTemplateViewModel>(`${this.basePath}/api/admin/documenttemplates/file/${encodeURIComponent(String(templateId))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a document template from reference
     * 
     * @param templateId the id of the document template to update
     * @param body the document template data to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTemplatePutTemplate(templateId: number, body?: PostPutDocumentTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<DocumentTemplateViewModel>;
    public documentTemplatePutTemplate(templateId: number, body?: PostPutDocumentTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTemplateViewModel>>;
    public documentTemplatePutTemplate(templateId: number, body?: PostPutDocumentTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTemplateViewModel>>;
    public documentTemplatePutTemplate(templateId: number, body?: PostPutDocumentTemplateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling documentTemplatePutTemplate.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DocumentTemplateViewModel>(`${this.basePath}/api/admin/documenttemplates/${encodeURIComponent(String(templateId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
