export interface IEnvironmentInterface {
  production: boolean;
  apiBasePath: string;
  protectDevelopmentPage: boolean;
  versionInfo: string;
  version: string;
  dropboxApiKey: string;
  dropboxApiEnv: string;
  googleMapsApiKey: string;
  googleMapsApiKeyNEW: string;
  veseApiKey: string;
  instrumentStoreDevtools: boolean;
  environmentType: EnvironmentTypeEnum;
  checkVersionUrl: string;
}

export enum EnvironmentTypeEnum {
  LOCAL = <any>'LOCAL',
  DEV = <any>'DEVELOPMENT',
  TEST = <any>'TESTING',
  STAGE = <any>'STAGING',
  PROD = <any>'PRODUCTION',
}
