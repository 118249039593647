import { FileLinkViewModel, UserViewModel } from '../../../shared/apis/advis';
import { MandatorOverviewVsModel } from '../../../shared/models/view-setting/user-overview-vs.model';

export interface IUserState {
  users: UserViewModel[];
  usersLoading: boolean;
  usersLoaded: boolean;
  userOverviewViewSettings: MandatorOverviewVsModel;
  userOverviewViewSettingsLoaded: boolean;
  userFiles: FileLinkViewModel[];
  userFilesLoading: boolean;
  userFilesLoaded: boolean;
}

export function initUserState(): IUserState {
  return {
    users: [],
    usersLoading: false,
    usersLoaded: false,
    userOverviewViewSettings: new MandatorOverviewVsModel(),
    userOverviewViewSettingsLoaded: false,
    userFiles: [],
    userFilesLoading: false,
    userFilesLoaded: false,
  };
}
