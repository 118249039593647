import { Component, OnDestroy, OnInit, reflectComponentType, ViewChild } from '@angular/core';
import { OverviewBaseComponent } from '../../../../../shared/components/overview-filter/overviewBaseComponent';
import { Subscription } from 'rxjs';
import { ModuleListViewModel } from '../../../../../shared/apis/advis';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IPageSettings } from '../../../../../shared/interfaces/Settings';
import { Store } from '@ngrx/store';
import { IRootState } from '../../../../../root.state';
import { Router } from '@angular/router';
import { OverviewControl } from '../../../../../shared/components/overview-filter/Type';
import { SetPageSettingsAction } from '../../../../../shared/store';
import { getModulesFiltered } from '../../../../state/masterdata.selectors';
import { DeleteModuleAction, GetModulesAction } from '../../../../state/masterdata.action';

@Component({
  selector: 'ac-masterdata-modules-overview',
  templateUrl: './masterdata-modules-overview.component.html',
  styleUrls: ['./masterdata-modules-overview.component.scss'],
})
export class MasterdataModuleOverviewComponent
  extends OverviewBaseComponent
  implements OnInit, OnDestroy
{
  private subscription: Subscription = new Subscription();

  modulesFiltered: ModuleListViewModel[] = [];

  displayedColumns: string[] = ['Id', 'Description', 'IsActive', 'Order', 'Actions'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageSettings: IPageSettings;

  constructor(store: Store<IRootState>, private router: Router) {
    super(store);
  }

  ngOnInit(): void {
    this.refresh();

    this.overviewFilterInit(
      reflectComponentType(MasterdataModuleOverviewComponent).selector,
      [
        {
          key: 'Description',
          translateKey: 'Name',
          control: OverviewControl.STRING,
          isServerSide: true,
        },
        {
          key: 'IsActive',
          translateKey: 'Short name',
          control: OverviewControl.BOOLEAN,
          isServerSide: true,
        },
        {
          key: 'Order',
          translateKey: 'Order',
          control: OverviewControl.NUMBER,
          isServerSide: true,
        },
      ],
      this.subscription
    );

    this.subscription.add(
      this.store.select(getModulesFiltered).subscribe((modules: ModuleListViewModel[]) => {
        this.modulesFiltered = modules;
        this.dataSource = new MatTableDataSource(this.modulesFiltered);
        this.dataSource.paginator = this.paginator;

        this.dataSource.sortingDataAccessor = (item: any, property: any) => {
          switch (property) {
            default:
              return item[property];
          }
        };
        this.dataSource.sort = this.sort;
        this.clientSideFiltering();
      })
    );
  }

  changePaging(e: PageEvent): void {
    this.pageSettings.pagingSetting.pageIndex = e.pageIndex;
    this.pageSettings.pagingSetting.pageSize = e.pageSize;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  changeSort(e: Sort): void {
    this.pageSettings.sortSetting = e;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  refresh(): void {
    this.store.dispatch(new GetModulesAction());
  }

  navigateToEdit(moduleId: string): void {
    this.router.navigate(['/pvmasterdata/modules/edit', moduleId]);
  }

  delete(moduleId: string): void {
    this.store.dispatch(new DeleteModuleAction(moduleId));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  clientSideFiltering(): void {
    this.dataSource.filter = this.pageSettings.overviewFilter.tableGlobalFilter || '';
  }

  serverSideFiltering(): void {
    // empty
  }
}
