export * from './account.service';
import { AccountService } from './account.service';
export * from './blueOfficeCacheManagement.service';
import { BlueOfficeCacheManagementService } from './blueOfficeCacheManagement.service';
export * from './documentTemplate.service';
import { DocumentTemplateService } from './documentTemplate.service';
export * from './emailTemplate.service';
import { EmailTemplateService } from './emailTemplate.service';
export * from './emobilityMasterData.service';
import { EmobilityMasterDataService } from './emobilityMasterData.service';
export * from './excelPort.service';
import { ExcelPortService } from './excelPort.service';
export * from './heatingMasterData.service';
import { HeatingMasterDataService } from './heatingMasterData.service';
export * from './hubspot.service';
import { HubspotService } from './hubspot.service';
export * from './ighConvert.service';
import { IghConvertService } from './ighConvert.service';
export * from './lead.service';
import { LeadService } from './lead.service';
export * from './mandator.service';
import { MandatorService } from './mandator.service';
export * from './masterData.service';
import { MasterDataService } from './masterData.service';
export * from './positionBuilder.service';
import { PositionBuilderService } from './positionBuilder.service';
export * from './positionDependencyRule.service';
import { PositionDependencyRuleService } from './positionDependencyRule.service';
export * from './positionGroupTemplate.service';
import { PositionGroupTemplateService } from './positionGroupTemplate.service';
export * from './processConfiguration.service';
import { ProcessConfigurationService } from './processConfiguration.service';
export * from './productline.service';
import { ProductlineService } from './productline.service';
export * from './projectChecklistTemplate.service';
import { ProjectChecklistTemplateService } from './projectChecklistTemplate.service';
export * from './projectReporting.service';
import { ProjectReportingService } from './projectReporting.service';
export * from './pvMasterData.service';
import { PvMasterDataService } from './pvMasterData.service';
export * from './regionalInformation.service';
import { RegionalInformationService } from './regionalInformation.service';
export * from './role.service';
import { RoleService } from './role.service';
export * from './roleTargetGroup.service';
import { RoleTargetGroupService } from './roleTargetGroup.service';
export * from './searchTableManagement.service';
import { SearchTableManagementService } from './searchTableManagement.service';
export * from './standardOperatingProceduresSOP.service';
import { StandardOperatingProceduresSOPService } from './standardOperatingProceduresSOP.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './validationRule.service';
import { ValidationRuleService } from './validationRule.service';
export const APIS = [AccountService, BlueOfficeCacheManagementService, DocumentTemplateService, EmailTemplateService, EmobilityMasterDataService, ExcelPortService, HeatingMasterDataService, HubspotService, IghConvertService, LeadService, MandatorService, MasterDataService, PositionBuilderService, PositionDependencyRuleService, PositionGroupTemplateService, ProcessConfigurationService, ProductlineService, ProjectChecklistTemplateService, ProjectReportingService, PvMasterDataService, RegionalInformationService, RoleService, RoleTargetGroupService, SearchTableManagementService, StandardOperatingProceduresSOPService, UserService, ValidationRuleService];
