import { IRootState } from '../../root.state';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { MandatorViewModel } from '../../shared/apis/advis';
import { IMandatorState } from './mandator.state';
import { MandatorOverviewVsModel } from '../../shared/models/view-setting/mandator-overview-vs.model';

export const getMandatorState: (state: IRootState) => IMandatorState = (state: IRootState) =>
  state.mandator;

export const getMandatorsFiltered: MemoizedSelector<IRootState, MandatorViewModel[]> =
  createSelector(getMandatorState, (state: IMandatorState) => state.mandators);

export const getMandatorsLoading: MemoizedSelector<IRootState, boolean> = createSelector(
  getMandatorState,
  (state: IMandatorState) => state.mandatorsLoading
);

export const getMandatorsLoaded: MemoizedSelector<IRootState, boolean> = createSelector(
  getMandatorState,
  (state: IMandatorState) => state.mandatorsLoaded
);

export const getMandatorOverviewViewSettings: MemoizedSelector<
  IRootState,
  MandatorOverviewVsModel
> = createSelector(getMandatorState, (state: IMandatorState) => state.mandatorOverviewViewSettings);

export const getMandatorOverviewViewSettingsLoaded: MemoizedSelector<IRootState, boolean> =
  createSelector(
    getMandatorState,
    (state: IMandatorState) => state.mandatorOVerviewViewSettingsLoaded
  );
