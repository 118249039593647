import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class SpinnerService {
  private numberOfActivatedSpinner: number = 0;
  private disabled: boolean = false;

  constructor(private spinner: NgxSpinnerService) {}

  /**
   * Register on the spinner. At first register it will be show the spinner
   */
  public regisgter(): void {
    if (this.disabled) {
      return;
    }
    this.numberOfActivatedSpinner += 1;
    this.spinner.show();
  }

  /**
   * When all are deregistered off, the spinner will be hide
   */
  public unregister(): void {
    this.numberOfActivatedSpinner -= 1;
    if (this.numberOfActivatedSpinner < 1) {
      this.spinner.hide();
    }
  }

  public EnableSpinner(): void {
    this.disabled = false;
  }

  public DisableSpinner(): void {
    this.disabled = true;
  }
}
