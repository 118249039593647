<ng-container *ngIf="localizedValues">
  <div [fxLayout]="layoutType" [fxLayoutGap]="layoutGap">
    <mat-form-field appearance="outline" *ngFor="let value of localizedValues">
      <mat-label>{{ title | translate }} ({{ value.Language }})</mat-label>
      <input
        [(ngModel)]="value.Value"
        [required]="required"
        matInput
        [maxlength]="maxLength"
        placeholder="{{ title | translate }} ({{ value.Language }})"
        type="text"
        [attr.name]="'value' + value.Language"
        [name]="'value' + value.Language"
        [readonly]="setReadonly" />
    </mat-form-field>
  </div>
</ng-container>
