/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalizedValueViewModel } from './localizedValueViewModel';


export interface PostPutShadingOptimizerRequest { 
    Id: string;
    IsActive?: boolean;
    ArticleNumber?: string;
    Description?: string;
    LocalizedTitles?: Array<LocalizedValueViewModel>;
    CompatibleInverterTypes?: Array<string>;
    CompatibleInverterManufacturers?: Array<string>;
    /**
     * This shading optimizer is only available for the mandators in this list  Mandators for which the user is not responsible are ignored
     */
    MandatorIds?: Array<number>;
}
