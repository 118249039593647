import cloneDeep from 'lodash/cloneDeep';
import { tassign } from 'tassign';
import { UserViewModel } from '../../../shared/apis/advis';
import * as UserAction from './user.action';
import {
  ActivateUsersAction,
  ActivateUsersFailedAction,
  ActivateUsersSuccessAction,
  AddUserAction,
  AddUserFailedAction,
  AddUserSuccessAction,
  DeactivateUsersAction,
  DeactivateUsersFailedAction,
  DeactivateUsersSuccessAction,
  EditUserAction,
  EditUserFailedAction,
  EditUserSuccessAction,
  GetUserAction,
  GetUserFailedAction,
  GetUserFilesFailedAction,
  GetUserFilesSuccessAction,
  GetUsersAction,
  GetUsersFailedAction,
  GetUsersSuccessAction,
  GetUserSuccessAction,
} from './user.action';
import { initUserState, IUserState } from './user.state';

export function reducer(
  state: IUserState = initUserState(),
  action: UserAction.Actions
): IUserState {
  if (action instanceof GetUsersAction) {
    return tassign(state, {
      usersLoading: true,
      usersLoaded: false,
    } as IUserState);
  }

  if (action instanceof GetUsersSuccessAction) {
    return tassign(state, {
      users: action.payload,
      usersLoading: false,
      usersLoaded: true,
    } as IUserState);
  }

  if (action instanceof GetUsersFailedAction) {
    return tassign(state, {
      users: [],
      usersLoading: false,
      usersLoaded: false,
    } as IUserState);
  }

  if (action instanceof GetUserAction) {
    return tassign(state, {
      usersLoading: true,
      usersLoaded: false,
    } as IUserState);
  }

  if (action instanceof GetUserSuccessAction) {
    return tassign(state, {
      usersLoading: false,
      usersLoaded: true,
      userFilesLoading: true,
      userFilesLoaded: false,
    } as IUserState);
  }

  if (action instanceof GetUserFailedAction) {
    return tassign(state, {
      usersLoading: false,
      usersLoaded: false,
    } as IUserState);
  }

  if (action instanceof GetUserFilesSuccessAction) {
    return tassign(state, {
      userFilesLoading: false,
      userFilesLoaded: true,
      userFiles: action.payload,
    } as IUserState);
  }

  if (action instanceof GetUserFilesFailedAction) {
    return tassign(state, {
      userFilesLoading: false,
      userFilesLoaded: false,
    } as IUserState);
  }

  if (action instanceof AddUserAction) {
    return tassign(state, {
      usersLoading: true,
      usersLoaded: false,
    } as IUserState);
  }

  if (action instanceof AddUserSuccessAction) {
    return tassign(state, {
      usersLoading: false,
      usersLoaded: true,
      users: updateAllUsers(action.payload),
    } as IUserState);
  }

  if (action instanceof AddUserFailedAction) {
    return tassign(state, {
      usersLoading: false,
      usersLoaded: false,
    } as IUserState);
  }

  if (action instanceof EditUserAction) {
    return tassign(state, {
      usersLoading: true,
      usersLoaded: false,
    } as IUserState);
  }

  if (action instanceof EditUserSuccessAction) {
    return tassign(state, {
      usersLoading: false,
      usersLoaded: true,
      users: updateAllUsers(action.payload),
    } as IUserState);
  }

  if (action instanceof EditUserFailedAction) {
    return tassign(state, {
      usersLoading: false,
      usersLoaded: false,
    } as IUserState);
  }

  if (action instanceof ActivateUsersAction) {
    return tassign(state, {
      usersLoading: true,
    } as IUserState);
  }

  if (action instanceof ActivateUsersSuccessAction) {
    return tassign(state, {
      usersLoading: false,
      usersLoaded: true,
      users: updateAllUsers(action.payload),
    } as IUserState);
  }

  if (action instanceof ActivateUsersFailedAction) {
    return tassign(state, {
      usersLoading: false,
      usersLoaded: false,
    } as IUserState);
  }

  if (action instanceof DeactivateUsersAction) {
    return tassign(state, {
      usersLoading: true,
    } as IUserState);
  }

  if (action instanceof DeactivateUsersSuccessAction) {
    return tassign(state, {
      usersLoading: false,
      usersLoaded: true,
      users: updateAllUsers(action.payload),
    } as IUserState);
  }

  if (action instanceof DeactivateUsersFailedAction) {
    return tassign(state, {
      usersLoading: false,
      usersLoaded: false,
    } as IUserState);
  }

  return state;

  function updateAllUsers(user: UserViewModel): UserViewModel[] {
    let allUsers: UserViewModel[] = cloneDeep(state.users);
    return allUsers.map((allUser: UserViewModel) => {
      if (allUser.Id !== user.Id) {
        return allUser;
      }
      return user;
    });
  }
}
