<ng-container>
  <div fxLayout="row" style="margin-bottom: 5px; margin-top: 25px">
    <div fxLayout="column" style="margin-right: 25px">
      <div style="white-space: nowrap">Block {{ index }}</div>

      <!-- BLOCK TYPE SELECT -->
      <mat-select name="language" [(ngModel)]="block.BlockType" placeholder="Block Type">
        <mat-option *ngFor="let blockType of blockTypesSelectable" [value]="blockType">{{
          blockType
        }}</mat-option>
      </mat-select>
    </div>

    <!-- BLOCK CONTENT -->
    <mat-form-field
      appearance="outline"
      fxFlex="100"
      *ngIf="block.BlockType === BlockTypeEnum.Text || block.BlockType === BlockTypeEnum.Link">
      <mat-label>Link Path / Text</mat-label>
      <textarea
        [(ngModel)]="block.Content"
        matInput
        placeholder="Link Path / Text"
        type="text"
        id="HT_MASTERDATA.STRING_GENERATION.BLOCK_CONTENT"
        name="HT_MASTERDATA.STRING_GENERATION.BLOCK_CONTENT"></textarea>
    </mat-form-field>

    <!-- BLOCK ADDITIONAL CONTENT -->
    <mat-form-field
      appearance="outline"
      fxFlex="100"
      *ngIf="block.BlockType === BlockTypeEnum.Link">
      <mat-label>Link Name</mat-label>
      <textarea
        [(ngModel)]="block.AdditionalContent"
        matInput
        placeholder="Link Name"
        type="text"
        id="HT_MASTERDATA.STRING_GENERATION.BLOCK_ADDITIONAL_CONTENT"
        name="HT_MASTERDATA.STRING_GENERATION.BLOCK_ADDITIONAL_CONTENT"></textarea>
    </mat-form-field>

    <!-- BLOCK LINE BREAK -->
    <div fxFlex="100" *ngIf="block.BlockType === BlockTypeEnum.BreakLine">[Line Break]</div>

    <div style="padding-left: 10px; padding-right: 10px; padding-top: 10px">
      <mat-icon class="remove-icon cursor-pointer" (click)="removeLink(index)">
        remove_circle
      </mat-icon>
    </div>
  </div>

  <mat-divider></mat-divider>
</ng-container>
