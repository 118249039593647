import { Component, OnDestroy, OnInit, reflectComponentType, ViewChild } from '@angular/core';
import { OverviewBaseComponent } from '../../../../../shared/components/overview-filter/overviewBaseComponent';
import { Subscription } from 'rxjs';
import { ShadingOptimizerListViewModel } from '../../../../../shared/apis/advis';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IPageSettings } from '../../../../../shared/interfaces/Settings';
import { Store } from '@ngrx/store';
import { IRootState } from '../../../../../root.state';
import { Router } from '@angular/router';
import { OverviewControl } from '../../../../../shared/components/overview-filter/Type';
import { SetPageSettingsAction } from '../../../../../shared/store';
import { getShadingOptimizerFiltered } from '../../../../state/masterdata.selectors';
import {
  DeleteShadingOptimizerAction,
  GetShadingOptimizersAction,
} from '../../../../state/masterdata.action';

@Component({
  selector: 'ac-masterdata-shading-optimizer-overview',
  templateUrl: './masterdata-shading-optimizers-overview.component.html',
  styleUrls: ['./masterdata-shading-optimizers-overview.component.scss'],
})
export class MasterdataShadingOptimizersOverviewComponent
  extends OverviewBaseComponent
  implements OnInit, OnDestroy
{
  private subscription: Subscription = new Subscription();

  shadingOptimizersFiltered: ShadingOptimizerListViewModel[] = [];

  displayedColumns: string[] = ['Id', 'Description', 'IsActive', 'Actions'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageSettings: IPageSettings;

  constructor(store: Store<IRootState>, private router: Router) {
    super(store);
  }

  ngOnInit(): void {
    this.refresh();

    this.overviewFilterInit(
      reflectComponentType(MasterdataShadingOptimizersOverviewComponent).selector,
      [
        {
          key: 'Description',
          translateKey: 'Name',
          control: OverviewControl.STRING,
          isServerSide: true,
        },
        {
          key: 'IsActive',
          translateKey: 'Short name',
          control: OverviewControl.BOOLEAN,
          isServerSide: true,
        },
      ],
      this.subscription
    );

    this.subscription.add(
      this.store
        .select(getShadingOptimizerFiltered)
        .subscribe((shadingOptimizers: ShadingOptimizerListViewModel[]) => {
          this.shadingOptimizersFiltered = shadingOptimizers;
          this.dataSource = new MatTableDataSource(this.shadingOptimizersFiltered);
          this.dataSource.paginator = this.paginator;

          this.dataSource.sortingDataAccessor = (item: any, property: any) => {
            switch (property) {
              default:
                return item[property];
            }
          };
          this.dataSource.sort = this.sort;
          this.clientSideFiltering();
        })
    );
  }

  changePaging(e: PageEvent): void {
    this.pageSettings.pagingSetting.pageIndex = e.pageIndex;
    this.pageSettings.pagingSetting.pageSize = e.pageSize;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  changeSort(e: Sort): void {
    this.pageSettings.sortSetting = e;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  refresh(): void {
    this.store.dispatch(new GetShadingOptimizersAction());
  }

  navigateToEdit(shadingOptimizerId: string): void {
    this.router.navigate(['/pvmasterdata/shading-optimizers/edit', shadingOptimizerId]);
  }

  delete(shadingOptimizerId: string): void {
    this.store.dispatch(new DeleteShadingOptimizerAction(shadingOptimizerId));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  clientSideFiltering(): void {
    this.dataSource.filter = this.pageSettings.overviewFilter.tableGlobalFilter || '';
  }

  serverSideFiltering(): void {
    // empty
  }
}
