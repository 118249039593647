<div fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="25px">
  <div fxFlex.lt-md="100" fxFlex.gt-sm="100" fxLayout="row" fxLayoutAlign="space-between">
    <h4>{{ titleKey | translate }}</h4>

    <div fxLayoutAlign="end center" fxLayoutGap="16px">
      <ac-custom-file-upload
        accept=".png,.jpeg,.jpg,.gif"
        class="logo-upload-button"
        iconName="cloud_upload"
        (select)="onPictureSelect($event)">
      </ac-custom-file-upload>
      <button *ngIf="pictureUrl" mat-raised-button [color]="'accent'" (click)="onPictureDelete()">
        <mat-icon><i class="material-icons">clear</i></mat-icon>
      </button>
    </div>
  </div>
</div>

<div fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="25px">
  <div fxFlex.lt-md="100" fxFlex.gt-sm="100" fxLayout="row">
    <img
      [src]="sanitizer.bypassSecurityTrustUrl(pictureUrl)"
      *ngIf="pictureUrl"
      mat-card-image
      class="img" />
    <span *ngIf="!pictureUrl">{{ 'USERS.NO_LOGO' | translate }}</span>
  </div>
</div>
