import { MasterDataViewModel, UserMasterDataViewModel } from '../apis/advis';
import { IPageSettings } from '../interfaces/Settings';
import { IErrorInfo, IMandatorSelectItem, ITradeTypeSelectItem } from './shared.reducer';

export interface ISharedState {
  navigation_processing: boolean;
  masterData: MasterDataViewModel;
  masterDataLoaded: boolean;
  userMasterData: UserMasterDataViewModel;
  userMasterDataLoaded: boolean;
  errors: IErrorInfo[];
  selectedMandatorsViewSettings: string[];
  selectedTradeTypesViewSettings: string[];
  allMandators: IMandatorSelectItem[];
  allTradeTypes: ITradeTypeSelectItem[];
  selectableMandators: IMandatorSelectItem[];
  selectableTradeTypes: ITradeTypeSelectItem[];
  selectMultipleTradeTypeCodes: boolean;
  tradeTypeCodeFilterActive: boolean;
  pageSettings: IPageSettings[];
}

export function initSharedState(): ISharedState {
  return {
    navigation_processing: false,
    masterData: {},
    masterDataLoaded: false,
    userMasterData: {},
    userMasterDataLoaded: false,
    errors: [],
    selectedMandatorsViewSettings: [],
    selectedTradeTypesViewSettings: [],
    allMandators: [],
    allTradeTypes: [],
    selectableMandators: [],
    selectableTradeTypes: [],
    selectMultipleTradeTypeCodes: true,
    tradeTypeCodeFilterActive: true,
    pageSettings: [],
  };
}
