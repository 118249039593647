import { Action } from '@ngrx/store';

const prefix: string = '[IghTheiaConverter]';

export const UPLOAD: string = `${prefix} Upload`;
export const UPLOAD_SUCCESS: string = `${prefix} Upload success`;
export const UPLOAD_FAILED: string = `${prefix} Upload failed`;

export interface IIghTheiaConverter {
  ighFileDE: Blob;
  ighFileFR: Blob;
  ighFileIT: Blob;
  ikkFile: Blob;
}

export class UploadAction implements Action {
  readonly type: string = UPLOAD;

  constructor(public payload: IIghTheiaConverter) {}
}

export class UploadSuccessAction implements Action {
  readonly type: string = UPLOAD_SUCCESS;

  constructor(public payload: Blob) {}
}

export class UploadFailedAction implements Action {
  readonly type: string = UPLOAD_FAILED;

  constructor(public payload: string) {}
}

export type Actions = UploadAction | UploadSuccessAction | UploadFailedAction;
