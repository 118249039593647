import { Injectable } from '@angular/core';
import { Authenticate, Principal } from '../models/principal.model';
import { Observable } from 'rxjs';
import { IPrincipal } from '../interfaces/principal.interface';
import * as LocalStorageSetting from './local-storage-config';
import { flatMap, map, tap } from 'rxjs/operators';
import { AccountService, LoginViewModel } from '../apis/advis';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private accountApi: AccountService) {
    // empty
  }

  isLoggedIn(): boolean {
    const principalSession: string = localStorage.getItem(LocalStorageSetting.MAIN_KEY_AUTH);
    if (principalSession) {
      return true;
    }
    return false;
  }

  public login(authenticate: Authenticate): Observable<IPrincipal> {
    const loginViewModel: LoginViewModel = {
      UserName: authenticate.username,
      Password: authenticate.password,
      Remember: true,
    };

    return this.accountApi.accountLogin(loginViewModel).pipe(
      flatMap(() => {
        return this.accountApi.accountPutLanguage(authenticate.language);
      }),
      flatMap(() => {
        return this.getPrincipal().pipe(
          tap((principal: IPrincipal) => {
            localStorage.setItem(LocalStorageSetting.MAIN_KEY_AUTH, JSON.stringify(principal));
          })
        );
      })
    );
  }

  public changeLanguage(language: string): Observable<IPrincipal> {
    return this.accountApi.accountPutLanguage(language);
  }

  public logout(): Observable<{}> {
    localStorage.removeItem(LocalStorageSetting.MAIN_KEY_AUTH);
    return this.accountApi.accountLogout();
  }

  // CAUTION: This method calls the API - don't use it except you know what you do.
  public getPrincipal(): Observable<IPrincipal> {
    return this.accountApi.accountGetUserInfo().pipe(
      map((data: any) => {
        return new Principal(data.User, data.UserId, data.Role, data.Mandant, data.MandantId);
      })
    );
  }

  // CAUTION: This method reads the Principal from the local storage- don't use it except you know what you do.
  public getPrincipalSession(): IPrincipal | undefined {
    const principalObj: any = JSON.parse(localStorage.getItem(LocalStorageSetting.MAIN_KEY_AUTH));
    if (principalObj) {
      return new Principal(
        principalObj.user,
        principalObj.userId,
        principalObj.role,
        principalObj.mandant,
        principalObj.mandantId
      );
    }
    return undefined;
  }
}
