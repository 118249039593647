/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DocumentTemplatesRequest { 
    Title?: string;
    Language?: DocumentTemplatesRequest.LanguageEnum;
    /**
     * >only retrieve entries with a matching mandator id (you can provide multiple mandants by separating them with a ','
     */
    MandatorIds?: string;
    Type?: DocumentTemplatesRequest.TypeEnum;
    /**
     * only retrieve entries with a matching trade type code (you can provide multiple codes by separating them with a ',')
     */
    TradeTypeCodes?: string;
    ShowForNboPhase?: boolean;
    ShowForBoPhases?: boolean;
    ShowForProjectPhases?: boolean;
    /**
     * the index of the current page(starts at 1)
     */
    PageIndex?: number;
    /**
     * the amount of entries per page (15 per default)
     */
    PageSize?: number;
    SortField?: DocumentTemplatesRequest.SortFieldEnum;
    SortOrder?: DocumentTemplatesRequest.SortOrderEnum;
}
export namespace DocumentTemplatesRequest {
    export type LanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const LanguageEnum = {
        DE: 'DE' as LanguageEnum,
        FR: 'FR' as LanguageEnum,
        IT: 'IT' as LanguageEnum,
        EN: 'EN' as LanguageEnum
    };
    export type TypeEnum = 'DocumentTemplate' | 'Anlage' | 'Report' | 'Invoice' | 'DefaultBo' | 'DefaultNbo' | 'DefaultProject' | 'AddressAddedTemplate';
    export const TypeEnum = {
        DocumentTemplate: 'DocumentTemplate' as TypeEnum,
        Anlage: 'Anlage' as TypeEnum,
        Report: 'Report' as TypeEnum,
        Invoice: 'Invoice' as TypeEnum,
        DefaultBo: 'DefaultBo' as TypeEnum,
        DefaultNbo: 'DefaultNbo' as TypeEnum,
        DefaultProject: 'DefaultProject' as TypeEnum,
        AddressAddedTemplate: 'AddressAddedTemplate' as TypeEnum
    };
    export type SortFieldEnum = 'None' | 'Id' | 'Title' | 'Language' | 'Type' | 'TradeTypeCode' | 'ShowForNboPhase' | 'ShowForBoPhases' | 'ShowForProjectPhases';
    export const SortFieldEnum = {
        None: 'None' as SortFieldEnum,
        Id: 'Id' as SortFieldEnum,
        Title: 'Title' as SortFieldEnum,
        Language: 'Language' as SortFieldEnum,
        Type: 'Type' as SortFieldEnum,
        TradeTypeCode: 'TradeTypeCode' as SortFieldEnum,
        ShowForNboPhase: 'ShowForNboPhase' as SortFieldEnum,
        ShowForBoPhases: 'ShowForBoPhases' as SortFieldEnum,
        ShowForProjectPhases: 'ShowForProjectPhases' as SortFieldEnum
    };
    export type SortOrderEnum = 'None' | 'Asc' | 'Desc';
    export const SortOrderEnum = {
        None: 'None' as SortOrderEnum,
        Asc: 'Asc' as SortOrderEnum,
        Desc: 'Desc' as SortOrderEnum
    };
}
