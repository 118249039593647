import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { AppInitializerGuard } from './shared/guards/app-initializer.guard';
import { HomeComponent } from './home/home.component';
import { RoleGuard } from './shared/guards/role.guard';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard, AppInitializerGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      },
      {
        path: 'pvmasterdata',
        loadChildren: () =>
          import('./pv-masterdata/pv-masterdata.module').then(m => m.PvMasterdataModule),
        canActivate: [RoleGuard],
        data: ['CanManagePvMasterData'],
      },
      {
        path: 'ht-masterdata',
        loadChildren: () =>
          import('./ht-masterdata/ht-masterdata.module').then(m => m.HtMasterdataModule),
        canActivate: [RoleGuard],
        data: ['CanManageHtMasterData'],
      },
      {
        path: 'em-masterdata',
        loadChildren: () =>
          import('./em-masterdata/em-masterdata.module').then(m => m.EmMasterdataModule),
        canActivate: [RoleGuard],
        data: ['CanManageEmMasterData'],
      },
      {
        path: 'mandator',
        loadChildren: () => import('./mandator/mandator.module').then(m => m.MandatorModule),
      },
      {
        path: 'document',
        loadChildren: () => import('./document/document.module').then(m => m.DocumentModule),
        canActivate: [RoleGuard],
        data: ['CanManageDocumentTemplates'],
      },
      {
        path: 'process-configuration',
        loadChildren: () =>
          import('./process-configuration/process-configuration.module').then(
            m => m.ProcessConfigurationModule
          ),
        canActivate: [RoleGuard],
        data: ['CanManageProcess'],
      },
      {
        path: 'project-checklist',
        loadChildren: () =>
          import('./project-checklist/project-checklist.module').then(
            m => m.ProjectChecklistModule
          ),
        canActivate: [RoleGuard],
        data: ['CanManageProjectChecklistTemplates'],
      },
      {
        path: 'administration',
        loadChildren: () =>
          import('./administration/administration.module').then(m => m.AdministrationModule),
      },
      {
        path: 'emailtemplates',
        loadChildren: () =>
          import('./emailtemplates/emailtemplates.module').then(m => m.EmailtemplatesModule),
        canActivate: [RoleGuard],
        data: ['CanManageEmailTemplates'],
      },
      {
        path: 'excelport',
        loadChildren: () => import('./excelport/excelport.module').then(m => m.ExcelportModule),
        canActivate: [RoleGuard],
        data: ['CanExcelPort'],
      },
      {
        path: 'project-reporting',
        loadChildren: () =>
          import('./project-reporting/project-reporting.module').then(
            m => m.ProjectReportingModule
          ),
        canActivate: [RoleGuard],
        data: ['CanImportProjectReportingData'],
      },
      {
        path: 'regional-information',
        loadChildren: () =>
          import('./regional-information/regional-information.module').then(
            m => m.RegionalInformationModule
          ),
        canActivate: [RoleGuard],
        data: ['CanManageRegionalInformation'],
      },
      {
        path: 'copy',
        loadChildren: () => import('./copy/copy.module').then(m => m.CopyModule),
        canActivate: [RoleGuard],
        data: ['CanManageProductlines'],
      },
      {
        path: 'igh-theia-converter',
        loadChildren: () =>
          import('./ighTheiaConverter/ighTheiaConverter.module').then(
            m => m.IghTheiaConverterModule
          ),
        canActivate: [RoleGuard],
        data: ['CanExcelPort'],
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
