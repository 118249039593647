/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GenericRulePropertyValueViewModel } from './genericRulePropertyValueViewModel';


export interface GenericRulePropertyViewModel { 
    /**
     * The name of the property
     */
    PropertyName?: string;
    Type?: GenericRulePropertyViewModel.TypeEnum;
    /**
     * The possible values of the property if it is a multiSelect or select property, else it is empty
     */
    Values?: Array<GenericRulePropertyValueViewModel>;
}
export namespace GenericRulePropertyViewModel {
    export type TypeEnum = 'Integer' | 'Decimal' | 'String' | 'Boolean' | 'DateTime' | 'Select' | 'MultiSelect';
    export const TypeEnum = {
        Integer: 'Integer' as TypeEnum,
        Decimal: 'Decimal' as TypeEnum,
        String: 'String' as TypeEnum,
        Boolean: 'Boolean' as TypeEnum,
        DateTime: 'DateTime' as TypeEnum,
        Select: 'Select' as TypeEnum,
        MultiSelect: 'MultiSelect' as TypeEnum
    };
}
